import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';

class AddonsCloud extends Component {
    
    render() {
        const addons = this.props.addons

        if(!addons) {
            return (
                <div className="fullwidth dark">
                    <div className="row">
                        <div className="twelve columns">
                            <h3>No Available Addons</h3>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="twelve columns">
                            <p>There are no add-ons available for this type of products at the moment.</p>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="fullwidth dark">
                <div className="row">
                    <div className="twelve columns">
                        <h3>Hardware</h3>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="four columns">
                    <p>Additional RAM, Storage and CPU clock speed are available as an add-on at extra cost, you can add it while placing an order or anytime later as you please.</p>
                    </div>
                    <div className="eight columns addons">
                        <div className="row">        
                            {addons.filter((a) => a.key === 'cpu-limit' || a.key === 'ram' || a.key === 'disk-space').map((item,i) =>
                            <div className="twelve columns" key={i}>
                            <div className="name six columns">Additional {item.items.name} ({item.items.units_each})</div>
                            <div className="price six columns">
                                <span className='base' style={{cursor:'default'}} data-tip data-for={item.items.id.toString()}>{item.items.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                <ReactTooltip id={item.items.id.toString()} effect='solid'>
                                    <span>{(item.items.price * this.props.usdRate).toFixed(2)} USD</span>
                                    <span>{(item.items.price * this.props.eurRate).toFixed(2)} EUR</span>
                                </ReactTooltip>
                            </div>
                            </div> 
                            )}                                          
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <h3>Bandwidth & IP</h3>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="four columns">
                    <p>Should you need to increase bandwidth limit or order a few more IP addresses, please update your order or submit a new query to the customer support desk. Kindly be advised that you'll be asked to provide a reason for obtaining extra addresses when ordering more than 3 IPs.</p>
                    </div>
                    <div className="eight columns addons">
                        <div className="row">
                            {addons.filter((a) => a.key === 'ipv4-addresses' || a.key === 'bandwidth').map((item,i) =>
                            <div className="twelve columns" key={i}>
                            <div className="name six columns">Additional {item.items.name} ({item.items.units_each})</div>
                            <div className="price six columns">
                                <span className='base' style={{cursor:'default'}} data-tip data-for={item.items.id.toString()}>{item.items.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                <ReactTooltip id={item.items.id.toString()} effect='solid'>
                                    <span>{(item.items.price * this.props.usdRate).toFixed(2)} USD</span>
                                    <span>{(item.items.price * this.props.eurRate).toFixed(2)} EUR</span>
                                </ReactTooltip>
                            </div>
                            </div> 
                            )} 
                        </div>
                    </div>
                </div>              
            </div>
        )
    }}

    export default AddonsCloud;
