import React, {Component} from 'react';
import { NavLink } from "react-router-dom";
import SiteSpecific from './siteSpecificQAs'

class Category extends Component {

    goBack() {
        window.history.back()
    }
       
    render() 
    {

        const faq = this.props.faq,
        categories = this.props.faq.map(item => item.group_name).filter(item => item !== 'revenue' && item !== 'home').sort(),
        selectedCategories = (new URL(document.location)).searchParams.get('category') !== null && categories.includes((new URL(document.location)).searchParams.get('category'))
        ? new URL(document.location).searchParams.get('category').split()
        : categories
        
        if (faq.length > 0)
            {
            return (
            <div className="fullwidth light">
		        <div className="row knowledgebase">
			        <div className="ten columns">
                {categories === selectedCategories && <SiteSpecific/>}
                {selectedCategories.map((category,i) => 
                <React.Fragment key={i}>
                <h2 className='knowledgebase'>{faq.filter((a) => a.group_name === category)[0].group}</h2>
                <hr className="colored"/>
			    <div className="panel light">
                {faq.filter((a) => a.group_name === category)[0].items.map((questions,i) =>
                    <div className="toggle-wrap" key={i}>
                        <h4 className="trigger" onClick={this.toggleAnimation}>{questions.q}</h4>
                        <div className="toggle-container">
                            <p>{questions.a}</p>
                        </div>
			        </div>               
                )}
                {category === 'dedicated' && 
                    <React.Fragment>
                        <div className="toggle-wrap">
                            <h4 className="trigger" onClick={this.toggleAnimation}>Do you provide KVM/IPMI access to a server?</h4>
                            <div className="toggle-container">
                                <p>Yes, KVM/IPMI is provided free of charge with every dedicated server.</p>
                            </div>
			            </div>
                    </React.Fragment>}
                 {category === 'payments' && 
                    <React.Fragment>
                        <div className="toggle-wrap">
                            <h4 className="trigger" onClick={this.toggleAnimation}>Do you accept cryptocurrencies such as Bitcoin?</h4>
                            <div className="toggle-container">
                                <p>Yes, we accept Bitcoin, Bitcoin Cash, Ethereum, Litecoin and Nextcoin payments option for all products and services that we offer. Here's the list of <NavLink to="/en/accepted-payment-methods">currently accepted cryptocurrencies</NavLink>.</p>
                            </div>
			            </div>
                        <div className="toggle-wrap">
                            <h4 className="trigger" onClick={this.toggleAnimation}>What are the shortest and longest billing cycles available?</h4>
                            <div className="toggle-container">
                                <p>Swiss Made Host operates on a prepaid basis with 1 month being a standard billing cycle and shortest billing period available. Should you need to choose a longer billing cycle, an order can be placed for up to a 12-month period. Kindly note, that we offer an additional discount of up to 22% for most products and services if you opt in for longer billing cycles.</p>
				            </div>
			            </div>
                    </React.Fragment>}
                    {categories !== selectedCategories && <React.Fragment><button style={{marginRight: '10px'}} className='button radius left' onClick={() => this.goBack()}>Go Back</button> <NavLink className={'button radius left'} to="/en/knowledgebase">View all questions</NavLink></React.Fragment>} 
                    </div>
                </React.Fragment>
                    )}
                </div>
            </div>
            <div className="row" style={{marginTop: '50px'}}>
			<div className="ten columns">
				<div className="panel nomargin bordered">
					<h3>Didn't find answer to your question?</h3>
					<hr className="black" />
					<h5 className="nobold">If you weren't able to find the answer to your question, please send us a message and we will get back to you as soon as possible.</h5>
					<NavLink className="button radius large" to="/en/contact-us">Contact Us</NavLink>
				</div>
			</div>
		</div>
        </div>
        )}

        else {
            return null
        }
    }
    toggleAnimation = (e) => {
    e.target.parentNode.getElementsByTagName('div')[0].classList.toggle('expanded')
      }
}

export default Category;
