import React from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';

function Analytics() {
    const history = useHistory();
	const TrackPage = () => {
		const { trackPageView } = useMatomo()
        React.useEffect(() => {
            trackPageView()
            
         },[trackPageView])
		React.useEffect(() => {
            return history.listen((location) => { trackPageView()
            }) 
         },[trackPageView])
	   
    }    

	TrackPage();

    return null
  }
  
  export default Analytics;
  