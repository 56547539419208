    import React from 'react';
    import { axiosinstance } from '../../components/axios';
    import BlogPreLoader from './blogPreloader';
    import BlogError from './blogError';
    import PropTypes from "prop-types";
    import { NavLink, withRouter } from "react-router-dom";
    import Helmet from 'react-helmet';
    import { LazyLoadImage } from 'react-lazy-load-image-component';
    import { FAQSchema, RichData } from './schemaJson';
    import {
    EmailShareButton,
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    } from "react-share";
    import {
    EmailIcon,
    FacebookIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    WhatsappIcon
    } from "react-share";

    class Articles extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            error: false,
            loading: true,
            pageLimit: 10
        }
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.location !== this.props.location) {
            this.load();
        }
    }

    load = () => {
        if (this.props.location.pathname === '/en/blog') {
            if (RegExp('\\bpage=\\b').test(this.props.location.hash) === true) {
                this.setState({
                    singleArticle: false,
                    loading: true,
                    error: false,
                    requestURL: process.env.REACT_APP_BACKEND_URL + "/blog.en.json?page=" + this.props.location.hash.replace("#page=", "") + "&limit=" + this.state.pageLimit
                })
                this.getData()
            } else {
                if (this.props.location.search.startsWith('?tag') || this.props.location.search.startsWith('?author') || this.props.location.search.startsWith('?category')) {
                    this.setState({
                        singleArticle: false,
                        loading: true,
                        error: false,
                        requestURL: process.env.REACT_APP_BACKEND_URL + "/blog.en.json" + this.props.location.search
                    })
                    this.getData()
                }

                else {
                    this.setState({
                        singleArticle: false,
                        loading: true,
                        error: false,
                        requestURL: process.env.REACT_APP_BACKEND_URL + "/blog.en.json?page=1&limit=" + this.state.pageLimit
                    })
                    this.getData()
                }
            }
        }

        else {
            if (this.props.location.pathname === '/en/blog/') { this.props.history.push('/en/blog') }
            this.setState({
                singleArticle: true,
                loading: true,
                error: false,
                requestURL: process.env.REACT_APP_BACKEND_URL + "/blog/" + this.props.location.pathname.split("/").pop() + ".en.json"
            })
            this.getData()
        }
    }

    getData() {
        this.setState({
            loading: true
        }, () => {
            axiosinstance.get(this.state.requestURL)
                .then(res => {
                    if (res.status === 200 && res.data.result === true) {
                        this.setState({
                            loading: false,
                            blogPageData: res.data.data
                        })
                        this.props.pageLimit(this.state.pageLimit);
                        this.props.blogPostsTotal(res.data.data.total);
                    }

                    else {
                        this.setState({
                            error: true,
                            loading: false
                        });
                    }

                })
                .catch(err => {
                    this.setState({
                        error: true,
                        loading: false
                    })
                })

        })

    }

    render() {



        if (this.state.loading) {
            return (
                <BlogPreLoader />
            )
        }
        if (this.state.error) {
            return (
                <BlogError />
            )
        } else {

if (this.state.singleArticle === false) {
if (this.state.blogPageData.total === 0)
return (
    <BlogError />
)
else {

return (
    <React.Fragment>
        <Helmet>
            <title>{
                this.props.location.search.startsWith('?tag') || this.props.location.search.startsWith('?author') || this.props.location.search.startsWith('?category')
                    ? this.props.location.search.startsWith('?tag')
                        ? 'Blog / Tags / ' + decodeURI(this.props.location.search.replace("?tag=", ""))
                        : this.props.location.search.startsWith('?author')
                            ? 'Blog / Authors / ' + decodeURI(this.props.location.search.replace("?author=", ""))
                            : this.props.location.search.startsWith('?category')
                                ? 'Blog / Categories / ' + decodeURI(this.props.location.search.replace("?category=", ""))
                                : ""
                    : "Blog"} | Swiss Made Host</title>
            <meta name="description" content="Read about the products and services that we offer, latest trends and important news of our company." />
        </Helmet>
        {this.state.blogPageData.posts.map((item, i) =>
            <React.Fragment key={i}>
                <div className="row margintop">
                {i === 0
                    ? this.props.location.search.startsWith('?tag') || this.props.location.search.startsWith('?author') || this.props.location.search.startsWith('?category')
                        ? this.props.location.search.startsWith('?tag')
                            ? <h4 className="searchTitle"><NavLink to='/en/blog'>Blog</NavLink> <small>/</small> Tags <small>/</small> {decodeURI(this.props.location.search.replace("?tag=", ""))}</h4>
                            : this.props.location.search.startsWith('?author')
                                ? <h4 className="searchTitle"><NavLink to='/en/blog'>Blog</NavLink> <small>/</small> Authors <small>/</small> {decodeURI(this.props.location.search.replace("?author=", ""))}</h4>
                                : this.props.location.search.startsWith('?category')
                                    ? <h4 className="searchTitle"><NavLink to='/en/blog'>Blog</NavLink> <small>/</small> Categories <small>/</small> {decodeURI(this.props.location.search.replace("?category=", ""))}</h4>
                                    : ""
                        : RegExp('\\bpage=\\b').test(this.props.location.hash)
                            ? <h4 className="searchTitle"><NavLink to='/en/blog'>Blog</NavLink> <small>/</small> Page {Number((this.props.location.hash).replace("#page=", ""))} of {Math.ceil(this.state.blogPageData.total / this.state.pageLimit)}</h4>
                            : <h4 className="searchTitle"><NavLink to='/en/blog'>Blog</NavLink> <small>/</small> Articles</h4>
                    : ""

                }
                    <div className="twelve columns">
                        <div className="article bordered">
                            <NavLink to={'/en/blog/' + encodeURIComponent(item.slug)}>
                                <LazyLoadImage className="blogimage" alt={item.title} height={600} src={item.image.length > 0 ? item.image : '/images/blog/' + item.id + '.jpg'} width={900} onError={(event) => event.target.setAttribute("style", "display: none")} />
                                <div className="date text-center">{new Date(item.date).toLocaleString("en-US", {year: "numeric", month: "long", day: "numeric"})}</div>
                            </NavLink>
                            <h2 className='articleTitle'><NavLink to={'/en/blog/' + encodeURIComponent(item.slug)}>{item.title}</NavLink></h2>
                            <ul className="bloginfo">
                                <li>By <NavLink className="blogLink" to={'/en/blog?author=' + encodeURIComponent(item.author)}>{item.author}</NavLink></li>
                                <li>In <NavLink className="blogLink" to={'/en/blog?category=' + encodeURIComponent(item.category)}>{item.category}</NavLink></li>
                                <li>Tags: {item.tags.map((item, i) => <span className='tag' key={i}><NavLink className="blogLink" to={'/en/blog?tag=' + encodeURIComponent(item)}>{item}</NavLink></span>)}</li>
                            </ul>

                            <div className="blog-article-content" dangerouslySetInnerHTML={{ __html: item.content.split('<break>')[0] }} />
                            <NavLink className="button radius" to={'/en/blog/' + encodeURIComponent(item.slug)}>Read More</NavLink>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )}
    </React.Fragment>
)
}
}


else {
    const faq = this.state.blogPageData?.post.faq
    const keywords = this.state.blogPageData.post.tags.join(', ')
    const description = this.state.blogPageData.post.description ?  this.state.blogPageData.post.description :  this.state.blogPageData.post.title
    const faqSchema = faq && FAQSchema(this.state.blogPageData)
    const richData = this.state.blogPageData && RichData(this.state.blogPageData, description, keywords)

    return (
    
        <React.Fragment>
        <Helmet>
          <title>{this.state.blogPageData.post.title} | Swiss Made Host</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <script type="application/ld+json">{JSON.stringify(richData)}</script>
          {faq && <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>}
        </Helmet>
    <div className="row margintop">
    <h4 className="searchTitle"><NavLink to='/en/blog'>Blog</NavLink> <small>/</small> Articles <small>/</small> {this.state.blogPageData.post.title}</h4>
        <div className="twelve columns">
            <div className="article">
                <LazyLoadImage className="blogimage" alt={this.state.blogPageData.post.title} height={600} src={this.state.blogPageData.post.image.length > 0 ? this.state.blogPageData.post.image : '/images/blog/' + this.state.blogPageData.post.id + '.jpg'} width={900} onError={(event) => event.target.setAttribute("style", "display: none")} />

                <div className="date text-center">{new Date(this.state.blogPageData.post.date).toLocaleString("en-US", {year: "numeric", month: "long", day: "numeric"})}</div>
                <h1>{this.state.blogPageData.post.title}</h1>
                <ul className="bloginfo">
                    <li>By <NavLink className="blogLink" to={'/en/blog?author=' + encodeURIComponent(this.state.blogPageData.post.author)}>{this.state.blogPageData.post.author}</NavLink></li>
                    <li>In <NavLink className="blogLink" to={'/en/blog?category=' + encodeURIComponent(this.state.blogPageData.post.category)}>{this.state.blogPageData.post.category}</NavLink></li>
                    <li>Tags: {this.state.blogPageData.post.tags.map((item, i) => <span className='tag' key={i}><NavLink className="blogLink" to={'/en/blog?tag=' + encodeURIComponent(item)}>{item}</NavLink></span>)}</li>
                </ul>

                <div className="blog-article-content" dangerouslySetInnerHTML={{ __html: this.state.blogPageData.post.content }} />

                <span className='shareButtons'><EmailShareButton url={"https://swissmade.host/en/blog/" + this.state.blogPageData.post.slug}><EmailIcon /></EmailShareButton>
                    <FacebookShareButton url={"https://swissmade.host/en/blog/" + this.state.blogPageData.post.slug}><FacebookIcon /></FacebookShareButton>
                    <TwitterShareButton url={"https://swissmade.host/en/blog/" + this.state.blogPageData.post.slug}><TwitterIcon /></TwitterShareButton>
                    <RedditShareButton url={"https://swissmade.host/en/blog/" + this.state.blogPageData.post.slug}><RedditIcon /></RedditShareButton>
                    <TumblrShareButton url={"https://swissmade.host/en/blog/" + this.state.blogPageData.post.slug}><TumblrIcon /></TumblrShareButton>
                    <TelegramShareButton url={"https://swissmade.host/en/blog/" + this.state.blogPageData.post.slug}><TelegramIcon /></TelegramShareButton>
                    <WhatsappShareButton url={"https://swissmade.host/en/blog/" + this.state.blogPageData.post.slug}><WhatsappIcon /></WhatsappShareButton>
                </span>
                <NavLink className="button radius" to="/en/blog"> Back to Blog</NavLink>

            </div>
        </div>
    </div>

                    </React.Fragment>
                )

            }

        }
    }
    }

    export default withRouter(Articles)
