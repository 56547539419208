import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';
import { NavLink } from 'react-router-dom';

const owloptions = {
	loop: true,
	margin: 10,
	nav: false,
	autoplay: true,
	autoplayTimeout: 5000,
	items: 3,
	slideBy: 1,
	responsive: {
		0: {
			items: 1,
			slideBy: 1,
			autoplayTimeout: 5000
		},
		900: {
			items: 3
		},
		1000: {
			items: 3
		}
	}
};


class FeatureSlider extends React.Component {

	render() {

		const protocol = this.props.protocol

		return (

			<div className="fullwidth dark">
				<div className="row">
					<div className="twelve columns">
						<OwlCarousel2 options={owloptions}>
							<div className="item">
								<h5>Dedicated IP</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa-solid fa-laptop fa-5x" aria-hidden="true"></i></div>
								<p>With every VPN-app purchase, you will be allocated a unique IP address that geolocates to Switzerland and is routed through our <NavLink to="/en/data-center-switzerland">data center in Zurich</NavLink>. This IP will be exclusively assigned to your VPN server, meaning that it won't be shared with anybody else, and will remain active during the duration of your subscription.</p>
							</div>
							<div className="item">
								<h5>No Logs Policy</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa-solid fa-eye-slash fa-5x"></i></div>
								<p>We do not keep any records of data that could potentially compromise or put our users at risk while they are connected to the VPN server. This applies to both meta and traffic data including connection, usage, IP address and activity logs. No-log policy maintains user privacy and protects against the potential misuse or unauthorized access to sensitive data.</p>
							</div>
							<div className="item">
								<h5>Fast Network</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa-solid fa-bolt fa-5x" aria-hidden="true"></i></div>
								<p>Swiss Made Host operates a high-capacity, multi-homed network with robust DDoS protection. VPN servers are connected to the Internet through a 10 Gbps port to ensure that every our customer can stream content, access websites, play online games without experiencing slow loading times, buffering or high latency issues.</p>
							</div>
							{(protocol === 'all' || protocol === 'openvpn' || protocol === 'wireguard') &&
								<>
									<div className="item">
										<h5>Strong Encryption</h5>
										<hr className="grey" />
										<div className="left paddingright"><i className="fa-solid fa-lock fa-5x"></i></div>
										<p>Relying on the proven AES-256 cipher, OpenVPN ensures what's often termed 'military-grade' encryption. WireGuard keeps up by incorporating modern cryptographic algorithms like ChaCha20 for encryption and Poly1305 for authentication. Surf with confidence, knowing that your data is just a set of uninterpretable ones and zeros to any third party.</p>
									</div>
									<div className="item">
										<h5>pfSense® Control Panel</h5>
										<hr className="grey" />
										<div className="left paddingright"><i className="fa-solid fa-cogs fa-5x" aria-hidden="true"></i></div>
										<p>With the Premium VPN plan, you get access to the pfSense® Control Panel where you can adjust connection settings including port and protocol, prioritize network traffic with QoS, enhance security with IDP and have full control over the server via easy to use web interface with many options and settings.</p>
									</div>
								</>
							}
							{(protocol === 'all' || protocol === 'shadowsocks') &&
								<div className="item">
									<h5>Traffic Obfuscation</h5>
									<hr className="grey" />
									<div className="left paddingright"><i className="fas fa-search fa-5x" aria-hidden="true"></i></div>
									<p>The obfuscation technique of the Shadowsocks protocol makes it difficult for internet censorship mechanisms to identify it, given that its traffic closely mimics standard HTTP or HTTPS traffic. Its proficiency in bypassing internet restrictions makes Shadowsocks an ideal choice for maintaining online freedom and privacy.</p>
								</div>
							}
							<div className="item">
								<h5>Crypto Accepted</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa-brands fa-bitcoin fa-5x" aria-hidden="true"></i></div>
								<p>For added security and privacy you can pay for your personal VPN server with every major cryptocurrency including Bitcoin, Litecoin, Ethereum, Tether USDT and other cryptocurrencies, stablecoins, and digital payment processors. We encourage you to pay for a VPN securely with crypto if you wish to keep your profile as low as possible.</p>
							</div>
							<div className="item">
								<h5>Direct Connection to SwissIX</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa fa-upload fa-5x" aria-hidden="true"></i></div>
								<p>Our data center in Zurich has direct connection to <a title="SwissIX Swiss Internet Exchange" href="https://www.swissix.ch/" target="_blank" rel="noopener noreferrer">SwissIX</a> Internet Exchange, which is the largest Internet Exchange Point (IXP) in Switzerland in terms of peering participants. Direct IXP connection lowers latency by improving routing efficiency and provides an extra layer of networking fault-tolerance.</p>
							</div>
							<div className="item">
								<h5>24/7 Live Support</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
								<p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function (event) { event.preventDefault(); toggleWidget() }} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
							</div>
							<div className="item">
								<h5>Swiss Approach</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa fa-plus-square fa-5x colored" aria-hidden="true"></i></div>
								<p>At SwissMade.host, we are proud to be a Zurich-based web hosting company that shares the most distinct features that Switzerland is renowned for. We guarantee that your orders and requests will be processed in accordance with our company's strict privacy, data protection, customer orientation and quality assurance policies.</p>
							</div>
						</OwlCarousel2>
					</div>
				</div>
			</div>

		)
	}
}

export default FeatureSlider