import React from 'react';
import Helmet from "react-helmet";
import DynamicContent from './dynamicContent';

class KnowledgeBase extends React.Component {
render() {

	return (
	<React.Fragment>
		<Helmet>
			<title>Knowledge Base | Swiss Made Host</title>
			<meta name="description" content="Answers to the frequently asked questions about products and services provided by Swiss Made Host." />
			<meta name="robots" content="noindex"/>
		</Helmet>
		<div className="fullwidth pic knowledgebase">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
					<h1 className="title">Knowledge Base<span>SwissMade.Host</span></h1>
					<h2>Answers to the most frequently asked questions</h2>
				</div>
			</div>
		</div>
	</div>
	<DynamicContent />
	</React.Fragment>
	)}}

export default KnowledgeBase;