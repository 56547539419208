import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutDedicated extends React.Component {

	render() {

		return (
			<div className="fullwidth dark">
				<div className="row">
					<div className="four columns">
						<h2>What is a Dedicated Server?</h2>
						<hr className="grey" />
						<p>A dedicated server is a standalone machine solely allocated to one client, providing dedicated resources and full control for hosting applications, websites, or services. Dedicated hosting is perfect for high-traffic websites, demanding applications, gaming servers, and extensive e-commerce platforms, ensuring exclusive resources, peak performance and high customizability.</p>
					</div>
					<div className="four columns">
						<h2>Swiss Made</h2>
						<hr className="grey" />
						<p>Dedicated hosting in Switzerland offers such advantages as robust <NavLink to="/en/data-protection-switzerland">data privacy</NavLink> regulations, stable political and economic environment, and excellent <NavLink to="/en/technology">network infrastructure</NavLink>, thanks to Switzerland's central location in Europe, that ensures low-latency connections. These factors contribute to reliable, secure, and high-performance hosting services, making Switzerland an attractive location for dedicated hosting.</p>
					</div>
					<div className="four columns">
						<h2>60+ Server Configurations</h2>
						<hr className="grey" />
						<p>Offering over 60 dedicated server options, we cater to diverse needs with configurations ranging from entry-level to high-end, featuring ample storage, RAM, and onboard GPU capabilities. Swiss Made Host's commitment to providing versatile and scalable hosting solutions ensures customers find the ideal hosting environment for their requirements, be it for personal projects or business applications.</p>
					</div>
				</div>
			</div>
		)
	}
}

export default AboutDedicated