import React from 'react';
import DynamicContentBlog from './dynamicContent';

class BlogHome extends React.Component {

	render() {

		return (
			<React.Fragment>
				<DynamicContentBlog location={this.props.location} />
			</React.Fragment>
		)

	}
}

export default BlogHome;