
import React,  { useEffect, useState } from 'react';
import '../../css/notifications.css';
import DynamicContent from './dynamicContent';

function DiscountCounter(cms) {

        const expiryDate = "2023-01-31T23:59:59+01:00",
		difference = +new Date(expiryDate) - +new Date(),
		couponCode = 'SWISSCMS',
		discountPercent = 50,
		firstMonth = true

		const calculateTimeLeft = () => {
			let timeLeft = {};
		
			  	timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60)
				  }
			
			return timeLeft;
		  };

		  
		  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
		
		  useEffect(() => {
			difference > 0 &&
			setTimeout(() => {
			  setTimeLeft(calculateTimeLeft());
			}, 1000);
		  });
		  const timerComponents = [];
		
		  Object.keys(timeLeft).forEach((interval,i) => {
			if (!timeLeft[interval]) {
			  return;
			}
			timerComponents.push(
			  <span key={i}>
				{timeLeft[interval]} {interval}{i === 2 ? "" : " "}
			  </span>
			)
		  })
		return (
		<DynamicContent 
		cms={cms.cms} 
		couponCode={couponCode} 
		discountPercent={discountPercent} 
		firstMonth={firstMonth}
		expiryDate={expiryDate}
		discountActive={timerComponents.length > 0 && Math.sign(timeLeft.minutes) !== -1}
		/>
)
}


export default DiscountCounter;
