import React, {Component} from 'react';

class OStemplates extends Component {


    render() {

        const os_templates = this.props.os_templates;

        if (!os_templates)
        {return (
            <div className="fullwidth dark">
                <div className="row">
                    <div className="twelve columns">
                        <h3>Operating Systems</h3>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <p>There's nothing on this list at the moment.</p>
                    </div>
                </div>
            </div>
            )}
        else {
            return(
            <div className="fullwidth dark">
                {(this.props.type === 'all' || this.props.type === 'linux' || this.props.os === 'linux') &&
                <React.Fragment>
                <div className="row">
                    <div className="twelve columns">
                        <h3>Linux / Unix / BSD OS</h3>
                        <hr/>
                    </div>
                </div>  
                        <div className="row">
                            <div className="four columns">
                            <p>A list of Linux, Unix and BSD distributions that can be installed on a VPS server. Please note the minimal disk size requirement for each distro.</p>
                            </div>
                            <div className="eight columns addons">
                            <div className="row">
                                {os_templates.filter((a) => a.windows === false).sort((a, b) => a.price - b.price).map((item,i) =>
                                    <div className="twelve columns" key={i}>
                                        <div className="name six columns">{item.name}</div>
                                        <div className="price six columns">
                                            <span className='base'>Min. disk size: {item.min_disk_size} GB</span>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>

                </React.Fragment>
                }
                 {(this.props.type === 'all' || this.props.type === 'windows' || this.props.os === 'windows') &&
                <React.Fragment>
                <div className="row">
                    <div className="twelve columns">
                        <h3>Windows OS</h3>
                        <hr/>
                    </div>
                </div>  
                        <div className="row">
                            <div className="four columns">
                            <p>A list of Windows operating system versions that can be installed on a VPS-WIN server. Please note the minimal disk size requirement for each version.</p>
                            </div>
                            <div className="eight columns addons">
                            <div className="row">
                                {os_templates.filter((a) => a.windows === true).sort((a, b) => a.price - b.price).map((item,i) =>
                                    <div className="twelve columns" key={i}>
                                        <div className="name six columns">{item.name}</div>
                                        <div className="price six columns">
                                            <span className='base'>Min. disk size: {item.min_disk_size} GB</span>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>

                </React.Fragment>
                }
            </div>
               
        )}
    }}

    export default OStemplates;
