import React from 'react';
import {axiosinstance, axiosCatch} from '../../components/axios';
import DedicatedServersList from './productList';
import PlanPreLoader from '../../components/planPreloader';
import AddonsDedicatedServers from './addonsList';
import LoadingError from '../../components/loadingError';
import lscache from 'lscache';
import ReactSlider from 'react-slider';
import Select from 'react-select';

class DynamicContent extends React.Component {

	constructor(props, context) {
		super(props, context);

        this.state = {
            products: [],
			error: false,
            loading: true,
			slider_value: 1,
			ddos: false,
            raid: false,
            discount: false,
            instock: false,
            gpu: false,
            payment_period: 1
        }
    }

    handleJsonLdGenerated = (JSON) => {
        this.props.onJsonLdGenerated(JSON)
    }

	componentDidMount () {

		this.load();
	}

	load = () => {

        this.setState({
            loading: true
        }, () => {

            if (lscache.get('dedicatedProducts') === null)
            {

            axiosinstance({
                method: 'get',
               url: process.env.REACT_APP_BACKEND_URL + "/products/server.en.json"
            })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.result === true) {
                            lscache.set('dedicatedProducts', response, 10);
                            this.setState({
								products: response.data.data.products,
                                addons: response.data.data.addons,
                                price_from_initial: Math.min(...response.data.data.products.map(o => o.eco_price)),
                                price_to_initial: Math.max(...response.data.data.products.map(o => o.eco_price)),
                                price_from: Math.min(...response.data.data.products.map(o => o.eco_price)),
                                price_to: Math.max(...response.data.data.products.map(o => o.eco_price)),
                                storage_from_initial: Math.min(...response.data.data.products.map(o => o.config.storage.disk_capacity)),
                                storage_to_initial: Math.max(...response.data.data.products.map(o => o.config.storage.disk_capacity)),
                                storage_from: Math.min(...response.data.data.products.map(o => o.config.storage.disk_capacity)),
                                storage_to: Math.max(...response.data.data.products.map(o => o.config.storage.disk_capacity)),
                                ram_from_initial: Math.min(...response.data.data.products.map(o => o.config.ram.gb)),
                                ram_to_initial: Math.max(...response.data.data.products.map(o => o.config.ram.gb)),
                                ram_from: Math.min(...response.data.data.products.map(o => o.config.ram.gb)),
                                ram_to: Math.max(...response.data.data.products.map(o => o.config.ram.gb)),
                                configCount: response.data.data.products.length,
                                loading: false
                            })

                        } else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }
                    } else {
                        this.setState({
                            error: true,
                            loading: false
                        });
                    }
                }).catch((args) => {
                axiosCatch(args, this.props);
            });

        }

        else {
            this.setState({
                products: lscache.get('dedicatedProducts').data.data.products,
                addons: lscache.get('dedicatedProducts').data.data.addons,
                price_from_initial: Math.min(...lscache.get('dedicatedProducts').data.data.products.map(o => o.eco_price)),
                price_to_initial: Math.max(...lscache.get('dedicatedProducts').data.data.products.map(o => o.eco_price)),
                price_from: Math.min(...lscache.get('dedicatedProducts').data.data.products.map(o => o.eco_price)),
                price_to: Math.max(...lscache.get('dedicatedProducts').data.data.products.map(o => o.eco_price)),
                storage_from_initial: Math.min(...lscache.get('dedicatedProducts').data.data.products.map(o => o.config.storage.disk_capacity)),
                storage_to_initial: Math.max(...lscache.get('dedicatedProducts').data.data.products.map(o => o.config.storage.disk_capacity)),
                storage_from: Math.min(...lscache.get('dedicatedProducts').data.data.products.map(o => o.config.storage.disk_capacity)),
                storage_to: Math.max(...lscache.get('dedicatedProducts').data.data.products.map(o => o.config.storage.disk_capacity)),
                ram_from_initial: Math.min(...lscache.get('dedicatedProducts').data.data.products.map(o => o.config.ram.gb)),
                ram_to_initial: Math.max(...lscache.get('dedicatedProducts').data.data.products.map(o => o.config.ram.gb)),
                ram_from: Math.min(...lscache.get('dedicatedProducts').data.data.products.map(o => o.config.ram.gb)),
                ram_to: Math.max(...lscache.get('dedicatedProducts').data.data.products.map(o => o.config.ram.gb)),
                configCount: lscache.get('dedicatedProducts').data.data.products.length,
                loading: false
                })

        }

            if (lscache.get('exchangeRates') === null)
            {

            axiosinstance.get(process.env.REACT_APP_BACKEND_URL + "/variables.json")
            .then(res => {

                if (res.status === 200 && res.data.result === true) {
              this.setState({
                usd:  res.data.data.currencies.find(x => x.short_name === 'USD').rate,
                eur:  res.data.data.currencies.find(x => x.short_name === 'EUR').rate,
                btc:  res.data.data.currencies.find(x => x.short_name === 'BTC').rate
                })
                lscache.set('exchangeRates', res, 3);
            }

            else {
                this.setState({
                    error: true,
                    loading: false
                });
            }

            })
            }
            else {
                this.setState({
                    usd: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'USD').rate,
                    eur: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'EUR').rate,
                    btc: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'BTC').rate
                    })
            }

        });
	};

    ddosFilter =(e) => {
        this.setState({
            ddos: e.target.checked
        })
    }

    raidFilter =(e) => {
        this.setState({
            raid: e.target.checked
        })
    }

    discountFilter =(e) => {
        this.setState({
            discount: e.target.checked
        })
    }

    gpuFilter =(e) => {
        this.setState({
            gpu: e.target.checked
        })
    }

    instockFilter =(e) => {
        this.setState({
            instock: e.target.checked
        })
    }

    cpuFilter = () => {

            const arr = this.state.products.map((item => {
                const newArray = {};
                newArray.value = item.config.cpu.code;
                newArray.label = item.config.cpu.code + ' ' + item.config.cpu.model + ' ' + item.config.cpu.freq + ' (' + item.config.cpu.cores + ' cores)' ;  
                return newArray
                }))
              
              const uniqueIds = [];
              
              const unique = arr.filter(element => {
                const isDuplicate = uniqueIds.includes(element.value);
              
                if (!isDuplicate) {
                  uniqueIds.push(element.value);
              
                  return true;
                }
              
                return false;
              });
              
             return unique        
    }

    cpuFilterOnChange = (selected) => {
        this.setState({
            cpuList: selected
        })
    }

    countConfigs = (value) =>
    {
        if (this.state.configCount !== value) 
        {
        this.setState({
            configCount: value            
            })
        }
    }

    resetFilters = () => {
        this.refs.cpuSelect.clearValue();
        this.setState({
            ddos: false,
            raid: false,
            discount: false,
            instock: false,
            gpu: false,
            priceSliderValue: [this.state.price_from_initial,this.state.price_to_initial],
            price_from: this.state.price_from_initial,
            price_to: this.state.price_to_initial,
            storageSliderValue: [this.state.storage_from_initial,this.state.storage_to_initial],
            storage_from: this.state.storage_from_initial,
            storage_to: this.state.storage_to_initial,
            ramSliderValue: [this.state.ram_from_initial,this.state.ram_to_initial],
            ram_from: this.state.ram_from_initial,
            ram_to: this.state.ram_to_initial,
            monthsSliderValue: 1,
            payment_period: 1
        });
        setTimeout(
            () => {
            this.setState({
                priceSliderValue: undefined,
                storageSliderValue: undefined,
                ramSliderValue: undefined,
                monthsSliderValue: undefined
            })
        }, 1)
    }

	render() {          

        if (this.state.loading) {
            return (
					<PlanPreLoader/>
            )
        }
        if (this.state.error) {
            return (

                <LoadingError/>
            )
        } else {

			return (
<React.Fragment>


<div className="fullwidth">
    <div className="row">
        <div className="twelve columns">
        {!this.props.id ?
				<fieldset className="filter-container-dedicated">
                <legend><strong>{this.state.configCount}</strong> Server{this.state.configCount !== 1 && 's'} found <button onClick={this.resetFilters}><i className="fa-solid fa-rotate-left"></i> reset</button></legend>
        <div className="row">
        <div className="four columns">
            <div className='slider-legend slider-price'><span className='title'>Price: </span><span className='value'>from {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(this.state.price_from)} </span><span className='value'> to {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(this.state.price_to)}</span></div>
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track-range"
                renderThumb={(props, state) => <div {...props}>{state.valueNow.toLocaleString('de-CH')}</div>}
                defaultValue={[this.state.price_from_initial, this.state.price_to_initial]}
                min={this.state.price_from_initial}
                max={this.state.price_to_initial}
                ariaLabel={['Price from', 'Price to']}
                ariaValuetext={state => `Price value ${state.valueNow} CHF`}
                step={1}
                pearling
                minDistance={50}
                value={this.state.priceSliderValue}
                onChange = {([thumb1,thumb2]) => {
                    this.setState({
                        price_from: thumb1,
                        price_to: thumb2
                   })
                }}
            />
        </div>
        <div className="four columns">
            <div className='slider-legend slider-price'><span className='title'>Storage: </span><span className='value'>from {this.state.storage_from.toLocaleString('de-CH')} GB </span><span className='value'> to {this.state.storage_to.toLocaleString('de-CH')} GB</span></div>
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track-range"
                defaultValue={[this.state.storage_from_initial, this.state.storage_to_initial]}
                min={this.state.storage_from_initial}
                max={this.state.storage_to_initial}
                ariaLabel={['Storage to', 'Storage to']}
                ariaValuetext={state => `Storage value ${new Intl.NumberFormat("en", {
                    notation: "compact",
                    style: "unit",
                    unit: "gigabyte",
                    unitDisplay: "narrow",
                  }).format(state.valueNow)
                  }`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow.toLocaleString('de-CH')}</div>}
                value={this.state.storageSliderValue}
                step={20}
                pearling
                minDistance={40}
                onChange = {([thumb1,thumb2]) => {
                    this.setState({
                        storage_from: thumb1,
                        storage_to: thumb2 
                   
                   })
                }}
            />
        </div>
        <div className="four columns">
            <div className='slider-legend slider-price'><span className='title'>RAM: </span><span className='value'>from {this.state.ram_from.toLocaleString('de-CH')} GB </span><span className='value'> to {this.state.ram_to.toLocaleString('de-CH')} GB</span></div>
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track-range"
                defaultValue={[this.state.ram_from_initial, this.state.ram_to_initial]}
                min={this.state.ram_from_initial}
                max={this.state.ram_to_initial}
                ariaLabel={['RAM from', 'RAM to']}
                ariaValuetext={state => `RAM value ${new Intl.NumberFormat("en", {
                    notation: "compact",
                    style: "unit",
                    unit: "gigabyte",
                    unitDisplay: "narrow",
                  }).format(state.valueNow)}`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow.toLocaleString('de-CH')}</div>}
                value={this.state.ramSliderValue}
                step={1}
                pearling
                minDistance={4}
                onChange = {([thumb1,thumb2]) => {
                    this.setState({
                        ram_from: thumb1,
                        ram_to: thumb2 
                   
                   })
                }}
            />
        </div>
    </div>
    <div className="row margin-top">
    <div className="four columns">
    <div className='slider-legend slider-price'><span className='title'>Billing cycle: </span><span className='value'>{this.state.payment_period} month(s)</span> <span className='title'>Discount:</span> <span className='value'>{(this.state.payment_period - 1) * 2}%</span></div>
    <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                defaultValue={1}
                step={1}
                renderThumb={(props, state) => <div {...props}>{state.valueNow }</div>}
                min={1}
                max={12}
                value={this.state.monthsSliderValue}
                onChange = {(value) => {
                    this.setState({
                        payment_period: value
                    })
                }}
            />

    </div>
    <div className="four columns">
    <div className='slider-legend slider-price'><span className='title'>CPU:</span></div>
    <Select 
    isMulti
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    className ={'r-select'}
    ref={'cpuSelect'}
    options={this.cpuFilter()}
    onChange={this.cpuFilterOnChange}
    menuPortalTarget={document.body} 
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                option: base => ({ ...base, fontSize: '1em' })
    }}
        />
    </div>
    <div className="four columns">
    <div className='row'>
        <div className="column-33 config-options">
        <div className='slider-legend slider-price'><span className='title'>Other options: </span></div>
            <input type="checkbox" className='css-checkbox' name="raid" id="raid" checked={this.state.raid} onChange={this.raidFilter}/>
            <label htmlFor="raid">RAID card</label>
            <input type="checkbox" className='css-checkbox' name="ddos" id="ddos" checked={this.state.ddos} onChange={this.ddosFilter}/>
            <label htmlFor="ddos">DDoS protection</label>
            <input type="checkbox" className='css-checkbox' name="instock" id="instock" checked={this.state.instock} onChange={this.instockFilter}/>
            <label htmlFor="instock">In stock</label>
            <input type="checkbox" className='css-checkbox' name="discount" id="discount" checked={this.state.discount} onChange={this.discountFilter}/>
            <label htmlFor="discount" className='special'>Special offer</label>
            <input type="checkbox" className='css-checkbox' name="gpu" id="gpu" checked={this.state.gpu} onChange={this.gpuFilter}/>
            <label htmlFor="gpu">GPU</label>
        </div>
    </div>
                </div>
            </div>
        </fieldset>
    :
    <div className="row discount-slider-single productPreview">
    <div className="eight columns">
            <ReactSlider
                className="horizontal-slider-single-block"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                defaultValue={this.state.payment_period}
                step={1}
                marks={[1,2,3,4,5,6,7,8,9,10,11,12]}
                renderThumb={(props, state) => <div {...props}>{(state.valueNow -1) * 2} %</div>}
                renderMark={(props) => <span {...props}>{props.key} m.</span>}
                min={1}
                max={12}
                onChange = {(value) => {
                    this.setState({
                        payment_period: value
                    })
                }}
            />
    </div>
    <div className="four columns">
        <p className='discountText'>The longer the billing period, the higher your discount will be. Move the slider thumb to see how much you can save on the longer-term Swiss Dedicated Server deal.</p>
    </div>
</div>
}

    <div className="config-container-sorting">
    <DedicatedServersList 
    usdRate={this.state.usd} 
    eurRate={this.state.eur} 
    btcRate={this.state.btc} 
    ddosProtectionOn={this.state.ddos}
    list={this.state.products} 
    payment_period={this.state.payment_period} 
    sliderPriceFrom={this.state.price_from} 
    sliderPriceTo={this.state.price_to} 
    sliderStorageFrom={this.state.storage_from} 
    sliderStorageTo={this.state.storage_to} 
    sliderRamFrom={this.state.ram_from} 
    sliderRamTo={this.state.ram_to} 
    countConfigs={this.countConfigs} 
    cpuList={this.state.cpuList}
    raid={this.state.raid}
    discount={this.state.discount}
    instock={this.state.instock}
    gpu={this.state.gpu}
    onJsonLdGenerated={this.handleJsonLdGenerated}
    id={this.props.id}
    osTemplates={this.state.addons.filter((a) => (a.key === 'operating-system' ))}
    />
                </div>
				</div>
                </div>

</div>
            <AddonsDedicatedServers addons={this.state.addons} eurRate={this.state.eur} usdRate={this.state.usd} btcRate={this.state.btc}/>
            </React.Fragment>
	)}}}

export default DynamicContent;
