import React from 'react';
import {axiosinstance, axiosCatch} from '../../components/axios';
import CloudConfigList from './productList';
import AddonsCloud from './addonsList'
import PlanPreLoader from '../../components/planPreloader';
import LoadingError from '../../components/loadingError';
import lscache from 'lscache';
import ReactSlider from 'react-slider';

class DynamicContent extends React.Component {

	constructor(props, context) {
		super(props, context);

        this.state = {
            products: [],
			error: false,
            loading: true,
            discount: false,
			payment_period: 1
        }
    }

	componentDidMount () {

		this.load();
	}

	load = () => {

        this.setState({
            loading: true
        }, () => {

            if (lscache.get('cloudProducts') === null)
            {

            axiosinstance({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL + "/products/cloud.en.json"
            })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.result === true) {
                            lscache.set('cloudProducts', response, 10);
                            this.setState({
								products: response.data.data.products,
                                addons: response.data.data.addons,
                                price_from_initial: Math.min(...response.data.data.products.map(i => i.price)),
                                price_to_initial: Math.max(...response.data.data.products.map(i => i.price)),
                                price_from: Math.min(...response.data.data.products.map(i => i.price)),
                                price_to: Math.max(...response.data.data.products.map(i => i.price)),
                                storage_from_initial: Math.min(...response.data.data.products.map(o => o.config.storage)),
                                storage_to_initial: Math.max(...response.data.data.products.map(o => o.config.storage)),
                                storage_from: Math.min(...response.data.data.products.map(o => o.config.storage)),
                                storage_to: Math.max(...response.data.data.products.map(o => o.config.storage)),
                                ram_from_initial: Math.min(...response.data.data.products.map(o => o.config.ram)),
                                ram_to_initial: Math.max(...response.data.data.products.map(o => o.config.ram)),
                                ram_from: Math.min(...response.data.data.products.map(o => o.config.ram)),
                                ram_to: Math.max(...response.data.data.products.map(o => o.config.ram)),
                                bandwidth_from_initial: Math.min(...response.data.data.products.map(o => o.config.bandwidth)),
                                bandwidth_to_initial: Math.max(...response.data.data.products.map(o => o.config.bandwidth)),
                                bandwidth_from: Math.min(...response.data.data.products.map(o => o.config.bandwidth)),
                                bandwidth_to: Math.max(...response.data.data.products.map(o => o.config.bandwidth)),
                                configCount: response.data.data.products.length,
                                loading: false
                            });

                        } else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }
                    } else {
                        this.setState({
                            error: true,
                            loading: false
                        });
                    }
                }).catch((args) => {
                axiosCatch(args, this.props);
            });

        }

        else {
            this.setState({
                products: lscache.get('cloudProducts').data.data.products,
                addons: lscache.get('cloudProducts').data.data.addons,
                price_from_initial: Math.min(...lscache.get('cloudProducts').data.data.products.map(i => i.price)),
                price_to_initial: Math.max(...lscache.get('cloudProducts').data.data.products.map(i => i.price)),
                price_from: Math.min(...lscache.get('cloudProducts').data.data.products.map(i => i.price)),
                price_to: Math.max(...lscache.get('cloudProducts').data.data.products.map(i => i.price)),
                storage_from_initial: Math.min(...lscache.get('cloudProducts').data.data.products.map(o => o.config.storage)),
                storage_to_initial: Math.max(...lscache.get('cloudProducts').data.data.products.map(o => o.config.storage)),
                storage_from: Math.min(...lscache.get('cloudProducts').data.data.products.map(o => o.config.storage)),
                storage_to: Math.max(...lscache.get('cloudProducts').data.data.products.map(o => o.config.storage)),
                ram_from_initial: Math.min(...lscache.get('cloudProducts').data.data.products.map(o => o.config.ram)),
                ram_to_initial: Math.max(...lscache.get('cloudProducts').data.data.products.map(o => o.config.ram)),
                ram_from: Math.min(...lscache.get('cloudProducts').data.data.products.map(o => o.config.ram)),
                ram_to: Math.max(...lscache.get('cloudProducts').data.data.products.map(o => o.config.ram)),
                bandwidth_from_initial: Math.min(...lscache.get('cloudProducts').data.data.products.map(o => o.config.bandwidth)),
                bandwidth_to_initial: Math.max(...lscache.get('cloudProducts').data.data.products.map(o => o.config.bandwidth)),
                bandwidth_from: Math.min(...lscache.get('cloudProducts').data.data.products.map(o => o.config.bandwidth)),
                bandwidth_to: Math.max(...lscache.get('cloudProducts').data.data.products.map(o => o.config.bandwidth)),
                configCount: lscache.get('cloudProducts').data.data.products.length,
                loading: false
                })

        }

            if (lscache.get('exchangeRates') === null)
            {

                axiosinstance.get(process.env.REACT_APP_BACKEND_URL + "/variables.json")
            .then(res => {

                if (res.status === 200 && res.data.result === true) {
              this.setState({
                usd:  res.data.data.currencies.find(x => x.short_name === 'USD').rate,
                eur:  res.data.data.currencies.find(x => x.short_name === 'EUR').rate,
                btc:  res.data.data.currencies.find(x => x.short_name === 'BTC').rate
                })
                lscache.set('exchangeRates', res, 3);
            }

            else {
                this.setState({
                    error: true,
                    loading: false
                });
            }

            })
            }
            else {
                this.setState({
                    usd: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'USD').rate,
                    eur: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'EUR').rate,
                    btc: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'BTC').rate
                    })
            }

        });
	}

    discountFilter =(e) => {
        this.setState({
            discount: e.target.checked
        })
    }

    countConfigs = (value) =>
    {
        if (this.state.configCount !== value) 
        {
        this.setState({
            configCount: value            
            })
        }
    }

    resetFilters = () => {
        this.setState({
            discount: false,
            priceSliderValue: [this.state.price_from_initial,this.state.price_to_initial],
            ramSliderValue: [this.state.ram_from_initial,this.state.ram_to_initial],
            storageSliderValue: [this.state.storage_from_initial,this.state.storage_to_initial],
            price_from: this.state.price_from_initial,
            price_to: this.state.price_to_initial,
            storage_from: this.state.storage_from_initial,
            storage_to: this.state.storage_to_initial,
            ram_from: this.state.ram_from_initial,
            ram_to: this.state.ram_to_initial,
            monthsSliderValue: 1,
            payment_period: 1
        })
        setTimeout(
            () => {
            this.setState({
                priceSliderValue: undefined,
                monthsSliderValue: undefined,
                ramSliderValue: undefined,
                storageSliderValue: undefined            })
        }, 1)
    }

    
    goCustom = () => {
        window.location.assign("//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/products/cloud")
    }

	render() {

        if (this.state.loading) {
            return (
                <PlanPreLoader/>
            )
        }
        if (this.state.error) {
            return (
                <LoadingError/>
            )
        } else {

			return (
<React.Fragment>

<div className="fullwidth">
    <div className="row">
        <div className="twelve columns">
				<fieldset className="filter-container-dedicated">
                <legend><strong>{this.state.configCount}</strong> Server{this.state.configCount !== 1 && 's'} found <button onClick={this.resetFilters}><i className="fa-solid fa-rotate-left"></i> reset</button> <button onClick={this.goCustom}><i className="fa-solid fa-sliders"></i> custom</button></legend>
        <div className="row">
        <div className="four columns">
            <div className='slider-legend slider-price'><span className='title'>Price: </span><span className='value'>from {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(this.state.price_from)} </span><span className='value'> to {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(this.state.price_to)}</span></div>
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track-range"
                renderThumb={(props, state) => <div {...props}>{state.valueNow.toLocaleString('de-CH')}</div>}
                defaultValue={[this.state.price_from_initial, this.state.price_to_initial]}
                min={this.state.price_from_initial}
                max={this.state.price_to_initial}
                ariaLabel={['Price from', 'Price to']}
                ariaValuetext={state => `Price value ${state.valueNow} CHF`}
                step={5}
                pearling
                minDistance={5}
                value={this.state.priceSliderValue}
                onChange = {([thumb1,thumb2]) => {
                    this.setState({
                        price_from: thumb1,
                        price_to: thumb2
                   })
                }}
            />
        </div>
        <div className="four columns">
            <div className='slider-legend slider-price'><span className='title'>Storage: </span><span className='value'>from {this.state.storage_from.toLocaleString('de-CH')} GB </span><span className='value'> to {this.state.storage_to.toLocaleString('de-CH')} GB</span></div>
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track-range"
                defaultValue={[this.state.storage_from_initial, this.state.storage_to_initial]}
                min={this.state.storage_from_initial}
                max={this.state.storage_to_initial}
                ariaLabel={['Storage to', 'Storage to']}
                ariaValuetext={state => `Storage value ${new Intl.NumberFormat("en", {
                    notation: "compact",
                    style: "unit",
                    unit: "gigabyte",
                    unitDisplay: "narrow",
                  }).format(state.valueNow)
                  }`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow.toLocaleString('de-CH')}</div>}
                value={this.state.storageSliderValue}
                step={10}
                pearling
                minDistance={10}
                onChange = {([thumb1,thumb2]) => {
                    this.setState({
                        storage_from: thumb1,
                        storage_to: thumb2 
                   
                   })
                }}
            />
        </div>
        <div className="four columns">
            <div className='slider-legend slider-price'><span className='title'>RAM: </span><span className='value'>from {this.state.ram_from.toLocaleString('de-CH')} GB </span><span className='value'> to {this.state.ram_to.toLocaleString('de-CH')} GB</span></div>
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track-range"
                defaultValue={[this.state.ram_from_initial, this.state.ram_to_initial]}
                min={this.state.ram_from_initial}
                max={this.state.ram_to_initial}
                ariaLabel={['RAM from', 'RAM to']}
                ariaValuetext={state => `RAM value ${new Intl.NumberFormat("en", {
                    notation: "compact",
                    style: "unit",
                    unit: "megabyte",
                    unitDisplay: "narrow",
                  }).format(state.valueNow)}`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow.toLocaleString('de-CH')}</div>}
                value={this.state.ramSliderValue}
                step={512}
                pearling
                minDistance={512}
                onChange = {([thumb1,thumb2]) => {
                    this.setState({
                        ram_from: thumb1,
                        ram_to: thumb2 
                   
                   })
                }}
            />
        </div>
    </div>
    <div className="row margin-top">
    <div className="four columns">
    <div className='slider-legend slider-price'><span className='title'>Storage: </span><span className='value'>from {this.state.storage_from.toLocaleString('de-CH')} GB </span><span className='value'> to {this.state.storage_to.toLocaleString('de-CH')} GB</span></div>
             <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track-range"
                defaultValue={[this.state.storage_from_initial, this.state.storage_to_initial]}
                min={this.state.storage_from_initial}
                max={this.state.storage_to_initial}
                ariaLabel={['Storage to', 'Storage to']}
                ariaValuetext={state => `Storage value ${new Intl.NumberFormat("en", {
                    notation: "compact",
                    style: "unit",
                    unit: "gigabyte",
                    unitDisplay: "narrow",
                  }).format(state.valueNow)
                  }`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow.toLocaleString('de-CH')}</div>}
                value={this.state.storageSliderValue}
                step={10}
                pearling
                minDistance={10}
                onChange = {([thumb1,thumb2]) => {
                    this.setState({
                        storage_from: thumb1,
                        storage_to: thumb2 
                   
                   })
                }}
            />
    </div>
    <div className="four columns">
    <div className='slider-legend slider-price'><span className='title'>Billing cycle: </span><span className='value'>{this.state.payment_period} month(s)</span> <span className='title'>Discount:</span> <span className='value'>{(this.state.payment_period - 1) * 2}%</span></div>
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="slider-thumb"
                    trackClassName="slider-track"
                    defaultValue={1}
                    step={1}
                    renderThumb={(props, state) => <div {...props}>{state.valueNow }</div>}
                    min={1}
                    max={12}
                    value={this.state.monthsSliderValue}
                    onChange = {(value) => {
                        this.setState({
                            payment_period: value
                        })
                    }}
                />

    </div>
    <div className="four columns">
    <div className='row'>
        <div className="column-33 config-options">
        <div className='slider-legend slider-price'><span className='title'>Other options: </span></div>
        <input type="checkbox" className='css-checkbox' name="discount" id="discount" checked={this.state.discount} onChange={this.discountFilter}/>
            <label htmlFor="discount" className='special'>Special offer</label>
        </div>
        </div>
    </div>
</div>
</fieldset>

    <div className="config-container-sorting">
    <CloudConfigList 
    usdRate={this.state.usd} 
    eurRate={this.state.eur} 
    btcRate={this.state.btc} 
    list={this.state.products} 
    payment_period={this.state.payment_period}
    sliderPriceFrom={this.state.price_from} 
    sliderPriceTo={this.state.price_to}
    sliderRamFrom={this.state.ram_from} 
    sliderRamTo={this.state.ram_to}
    sliderStorageFrom={this.state.storage_from} 
    sliderStorageTo={this.state.storage_to}
    discount={this.state.discount}
    countConfigs={this.countConfigs} 
    />
                </div>
				</div>
                </div>

</div>
<AddonsCloud addons={this.state.addons} eurRate={this.state.eur} usdRate={this.state.usd} btcRate={this.state.btc}/>
    </React.Fragment>
	)}}}

export default DynamicContent;
