import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutVPS extends React.Component {

	render() {

		return (
			<div className="fullwidth dark">
				<div className="row">
					<div className="four columns">
						<h2>What is a VPS?</h2>
						<hr className="grey" />
						<p>A Virtual Private Server (VPS) is a virtualized hosting solution that mimics the features of a dedicated server while sharing physical resources of a bare metal server with other clients. VPS hosting is ideal for small to medium-sized businesses, developers, and individuals seeking more control and flexibility than shared hosting. With a VPS, users have root access, allowing for custom configurations and enhanced security.</p>
					</div>
					<div className="four columns">
						<h2>Swiss Made</h2>
						<hr className="grey" />
						<p>VPS hosting in Switzerland provides benefits such as strong <NavLink to="/en/data-protection-switzerland">data protection</NavLink> laws, a stable political and economic landscape and outstanding <NavLink to="/en/technology">network infrastructure</NavLink>. Country's central position within Europe guarantees low-latency connections across the continent. These factors contribute to reliable, secure, and high-performance hosting services, making Switzerland an attractive location for VPS hosting.</p>
					</div>
					<div className="four columns">
						<h2>VMware®-powered</h2>
						<hr className="grey" />
						<p>VMware vSphere® High Availability is a prominent virtualization solution that enhances the reliability of virtual machines in a virtualized environment. By minimizing downtime and data loss through failover protection, resource monitoring, and flexible configurations, it provides an experience akin to a true dedicated server, making it a top choice in the market.</p>
					</div>
				</div>
			</div>
		)
	}
}

export default AboutVPS