import React from 'react';
import Helmet from "react-helmet";
import ListOfProducts from "./listOfProducts";
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';

class Products extends React.Component {

    render() {
    
        return (
<React.Fragment>
				<Helmet>
					<title>Products &amp; Services | Swiss Made Host</title>
					<meta name="description" content="Dedicated Servers, Virtual Servers, DDoS Protection, Colocation &amp; related services. Proudly made in Zurich, Switzerland." />
					<link rel="canonical" href="https://swissmade.host/en/products"/>
				</Helmet>
				<div className="fullwidth pic products">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Products and Services by <span><i className="fa fa-plus-square" aria-hidden="true"></i> Swiss Made Host</span></h1>
								<h2>Privacy-focused hosting in Switzerland</h2>
							</div>
						</div>
					</div>
				</div>
				<ListOfProducts/>
				<PaymentMethodsInclude/>
</React.Fragment>
        )}}

export default Products