import React from 'react';
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";

function SLA() {
  return (

    <React.Fragment>
    <Helmet>
		<title>Service Level Agreement | Swiss Made Host</title>
		<meta name="description" content="Service Level Agreement (SLA) states a set of guarantees that we deliver to our clients." />
		<meta name="robots" content="noindex"/>
	</Helmet>
	<div className="fullwidth pic legal">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
					<h1 className="title">Service Level Agreement <span>SWISSMADE.HOST</span></h1>
					<h2>The guarantees that we deliver to our clients</h2>
				</div>
			</div>
		</div>
	</div>
<div className="fullwidth light">
	<div className="row flex-center">
		<div className="ten columns">
				<div className="panel light">
					<h3 className="mt30">'Best Efforts' Service Level Agreement</h3>
					<blockquote className="white">Solar Communications Gmbh ("Solar") Service Level Agreement ("SLA") states a set of guarantees that we deliver to our clients. The SLA covers a number of components that ensure smooth operation of your infrastructure while outlining our responsibilities to you as our client.</blockquote>
					<h3 className="mt30">Network Guarantee</h3>
					<p>Solar Communications Gmbh  guarantees 99.9% online availability of your server in a given month, excluding scheduled maintenance.</p>
					<h3 className="mt30">Infrastructure</h3>
					<p>We guaranty 100% uninterrupted access to electricity and HVAC in a given month, excluding scheduled maintenance.</p>
					<h3>Solar Internal Network</h3>
					<p>Solar will utilize the best technology available to us to achieve zero packet loss, excluding scheduled maintenance, within Solar Internal Network, hence ensure outstanding performance on the Internet.</p>
					<h3 className="mt30">Hardware</h3>
					<p>Solar guarantees that all hardware components will be maintained in a proper working order and in an event of failure the hardware will be replaced within 1 hour after the problem has been identified at no cost to customer. Hardware is: Processor(s), RAM, hard disk(s), motherboard, NIC card and other hardware that is a part of a server. Rebuilding RAID arrays is excluded from this guarantee as may take more time to complete.</p>
					<h3 className="mt30">Gold Service Level Agreement</h3>
					<p>For those customers that require additional priority assistance anytime 24/7 with response time within 30 minutes we offer Gold SLA subscription. Gold SLA subscribers have access to high priority support for a fixed monthly fee. We guarantee that technical support will always be there when you need it and client's critical needs are always promptly and completely met.</p>
					<p>To underline the advantages of Gold SLA we take heightened financial responsibility before you in the form of the credits that count towards your next invoice. Take the standard rate per hour of downtime, add 3% and you will get the amount of credits that you will receive in an unlikely event of our failure to meet our obligations. As part of Gold SLA we guarantee at most 30 minute response time to your assistance requests submitted via our ticketing system.</p>
					<h3 className="mt30">Credits</h3>
					<p>In a highly unlikely event that Solar fails to meet the above mentioned expectations we will credit your account at the beginning of the next billing period as follows (your next invoice will be adjusted accordingly:</p>
					<blockquote className="white">
						<h4>Network</h4>
						<p>We will credit the customer 5% of the monthly fee for each hour of downtime. Credits issued  during any one month's time shall not exceed 100% of the monthly fees. The problem is considered resolved once the network functionality is restored.</p>
						<h4 className="mt30">Data Center Infrastructure</h4>
						<p>We will credit the customer 5% of the monthly fee for each hour of downtime. Credits issued during any one month's time shall not exceed 100% of the monthly fees. Infrastructure failure is a shutdown as a result of equipment overheating and power loss. This guarantee excludes power supply at dedicated servers. The problem is considered resolved once the power supply is restored.</p>
						<h4 className="mt30">Internal Network</h4>
						<p>We will credit customer 5% of the monthly fee for each hour the internal network latency is observed. Credits issued during any one month's time	shall not exceed 50% of the monthly fees. The problem is considered resolved once the internal network latency is eliminated.</p>
						<h4 className="mt30">Hardware</h4>
						<p>We will credit the customer 5% of the monthly fee for each hour of downtime. Credits issued during any one month's time shall not exceed 100% of the monthly fees.  The problem is considered resolved once the equipment is back online and in working order.</p>
						<p>Overall total credits issued in accordance with this SLA agreement during any billing month shall not exceed 100% of the recurring monthly fees. The time count for all of the above scenarios starts once an appropriate technical/customer support ticket is created and stop whenever the problem is resolved.</p>
					</blockquote>
					<h3 className="mt30">How to Receive Credits</h3>
					<p>To receive credits, please contact Solar Customer Service Specialist by creating a support ticket within 15 days from the resolution of any of the above mentioned problems. Please note that you will have to demonstrate that your web infrastructure, business, etc was adversely effected by the problem.</p>
					<h3 className="mt30">Limitations to SLA</h3>
					<p>No part of this agreement covers outages or other service interruption that is caused by equipment and/or events that are the effect of or under the control of the client including script, software failures and server overload. No part of this agreement shall cover loss of power to a server as a result of the client's utilization of remote power management tools. This SLA does not cover interruptions of service causes by DDoS attacks and/or actions of hackers against clients server(s) and force-major situations beyond control of Solar.</p>
					<h3 className="mt30">Special Notes</h3>
					<p>You do not qualify to receive credits if you have violated either Solar <NavLink to="/en/terms-of-service">Terms of Service</NavLink> or <NavLink to="/en/acceptable-use-policy">Acceptable Use Policy</NavLink> or both. You do to qualify to credits if you have a past due invoices and if the problem has resulted from your misuse of services provided by Solar.</p>
				</div>
		</div>
	</div>
</div>
	</React.Fragment>
  );
}
 
export default SLA;