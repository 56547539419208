import React, { Component } from 'react';

class VpnProductList extends Component {

    render() {

        const usdRate = this.props.usdRate,
            eurRate = this.props.eurRate,
            payment_period = this.props.payment_period,
            discountPercent = this.props.discountPercent,
            discountActive = this.props.discountActive,
            firstMonth = this.props.firstMonth,
            protocol = this.props.protocol,
            couponCode = this.props.couponCode,
            list = protocol === 'shadowsocks'
                ? this.props.list.filter(a => a.type === 'shadowsocks')
                : protocol === 'openvpn' || protocol === 'wireguard'
                    ? this.props.list.filter(a => a.type === 'vpn')
                    : this.props.list.filter(a => a.type === 'vpn' || a.type === 'shadowsocks')

        this.replaceDigits = (payment_period) => {
            var words = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve']
            return (
                words[payment_period]
            )
        }

        return (
            <React.Fragment>
                {list.map((item, i) =>

                    <div className="four columns" key={i}>
                        <div className="panel product-alt">
                            <h3 className="cdn">
                                {(protocol === 'all' && item.type === 'vpn') && 'VPN '}
                                {(protocol === 'all' && item.type === 'shadowsocks') && 'Shadowsocks '}
                                {protocol === 'wireguard' && 'WireGuard® '}
                                {protocol === 'openvpn' && 'OpenVPN '}
                                {protocol === 'shadowsocks' && 'Shadowsocks '}
                                {item.name}
                            </h3>
                            {item.old_price !== 0 && <div className='tag'>Special Offer</div>}
                            {discountActive === true && firstMonth !== true ? <div className='tag'>{discountPercent}% Off First Month</div> : null}
                            <div className='line'>
                                <span>Protocols:</span>
                                <span>
                                    {(protocol === 'all' && item.type === 'vpn') && 'WireGuard®, OpenVPN'}
                                    {(protocol === 'all' && item.type === 'shadowsocks') && 'Shadowsocks'}
                                    {protocol === 'wireguard' && 'WireGuard®'}
                                    {protocol === 'openvpn' && 'OpenVPN'}
                                    {protocol === 'shadowsocks' && 'Shadowsocks'}
                                </span>
                            </div>
                            <div className='line'><span>Max. connections:</span><span>{item.max_concurrent_connection === 0 || item.max_passwords === 0 ? 'Unlimited' : item.max_concurrent_connection ?? item.max_passwords}</span></div>
                            {item.id === 261 && <div className='line'><span>pfSense® firewall:</span><span><i className="fa-solid fa-xmark"></i></span></div>}
                            {item.id === 262 && <div className='line'><span>pfSense® firewall:</span><span><i className="fa-solid fa-xmark"></i></span></div>}
                            {item.id === 263 && <div className='line'><span>pfSense® firewall:</span><span><i className="fa-solid fa-check"></i></span></div>}
                            <div className='line'><span>Traffic obfuscation:</span><span>{item.type === 'shadowsocks' ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>}</span></div>
                            <div className='line'><span>Location:</span><span>Switzerland <img src="/images/swiss-flag.png" alt="Switzerland" /></span></div>
                            <div className='line'><span>Server:</span><span>Private, reserved resources</span></div>
                            <div className='line'><span>IP address:</span><span>Dedicated (non-shared)</span></div>
                            <div className='line'><span>Network:</span><span>10 Gbps port</span></div>
                            <div className='line'><span>Crypto:</span><span>Accepted</span></div>
                            {(

                                discountActive === true
                                    ?
                                    payment_period === 1
                                        ? item.old_price !== 0
                                            ? <div className="price">
                                                <span style={{ color: '#d74937', textDecoration: 'line-through' }}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price)}</span></span>
                                                <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price * discountPercent / 100)} </span>
                                                <div className="coinPrice"><span>${(item.price * discountPercent / 100 * usdRate).toFixed(2)} / €{(item.price * discountPercent / 100 * eurRate).toFixed(2)}</span><span><small>per {firstMonth === true && 'first'} month</small></span></div>
                                            </div>
                                            : <div className="price">
                                                <span style={{ color: '#d74937', textDecoration: 'line-through' }}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)}</span></span>
                                                <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price * discountPercent / 100))} </span>
                                                <div className="coinPrice"><span>${(item.price * discountPercent / 100 * usdRate).toFixed(2)} / €{(item.price * discountPercent / 100 * eurRate).toFixed(2)}</span><span><small>per {firstMonth === true && 'first'} month</small></span></div>
                                            </div>
                                        : item.old_price !== 0
                                            ? <React.Fragment><span className='savings'>Save {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(((item.old_price - item.price) * payment_period) + ((item.price - ((item.price - (item.price / 100 * (payment_period - 1) * 2)))) * payment_period))} over {this.replaceDigits(payment_period)} months.</span>
                                                <div className="price">
                                                    <span style={{ color: '#d74937', textDecoration: 'line-through' }}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price)}</span></span>
                                                    <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                    <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>{firstMonth === true ? 'per second and further months' : 'per month'}</small></span></div>
                                                </div>
                                            </React.Fragment>
                                            : <React.Fragment><span className='savings'>Save {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(((item.price - ((item.price - (item.price / 100 * (payment_period - 1) * 2)))) * payment_period))} ({(payment_period - 1) * 2}%) over {this.replaceDigits(payment_period)} months.</span>
                                                <div className="price">
                                                    <span style={{ color: '#d74937', textDecoration: 'line-through' }}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)}</span></span>
                                                    <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                    <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>{firstMonth === true ? 'per second and further months' : 'per month'}</small></span></div>
                                                </div>
                                            </React.Fragment>

                                    :
                                    payment_period === 1
                                        ? item.old_price !== 0
                                            ? <div className="price">
                                                <span style={{ color: '#d74937', textDecoration: 'line-through' }}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price)}</span></span>
                                                <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>per month</small></span></div>
                                            </div>
                                            : <React.Fragment><div className="price"><span className='basePrice'>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)}</span><span className='coinPrice'>${(item.price * usdRate).toFixed(2)} / €{(item.price * eurRate).toFixed(2)}</span><span><small>per month</small></span></div></React.Fragment>
                                        : item.old_price !== 0
                                            ? <React.Fragment><span className='savings'>Save {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(((item.old_price - item.price) * payment_period) + ((item.price - ((item.price - (item.price / 100 * (payment_period - 1) * 2)))) * payment_period))} over {this.replaceDigits(payment_period)} months.</span>
                                                <div className="price">
                                                    <span style={{ color: '#d74937', textDecoration: 'line-through' }}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price)}</span></span>
                                                    <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                    <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>per month</small></span></div>
                                                </div>
                                            </React.Fragment>
                                            : <React.Fragment><span className='savings'>Save {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(((item.price - ((item.price - (item.price / 100 * (payment_period - 1) * 2)))) * payment_period))} ({(payment_period - 1) * 2}%) over {this.replaceDigits(payment_period)} months.</span>
                                                <div className="price">
                                                    <span style={{ color: '#d74937', textDecoration: 'line-through' }}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)}</span></span>
                                                    <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                    <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>per month</small></span></div>
                                                </div>
                                            </React.Fragment>
                            )}
                            <a className="button radius large" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + item.id + "?bill=" + payment_period + (discountActive === true ? '&voucher=' + couponCode : '')}>{discountActive === true || payment_period > 1 ? 'Buy with discount' : 'Configure'}</a>
                        </div>
                    </div>


                )}
            </React.Fragment>
        )
    }
}

export default VpnProductList;
