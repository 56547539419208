import React from 'react';
import { NavLink } from "react-router-dom";
import { toggleWidget } from 'react-chat-widget';
import Analytics from "../analytics"

function Footer() {

    return (
   <footer id="footer">
   <div className="footer">
      <div className="row notify footerNotification" id="footerNotification" data-title="footer" data-expires="30" data-dismissible="true">
         <div className="twelve columns">
		      <p><i className="fa-solid fa-cookie"></i> This website uses cookies to optimize functionality and ensure you get the best experience on our website. Please read more on the <NavLink className="faded" to="/en/privacy-policy">privacy policy</NavLink> page.
            </p>
         </div>
      </div>
      <div className="row">
         <div className="three columns">
            <h4>Products</h4>
            <hr className="black"/>
        <ul className="supportlinks">
               <li><NavLink to="/en/dedicated-servers-switzerland" title="Swiss Dedicated server configurations"><i className="fa fa-caret-right"></i> Dedicated Servers</NavLink></li>
               <li><NavLink to="/en/vps-switzerland" title="Swiss VPS configurations"><i className="fa fa-caret-right"></i> VPS</NavLink></li>
               <li><NavLink to="/en/cloud-switzerland" title="Swiss Cloud server configurations"><i className="fa fa-caret-right"></i> Cloud Servers</NavLink></li>
               <li><NavLink to="/en/ddos-protection-switzerland" title="DDoS protection in Switzerland"><i className="fa fa-caret-right"></i> DDoS Protection</NavLink></li>
               <li><NavLink to="/en/colocation-switzerland" title="Colocation in Switzerland"><i className="fa fa-caret-right"></i> Colocation</NavLink></li>
               <li><NavLink to="/en/vpn-switzerland" title="VPN in Switzerland"><i className="fa fa-caret-right"></i> VPN</NavLink></li>
            </ul>
         </div>
         <div className="three columns">
            <h4>Info &amp; Support</h4>
            <hr className="black"/>
            <ul className="supportlinks">
               <li><NavLink to="/en/about-us" title="About us"><i className="fa fa-caret-right"></i> About Us</NavLink></li>
               <li><NavLink to="/en/blog" title="Blog, news &amp; announcements"><i className="fa fa-caret-right"></i> Blog</NavLink></li>
               <li><NavLink to="/en/knowledgebase" title="Knowledgebase"><i className="fa fa-caret-right"></i> Knowledge Base</NavLink></li>
               <li><NavLink to="/en/contact-us" title="Contac Us"><i className="fa fa-caret-right"></i> Contact Us</NavLink></li>
               <li><a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Live chat 24/7"><i className="fa fa-caret-right"></i> Live Chat</a></li>
               <li><a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/ticket/list'} title="Open a support ticket"><i className="fa fa-caret-right"></i> Support Tickets</a></li>
            </ul>
         </div>
         <div className="three columns">
            <h4>Account</h4>
            <hr className="black"/>
            <ul className="supportlinks">
               <li><a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN} title="Existing customer login"><i className="fa fa-caret-right"></i> Sign In</a></li>
               <li><a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/auth/signup'} title="Create a new account"><i className="fa fa-caret-right"></i> Create Account</a></li>
            </ul>
	 </div>
         <div className="three columns">
            <h4>Social &amp; Reviews</h4>
            <hr className="black"/>
            <ul className="supportlinks">
               <li><a href="https://twitter.com/swissmadehost" title="SwissMadeHOST Twitter account" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-twitter-square"></i> Twitter</a></li>
               <li><a href="https://www.whtop.com/review/swissmade.host" title="Swiss Made Host Reviews" target="_blank" rel="noopener noreferrer"><i className="fa fa-star"></i> Customer Reviews</a></li>
               <li><a href="https://speedtest.swissmade.host" title="Speed Test"><i className="fas fa-tachometer-alt"></i> Speed Test</a></li>
	    </ul>
         </div>
      </div>
      <div className="row">
      <div className="twelve columns">
      <hr className="black"/>
      </div>
      </div>
      <div className="row">
      <div className="four columns swissmade">
      <NavLink to="/en/" title="Proudly made in Switzerland">
      <span className="swissmade">Swiss <i className="fa fa-plus-square fa-spin" aria-hidden="true"></i> Made</span>
      </NavLink>
      </div>
      <div className="eight columns">
      <ul className="terms">
      <li><NavLink to="/en/terms-of-service"><i className="fa fa-file-text"></i> TERMS OF SERVICE</NavLink></li>
      <li><NavLink to="/en/acceptable-use-policy"><i className="fa fa-file-text"></i> ACCEPTABLE USE POLICY</NavLink></li>
      <li><NavLink to="/en/privacy-policy"><i className="fa fa-file-text"></i> PRIVACY POLICY</NavLink></li>
      <li className="last">All Rights Reserved 2011-{new Date().getFullYear()} &copy;</li>
      </ul>
      </div>
      </div>
	</div>
	<Analytics/>
		</footer>
    )
  }

  export default Footer;
