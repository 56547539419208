import React from 'react';
import { NavLink } from "react-router-dom";

class AboutCMS extends React.Component {

	render() {

		return (
			<div className="fullwidth light cms-hosting">
				<div className="row">
					<div className="four columns">
						<h3>What is a CMS</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fa-solid fa-lock fa-5x" aria-hidden="true"></i></div>
						<p>A Content Management System, or CMS, is a powerful tool that enables anyone to create and manage websites without any prior technical knowledge or expertise. With the help of a CMS, you can effortlessly craft a stunning and fully functional blog, photo gallery, portfolio, single-page site, or even a comprehensive e-commerce store.</p>
					</div>
					<div className="four columns">
						<h3>Dedicated Resources</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fa-solid fa-server fa-5x" aria-hidden="true"></i></div>
						<p>Every order is backed with hardware and networking resources (CPU cores, RAM and dedicated IP address), reserved exclusively to your CMS. A virtual server with a CMS of your choice will be up and running in just minutes after payment. Login instructions for the CMS control panel will be sent to your email for fast and easy access.</p>
					</div>
					{this.props.cms === 'all' &&
						<div className="four columns">
							<h3>Supported CMS's</h3>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa-brands fa-wordpress fa-5x" aria-hidden="true"></i></div>
							<p><NavLink to={this.props.page === 'offshore' ? '/en/offshore-wordpress-hosting' : '/en/wordpress-hosting-switzerland'}>WordPress</NavLink>, <NavLink to="/en/joomla-hosting-switzerland">Joomla</NavLink>, and <NavLink to="/en/drupal-hosting-switzerland">Drupal</NavLink> are the three most widely used content management systems (CMS) available on the web today. Among these three, WordPress has the biggest community and is known for flexibility, scalability and wide selection of plugins. If unsure, it is recommended to select WordPress as a CMS for your website.</p>
						</div>
					}
					{this.props.cms === 'wordpress' &&
						<div className="four columns">
							<h3>WordPress CMS</h3>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa-brands fa-wordpress fa-5x" aria-hidden="true"></i></div>
							<p><a href="https://wordpress.com/" target="_blank" rel="noopener noreferrer">WordPress</a> is a popular open-source content management system (CMS). It was first released in 2003 and is now one of the most widely used website creation tools on the internet. WordPress allows users to easily build and manage their website's content without needing to know HTML, CSS, or other programming languages.</p>
						</div>
					}
					{this.props.cms === 'joomla' &&
						<div className="four columns">
							<h3>Joomla CMS</h3>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa-brands fa-joomla fa-5x" aria-hidden="true"></i></div>
							<p><a href="https://www.joomla.org/" target="_blank" rel="noopener noreferrer">Joomla</a> is an open-source content management system (CMS) that provides a wide range of features and functionalities for website development. Joomla is often used for larger and more complex websites, such as news websites, online magazines, and intranet applications. It offers robust security features, a variety of extensions, and a flexible template system.</p>
						</div>
					}
					{this.props.cms === 'drupal' &&
						<div className="four columns">
							<h3>Drupal CMS</h3>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa-brands fa-drupal fa-5x" aria-hidden="true"></i></div>
							<p><a href="https://www.drupal.org/" target="_blank" rel="noopener noreferrer">Drupal</a> is a highly scalable and modular platform that is used for building a wide range of websites, from small personal blogs to large, complex enterprise websites. Drupal is known for its strong security features, flexibility, and scalability, making it a popular choice for organizations that need a robust, enterprise-level solution.</p>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default AboutCMS