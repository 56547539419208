import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react'; 
import { useParams } from 'react-router-dom';
import Redirecting from './redirecting';

function RedirectMemberPanel() {
  const history = useHistory();
  const { id, category, subcategory } = useParams();
  const memberDomain = process.env.REACT_APP_MEMBER_DOMAIN;

  useEffect(() => {
    const balancePath = `/en/balance`;
    let newPath = '';

    if (history.location.pathname === balancePath) {
      newPath = `//${memberDomain}/en/balance`;
    } else if (id) {
      newPath = `//${memberDomain}/en/cart/add/${id}`;
    } else if (category && subcategory) {
      newPath = `//${memberDomain}/en/docs/vmware/${category}/${subcategory}`;
    }

    if (newPath) {
      const timeoutId = setTimeout(() => {
        window.location.href = newPath;
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [history, id, category, subcategory, memberDomain]);

  return <Redirecting />; 
}

export default RedirectMemberPanel;
