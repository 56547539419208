import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';

const owloptions = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 3,
    slideBy: 1,
    responsive:{
        0:{
            items:1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        900:{
            items:3
        },
        1000:{
            items:3
        }
    }
};


class FeatureSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
	<div className="row">
		<div className="twelve columns">
    <OwlCarousel2 options={owloptions}>
	<div className="item">
				<h5>Limitless Scalability</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fas fa-expand-arrows-alt fa-5x" aria-hidden="true"></i></div>
				<p>Object storage is highly scalable, which means that it can easily and efficiently accommodate changes in the volume, variety, and velocity of data. To ensure ease of scalability, data is spread across multiple storage nodes or servers. Each node can be added or removed as needed without affecting the overall performance or availability of the storage system.</p>
			</div>
			<div className="item">
				<h5>Data Loss Protection</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fas fa-server fa-5x"></i></div>
				<p>Object storage offers robust data loss protection mechanisms that ensure the safety and integrity of data. One key aspect is the use of redundancy techniques such as erasure coding and replication. These techniques distribute multiple copies of data across different storage nodes for fail-proofing against hardware issues or network outages.</p>
			</div>
			<div className="item">
				<h5>Universal Storage</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fas fa-cube fa-5x" aria-hidden="true"></i></div>
				<p>Object storage is not tied to any specific application or workload, so you can use it for a wide range of purposes, including storing files, media, backups, and more. Object storage's flexibility and compatibility allow it to seamlessly integrate with different applications and provide a reliable and cost-effective storage solution for various use cases. </p>
			</div>
			<div className="item">
				<h5>Highest Availability</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-battery-full fa-5x" aria-hidden="true"></i></div>
				<p>Downtime can be a huge stress for clients and engineers working at the data center. Here's an insight on what measures are taken to avoid any unlikely occurrence: multiple powerlines with automatic switchover, UPS and diesel generators, Tier-1 network multihoming and SSD storage with data loss prevention for an extra layer of redundancy.</p>
			</div>
			<div className="item">
				<h5>Fast Network</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-bolt fa-5x" aria-hidden="true"></i></div>
				<p>Swiss Made Host operates a high-capacity, multi-homed network with robust DDoS protection. Object Storage nodes are connected to the Internet through a 10 Gbps port to ensure fast and responsive access to large amounts of data and eliminate latency issues and bottlenecks, which can impact the performance and responsiveness of client applications.</p>
			</div>

			<div className="item">
				<h5>Pay as You Go</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fas fa-wallet fa-5x" aria-hidden="true"></i></div>
				<p>Instead of committing to a fixed contract or upfront payment, customers are charged based on their actual usage of storage space and bandwidth. This approach allows customers to avoid large upfront costs and only pay for the GBs of storage and bandwidth they consumed, which can be more cost-effective and flexible.</p>
			</div>
			<div className="item">
				<h5>Direct Connection to SwissIX</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-upload fa-5x" aria-hidden="true"></i></div>
				<p>Our data center in Zurich has direct connection to <a title="SwissIX Swiss Internet Exchange" href="https://www.swissix.ch/" target="_blank" rel="noopener noreferrer">SwissIX</a> Internet Exchange, which is the largest Internet Exchange Point (IXP) in Switzerland in terms of peering participants. Direct IXP connection lowers latency by improving routing efficiency and provides an extra layer of networking fault-tolerance.</p>
			</div>
			<div className="item">
				<h5>24/7 Live Support</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
				<p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
			</div>
			<div className="item">
				<h5>Swiss Approach</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-plus-square fa-5x colored" aria-hidden="true"></i></div>
				<p>At SwissMade.host, we are proud to be a Zurich-based web hosting company that shares the most distinct features that Switzerland is renowned for. We guarantee that your orders and requests will be processed in accordance with our company's strict privacy, data protection, customer orientation and quality assurance policies.</p>
			</div>
		</OwlCarousel2>
		</div>
	</div>
</div>

            )
            }}

export default FeatureSlider