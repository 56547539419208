import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import DiscountCounter from './dscountCounter';
import AboutCMS from './aboutCMS';
import FreeSSL from './freeSSL'

class DrupalCMS extends React.Component {

	render() {

		const cms = 'drupal'

		return (
			<React.Fragment>
				<Helmet>
					<title>Drupal Hosting in Switzerland - Dedicated IP &amp; Private Server | Swiss Made Host</title>
					<meta name="description" content="Drupal CMS Hosting in Switzerland with dedicated resources and IP address." />
					<link rel="canonical" href="https://swissmade.host/en/drupal-hosting-switzerland" />
				</Helmet>
				<div className="fullwidth pic cms">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Drupal Hosting<span>in Switzerland</span></h1>
								<h2>Privacy-focused Content Management System Hosting powered by Drupal</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<AboutCMS cms={cms}/>
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider />
				</ReactDependentScript>
				<DiscountCounter cms={cms}/>
				<FreeSSL cms={cms}/>
				<PaymentMethodsInclude />
			</React.Fragment>
		)
	}
	toggleAnimation = (e) => {
		document.getElementById('ddos-protection-info').classList.toggle('expanded')
	}
}

export default DrupalCMS;