import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';


class DDoSConfigList extends Component {

    render() {

        const list = this.props.list;
        const payment_period = this.props.payment_period;
        const usdRate = this.props.usdRate;
        const eurRate = this.props.eurRate;

        return (
        <div className="fullwidth light">
	        <div className="row">
		    <div className="twelve columns">
            {list.map((item, i) =>
            <div className="row" key={i}>
            <div className="twelve columns">
                <div className="panel pricing">
                    <h3><span className="colored">{item.name}</span> Mitigation Package</h3>
                    <hr className="black"/>
                    <div className="four columns">
                        {item.id === 1 && <p>DDoS protection package for a single website that supports basic protocols only. It is recommended to select this package if only you are certain about the type and magnitude of attack that targets your website.</p>}
                        {item.id === 2 && <p>Advanced protection package for multiple websites and domains with extra features and extended support of protocols including HTTPS, SMTP and protected DNS hosting. As a rule of thumb, select this package when unsure what type or magnitude of a DDoS attack your web property is being hit with.</p>}
                        {item.id === 3 && <p>Enterprise-grade protection against all types of DDoS attacks for up to five websites and ten domains. This package includes industry leading mitigation technology, hours of our skilled engineers' work to fine-tune the filters and a ton of features to keep your web property secure yet accessible for all the legitimate requests.</p>}
                        <div className="row">
                            <div className="twelve columns monthly-fee margintop price-for-standard">
                                <span className="chf">
                                    <span className="val">
                                        {(payment_period === 1
                                            ? new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)
                                            : <React.Fragment><small style={{ color: '#D74937', textDecoration: 'line-through' }}><span style={{ color: '#4D4D4E' }}>{item.price.toFixed(2)} CHF</span></small><br/>
                                            {(item.price - (item.price / 100 * (payment_period - 1) * 2)).toFixed(2)} CHF
                                            </React.Fragment>
                                        )}
                                    </span></span>       
                                <span className="usd">
                                    <span className="val">{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)}</span> USD </span>
                                    / 
                                    <span className="eur"><span className="val"> {((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span> EUR</span>
                                    <br/> per month</div>
                        </div>
                        <hr className="black"/>
                        <div className="row ">
                            <div className="four columns">One-time Setup Fee:</div>
                            <div className="eight columns setup-fee">
                            {item.setup_fee === 0
                                ? <span className="chf">Free</span>
                                : <React.Fragment>
                                    <span className="chf">
                                {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.setup_fee)}
                                    </span> 
                                {(item.setup_fee * usdRate).toFixed(2)} USD / {(item.setup_fee * eurRate).toFixed(2)} EUR 
                                </React.Fragment>}
                          </div>
                        </div>
                        <span className="button radius large right showInfo" title="View details" onClick={this.toggleAnimation} animation-id={item.id}><i className="fa fa-info" aria-hidden="true" animation-id={item.id}></i></span><span className="link-for-standard"><a className="button radius large right" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + item.id + "?bill=" + payment_period} title="Configure and place order">{item.old_price !== 0 || payment_period > 1 ? 'Buy with discount' : 'Configure'}</a></span>
                    </div>
                    <div className="eight columns">
                        <ul className="dp">
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Max. websites</span> <span>{item.config.max_websites}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Max. domains </span><span>{item.config.max_domains}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Max. subdomains</span> <span>{item.config.max_subdomains < 1
                            ? 'Unlimited'
                            : item.config.max_subdomains
                            }</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Protected Protocols</span> <span className="value">{item.config.protocols}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Max. packets/s</span><span>{item.limits.packets}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Max. requests/s</span><span>{item.limits.requests < 1
                            ? 'Unlimited'
                            : item.limits.requests
                            }</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Max. attack bandwidth</span><span>{item.limits.attack_bandwidth} mbit/s</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Max. clean bandwidth</span><span>{item.limits.clean_bandwidth} mbit/s</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Mitigation Guarantee <i className="fa-regular fa-circle-question no-margin" data-tip data-for={'guarantee-'+item.id.toString()}></i></span><span><i className="fas fa-check colored"></i></span><ReactTooltip id={'guarantee-'+item.id.toString()} effect='solid'><span>100% SLA, moneyback policy</span></ReactTooltip></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Protected DNS hosting</span> <span>{item.config.dns_hosting === true ? <i className="fas fa-check colored"></i> : <i className="fas fa-times"></i>}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Content caching</span> <span>{item.config.content_caching === true ? <i className="fas fa-check colored"></i> : <i className="fas fa-times"></i>}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>SMTP protection</span> <span>{item.config.smtp_protection === true ? <i className="fas fa-check colored"></i> : <i className="fas fa-times"></i>}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>SSL protection</span> <span>{item.config.ssl_protection === true ? <i className="fas fa-check colored"></i> : <i className="fas fa-times"></i>}</span></li>

                        </ul>
                        <ul className="nobullet co expandInfo" id={"details-"+item.id}>
                        {Object.entries(item.upgrades)
                            .reduce((acc, val) => [
                                ...acc,
                                {...val[1], slug: val[0], plan_id: item.id}
                            ], [])
                            .sort((a, b) => a.price - b.price).map((item, i) =>
                            <li key={i}>
                                <span className="name">
                                    {item.name} {item.units_each.length !== 0 && '(' + item.units_each + ')'}
                                </span>
                                <div>
                                <span style={{cursor:'default'}} data-tip data-for={(item.id).toString()+(item.plan_id).toString()+(i).toString()}>{item.price} CHF per month <i className="fa-regular fa-circle-question"></i></span>
                                <ReactTooltip id={(item.id).toString()+(item.plan_id).toString()+(i).toString()} effect='solid'>
                                <p>{(item.price * this.props.usdRate).toFixed(2)} USD <br/> {(item.price * this.props.eurRate).toFixed(2)} EUR</p>
                                </ReactTooltip>
                                </div>
                            </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        )}
            </div>
            </div>
            </div>
            )
    }
    toggleAnimation = (e) => {
        document.getElementById("details-"+e.target.getAttribute('animation-id')).classList.toggle('expanded')
    }
}


export default DDoSConfigList;
