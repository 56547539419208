import React, { useState } from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import FAQinclude from '../../components/includes/FAQinclude';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import OffshoreSwitzerland from '../offshore-hosting/offshoreSwitzerland';
import AboutDedicated from './aboutDedicated';

const DedicatedServersOffshore = () => {
	const [productsJSON, setProductsJSON] = useState(null), 
	handleJsonLdGenerated = (jsonLd) => {
	  if (productsJSON === null) {
		setProductsJSON(jsonLd);
	  }
	  };

	return (
    <React.Fragment>
      <Helmet>
	  	<title>Offshore Dedicated Servers - Privacy-focused Hosting | Swiss Made Host</title>
		<meta name="description" content="Offshore Dedicated servers ranging from classic bare metal boxes to high end storage and GPU servers. Data center location: Zurich, Switzerland. Privacy guaranteed." />
		<link rel="canonical" href="https://swissmade.host/en/offshore-dedicated-servers"/>
		{productsJSON && <script type="application/ld+json">{JSON.stringify(productsJSON)}</script>}
	  </Helmet>

    <div className="fullwidth pic dedicated">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
					<h1 className="title">Offshore <span>Dedicated Servers</span></h1>
					<h2>High Privacy Dedicated Hosting with Strict Security Policies</h2>
				</div>
			</div>
		</div>
	</div>
      		<DiscountInclude/>
			 <AboutDedicated />
			<OffshoreSwitzerland />
			<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
				<FeatureSlider/>
			</ReactDependentScript>
			<DynamicContent onJsonLdGenerated={handleJsonLdGenerated} />
			<FAQinclude category={'dedicated'} color={'gray'}/>
			<PaymentMethodsInclude />

	</React.Fragment>
	)}

export default DedicatedServersOffshore;