import React from 'react';
import { NavLink } from "react-router-dom";

class Timeline extends React.Component {

	render() {

		return (
			<div className="fullwidth light align-center">
				<div className="row">
					<div className="twelve columns">
						<h2>Milestones</h2>
						<p>These are the major events and achievements that shape our company's path since it's estabishment in 2011.</p>
					</div>
				</div>
				<div className="row">
					<div className="twelve columns timeline">
						<div className="entries">
							<div className="entry">
								<div className="title big"><small>Jul</small> 2024</div>
								<div className="body">
									<p>Order management <NavLink to='/en/blog/swissmadehost-launches-new-api-for-streamlined-order-management'>API</NavLink> launched: This powerful tool offers a comprehensive range of functionalities to programatically create and manage your orders</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>Sep</small> 2023</div>
								<div className="body">
									<p>Speed Test widget lauched: This handy <a href="https://speedtest.swissmade.host/">widget</a> gives you instant insights into the network speed between your devices and our server in Zurich, Switzerland - a super useful tool, especially for our <NavLink to='/en/vpn-switzerland'>VPN</NavLink> customers</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Jul</small> 2023</div>
								<div className="body">
									<p>New addition to our offerings: The <NavLink to='/en/shadowsocks-vpn-switzerland'>Shadowsocks</NavLink> protocol is now available in our <NavLink to='/en/vpn-switzerland'>VPN servers</NavLink> lineup</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Oct</small> 2022</div>
								<div className="body">
									<p>New product launched: premium <NavLink to='/en/vpn-switzerland'>VPN</NavLink> based on a non-shared virtual server with dedicated IP address, powered by <NavLink to='/en/openvpn-switzerland'>OpenVPN</NavLink> and <NavLink to='/en/wireguard-vpn-switzerland'>WireGuard</NavLink>® protocols</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>Sep</small> 2022</div>
								<div className="body">
									<p>A total of 65 different <NavLink to='/en/dedicated-servers-switzerland'>dedicated server</NavLink> configurations are now available to order</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Aug</small> 2022</div>
								<div className="body">
									<p>New product launched: <NavLink to='/en/cms-hosting-switzerland'>CMS hosting</NavLink> running on a non-shared virtual server with dedicated IP, powered by <NavLink to='/en/wordpress-hosting-switzerland'>WordPress</NavLink>, <NavLink to='/en/drupal-hosting-switzerland'>Drupal</NavLink> and <NavLink to='/en/joomla-hosting-switzerland'>Joomla</NavLink> content management systems</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>Jan</small> 2022</div>
								<div className="body">
									<p>A warm welcome to our 25'000 client</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Dec</small> 2021</div>
								<div className="body">
									<p>New product launched:<NavLink to='/en/object-storage-switzerland'>S3-compatible Object Storage</NavLink> that can be used for a wide range of purposes, including storing files, media and backups</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>Jul</small> 2021</div>
								<div className="body">
									<p>A new <a href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/257"} target='_blank' rel="noopener noreferrer">Epic</a> server configuration with 2x AMD EPYC 7742 CPUs and a whopping 1TB of RAM added to stock, a total of 57 different <NavLink to='/en/dedicated-servers-switzerland'>dedicated server</NavLink> configurations are now available to order</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Jun</small> 2021</div>
								<div className="body">
									<p>A new, beautiful, intuitive and easy to navigate <a href={"//" + process.env.REACT_APP_MEMBER_DOMAIN} target='_blank' rel="noopener noreferrer">member account</a> user interface goes live</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Apr</small> 2021</div>
								<div className="body">
									<p>A number of new high performance dedicated server configurations have been <NavLink to="/en/blog/new-high-performance-swiss-based-dedicated-servers-with-a-10-discount">added</NavLink> to our inventory</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>May</small> 2020</div>
								<div className="body">
									<p>A total of 43 different <NavLink to='/en/dedicated-servers-switzerland'>dedicated server</NavLink> configurations are now available to order</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>Jan</small> 2020</div>
								<div className="body">
									<p>A warm welcome to our 20'000 client</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>Jun</small> 2019</div>
								<div className="body">
									<p>A total of 35 different <NavLink to='/en/dedicated-servers-switzerland'>dedicated server</NavLink> configurations are now available to order</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Jan</small> 2017</div>
								<div className="body">
									<p><NavLink to='/en/vps-switzerland'>VPS</NavLink> and <NavLink to='/en/cloud-switzerland'>Cloud</NavLink> nodes' data moved from SAN storage to the lightning fast encrypted and resilient <a href="https://www.netapp.com/data-storage/solidfire/" target='_blank' rel="noreferrer noopener">SSD storage by SolidFire®</a></p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Nov</small> 2016</div>
								<div className="body">
									<p>A major upgrade to the <a href={"//" + process.env.REACT_APP_MEMBER_DOMAIN} target='_blank' rel="noopener noreferrer">member account</a> user interface packed with advanced features, improved usability and beautiful layout</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Jul</small> 2016</div>
								<div className="body">
									<p>Capacity of <NavLink to='/en/ddos-protection-switzerland'>DDoS protection</NavLink> filter increased to 400 Gbps to efficiently mitigate against some of the most massive attacks registered to date</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>Jun</small> 2016</div>
								<div className="body">
									<p>A warm welcome to our 10'000 client</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>Apr</small> 2016</div>
								<div className="body">
									<p>A total of 29 different <NavLink to='/en/dedicated-servers-switzerland'>dedicated server</NavLink> configurations are now available to order</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Jan</small> 2016</div>
								<div className="body">
									<p><NavLink to='/en/vps-switzerland'>VPS</NavLink> nodes nodes migrated from OnApp® and Xen hypervisors to enterprise-class VMware® hypervisors</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Dec</small> 2015</div>
								<div className="body">
									<p>We moved all our hardware to an ultra secure, carbon neutral and multihomed Interxion™ data storage & processing <NavLink to='/en/data-center-switzerland'>facility</NavLink> in Zurich, Switzerland</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Dec</small> 2014</div>
								<div className="body">
									<p>New product launched: <NavLink to='/en/cdn-switzerland'>CDN</NavLink> that can deliver content up to four times faster than a single server</p>
								</div>
							</div>
							<div className="entry">
								<div className="title"><small>Oct</small> 2013</div>
								<div className="body">
									<p>A warm welcome to our 5'000 client</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Jan</small> 2013</div>
								<div className="body">
									<p>New product launched: <NavLink to='/en/vps-switzerland'>VPS</NavLink> - a cost-efficient web hosting platform for non-demanding tasks</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Mar</small> 2012</div>
								<div className="body">
									<p>New product launched: high availability <NavLink to='/en/cloud-switzerland'>Cloud hosting</NavLink> with self-healing architecture</p>
								</div>
							</div>
							<div className="entry">
								<div className="title big"><small>Mar</small> 2011</div>
								<div className="body">
									<p>First customers put their trust in <NavLink to='/en/dedicated-servers-switzerland'>dedicated server</NavLink> hosting plans offered by our company</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Timeline