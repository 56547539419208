import React from 'react';
import Helmet from "react-helmet";
import PickProduct from './pickProduct';

function NotFound() {
  return (

    <React.Fragment>
      <Helmet>
                <title>404 - Page Not Found | Swiss Made Host</title>
                <meta name="description" content="The page your were looking for could not be found." />
      </Helmet>
		  <div className="fullwidth pic error404">
			  <div className="row">
				  <div className="twelve columns">
					  <div className="transparent">
						  <h1 className="title">Page Not Found <span>ERROR 404</span></h1>
						  <h2>We are sorry, but the page you are looking for does not exist</h2>
					  </div>
				  </div>
			  </div>
		  </div>
		<PickProduct />
	</React.Fragment>
  );
}
 
export default NotFound;