import React from 'react';
import { NavLink } from "react-router-dom";

function PickProduct() {
  return (
		  <div className="fullwidth dark">
			  <div className="row">
				  <div className="twelve columns">
					  <div className="row">
						  <div className="four columns">
							  <h2>Pick a Product</h2>
							  <hr className="gray"/>
								  <p>If you typed a web address in your browser please check that it was correct. If you were looking for products and services, provided by our company, please click a corresponding link.</p>
						  </div>
						  <div className="four columns">
							  <ul className="arrow">
								  <li><NavLink to="/en/">Home Page</NavLink></li>
								  <li><NavLink to="/en/dedicated-servers-switzerland">Dedicated Servers</NavLink></li>
								  <li><NavLink to="/en/cloud-switzerland">Cloud Servers</NavLink></li>
								  <li><NavLink to="/en/vps-switzerland">VPS</NavLink></li>
								  <li><NavLink to="/en/vpn-switzerland">VPN</NavLink></li>
								  <li><NavLink to="/en/cms-hosting-switzerland">CMS Hosting</NavLink></li>
							  </ul>
						  </div>
						  <div className="four columns">
							  <ul className="arrow">
								  <li><NavLink to="/en/ddos-protection-switzerland">DDoS Protection</NavLink></li>
								  <li><NavLink to="/en/colocation-switzerland">Colocation</NavLink></li>
								  <li><NavLink to="/en/cdn-switzerland">CDN</NavLink></li>
								  <li><NavLink to="/en/cpanel-switzerland">Control Panels</NavLink></li>
								  <li><NavLink to="/en/object-storage-switzerland">Object Storage</NavLink></li>
							  </ul>
						  </div>
					  </div>
				  </div>

			  </div>
		  </div>
  );
}
 
export default PickProduct;