import React, { Component } from 'react';

class CMSProductList extends Component {

    render() {

        const list = this.props.list,
            usdRate = this.props.usdRate,
            eurRate = this.props.eurRate,
            payment_period = this.props.payment_period,
            discountPercent = this.props.discountPercent,
            discountActive = this.props.discountActive,
            firstMonth = this.props.firstMonth,
            cms = this.props.cms,
            couponCode = this.props.couponCode

        this.replaceDigits = (payment_period) => {
            var words = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve']
            return (
                words[payment_period]
            )
        }

        return (
            <React.Fragment>
                {list.filter(app => app.type === 'cms').map((item, i) =>

                    <div className="four columns" key={i}>
                        <div className="panel product-alt">
                            <h3>
                                {cms === 'all' && 'CMS '}
                                {cms === 'wordpress' && 'WordPress '}
                                {cms === 'joomla' && 'Joomla '}
                                {cms === 'drupal' && 'Drupal '}
                                {item.name}
                            </h3>
                            {item.old_price !== 0 && <div className='tag'>Special Offer</div>}
                            {discountActive === true && firstMonth !== true ? <div className='tag'>{discountPercent}% Off First Month</div> : null}
                            <div className='line'><span>Cores:</span><span>{item.config.cores}</span></div>    
                            <div className='line'><span>Storage:</span><span>{item.storage} GB</span></div>                        
                            <div className='line'><span>RAM:</span><span>{item.config.ram} MB</span></div>                        
                            <div className='line'>
                                <span>CMS:</span>
                                <span>
                                    {cms === 'all' && 'Wordpress, Drupal, Joomla'}
                                    {cms === 'wordpress' && 'Wordpress'}
                                    {cms === 'drupal' && 'Drupal'}
                                    {cms === 'joomla' && 'Joomla'}
                                </span>
                            </div>
                            <div className='line'><span>Location:</span><span>Switzerland <img src="/images/swiss-flag.png" alt="Switzerland" /></span></div>
                            <div className='line'><span>Server:</span><span>Private</span></div>
                            <div className='line'><span>IP address:</span><span>Dedicated</span></div>
                            <div className='line'><span>SSL certificate:</span><span>Free of charge</span></div>
                            <div className='line'><span>Crypto:</span><span>Accepted</span></div>
                            {(

                                discountActive === true
                                    ?
                                    payment_period === 1
                                        ? item.old_price !== 0
                                            ? <div className="price">
                                                <span style={{ color: '#d74937', textDecoration: 'line-through'}}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price)}</span></span>
                                                <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price * discountPercent / 100)} </span>
                                                <div className="coinPrice"><span>${(item.price * discountPercent / 100 * usdRate).toFixed(2)} / €{(item.price * discountPercent / 100 * eurRate).toFixed(2)}</span><span><small>per {firstMonth === true && 'first'} month</small></span></div>
                                            </div>
                                            : <div className="price">
                                                <span style={{ color: '#d74937', textDecoration: 'line-through'}}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)}</span></span>
                                                <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price * discountPercent / 100))} </span>
                                                <div className="coinPrice"><span>${(item.price * discountPercent / 100 * usdRate).toFixed(2)} / €{(item.price * discountPercent / 100 * eurRate).toFixed(2)}</span><span><small>per {firstMonth === true && 'first'} month</small></span></div>
                                            </div>
                                        : item.old_price !== 0
                                            ? <React.Fragment><span className='savings'>Save {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(((item.old_price - item.price) * payment_period) + ((item.price - ((item.price - (item.price / 100 * (payment_period - 1) * 2)))) * payment_period))} over {this.replaceDigits(payment_period)} months.</span>
                                                <div className="price">
                                                    <span style={{ color: '#d74937', textDecoration: 'line-through'}}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price)}</span></span>
                                                    <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                    <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>{firstMonth === true ? 'per second and further months' : 'per month'}</small></span></div>
                                                </div>
                                            </React.Fragment>
                                            : <React.Fragment><span className='savings'>Save {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(((item.price - ((item.price - (item.price / 100 * (payment_period - 1) * 2)))) * payment_period))} ({(payment_period - 1) * 2}%) over {this.replaceDigits(payment_period)} months.</span>
                                                <div className="price">
                                                    <span style={{ color: '#d74937', textDecoration: 'line-through'}}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)}</span></span>
                                                    <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                    <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>{firstMonth === true ? 'per second and further months' : 'per month'}</small></span></div>
                                                </div>
                                            </React.Fragment>

                                    :
                                    payment_period === 1
                                        ? item.old_price !== 0
                                            ? <div className="price">
                                                <span style={{ color: '#d74937', textDecoration: 'line-through'}}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price)}</span></span>
                                                <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>per month</small></span></div>
                                            </div>
                                            : <React.Fragment><div className="price"><span className='basePrice'>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)}</span><span className='coinPrice'>${(item.price * usdRate).toFixed(2)} / €{(item.price * eurRate).toFixed(2)}</span><span><small>per month</small></span></div></React.Fragment>
                                        : item.old_price !== 0
                                            ? <React.Fragment><span className='savings'>Save {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(((item.old_price - item.price) * payment_period) + ((item.price - ((item.price - (item.price / 100 * (payment_period - 1) * 2)))) * payment_period))} over {this.replaceDigits(payment_period)} months.</span>
                                                <div className="price">
                                                    <span style={{ color: '#d74937', textDecoration: 'line-through'}}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price)}</span></span>
                                                    <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                    <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>per month</small></span></div>
                                                </div>
                                            </React.Fragment>
                                            : <React.Fragment><span className='savings'>Save {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(((item.price - ((item.price - (item.price / 100 * (payment_period - 1) * 2)))) * payment_period))} ({(payment_period - 1) * 2}%) over {this.replaceDigits(payment_period)} months.</span>
                                                <div className="price">
                                                    <span style={{ color: '#d74937', textDecoration: 'line-through'}}><span className="oldPrice">{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)}</span></span>
                                                    <span className='basePrice' style={{ paddingTop: '0' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))} </span>
                                                    <div className="coinPrice"><span>${((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)} / €{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span><span><small>per month</small></span></div>
                                                </div>
                                            </React.Fragment>
                            )}
                                <a className="button radius large" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + item.id + "?bill=" + payment_period + (discountActive === true ? '&voucher=' + couponCode : '')}>{discountActive === true || payment_period > 1 || item.old_price !== 0 ? 'Buy with discount' : 'Configure'}</a>                     
                        </div>
                    </div>


                )}
            </React.Fragment>
        )
    }
}

export default CMSProductList;
