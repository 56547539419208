import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import FeatureSlider from './featureSlider';
import ProductSlider from './productSlider';
import ReactDependentScript from "react-dependent-script";
import "../../css/owl.theme.default.min.css";
import "../../css/owl.carousel.min.css";
import LocationSwitzerland from '../../components/includes/locationSwitzerland';
import DataVault from '../../components/includes/dataVault';
import BlogWidget from './blogWidget';

class HomePage extends React.Component {

	render() {

		return (

			<React.Fragment>
				<Helmet>
					<title>Swiss Hosting Solutions - Proudly made in Switzerland with privacy in mind | Swiss Made Host</title>
					<meta name="description" content="Privacy-focused dedicated servers, VPS and cloud hosting at an ultra-secure data center in Zurich, Switzerland with zero carbon footprint and premium connectivity." />
					<link rel="canonical" href="https://swissmade.host/en/" />
				</Helmet>
				<div className="fullwidth pic index">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Dedicated and Virtual Server Hosting in <span><i className="fa fa-plus-square" aria-hidden="true"></i> Switzerland</span></h1>
								<h2>We care about your privacy</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<ProductSlider />
					<LocationSwitzerland />
					<DataVault />
					<FeatureSlider />
				</ReactDependentScript>
				<PaymentMethodsInclude />
				<BlogWidget />
			</React.Fragment>
		)
	}
}

export default HomePage;