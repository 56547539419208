import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import FAQinclude from '../../components/includes/FAQinclude';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import LocationSwitzerland from '../../components/includes/locationSwitzerland';
import MapCompanyDataCenter from '../../components/includes/mapCompanyDataCenter';

class ColocationSwitzerland extends React.Component {

	render() { 

	return (
    <React.Fragment>
    	<Helmet>
		<title>Colocation in Zurich, Switzerland @ Interxion™ Data Center | Swiss Made Host</title>
		<meta name="description" content="Swiss made colocation services at an ultra secure, carbon and carrier-neutral Interxion data center in Zurich, Switzerland. Compliance with ITILv3 framework, ISO 27001 and ISO 22301." />
		<link rel="canonical" href="https://swissmade.host/en/colocation-switzerland"/>
		</Helmet>
    <div className="fullwidth pic colocation">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
					<h1 className="title">Colocation in Zurich, <span><i className="fa fa-plus-square" aria-hidden="true"></i> Switzerland</span></h1>
					<h2>@ Interxion™ Data Center</h2>
				</div>
			</div>
		</div>
	</div>

	<DiscountInclude/>
	<LocationSwitzerland/>
	<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
		<FeatureSlider/>
	</ReactDependentScript>
	<DynamicContent type='all'/>
	<FAQinclude category={'colocation'} color={'dark'}/>
	<ReactDependentScript scripts={["https://maps.googleapis.com/maps/api/js?key=AIzaSyAV2hiC14nyL2QLQvfd-WF2yT1iqZno4kI&libraries=places&callback=initMap"]}>
	<MapCompanyDataCenter color={'light'}/>
</ReactDependentScript>
	<PaymentMethodsInclude color={'dark'}/>

	</React.Fragment>
	)}
}

export default ColocationSwitzerland;