import React from 'react';
import { NavLink } from "react-router-dom";
import { axiosinstance, axiosCatch } from '../../components/axios';
import lscache from 'lscache';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import OwlCarousel2 from 'react-owl-carousel2';
import "../../css/owl.carousel.min.css";

const owlOptionsHomePage = {
	loop: true,
	dots: true,
	margin: 10,
	nav: false,
	autoplay: true,
	autoplayTimeout: 5000,
	center: true,
	items: 3,
	slideBy: 1,
	responsive: {
		0: {
			items: 1,
			slideBy: 1,
			autoplayTimeout: 5000
		},
		800: {
			items: 1,
			slideBy: 1,
			autoplayTimeout: 5000
		},
		1000: {
			items: 3,
			slideBy: 1,
			autoplayTimeout: 5000
		}
	}
};

class ListOfProducts extends React.Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			error: false,
			products: [
				{
					"title": "Dedicated Servers",
					"description": "A wide selection of dedicated servers ranging from low end bare metal servers that offer a healthy balance between price and computation power to extreme performance clusters and high capacity storage servers.",
					"short": "server",
					"img_url": "dedicated-servers.jpg",
					"img_alt": "Dedicated Servers in Switzerland",
					"url": "/en/dedicated-servers-switzerland",
					"url_offshore": "/en/offshore-dedicated-servers",
					"url_freespeech": "/en/free-speech-dedicated-servers"
				},
				{
					"title": "VPS Hosting",
					"description": "High-availability VMware®-based Virtual Private Servers with all-flash SSD storage that can fit virtually any task and provide user experience very similar to that of a dedicated server.",
					"short": "vps",
					"img_url": "vps.jpg",
					"img_alt": "VPS in Switzerland",
					"url": "/en/vps-switzerland",
					"url_offshore": "/en/offshore-vps",
					"url_freespeech": "/en/free-speech-vps"
				},
				{
					"title": "Cloud Servers",
					"description": "Cloud Server is basically one of the most advanced, secure and highly available means of hosting a mission critical web site, service or application. Main advantages of Cloud hosting are scalability and higher reliability.",
					"short": "cloud",
					"img_url": "cloud.jpg",
					"img_alt": "Cloud Servers in Switzerland",
					"url": "/en/cloud-switzerland",
					"url_offshore": "/en/offshore-cloud",
					"url_freespeech": "/en/free-speech-cloud"
				},
				{
					"title": "Colocation Zürich",
					"description": "Colocation service at Interxion™ Zurich data center - an ultra-secure, known location for storing and processing data in line with Swiss data protection regulations, ISO 27001 and ISO 22301 certification.",
					"short": "colocation",
					"img_url": "colocation.jpg",
					"img_alt": "Colocation in Switzerland",
					"url": "/en/colocation-switzerland",
					"url_offshore": "/en/offshore-colocation",
					"url_freespeech": "/en/free-speech-colocation"
				},
				{
					"title": "DDoS Protection",
					"description": "Our team has been successfully mitigating against DDoS attacks since 2011. We've developed a proprietary solution that will protect your website against DDoS attacks with SLA guarantee.",
					"short": "protection",
					"img_url": "ddos-protection.jpg",
					"img_alt": "DDoS Protection Switzerland",
					"url": "/en/ddos-protection-switzerland",
					"url_offshore": "/en/offshore-ddos-protection",
					"url_freespeech": "/en/ddos-protection-switzerland"
				},
				{
					"title": "CDN",
					"description": "The main objective of CDN is to reduce time of content delivery to each particular user to up to 4 times in comparison to serving content from a single server and handle bandwidth usage spikes.",
					"short": "cdn_per_gb",
					"img_url": "cdn.jpg",
					"img_alt": "CDN",
					"url": "/en/cdn-switzerland",
					"url_offshore": "/en/cdn-switzerland",
					"url_freespeech": "/en/cdn-switzerland"
				},
				{
					"title": "Control Panels",
					"description": "cPanel® & WHM® control panels offer a wide range of built-in features and tools that allow to control virtually every aspect of your website including mail, security settings, domain and DNS, file backups and databases.",
					"short": "license",
					"img_url": "control-panels.png",
					"img_alt": "cPanel, WHM & Remote Desktop",
					"url": "/en/cpanel-switzerland",
					"url_offshore": "/en/cpanel-switzerland",
					"url_freespeech": "/en/cpanel-switzerland"
				},
				{
					"title": "VPN Switzerland",
					"description": "A private VPN server with dedicated IP address that geolocates to Zurich, Switzerland. Uncompromised security without having to set up and configure your own server.",
					"short": "vpn",
					"img_url": "vpn.png",
					"img_alt": "VPN in Switzerland",
					"url": "/en/vpn-switzerland",
					"url_offshore": "/en/offshore-vpn",
					"url_freespeech": "/en/free-speech-vpn"
				},
				{
					"title": "CMS Hosting",
					"description": "A Content Management System, or CMS, is a powerful tool that enables anyone to create and manage websites without any prior technical knowledge or expertise. Supported platforms: WordPress, Drupal and Joomla.",
					"short": "cms",
					"img_url": "cms.png",
					"img_alt": "CMS Hosting in Switzerland",
					"url": "/en/cms-hosting-switzerland",
					"url_offshore": "/en/offshore-cms-hosting",
					"url_freespeech": "/en/free-speech-cms-hosting"
				},
				{
					"title": "Object Storage S3",
					"description": "Object storage offers a range of advantages over hierarchical storage, including scalability, durability, accessibility, and cost-effectiveness. Easy integration with other S3-compatible applications and services.",
					"short": "storage",
					"img_url": "cms.png",
					"img_alt": "CMS Hosting in Switzerland",
					"url": "/en/object-storage-switzerland",
					"url_offshore": "/en/offshore-object-storage",
					"url_freespeech": "/en/free-speech-object-storage"
				}
			]
		}
	}

	componentDidMount() {

		this.load();
	}

	load = () => {

		if (lscache.get('startingFrom') === null) {

			axiosinstance({
				method: 'get',
				url: process.env.REACT_APP_BACKEND_URL + "/products_promo.json",
				params: {},
			})
				.then((response) => {
					if (response.status === 200) {
						if (response.data.result === true) {
							lscache.set('startingFrom', response.data.data, 10);
							this.setState({
								minPrices: response.data.data,
								productsWithPricing: Object.entries(this.state.products).reduce((acc, val) => [
									...acc,
									{ ...val[1], price_from: response.data.data[val[1].short] }
								], [])
							});

						} else {
							this.setState({
								error: true
							});
						}
					} else {
						this.setState({
							error: true
						});
					}
				}).catch((args) => {
					axiosCatch(args, this.props);
				});

		}

		else {
			this.setState({
				minPrices: lscache.get('startingFrom'),
				productsWithPricing: Object.entries(this.state.products).reduce((acc, val) => [
					...acc,
					{ ...val[1], price_from: lscache.get('startingFrom')[val[1].short] }
				], [])
			})
		}

		if (lscache.get('exchangeRates') === null) {

			axiosinstance.get(process.env.REACT_APP_BACKEND_URL + "/variables.json")
				.then(res => {

					if (res.status === 200 && res.data.result === true) {
						this.setState({
							usd: res.data.data.currencies.find(x => x.short_name === 'USD').rate,
							eur: res.data.data.currencies.find(x => x.short_name === 'EUR').rate,
							btc: res.data.data.currencies.find(x => x.short_name === 'BTC').rate
						})
						lscache.set('exchangeRates', res, 3);
					}

					else {
						this.setState({
							error: true
						});
					}

				})
		}
		else {
			this.setState({
				usd: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'USD').rate,
				eur: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'EUR').rate,
				btc: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'BTC').rate
			})
		}

	};

	render() {

		const page = this.props.page

		let productsMap = !this.state.productsWithPricing ? this.state.products : this.state.productsWithPricing

		if (page === 'home') {
			return (
				<OwlCarousel2 options={owlOptionsHomePage} ref={this.carousel}>
					{productsMap.map((product, i) =>
						<div className="item" key={i}>
							<div className="panel products">
								<NavLink to={product.url} className="product-title"><h2>{product.title}</h2></NavLink>
								<hr className="black" />
								<p>{product.description}</p>
								<div className="row">
									<div className="twelve columns from margintop">
										{!this.state.productsWithPricing
											? <Skeleton className={'skeletonPreloader'} />
											: <React.Fragment><span className="chf"><small>from</small> {product.price_from} CHF</span><span>{(product.price_from * this.state.usd).toFixed(2)} USD / {(product.price_from * this.state.eur).toFixed(2)} EUR</span><br /><span>{product.short === 'cdn_per_gb' ? 'per GB' : 'per month'}</span></React.Fragment>
										}
									</div>
									<NavLink to={product.url} className="button radius large right">View plans</NavLink>
								</div>
							</div>
						</div>
					)}
				</OwlCarousel2>
			)
		}

		else {
			return (
				<div className="fullwidth light">
					<div className="row" >
						<div className="twelve columns products-pane" >
							{productsMap.map((product, i) =>
								<div className="four columns" key={i}>
									<div className="panel pricing products">
										<NavLink to={page === 'offshore' ? product.url_offshore : page === 'freespeech' ? product.url_freespeech : product.url} className="product-title"><h1>{product.title}</h1></NavLink>
										<hr className="black" />
										<p>{product.description}</p>
										<div className="row price">
											<div className="twelve columns from margintop">
												{!this.state.productsWithPricing
													? <Skeleton className={'skeletonPreloader'} />
													: <React.Fragment><span className="chf"><small>from</small> {product.price_from} CHF</span><span>{(product.price_from * this.state.usd).toFixed(2)} USD / {(product.price_from * this.state.eur).toFixed(2)} EUR</span><br /><span>{product.short === 'cdn_per_gb' ? 'per GB' : 'per month'}</span></React.Fragment>
												}
											</div>
										</div>
										<NavLink className="button radius large right" to={page === 'offshore' ? product.url_offshore : page === 'freespeech' ? product.url_freespeech : product.url}>View plans</NavLink>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			)
		}
	}
}

export default ListOfProducts
