import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';
import { NavLink } from 'react-router-dom';

const owloptions = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 3,
    slideBy: 1,
    responsive:{
        0:{
            items:1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        900:{
            items:3
        },
        1000:{
            items:3
        }
    }
};


class FeatureSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
	<div className="row">
		<div className="twelve columns">
    <OwlCarousel2 options={owloptions}>
			<div className="item">
				<h5>High Availability</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-battery-full fa-5x" aria-hidden="true"></i></div>
				<p>Downtime can be a huge stress for clients and engineers working at the data center. Here's an insight on what measures are taken to avoid any unlikely occurrence: multiple powerlines with automatic switchover, UPS and diesel generators, Tier-1 network multihoming and SSD storage with data loss prevention for an extra layer of redundancy.</p>
			</div>
			<div className="item">
				<h5>Premium Network Connectivity</h5>
				<hr className="grey"/>
				<div className="left paddingright"><span className="fa-stack fa-3x">
					<i className="fa-regular fa-square fa-stack-2x"></i>
					<i className="fa-solid fa-signal fa-stack-1x"></i>
					</span>
				</div>
				<p>We have put a lot of effort into building a no single point of failure network architecture: redundant hardware with automatic failover protection, multiple Tier-1 bandwidth providers, automatic network health monitoring and 24/7/365 intelligent hands on site. </p>
			</div>
			<div className="item">
				<h5>Full Disk Encryption</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-hdd fa-5x" aria-hidden="true"></i></div>
				<p>Full disk encryption is a method of protecting data by encrypting all of the data on server's hard drive including OS files. When fully encrypted, a passphrase needs to be entered via KVM/IPMI on every boot. This can help prevent data breaches and unauthorized access to sensitive information.</p>
			</div>
			<div className="item">
				<h5>Direct Connection to SwissIX</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-upload fa-5x" aria-hidden="true"></i></div>
				<p>Our data center in Zurich has direct connection to <a title="SwissIX Swiss Internet Exchange" href="https://www.swissix.ch/" target="_blank" rel="noopener noreferrer">SwissIX</a> Internet Exchange, which is the largest Internet Exchange Point (IXP) in Switzerland in terms of peering participants. Direct IXP connection lowers latency by improving routing efficiency and provides an extra layer of networking fault-tolerance.</p>
			</div>
			<div className="item">
				<h5>15 TB of Bandwidth Included</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-globe fa-5x" aria-hidden="true"></i></div>
				<p>Each bare metal server is provisioned with 15 TB of bandwidth for both uplink/downlink connections. Should you need to increase your bandwidth limits, please select a corresponding option while configuring your server or anytime later after placing the order. </p>
			</div>
			<div className="item">
				<h5>DDoS Protection</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-shield-halved fa-5x" aria-hidden="true"></i></div>
				<p>DDoS attack can disrupt operation of almost any web property, even a well established one. Please don't worry about this unlikely scenario, we've got you covered. Our engineers have developed a proprietary solution that will <NavLink to="/en/ddos-protection-switzerland">protect your servers</NavLink> against 99.999% of DDoS attacks registered to this day.</p>
			</div>
			<div className="item">
				<h5>Data Protection</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-key fa-5x" aria-hidden="true"></i></div>
				<p>Switzerland is well known for its strict laws on privacy and data protection - a hosting provider cannot be forced to disclose data unless when Swiss authorities investigate a client and an injunction is obtained. There's hardly any other country in the world with a matching grade of <NavLink to="/en/data-protection-switzerland">data protection</NavLink> at the state level.</p>
			</div>
			<div className="item">
				<h5>KVM/IPMI With Every Server</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-terminal fa-5x" aria-hidden="true"></i></div>
				<p>IPMI (Intelligent Platform Management Interface) and KVM (Keyboard, Video, Mouse, over IP) are very usefull tools that will help you manage your server remotely and install custom operating systems with your own image as if you had direct physical access to your server. </p>
			</div>
			<div className="item">
				<h5>Private Networking</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-sitemap fa-5x" aria-hidden="true"></i></div>
				<p>If you have ordered two or more dedicated servers from our company, you can easily connect them to an isolated private network with no public access from the internet. This feature can be especially useful for certain Backend/Frontend interconnection setups.</p>
			</div>
			<div className="item">
				<h5>Virtualization Ready</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-object-group fa-5x" aria-hidden="true"></i></div>
				<p>Most of our dedicated servers are virtualization-ready and can serve you as a hypervisor. This technology enables you to allocate hardware resources to virtual instances, deploy and manage your own virtual machines or build a private cloud.</p>
			</div>
			<div className="item">
				<h5>Fast Server Deployment</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-bolt fa-5x" aria-hidden="true"></i></div>
				<p>Once your payment is processed and cleared, our engineers will initiate server deployment process according to your order specifications. This may take anywhere between half an hour and five hours depending on server configuration and OS selection, however, normally it takes no more than just an hour.</p>
			</div>
			<div className="item">
				<h5>24/7 Live Support</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
				<p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}}>live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
			</div>
			<div className="item">
				<h5>Swiss Approach</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-plus-square fa-5x colored" aria-hidden="true"></i></div>
				<p>At SwissMade.host, we are proud to be a Zurich-based web hosting company that shares the most distinct features that Switzerland is renowned for. We guarantee that your orders and requests will be processed in accordance with our company's strict privacy, data protection, customer orientation and quality assurance policies.</p>
			</div>
		</OwlCarousel2>
		</div>
	</div>
</div>

            )
            }}

export default FeatureSlider