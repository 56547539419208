import React from 'react';
import Helmet from "react-helmet";
import PickProduct from '../../pages/404/pickProduct';

function Redirecting() {
  return (
    <React.Fragment>
      <Helmet>
                <title>301 - Page Moved Permanently | Swiss Made Host</title>
                <meta name="description" content="The page your were looking has been moved to another location." />
      </Helmet>
		  <div className="fullwidth pic error301">
			  <div className="row">
				  <div className="twelve columns">
					  <div className="transparent">
						  <h1 className="title">Page Moved Permanently <span>CODE 301</span></h1>
						  <h2>You will now be redirected, as the page you are looking for has been moved to another location.</h2>
					  </div>
				  </div>
			  </div>
		  </div>
		  <PickProduct />
	</React.Fragment>
  );
}
 
export default Redirecting;