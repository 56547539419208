import React, { useContext, useState } from 'react';
import { JsonData } from '../../components/json/JsonData';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ListOptions = () => {

    const paymentOptions = useContext(JsonData)
    const [searchTerm, setSearchTerm] = useState('');
    const cryptoPayment = paymentOptions.cryptocurrencies.filter((a) => a.url !== 'crypto' && (a.name.toLowerCase().includes(searchTerm.toLowerCase()) || a.ticker.toLowerCase().includes(searchTerm.toLowerCase())));
    const fiatPayment = paymentOptions.fiat.filter((a) => a.name.toLowerCase().includes(searchTerm.toLowerCase()) || a.ticker.toLowerCase().includes(searchTerm.toLowerCase()) || a.category.toLowerCase().includes(searchTerm.toLowerCase()));
    const paymentOptionsTotal = paymentOptions.cryptocurrencies.length + paymentOptions.fiat.length

    return (


        <div className="fullwidth light">
            <div className="row">
                <div className="twelve columns min-height">
                    <div className="row flex-center">
                        <div className="six columns search-container">
                            <input
                                type="text"
                                placeholder={`Search ${paymentOptionsTotal} payment options...`}
                                onChange={(event) => setSearchTerm(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row paymentMethods">
                        {fiatPayment.length > 0
                            ? (fiatPayment.map((item, i) =>
                                <div className="four columns option" key={i} title={`SWISSMADE.HOST accepts ${item.name}`}>
                                    <span>
                                        <h3>{`${item.name} (${item.ticker})`}</h3>
                                        <LazyLoadImage alt={`${item.name} (${item.ticker}) accepted`} title={`${item.name} (${item.ticker}) accepted`} height={50} width={50} src={`/images/payment/${item.ticker.toLowerCase()}.svg`} onError={(event) => event.target.setAttribute('src', '/images/payment/crypto.svg')} />
                                    </span>
                                    <p>{item.short_desc}</p>
                                </div>
                            ))
                            : cryptoPayment.length >= 0 ? '' :
                                <div className="four columns option">
                                    <div className="panel">
                                        <div className="row">
                                            <div className="four columns">
                                                <h3>Not Found</h3>
                                                <p>No payment method matches search parameters.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="row paymentMethods">
                        {cryptoPayment.length > 0
                            ? (cryptoPayment.map((item, i) =>
                                <div className="four columns option" key={i} title={`SWISSMADE.HOST accepts ${item.name}`}>
                                    <span>
                                        <h3>{`${item.name} (${item.ticker})`}</h3>
                                        <LazyLoadImage alt={`${item.name} (${item.ticker}) accepted`} title={`${item.name} (${item.ticker}) accepted`} height={50} width={50} src={`/images/payment/${item.ticker.toLowerCase()}.svg`} onError={(event) => event.target.setAttribute('src', '/images/payment/crypto.svg')} />
                                    </span>
                                    <p>{item.short_desc}</p>
                                </div>
                            ))
                            : fiatPayment.length > 0 ? '' :
                                <div className="four columns option">
                                    <span>
                                        <h3>Not Found</h3>
                                    </span>
                                    <p>No payment methods match search parameters.</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListOptions
