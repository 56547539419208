import React, {Component} from 'react';
import { NavLink } from "react-router-dom";
import { toggleWidget } from 'react-chat-widget';

class SiteSpecific extends Component {
       
    render() {
            return (
            <React.Fragment>
			
			<h2 className='knowledgebase'>Content Policy</h2>
			<hr className="colored"/>
			<div className="panel light">
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>What content is allowed on Swiss Made Host's servers?</h4>
				<div className="toggle-container">
					<p>We do not allow malicious software, copyrighted content, unsolicited messaging and other illegal practices on our servers. Please find the full list of content and practices that are prohibited on our servers in <NavLink to="/en/terms-of-service">Terms of Service</NavLink> and <NavLink to="/en/acceptable-use-policy">Acceptable Use Policy</NavLink>.</p>
				</div>
			</div>
			
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>Is porn &amp; adult content allowed on your servers?</h4>
				<div className="toggle-container">
					<p>Yes, we do allow legal adult/porn content on our servers. If you are unsure if any specific content may be considered illegal in Switzerland, please contact our support team before placing an order.</p>
				</div>
			</div>

			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>Do you allow hosting of copyrighted and DMCA-protected content?</h4>
				<div className="toggle-container">
					<p>Even though DMCA has no legal force in Switzerland, there's a number of domestic laws that regulate digital content distribution sphere and all hosting providers are subject to removing any materials that violate copyright law. Swiss Made Host does not allow said materials on its servers.</p>
				</div>
			</div>
			
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>Do you provide free speech hosting?</h4>
				<div className="toggle-container">
					<p>Switzerland is a country that ranks top five in free press and freedom of speech rankings compiled by international organizations. Without any doubt you can host a blog about politics or a website dedicated to political and social activism, and share your thoughts freely while keeping your privacy uncompromised.</p>
				</div>
			</div>
			</div>
			<h2 className='knowledgebase'>Privacy Protection</h2>
			<hr className="colored"/>
			<div className="panel light">
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>What information do you gather?</h4>
				<div className="toggle-container">
					<p>For proper site operation, improved customer experience or as per regulatory requirements we may collect the following information: name and job title, contact information including email address, IP address and browser user agent and other information that you chose to provide to us.</p>
				</div>
			</div>
			
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>How do you protect customers' privacy?</h4>
				<div className="toggle-container">
					<p>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online. We stick to a strict <NavLink to="/en/privacy-policy">Privacy Policy</NavLink> and as a company that resides in Switzerland we are bound by Swiss Federal Act on Data Protection. Please read more about data protection in Switzerland <NavLink to="/en/data-protection-switzerland">here</NavLink>.</p>
				</div>
			</div>
			
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>Can I opt out from data aggregation?</h4>
				<div className="toggle-container">
					<p>You may choose to prevent this website from aggregating and analyzing the actions you take here. Doing so will protect your privacy, but will also prevent the Swiss Made Host from learning from your actions and creating a better experience for you and other users. To opt out please proceed to the <NavLink to="/en/privacy-policy">Privacy Policy</NavLink> page.</p>
				</div>
			</div>
			
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>Can I download my data that is stored on your website?</h4>
				<div className="toggle-container">
					<p>Yes, you can download all of your personal data that we have collected. To do so please <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN} title="Existing customer login">log in</a> your account and go to the 'Client profile' section.</p>
				</div>
			</div>
			
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>Can I request removal of data that is stored on your website?</h4>
				<div className="toggle-container">
					<p>Yes, you can request removal of your personal data that we have collected. To do so please <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN} title="Existing customer login">log in</a> your account and go to the 'Client profile' section. Please be informed, that deleting your data will result in immediate cease of providing you with any services by our company. Access to your personal account will be terminated as well.</p>
				</div>
			</div>
			</div>
			<h2 className='knowledgebase'>Communication</h2>
			<hr className="colored"/>
			<div className="panel light">
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>What is the best way to contact you?</h4>
				<div className="toggle-container">
					<p>Should you have a general or sales question, please feel free to contact our <span className="livechatToggle" onClick={function(){toggleWidget()}}>live chat</span> operators that are available 24/7. If you need technical assistance, it is recommended to open a support ticket in your account area with all necessary information enclosed.</p>
				</div>
			</div>
			
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>What are the working hours of support team?</h4>
				<div className="toggle-container">
					<p>We provide 24/7/365 support and are always ready to help whenever you need assistance.</p>
				</div>
			</div>
			
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>What is the ticket response time?</h4>
				<div className="toggle-container">
					<p>Normally we reply to every request within 1 hour, however, reply time may slightly increase during high load hours.</p>
				</div>
			</div>
			
						<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>What languages are supported?</h4>
				<div className="toggle-container">
					<p>Swiss Made Host provides hosting solutions to customers all around the globe. To serve as many of our valued clients as possible we provide support in English language. User account and control panel interfaces are available in following languages: English, German, Spanish, Russian.</p>
				</div>
			</div>
			
			<div className="toggle-wrap">
				<h4 className="trigger" onClick={this.toggleAnimation}>What are your social network accounts?</h4>
				<div className="toggle-container">
					<p>We publish all news and important updates in our <NavLink to="/en/blog">blog</NavLink> and <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/swissmadehost">Twitter</a> feed. Follow us to keep updated!</p>
				</div>
			</div>
			</div>
			</React.Fragment>

)}
toggleAnimation = (e) => {
    e.target.parentNode.getElementsByTagName('div')[0].classList.toggle('expanded')
      }
}

export default SiteSpecific;
