import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import FAQinclude from '../../components/includes/FAQinclude';

const VPSProduct = () => {
  const { os, specs, name, id } = useParams();
  const [productsJSON, setProductsJSON] = useState(null);
  const [numericValues, setNumericValues] = useState([]);

  useEffect(() => {
    const matchedValues = specs.match(/\d+/g);

    if (matchedValues && matchedValues.length > 1) {
      setNumericValues(matchedValues.map(Number));
    }
  }, [specs]);

  const handleJsonLdGenerated = (jsonLd) => {
    if (productsJSON === null) {
      setProductsJSON(jsonLd);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Switzerland {(os === "windows" ? 'Windows VPS: ' : 'Linux/BSD VPS: ') + (numericValues[0] > 1 ? numericValues[0] + " cores, " : numericValues[0] + " core, ") + numericValues[1] + ' MB RAM, ' + numericValues[2] + ' GB SSD | ' + name.toUpperCase()}</title>
        <meta name="description" content={(os === "windows" ? 'Windows' : os === "linux" && 'Linux/BSD') + "-based VPS with " + (numericValues[0] > 1 ? numericValues[0] + " CPU cores, " : numericValues[0] + " CPU core, ") + numericValues[1] + " MB RAM and " + (numericValues[2]) + " GB SSD storage. Location: Zurich, Switzerland. Backed by VMware® hypervisor, free DDoS protection and DNS hosting."} />
      	{productsJSON && <script type="application/ld+json">{JSON.stringify(productsJSON)}</script>}
        <link rel="canonical" href={`https://swissmade.host${window.location.pathname}`} />
      </Helmet>
      <div className="fullwidth pic vps">
        <div className="row">
          <div className="twelve columns">
            <div className="transparent">
              <h1 className="title">
                {os === "windows" ? 'Windows ' : 'Linux/BSD '} {name.toUpperCase()} in <span><i className="fa fa-plus-square" aria-hidden="true"></i> Switzerland</span>
              </h1>
              <h2>{numericValues[0] > 1 ? numericValues[0] + " CPU cores" : numericValues[0] + " CPU core"}, {numericValues[1] + ' MB RAM'}, {numericValues[2] + ' GB SSD, VMware Hypervisor'}</h2>
            </div>
          </div>
        </div>
      </div>
      <DiscountInclude />
      <DynamicContent onJsonLdGenerated={handleJsonLdGenerated} id={id} />
      <FAQinclude category={'vps'} color={'light'} />
      <PaymentMethodsInclude />
    </React.Fragment>
  );
};

export default VPSProduct;
