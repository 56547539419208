import React from 'react';
import { NavLink } from "react-router-dom";

class Speedtest extends React.Component {

	render() {

		return (

			<div className="panel nomargin marginmobile">
				<h3><i className="fas fa-tachometer-alt"></i> Speed Test</h3>
				<hr className="black" />
				<p>Measure the network speed between your device and our server in <NavLink to="/en/data-center-switzerland">Zurich, Switzerland</NavLink>. Speed test widget checks out download and upload speeds, jitter, and ping, giving you a clear picture of your connection to Swiss Made Host's network.</p>
				<a href="https://speedtest.swissmade.host/" target='_blank' rel="noopener noreferrer nofollow" className='readmore left'><i className="fa-regular fa-square-caret-right"></i> Test Your Network Speed</a>
			</div>

		)
	}
}

export default Speedtest