import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import DiscountCounter from './dscountCounter';
import AppList from './appsList';
import ProtocolsList from './protocolsList';


const ShadowsocksSwitzerland = () => {

	const protocol = 'shadowsocks'

		return (
			<React.Fragment>
				<Helmet>
					<title>Private Shadowsocks VPN in Switzerland - Dedicated IP &amp; Non-shared server | Swiss Made Host</title>
					<meta name="description" content="Private Swiss-based Shadowsocks VPN untilizes a hard to detect encrypted protocol that protects identity, enables access to uncensored content and improves secrutiy of your browsing." />
					<link rel="canonical" href="https://swissmade.host/en/shadowsocks-vpn-switzerland" />
				</Helmet>
				<div className="fullwidth pic vpn">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Private Shadowsocks VPN<span>in Switzerland</span></h1>
								<h2>A hard to detect VPN for Internet censorship circumvention</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<ProtocolsList protocol={protocol}/>
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider protocol={protocol} />
				</ReactDependentScript>
				<DiscountCounter protocol={protocol}/>
				<AppList protocol={protocol}/>
				<PaymentMethodsInclude />
			</React.Fragment>
		)
	}

export default ShadowsocksSwitzerland;