import React from 'react';
import { Widget, addResponseMessage, addUserMessage, setQuickButtons, renderCustomComponent } from 'react-chat-widget';
import '../../css/livechat.css';
import {axiosinstance} from '../../components/axios';
import SystemMessages from './systemMessages';
import AdminResponse from './adminResponse';
import DownloadLink from './downloadLink';
import DialogConfirmation from './dialogConfirmation';
import Cookies from 'js-cookie';

class Livechat extends React.Component {

    constructor(props, context) {
		super(props, context);

        this.state = {
            badge: 0,
            greetingShown: false,
            requestURL: process.env.REACT_APP_BACKEND_URL + "/livechat.en.json",
            uploadURL:  process.env.REACT_APP_BACKEND_URL + "/livechat_upload.en.json"
        }
    }

    componentDidMount() {
        Cookies.set('notificationSounds', 'off', { expires: 365 });
        document.getElementsByClassName("rcw-launcher")[0].addEventListener('click',this.cleardDialogsOnOpen.bind(this));
        this.requestLiveData.bind(this)();
      }

      cleardDialogsOnOpen(){

        this.setState({
            badge: 0
        })

        setTimeout(function(){
            document.querySelectorAll(".rcw-dialog-message").forEach(function(element) {
                element.parentElement.remove()
            });
        }, 50);

        if (this.state.networkError !== true)
        {
        setTimeout(function(){
        document.querySelectorAll(".networkError").forEach(function(element) {
            element.remove()
            })}, 50);
        }

        else {
            setTimeout(function(){
            if (document.querySelector(".rcw-new-message") !== null)
            {document.querySelector(".rcw-new-message").disabled = true
            document.querySelector(".rcw-send").disabled = true
            var errorMessages = document.querySelectorAll(".networkError")
            var errorsCount = errorMessages.length
            errorMessages.forEach(function(element, i) {
                i !== errorsCount - 1 && element.remove()
                })
            }
            }, 50)
        }

      }

      requestLiveData(){
        axiosinstance({
            method: 'get',
            withCredentials: true,
            url: this.state.requestURL
    }).then(res => {
        this.setState({sessionToken: res.headers['session-token']})
        Cookies.set('session', res.headers['session-token'], {domain:'swissmade.host'})
            if (res.status === 200 && res.data.result === true) {
            if (typeof res.data.data.online == 'boolean' && res.data.data.messages === undefined)
            {   if (this.state.greetingShown === false)
                    {
                    this.setState({
                        greetingShown: true
                        });
                    addResponseMessage('Good to see you! Please type your message and press enter to start the chat.')
                    setQuickButtons([])
                    }
                    setTimeout(this.requestLiveData.bind(this), 15000);
            }
           else {

            setQuickButtons(
                [{label: <i className="fas fa-volume-mute"></i>, value: "toggleSounds"}, {label: <i className="fas fa-file-upload"></i>, value: "upload"}, {label: <i className="far fa-thumbs-up"></i>, value: "thumbsUp"}, {label: <i className="far fa-thumbs-down"></i>, value: "thumbsDown"}, {label: <i className="fas fa-sign-out-alt"></i>, value: 'quit'}]
              )
                    if (this.state.archiveData === undefined)
                        {this.setState({
                                archiveData: res.data.data
                                })
                        this.renderMessages(res.data.data.messages);

                        }
                    else {
                        const newMessages = res.data.data.messages.filter(({ id: id1 }) => !this.state.archiveData.messages.some(({ id: id2 }) => id2 === id1)),
                        rated = res.data.data.rated,
                        rate = res.data.data.rate
                        this.renderMessages(newMessages, rated, rate);
                        this.setState({
                            archiveData: res.data.data
                            })
                        }
                        setTimeout(this.requestLiveData.bind(this), 3000);
           }
           if (document.querySelector('.networkError') !== null)
           { this.hideErrors()
           this.setState({
            networkError: false
                        })
            }
        }

        else {
            this.networkError()
        setTimeout(this.requestLiveData.bind(this), 15000);
        }

    }).catch(() => {
        this.networkError()
        setTimeout(this.requestLiveData.bind(this), 15000);
      })
}

    networkError(){
        if (this.state.networkError !== true)
        {
        renderCustomComponent(SystemMessages, {'message': 'Reconnecting', 'network': 'error'})
        this.setState({
            networkError: true
        })
        }
        if (document.querySelector(".rcw-new-message") !== null)
            {document.querySelector(".rcw-new-message").disabled = true
            document.querySelector(".rcw-send").disabled = true
            }
    }

    hideErrors(){
        document.querySelectorAll(".networkError").forEach(function(element) {
        element.remove()
        if (document.querySelector(".rcw-new-message") !== null)
            {document.querySelector(".rcw-new-message").disabled = false
            document.querySelector(".rcw-send").disabled = false
            }
        })}

      renderMessages(newMessages, rated, rate) {
        newMessages.map((item) =>
        item.system === true
            ? renderCustomComponent(SystemMessages, {'message': item.message_param.name + ' has joined the chat, wait time: ' + item.message_param.wait_time})
            : item.operator === true && item.image !== "1"
                ? renderCustomComponent(AdminResponse, {'message': item.message}, {'showAvatar':true}, this.playNotificationSound(), document.getElementById('messages') === null && this.setState({badge: this.state.badge + 1}))
                : item.image !== "1" && item.operator === false && item.message !== this.state.lastUserMessage
                    ? addUserMessage(item.message)
                    : item.image === "1" && item.operator === true
                        ? renderCustomComponent(DownloadLink, {'message': item.message, 'operator': item.operator ? true : false}, {'showAvatar': true})
                        : item.image === "1" && item.operator === false
                            ? renderCustomComponent(DownloadLink, {'message': item.message, 'operator': item.operator ? true : false})
                            : null
        )

        var thumbsUp = document.querySelector('.quick-buttons-container .quick-buttons .quick-list-button:nth-child(3)'),
        thumbsDown = document.querySelector('.quick-buttons-container .quick-buttons .quick-list-button:nth-child(4)'),
        mutSounds = document.querySelector('.quick-buttons-container .quick-buttons .quick-list-button:nth-child(1)')
        if (rated === true && thumbsUp !== null && thumbsDown !== null)
            {
                if (rated === true && rate === true)
                {thumbsUp.classList.add('active')
                thumbsDown.classList.remove('active')
                }
            if (rated === true && rate === false)
                {thumbsDown.classList.add('active')
                thumbsUp.classList.remove('active')
                }
            }

            if (mutSounds !== null)
            {
            if (Cookies.get('notificationSounds') === 'off')
            {mutSounds.classList.add('active')}
            else {mutSounds.classList.remove('active')}
            }
    }

    playNotificationSound(){
       if (Cookies.get('notificationSounds') === 'on' || Cookies.get('notificationSounds') === undefined)
       {document.getElementById('incomingMessage').play()}
    }

    handleNewUserMessage = (newMessage) => {
        axiosinstance({
                method: 'post',
                url: this.state.requestURL,
                data: {
                    act:	'reply',
                    message:	newMessage,
                    referer: window.location.href
                },
                withCredentials: true,
                headers: {'Session-Token':this.state.sessionToken}
        }).then(res => {
            if (this.state.archiveData === undefined)
            {window._paq = window._paq || [];
            window._paq.push(['trackGoal', 1]);
            renderCustomComponent(SystemMessages, {'message': 'Chat initiated, waiting for an operator to join.'})
            this.setState({
                lastUserMessage: newMessage,
                archiveData: res.data.data,
                sessionToken: res.headers['session-token']
            })}
           else {
            this.setState({
                lastUserMessage: newMessage,
                archiveData: res.data.data,
                sessionToken: res.headers['session-token']
            })}
        }).catch(() => {
            this.networkError()
          })
    }

    handleQuickButtonClicked(value){
        if (value === 'toggleSounds')
        {if (Cookies.get('notificationSounds') === 'off')
            {Cookies.set('notificationSounds', 'on', { expires: 365 });renderCustomComponent(SystemMessages, {'message': 'Notification sounds are', 'sounds': 'ON'})}
            else {Cookies.set('notificationSounds', 'off', { expires: 365 }); renderCustomComponent(SystemMessages, {'message': 'Notification sounds are', 'sounds': 'OFF'})}
        }
        if (value === 'quit')
        {renderCustomComponent(DialogConfirmation, {'message': 'Quit the chat?', 'value': 'quit', 'requestURL': this.state.requestURL, 'sessionToken': this.state.sessionToken, 'refreshStateOnQuit': this.refreshStateOnQuit})
        }
        if (value === 'upload')
        {renderCustomComponent(DialogConfirmation, {'message': 'Send a file:', 'value': 'upload', 'uploadURL': this.state.uploadURL, 'sessionToken': this.state.sessionToken})
        }
        if (value === 'thumbsUp')
        {renderCustomComponent(DialogConfirmation, {'message': 'Rate operator?', 'value': 'thumbsUp', 'requestURL': this.state.requestURL, 'sessionToken': this.state.sessionToken})
        }
        if (value === 'thumbsDown')
        {renderCustomComponent(DialogConfirmation, {'message': 'Rate operator?', 'value': 'thumbsDown', 'requestURL': this.state.requestURL, 'sessionToken': this.state.sessionToken})
        }
    }

    refreshStateOnQuit = (data) => {
        this.setState({greetingShown: data})
    }

    render() {
        return (
            <React.Fragment>
            <audio id="incomingMessage">
                <source src="/sounds/tiny-bell.m4r" type="audio/m4r"/>
                <source src="/sounds/tiny-bell.mp3" type="audio/mpeg"/>
                <source src="/sounds/tiny-bell.ogg" type="audio/ogg"/>
            </audio>
            <Widget title={"24/7 Live Support"} handleNewUserMessage={this.handleNewUserMessage} senderPlaceHolder={"Type a message and press enter..."} profileAvatar={"/apple-touch-icon.png"} showCloseButton={true} handleQuickButtonClicked={this.handleQuickButtonClicked.bind(this)} badge={this.state.badge} autofocus={window.screen.availWidth < 1060 ? false : true}/>
            </React.Fragment>
        )}}

export default Livechat
