import React from 'react';
import { axiosinstance, axiosCatch } from '../../components/axios';
import CMSProductList from './productList';
import CpanelPreloader from '../../components/cpanelPreloader';
import LoadingError from '../../components/loadingError';
import lscache from 'lscache';
import ReactSlider from 'react-slider';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import '../../css/notifications.css';

class DynamicContent extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            products: [],
            error: false,
            loading: true,
            payment_period: this.props.discountActive === true ? 1 : 6
        }
    }

    componentDidMount() {

        this.load();
    }
    // notification Pop-up

    createNotification = type => () => {
        switch (type) {
            case 'coupon-copied':
                NotificationManager.info('Coupon code has been copied to clipboard');
                break;
            default:
                break;
        }
    }

    load = () => {

        this.setState({
            loading: true
        }, () => {

            if (lscache.get('appProducts') === null) {

                axiosinstance({
                    method: 'get',
                    url: process.env.REACT_APP_BACKEND_URL + "/products/app.en.json"
                })
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data.result === true) {
                                lscache.set('appProducts', response, 10);
                                this.setState({
                                    products: response.data.data.products,
                                    loading: false
                                });

                            } else {
                                this.setState({
                                    error: true,
                                    loading: false
                                });
                            }
                        } else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }
                    }).catch((args) => {
                        axiosCatch(args, this.props);
                    });

            }

            else {
                this.setState({
                    products: lscache.get('appProducts').data.data.products,
                    loading: false
                })

            }

            if (lscache.get('exchangeRates') === null) {

                axiosinstance.get(process.env.REACT_APP_BACKEND_URL + "/variables.json")
                    .then(res => {

                        if (res.status === 200 && res.data.result === true) {
                            this.setState({
                                usd: res.data.data.currencies.find(x => x.short_name === 'USD').rate,
                                eur: res.data.data.currencies.find(x => x.short_name === 'EUR').rate,
                                btc: res.data.data.currencies.find(x => x.short_name === 'BTC').rate
                            })
                            lscache.set('exchangeRates', res, 3);
                        }

                        else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }

                    })
            }
            else {
                this.setState({
                    usd: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'USD').rate,
                    eur: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'EUR').rate,
                    btc: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'BTC').rate
                })
            }

        });
    };

    render() {

        if (this.state.loading) {
            return (
                <CpanelPreloader />
            )
        }
        if (this.state.error) {
            return (
                <LoadingError />
            )
        } else {

            return (
                <React.Fragment>
                    {this.props.discountActive === true &&
                        <div className="fullwidth light">
                            <div className="row flex-center">
                                <NotificationContainer />
                                <div className="eight columns" id="discount-timer">
                                    <div className="plan-discount">
                                        <h4>Current special: {this.props.discountPercent}% off {this.props.firstMonth === true && 'first month'}</h4>
                                        <p>Try our CMS hosting out at just half of its normal price. Redeem voucher code <CopyToClipboard text={this.props.couponCode} onCopy={this.createNotification('coupon-copied')}><strong>{this.props.couponCode}</strong></CopyToClipboard> to apply a {this.props.discountPercent}% discount to your {this.props.firstMonth === true && 'first month'} payment for any CMS hosting plan. Offer valid till {new Date(this.props.expiryDate).toUTCString().slice(0, -13)}. You can also get additional discount of up to 22% off your monthly payment by selecting a longer billing period with the slider below.</p>
                                        <CopyToClipboard text={this.props.couponCode} onCopy={this.createNotification('coupon-copied')}><button className='button radius'>COPY CODE</button></CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="fullwidth light">
                        <div className="row discount-slider-single">
                            <div className="eight columns">
                                <ReactSlider
                                    className="horizontal-slider-single-block"
                                    thumbClassName="slider-thumb"
                                    trackClassName="slider-track"
                                    defaultValue={this.state.payment_period}
                                    step={1}
                                    marks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                                    renderThumb={(props, state) => <div {...props}>{(state.valueNow - 1) * 2} %</div>}
                                    renderMark={(props) => <span {...props}>{props.key} m.</span>}
                                    min={1}
                                    max={12}
                                    onChange={(value) => {
                                        this.setState({
                                            payment_period: value
                                        })
                                    }}
                                />
                            </div>
                            <div className="four columns">
                                <p className='discountText'>The longer the billing period, the higher your discount will be. Move the slider thumb to see how much you can save on the longer-term {this.props.cms === 'all' && 'CMS '}{this.props.cms === 'wordpress' && 'WordPress '}{this.props.cms === 'drupal' && 'Drupal '}{this.props.cms === 'joomla' && 'Joomla '} hosting deal.</p>
                            </div>
                        </div>
                    </div>
                    <div className="fullwidth light">
                        <div className="row">
                            <CMSProductList
                                usdRate={this.state.usd}
                                eurRate={this.state.eur}
                                btcRate={this.state.btc}
                                list={this.state.products}
                                payment_period={this.state.payment_period}
                                cms={this.props.cms}
                                discountActive={this.props.discountActive}
                                discountPercent={this.props.discountPercent}
                                firstMonth={this.props.firstMonth}
                                couponCode={this.props.couponCode}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default DynamicContent;
