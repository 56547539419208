import React from 'react';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import DynamicContent from './dynamicContent';
import { toggleWidget } from 'react-chat-widget';

class InfoPointsDDoS extends React.Component {

	render() {

		return (
			<React.Fragment>
				<div className="fullwidth light">
					<div className="row">
						<div className="six columns">
							<h3>DDoS Attack Threats</h3>
							<p>A Distributed Denial-of-Services attack can pretty much be related to a traffic jam. Just like vehicles are slowed down or even get stalled by a traffic jam, numerous malicious IP packets flood the network and prevent legitimate requests from reaching your server. DDoS attacks are relatively cheap to launch these days, though they often require significant resources to mitigate against.</p>
							<p>Out team has been successfully mitigating against DDoS attacks for years. Based on our vast experience we've developed a solution combining both hardware and proprietary software components that will protect your web property against DDoS attacks of all types with SLA guarantee.</p>
						</div>
						<div className="six columns">
							<h3>Types of Web Based Attacks</h3>
							<p>Typically a Distributed Denial-of-Service (DDoS) attack involves numerous compromised devices across the globe which are often referred to as a Botnet, whereas a Denial-of-Service (DoS) attack can be launched from a single device connected to the Internet. Most web based attacks, whether it is a DoS or a DDoS attack, can be divided into three major categories: volume based attacks, attacks targeting core web protocols and application layer attacks. Some of these attacks can bring down a server, whereas others are capable of knocking out the whole network segment.</p>
							<span className="left readmore" id="readmore" onClick={this.toggleAnimation}>Read more <i className="fa-regular fa-caret-square-right" id="caret" aria-hidden="true"></i></span>
						</div>
					</div>
					<div className="row expandableContent margintop" id="ddos-protection-info">
						<div className="four columns">
							<h5>Volumetric Attacks</h5>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa fa-bar-chart fa-5x" aria-hidden="true"></i></div>
							<p>Volume based atacks are the most common types of web based threats making up of almost 65% of all registered ones. The goal of these attacks is to consume bandwidth capacity of the target server or network and render it unreachable to legitimate requests. UDP/ICMP and other spoofed-packet floods fall under this catefory.  The scale of volumetric attacks is measured in bits per second (Bps), or more typically in Gigabits per second (Gbps).</p>
						</div>
						<div className="four columns">
							<h5>Protocol Attacks</h5>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa fa-chain-broken fa-5x" aria-hidden="true"></i></div>
							<p>The target of protocol based attacks is to consume hardware resources of application servers and network equipment including firewall and load balancers rather than saturating bandwidth. Most common examples of this type of DDoS attacks are fragmented packet attacks, SYN floods, Smurf DDoS and  Ping of Death. DDoS attacks targeting hardware resources are measured in packets per second (Pps) or more typically Mpps which stands for million packets per second.</p>
						</div>
						<div className="four columns">
							<h5>Application Layer Attacks</h5>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa fa-file-code-o fa-5x" aria-hidden="true"></i></div>
							<p>The trickiest type of DDoS attacks targets web servers and operating system vulnerabilities. These attacks can be extremely effective yet very difficult to detect and mitigate against, as they tend to mimic legitimate and virtually harmless requests. GET/POST floods, attacks that target web servers such as Apache and Nginx or critical Windows and Linux OS flaws fall under this category. The scale of Application Layer Attacks is often measured in Requests per second (Rps).</p>
						</div>
					</div>
				</div>
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider />
				</ReactDependentScript>
				<DynamicContent type='all' />
				<div className="fullwidth dark">
					<div className="row paddingtop">
						<div className="twelve columns">
							<h3><span className="colored">I'm Under a DDoS Attack.</span> How Do I get Protected?</h3>
							<hr className="black" />
						</div>
					</div>
					<div className="row">
						<div className="six columns">
							<h3>I have a server with Swiss <i className="fa fa-plus-square colored" aria-hidden="true"></i> Made</h3>
							<p>If you already are a client of our company, there's not much needed to do on your side in case of a DDoS attack. Most likely you won't even notice that your website or server is under an attack, courtesy of the basic DDoS protection feature that is provided with most of our hosting products. However, if an attack is massive enough and scales beyond the free-of-charge mitigation limit that is included with your VPS, Cloud or Dedicated Server plan, you may want to consider ordering a DDoS protection add-on to cope with it.</p>
							<p>Upgrading to a higher level of DDoS protection won't take much time, however, it is a good idea to order a more comprehensive DDoS protection plan beforehand, if you operate a web-based business that is known to attract frequent DDos attacks, e.g. a game server. Get a quote on the mitigation capacity add-on for your hosting plan from our help desk through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Live chat 24/7">live chat</a> widget or open a new <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/ticket/list'} title="Open a support ticket">ticket</a> inside your account area.</p>
						</div>
						<div className="six columns">
							<h3>I have a server with another provider</h3>
							<p>Obviously, it's an advantage to have your data and applications hosted within our protected network in case of a DDoS attack, but it's not the only option. If you rent a server from any other web hosting provider and want to get protected against DDoS attacks, we have a solution for you.</p>
							<p>This technique is often called a <i>Remote DDoS Protected Proxy</i> and is basically an encrypted tunnel between our secure facility in Zürich and your backend server. In case of a DDoS attack all requests that target your server will be routed through the mitigating hardware located at our data center in Zürich to filter out malicious packets and make sure that all legitimate requests reach the backend server. Your backend server's real IP address will be obscured behind our secure proxy to make it unreachable for the attackers.</p>
						</div>
					</div>
					<div className="row">
						<div className="twelve columns">
							<div className="panel bordered">
								<p>Some Layer 4 attacks cannot be successfully mitigated through a remote DDoS protected proxy by design. Please consider moving your website(s) or service(s) to our protected network for maximum security.</p>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
	toggleAnimation = (e) => {
		document.getElementById('ddos-protection-info').classList.toggle('expanded')
	}
}

export default InfoPointsDDoS