import React from 'react';

class AdminResponse extends React.Component {

     render() {
        return (

    <div className="rcw-response">
        <div className="rcw-message-text">
        <p>{this.props.message}</p>
        </div>
    </div>
            )}}

export default AdminResponse
