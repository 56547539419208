import React, {Component} from 'react';

class CDNproducts extends Component {

    render() {

        const cdn = this.props.list;
        const usdRate = this.props.usdRate;
        const eurRate = this.props.eurRate;

        return (
      <div className="fullwidth light">
        <div className="row">
        <div className="pricingbox">
          <div className="three columns">
            <div className="panel reddish radius text-center">
              <div className="package">
                <h3 className="cdn">PoPs in North America</h3>
                <div className="price reddish">
                  <h4>{cdn[0].price_n_america} CHF</h4>
                  <span>per 1 GB of bandwidth</span>
                </div>
                <div className="elements">
                  <ul className="no-bullet product base margintop">
                    <li>{(cdn[0].price_n_america * usdRate).toFixed(2)} USD per 1 GB</li>
                    <li>{(cdn[0].price_n_america * eurRate).toFixed(2)} EUR per 1 GB</li>
                  </ul>
                  <div className="price margintop">
                    <a className="button radius large" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + cdn[0].id}>Configure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="three columns">
            <div className="panel reddish radius text-center">
              <div className="package">
                <h3 className="cdn">PoPs in South America</h3>
                <div className="price reddish">
                  <h4>{cdn[0].price_s_america} CHF</h4>
                  <span>per 1 GB of bandwidth</span>
                </div>
                <div className="elements">
                  <ul className="no-bullet product base margintop">
                    <li>{(cdn[0].price_s_america * usdRate).toFixed(2)} USD per 1 GB</li>
                    <li>{(cdn[0].price_s_america * eurRate).toFixed(2)} EUR per 1 GB</li>
                  </ul>
                  <div className="price margintop">
                    <a className="button radius large" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + cdn[0].id}>Configure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="three columns">
            <div className="panel reddish radius text-center">
              <div className="package">
                <h3 className="cdn">PoPs in Europe</h3>
                <div className="price reddish">
                  <h4>{cdn[0].price_europe} CHF</h4>
                  <span>per 1 GB of bandwidth</span>
                </div>
                <div className="elements">
                  <ul className="no-bullet product base margintop">
                    <li>{(cdn[0].price_europe * usdRate).toFixed(2)} USD per 1 GB</li>
                    <li>{(cdn[0].price_europe * eurRate).toFixed(2)} EUR per 1 GB</li>
                  </ul>
                  <div className="price margintop">
                    <a className="button radius large" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + cdn[0].id}>Configure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="three columns">
            <div className="panel reddish radius text-center">
              <div className="package">
                <h3 className="cdn">PoPs in Asia</h3>
                <div className="price reddish">
                  <h4>{cdn[0].price_asia} CHF</h4>
                  <span>per 1 GB of bandwidth</span>
                </div>
                <div className="elements">
                  <ul className="no-bullet product base margintop">
                    <li>{(cdn[0].price_asia * usdRate).toFixed(2)} USD per 1 GB</li>
                    <li>{(cdn[0].price_asia * eurRate).toFixed(2)} EUR per 1 GB</li>
                  </ul>
                  <div className="price margintop">
                    <a className="button radius large" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + cdn[0].id}>Configure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
       </div>
       </div>
      </div>
        )
      
    }
}

export default CDNproducts;
