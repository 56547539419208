import React from 'react';
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";

function DataProtection() {

	const richData = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
		{
		"@type": "Question",
		"name": "What are the key principles of data protection in Switzerland?",
		"acceptedAnswer": {
		"@type": "Answer",
		"text": "1. Lawfulness: Personal data must be processed lawfully and in good faith. 2. Purpose Limitation: Data should only be collected and processed for specified, explicit, and legitimate purposes. 3. Proportionality: The processing of personal data must be necessary and proportionate to the intended purpose. 4. Data Accuracy: Personal data must be accurate, up-to-date, and complete. 5. Data Security: Organizations and individuals handling personal data must implement appropriate technical and organizational measures to protect the data. 6. Transparency: Data subjects have the right to be informed about the collection and processing of their personal data. 7. Data Subject Rights: Individuals have the right to access, correct, and delete their personal data, as well as to object to the processing of their data in certain circumstances."
		}
		},
		{
		"@type": "Question",
		"name": "What is the Federal Act on Data Protection (FADP)?",
		"acceptedAnswer": {
		"@type": "Answer",
		"text": "The 235.1 Federal Act of 19 June 1992 on Data Protection establishes a comprehensive legal framework that aims to protect individuals' privacy rights by regulating the collection, processing, and use of personal data. It sets forth a series of principles and obligations that ensure transparency, security, and respect for data subjects' rights, thereby fostering trust in data handling practices."
		}
		},
		{
		"@type": "Question",
		"name": "What is the Canton of Zürich's Act on Information and Data Protection?",
		"acceptedAnswer": {
		"@type": "Answer",
		"text": "The 170.4 Canton of Zürich's Act on Information and Data Protection establishes a robust legal framework at the cantonal level that aims to protect individuals' privacy rights by regulating the collection, processing, and use of personal data by public authorities. It enforces a set of principles and obligations that ensure transparency, security, and respect for data subjects' rights, thus promoting trust in data handling practices within the Canton of Zürich."
		}
		},
		{
		"@type": "Question",
		"name": "What is the New Federal Act on Data Protection (nFADP)?",
		"acceptedAnswer": {
		"@type": "Answer",
		"text": "The new Data Protection Act and the associated ordinance will enter into force on September 1, 2023. The law applies to the processing of personal data by private individuals and federal bodies, including private companies, associations, and private individuals. Personal data refers to all data relating to an identified or identifiable natural person, and processing includes almost all activities related to it, such as procurement, storage, retention, use, modification, disclosure, archiving, deletion, or destruction of data."
		}
		},
		{
			"@type": "Question",
			"name": "What are the roles of Data Protection and Privacy Commissions in Switzerland?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Both at the federal and cantonal levels, there are independent data protection and privacy commissions responsible for monitoring and enforcing data protection laws. These commissions offer guidance, respond to complaints, and may impose sanctions on organizations that violate data protection regulations."
			}
		  },
		  {
			"@type": "Question",
			"name": "How does Switzerland regulate international data transfers?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Switzerland has strict regulations concerning the transfer of personal data to countries outside of the European Economic Area (EEA) and Switzerland. In order to transfer data internationally, the receiving country must provide an adequate level of data protection, have appropriate safeguards in place, or the data subject must give explicit consent for the transfer."
			}
		  },
		  {
			"@type": "Question",
			"name": "What are the data breach notification requirements in Switzerland?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Under the new Federal Act on Data Protection (nFADP), organizations are required to notify the relevant data protection authority and affected individuals of data breaches that pose a high risk to the rights and freedoms of the data subjects. The notification must be made without undue delay."
			}
		  },
		  {
			"@type": "Question",
			"name": "How does Switzerland's data protection law compare to the EU's GDPR?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Switzerland's new Federal Act on Data Protection (nFADP) is designed to align with the EU's General Data Protection Regulation (GDPR). Both laws share similar principles, such as lawfulness, purpose limitation, data minimization, accuracy, and data security. They also provide individuals with similar rights, such as the right to access, rectify, and delete their data. However, there may be some differences in the implementation and enforcement of the laws."
			}
		  },
		  {
			"@type": "Question",
			"name": "What are the penalties for non-compliance with data protection laws in Switzerland?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Under the new Federal Act on Data Protection (nFADP), non-compliance can result in criminal liability for the responsible natural person. Punishable offenses include breach of information obligations, breach of data security, and breach of obligations to provide information. Fines can reach up to CHF 250,000 (Swiss Francs) depending on the severity of the violation."
			}
		  },
		  {
			"@type": "Question",
			"name": "How do Swiss data protection laws apply to non-Swiss companies?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Non-Swiss companies that process personal data of Swiss residents may be subject to Swiss data protection laws if they have a significant connection to Switzerland. This may include having an establishment, offering goods or services to Swiss residents, or monitoring the behavior of Swiss residents. It's important for non-Swiss companies to assess their connection to Switzerland and ensure compliance with the country's data protection laws."
			}
		  },
		  {
			"@type": "Question",
			"name": "What is the role of the Federal Data Protection and Information Commissioner (FDPIC) in Switzerland?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "The Federal Data Protection and Information Commissioner (FDPIC) is responsible for supervising the compliance with Swiss data protection laws and advising individuals, organizations, and federal bodies on data protection matters. The FDPIC also investigates reported data breaches, provides recommendations on data protection best practices, and cooperates with international data protection authorities."
			}
		  },
		  {
			"@type": "Question",
			"name": "What is the process for reporting a data breach in Switzerland?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "In Switzerland, organizations that experience a data breach should report the incident to the Federal Data Protection and Information Commissioner (FDPIC) as soon as possible. The notification should include a description of the breach, the affected personal data, the potential consequences, and the measures taken to address the issue. Organizations should also inform the affected individuals if the breach poses a high risk to their rights and freedoms."
			}
		  },
		  {
			"@type": "Question",
			"name": "Are data transfers to other countries allowed under Swiss data protection laws?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Swiss data protection laws permit data transfers to other countries as long as the receiving country provides an adequate level of data protection. The Federal Data Protection and Information Commissioner (FDPIC) maintains a list of countries with adequate data protection laws. If the receiving country does not provide an adequate level of data protection, organizations must implement appropriate safeguards, such as Standard Contractual Clauses (SCCs) or Binding Corporate Rules (BCRs), to ensure the protection of the transferred data."
			}
		  },
		  {
			"@type": "Question",
			"name": "How can organizations ensure compliance with Swiss data protection laws?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Organizations can ensure compliance with Swiss data protection laws by implementing the following measures: 1) Appoint a data protection officer (DPO) to oversee data protection efforts, 2) Conduct regular data protection training for employees, 3) Create and maintain a processing inventory, 4) Develop and implement data protection policies and procedures, 5) Implement Privacy by Design and Privacy by Default principles, 6) Conduct data protection impact assessments for high-risk processing activities, 7) Establish secure data storage and processing measures, 8) Develop a data breach response plan, and 9) Regularly review and update data protection practices to stay compliant with the evolving legal landscape."
			}
		  }
		]
		}

	return (

		<React.Fragment>
			<Helmet>
				<title>Data Protection in Switzerland | Swiss Made Host</title>
				<meta name="description" content="Learn more about strict laws and regulations on privacy and data protection that Switzerland is renowned for." />
				<link rel="canonical" href="https://swissmade.host/en/data-protection-switzerland" />
				<script type="application/ld+json">{JSON.stringify(richData)}</script>
			</Helmet>
			<div className="fullwidth pic data-protection">
				<div className="row">
					<div className="twelve columns">
						<div className="transparent">
							<h1 className="title">Data Protection in <span><i className="fa fa-plus-square" aria-hidden="true"></i> Switzerland</span></h1>
							<h2>Your personal data vault</h2>
						</div>
					</div>
				</div>
			</div>
			<div className="fullwidth light data-protection">
				<div className="row">
					<div className="four columns">
						<h3>State Level</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fa fa-balance-scale fa-5x" aria-hidden="true"></i></div>
						<p>Switzerland is renowned for its strict laws and regulations on privacy and data protection that make it impossible for any third party including authorities to get access to a web hosting provider's data, unless a formal request is filed by a prosecutor, who is investigating a client in particular. There's hardly any other country in the world with a matching grade of privacy protection at the political level.</p>
					</div>
					<div className="four columns">
						<h3>Policy Level</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fa fa-file-text fa-5x" aria-hidden="true"></i></div>
						<p>Security, privacy, excellence and responsibility are the cornerstones of our company's philosophy. We are deeply convinced that every our client has the fundamental and unbreachable right of privacy. SwissMade.Host is bound by strict privacy, confidentiality and data protection policies to make sure that our services are provided in accordance with Swiss federal law and core principles of our company.</p>
					</div>
					<div className="four columns">
						<h3>Infrastructure Level</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fa fa-building fa-5x" aria-hidden="true"></i></div>
						<p>Not only your data and privacy are protected by Swiss laws, but we also take weighty efforts to secure it at the infrastructure level. Our data center in Zürich is practically a fortress: 24x7 CCTV surveillance, perimeter and inner premises security patrols, multi-layered access barriers, including mantraps and biometric readers. <NavLink to="/en/data-center-switzerland">Take a tour</NavLink> to learn more about our data center in Zürich.</p>
					</div>
				</div>
			</div>
			<div className="fullwidth dark">
				<div className="row">
				<div className="eight columns">
						<div className="panel nomargin bordered">
							<h3>The Key Principles of Data Protection in Switzerland</h3>
							<ol>
								<li>
									<h5 className="nobold">Lawfulness:</h5>
									<p>Personal data must be processed lawfully and in good faith. Organizations and individuals are required to have a legitimate and well-founded reason for collecting and using personal data. Additionally, they must comply with all applicable laws and regulations to ensure that data processing activities do not infringe upon individuals' privacy rights or legal protections. This principle helps create a foundation of trust between data subjects and those handling their information.</p>
								</li>
								<li>
									<h5 className="nobold">Purpose Limitation:</h5>
									<p>Data should only be collected and processed for specified, explicit, and legitimate purposes. Any processing activities must align with the initial objectives stated when the data was collected. This principle prevents organizations and individuals from using personal data for purposes unrelated or incompatible with the original intent, thus safeguarding individuals' privacy and ensuring the responsible use of their information.</p>
								</li>
								<li>
									<h5 className="nobold">Proportionality:</h5>
									<p>The processing of personal data must be necessary and proportionate to the intended purpose. This principle ensures that only the minimum amount of data required for a specific purpose is collected and used. By limiting data collection and processing to what is strictly necessary, organizations and individuals can reduce the potential for privacy violations and minimize the risk of data breaches.</p>
								</li>
								<li>
									<h5 className="nobold">Data Accuracy:</h5>
									<p>Personal data must be accurate, up-to-date, and complete. Organizations have an obligation to verify the information they collect and to take appropriate measures to correct or delete any inaccurate or incomplete data. Ensuring data accuracy not only protects individuals' privacy rights but also helps organizations make informed decisions based on reliable information.</p>
								</li>
								<li>
									<h5 className="nobold">Data Security:</h5>
									<p>Organizations and individuals handling personal data must implement appropriate technical and organizational measures to protect the data against unauthorized access, disclosure, alteration, or destruction. This includes using encryption, access controls, and secure storage solutions, as well as regularly reviewing and updating security practices. By prioritizing data security, organizations can prevent data breaches and protect individuals' privacy rights.</p>
								</li>
								<li>
									<h5 className="nobold">Transparency:</h5>
									<p>Data subjects have the right to be informed about the collection and processing of their personal data. This includes information about the purpose, legal basis, and any recipients of the data. Organizations and individuals must be transparent about their data handling practices to ensure data subjects are aware of how their information is being used, fostering a relationship of trust and accountability.</p>
								</li>
								<li>
									<h5 className="nobold">Data Subject Rights:</h5>
									<p>Individuals have the right to access, correct, and delete their personal data, as well as to object to the processing of their data in certain circumstances. These rights empower data subjects to take control of their information and hold organizations and individuals accountable for their data processing activities. By respecting and facilitating the exercise of these rights, organizations can demonstrate their commitment to protecting individuals' privacy and promoting responsible data handling practices.</p>
								</li>
								</ol>
						</div>
						<div className="panel nomargin bordered">
							<h3>New Federal Act on Data Protection (nFADP)</h3>
							<p>Here are some of the changes that have been made in the revised Federal Act on Data Protection (FADP) and the Ordinance to the Federal Act on Data Protection (DPO), which will enter into force on September 1, 2023:</p>
							<ol>
							<li>
								<h5 className="nobold">Applicability</h5>
								<p>The new law applies to the processing of personal data by private individuals and federal bodies, including private companies, associations, and private individuals.</p>
							</li>
							<li>
								<h5 className="nobold">Definition of Personal Data and Processing</h5>
								<p>The definition of personal data has been broadened, and processing includes almost all activities related to it.</p>
							</li>
							<li>
								<h5 className="nobold">Processing Inventory</h5>
								<p>The revised law requires companies and organizations with 250 or more employees to keep an inventory of all processing activities.</p>
							</li>
							<li>
								<h5 className="nobold">Information Obligations and Privacy Policy</h5>
								<p>The new law imposes more extensive information obligations and requires a privacy policy to provide information about all data processing, not just about the processing of data via the website.</p>
							</li>
							<li>
								<h5 className="nobold">Data Processing Agreement</h5>
								<p>The new law requires a data processing agreement to be concluded with providers that process personal data on behalf of the controller.</p>
							</li>
							<li> 
								<h5 className="nobold">Privacy by Default and Privacy by Design</h5>
								<p>The new law requires data controllers to structure data processing technically and organizationally in such a way that data protection law is complied with and that default settings are as data protection-friendly as possible.</p>
							</li>
							<li>
								<h5 className="nobold">Extended Professional Secrecy</h5>
								The new law expands professional secrecy in data protection law.
							</li>
							<li>
								<h5 className="nobold">Data Protection Impact Assessment</h5>
								<p>The new law requires a data protection impact assessment to be carried out if new data processing operations are planned that could potentially have a high risk for data subjects.</p>
							</li>
							<li>
								<h5 className="nobold">Criminal Liability</h5>
								<p>The new law imposes criminal liability for breach of certain obligations, which is borne by the natural person responsible for it, and punishable offenses include breach of information obligations, breach of data security, and breach of obligations to provide information.</p>
							</li>
							</ol>
							<p>These are some of the updates that have been made in the revised Federal Act on Data Protection (FADP) and the Ordinance to the Federal Act on Data Protection (DPO), which aim to strengthen the protection of personal data and ensure compliance with the EU's General Data Protection Regulation (GDPR).</p>
						</div>
					</div>
					<div className="four columns">
						<div className="panel nomargin bordered">
							<h3>235.1 Federal Act of 19 June 1992 on Data Protection</h3>
							<hr className="black" />
							<h5 className="nobold">At the federal level, the collection and use of personal data is regulated by the Federal Act on Data Protection.</h5>
							<p>The 235.1 Federal Act of 19 June 1992 on Data Protection establishes a comprehensive legal framework that aims to protect individuals' privacy rights by regulating the collection, processing, and use of personal data. It sets forth a series of principles and obligations that ensure transparency, security, and respect for data subjects' rights, thereby fostering trust in data handling practices.</p>
							<a className="button radius large" target="_blank" href="https://www.admin.ch/opc/en/classified-compilation/19920153/index.html" rel="noopener noreferrer">Learn more</a>
						</div>
						<div className="panel nomargin bordered">
							<h3>170.4 Canton of Zürich's Act on Information and Data Protection</h3>
							<hr className="black" />
							<h5 className="nobold">At the Canton level, the collection and use of personal data is regulated by Act on Information and Data Protection of Canton of Zürich.</h5>
							<p>The 170.4 Canton of Zürich's Act on Information and Data Protection establishes a robust legal framework at the cantonal level that aims to protect individuals' privacy rights by regulating the collection, processing, and use of personal data by public authorities. It enforces a set of principles and obligations that ensure transparency, security, and respect for data subjects' rights, thus promoting trust in data handling practices within the Canton of Zürich.</p>
							<a className="button radius large" target="_blank" href="http://www.zhlex.zh.ch/Erlass.html?Open&Ordnr=170.4" rel="noopener noreferrer">Learn more</a>
						</div>
						<div className="panel nomargin bordered">
							<h3>New Federal Act on Data Protection (nFADP)</h3>
							<hr className="black" />
							<h5 className="nobold">Switzerland is implementing new legislation to better protect its citizens' data. Swiss companies will have to comply with this legislation from September 1, 2023.</h5>
							<p>The new Data Protection Act and the associated ordinance will enter into force on September 1, 2023. The law applies to the processing of personal data by private individuals and federal bodies, including private companies, associations, and private individuals. Personal data refers to all data relating to an identified or identifiable natural person, and processing includes almost all activities related to it, such as procurement, storage, retention, use, modification, disclosure, archiving, deletion, or destruction of data.</p>
							<a className="button radius large" target="_blank" href="https://www.kmu.admin.ch/kmu/en/home/facts-and-trends/digitization/data-protection/new-federal-act-on-data-protection-nfadp.html" rel="noopener noreferrer">Learn more</a>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default DataProtection;