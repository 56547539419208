import React from 'react';
import BlogSidebar from './blogSidebar';
import Articles from './articles';
import Pagination from './pagination';
import { axiosinstance } from '../../components/axios';
import LoadingError from '../../components/loadingError';
import lscache from 'lscache';
import BlogPreLoader from './blogPreloader';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

class DynamicContentBlog extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            error: false,
            loading: true
        }
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        this.setState({
            loading: true
        }, () => {

            if (lscache.get('blogOverall') === null) {

                axiosinstance.get(process.env.REACT_APP_BACKEND_URL + "/blog/overall.en.json")
                    .then(res => {

                        if (res.status === 200 && res.data.result === true) {
                            lscache.set('blogOverall', res.data.data, 3);
                            this.setState({
                                loading: false,
                                blogOverall: res.data.data
                            })

                        }

                        else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }

                    })
            }
            else {

                this.setState({
                    loading: false,
                    blogOverall: lscache.get('blogOverall')
                })
            }

        })
    }

    showPagination = (dataFromArticlesComponent) => {
        this.setState({ blogPostsTotal: dataFromArticlesComponent })
    }

    getPageLimit = (dataFromArticlesComponent) => {
        this.setState({ pageLimit: dataFromArticlesComponent })
    }

    render() {

        if (this.state.loading) {
            return (
                <div className="fullwidth light">
                    <div className="row">
                        <div className="nine columns">
                            <BlogPreLoader/>
                        </div>
                        <div className="three columns">
                            <Skeleton style={{height: '40px'}} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                            <p />
                            <Skeleton count={1} />
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.error) {
            return (
                <LoadingError />
            )
        } else {
            return (
                <React.Fragment>
                    <div className="fullwidth light">
                        <div className="row">
                            <div className="nine columns">
                                <Articles location={this.props.location} blogPostsTotal={this.showPagination} pageLimit={this.getPageLimit} />
                                <Pagination blogPostsTotal={this.state.blogPostsTotal} pageLimit={this.state.pageLimit} location={this.props.location} />
                            </div>
                            <div className="three columns">
                                <BlogSidebar blogOverall={this.state.blogOverall} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default DynamicContentBlog
