import React from 'react';
import Helmet from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink } from "react-router-dom";

class BlogError extends React.Component {
    render() { return (
		<React.Fragment>
		<Helmet>
			<title>404 - Blog Page Not Found | Swiss Made Host</title>
			<meta name="description" content="Looks like there is no article or any other page matching this URL. Please make sure that URL is correct." />
		</Helmet>
		<div className="blog-article">
		<h4 className="searchTitle"><NavLink to='/en/blog'>Blog</NavLink> <small>/</small> Error <small>/</small> Page Not Found</h4>
            <header className="blog-article-header">
				<LazyLoadImage src="/images/backgrounds/404.png" alt="404 - Page not found" />
				<h3>The Page You Were Looking For Could Not Be Found</h3>
				<p>It appears, that there is no article or any other page matching this URL. Please make sure that URL in your browser's address bar is correct.</p>
				<p style={{paddingTop: '20px'}}>
			    <NavLink className="button radius left" to="/en/blog">Back to Blog</NavLink>
			</p>
			</header>
		</div>
		</React.Fragment>
    ) }
}

export default BlogError;