import React from 'react';
import {axiosinstance, axiosCatch} from '../../components/axios';
import CpanelProductList from './productList';
import CpanelPreloader from '../../components/cpanelPreloader';
import LoadingError from '../../components/loadingError';
import lscache from 'lscache';


class DynamicContent extends React.Component {

	constructor(props, context) {
		super(props, context);

        this.state = {
            products: [],
			error: false,
            loading: true
        }
    }

	componentDidMount () {

		this.load();
	}

	load = () => {

        this.setState({
            loading: true
        }, () => {

            if (lscache.get('cPanelProducts') === null)
            {

            axiosinstance({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL + "/products/license.en.json"
            })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.result === true) {
                            lscache.set('cPanelProducts', response, 10);
                            this.setState({
								products: response.data.data.products,
                                loading: false
                            });

                        } else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }
                    } else {
                        this.setState({
                            error: true,
                            loading: false
                        });
                    }
                }).catch((args) => {
                axiosCatch(args, this.props);
            });

        }

        else {
            this.setState({
                products: lscache.get('cPanelProducts').data.data.products,
                loading: false
                })

        }

            if (lscache.get('exchangeRates') === null)
            {

                axiosinstance.get(process.env.REACT_APP_BACKEND_URL + "/variables.json")
            .then(res => {

                if (res.status === 200 && res.data.result === true) {
              this.setState({
                usd:  res.data.data.currencies.find(x => x.short_name === 'USD').rate,
                eur:  res.data.data.currencies.find(x => x.short_name === 'EUR').rate,
                btc:  res.data.data.currencies.find(x => x.short_name === 'BTC').rate
                })
                lscache.set('exchangeRates', res, 3);
            }

            else {
                this.setState({
                    error: true,
                    loading: false
                });
            }

            })
            }
            else {
                this.setState({
                    usd: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'USD').rate,
                    eur: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'EUR').rate,
                    btc: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'BTC').rate
                    })
            }

        });
	};

	onSliderChange = (value) => {
        this.setState({
            slider_value: value,
        });
	};

	render() {

        if (this.state.loading) {
            return (
                <CpanelPreloader/>
            )
        }
        if (this.state.error) {
            return (
                <LoadingError/>
            )
        } else {

			return (
                <div className="fullwidth light">
                    <div className="row">
                        <div className="pricingbox">
                            <CpanelProductList usdRate={this.state.usd} eurRate={this.state.eur} btcRate={this.state.btc} list={this.state.products}/>
				        </div>
			        </div>
                </div>
	)}}}

export default DynamicContent;
