import React from 'react';
import {axiosinstance, axiosCatch} from '../../components/axios';
import DDoSConfigList from './productList';
import PlanPreLoader from '../../components/planPreloader';
import LoadingError from '../../components/loadingError';
import lscache from 'lscache';
import ReactSlider from 'react-slider';

class DynamicContent extends React.Component {

	constructor(props, context) {
		super(props, context);

        this.state = {
            products: [],
			error: false,
            loading: true,
			payment_period: 1
        }
    }

	componentDidMount () {

		this.load();
	}

	load = () => {

        this.setState({
            loading: true
        }, () => {

            if (lscache.get('ddosProducts') === null)
            {

            axiosinstance({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL + "/products/protection.en.json"
            })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.result === true) {
                            lscache.set('ddosProducts', response, 10);
                            this.setState({
								products: response.data.data.products,
                                addons: response.data.data.addons,
                                loading: false
                            });

                        } else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }
                    } else {
                        this.setState({
                            error: true,
                            loading: false
                        });
                    }
                }).catch((args) => {
                axiosCatch(args, this.props);
            });

        }

        else {
            this.setState({
                products: lscache.get('ddosProducts').data.data.products,
                addons: lscache.get('ddosProducts').data.data.addons,
                loading: false
                })

        }

            if (lscache.get('exchangeRates') === null)
            {

                axiosinstance.get(process.env.REACT_APP_BACKEND_URL + "/variables.json")
            .then(res => {

                if (res.status === 200 && res.data.result === true) {
              this.setState({
                usd:  res.data.data.currencies.find(x => x.short_name === 'USD').rate,
                eur:  res.data.data.currencies.find(x => x.short_name === 'EUR').rate,
                btc:  res.data.data.currencies.find(x => x.short_name === 'BTC').rate
                })
                lscache.set('exchangeRates', res, 3);
            }

            else {
                this.setState({
                    error: true,
                    loading: false
                });
            }

            })
            }
            else {
                this.setState({
                    usd: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'USD').rate,
                    eur: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'EUR').rate,
                    btc: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'BTC').rate
                    })
            }

        });
	};

	render() {

        if (this.state.loading) {
            return (
                <PlanPreLoader/>
            )
        }
        if (this.state.error) {
            return (
                <LoadingError/>
            )
        } else {

			return (
<React.Fragment>


        <div className="fullwidth light">
	        <div className="row discount-slider-single">
		        <div className="eight columns">
                        <ReactSlider
                            className="horizontal-slider-single-block"
                            thumbClassName="slider-thumb"
                            trackClassName="slider-track"
                            defaultValue={this.state.payment_period}
                            step={1}
                            marks={[1,2,3,4,5,6,7,8,9,10,11,12]}
                            renderThumb={(props, state) => <div {...props}>{(state.valueNow -1) * 2} %</div>}
                            renderMark={(props) => <span {...props}>{props.key} m.</span>}
                            min={1}
                            max={12}
                            onChange = {(value) => {
                                this.setState({
                                    payment_period: value
                                })
                            }}
                        />
                </div>
                <div className="four columns">
                    <p className='discountText'>The longer the billing period, the higher your discount will be. Move the slider thumb to see how much you can save on the longer-term DDoS protection deal.</p>
                </div>
            </div>
        </div>
<DDoSConfigList usdRate={this.state.usd} eurRate={this.state.eur} btcRate={this.state.btc} list={this.state.products} payment_period={this.state.payment_period}/>


            </React.Fragment>
	)}}}

export default DynamicContent;
