import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import FAQinclude from '../../components/includes/FAQinclude';
import InfoPointsDDoS from './infoPoints';
import OffshoreSwitzerland from '../offshore-hosting/offshoreSwitzerland';

class DDoSProtectionOffshore extends React.Component {

	render() {

		return (
			<React.Fragment>
				<Helmet>
					<title>DDoS Attack Protection - Offshore Location | Swiss Made Host</title>
					<meta name="description" content="Offshore on-site and remote protection against all types of DDoS attacks with SLA-backed guarantee. Proudly made in Zurich, Switzerland." />
					<link rel="canonical" href="https://swissmade.host/en/offshore-ddos-protection" />
				</Helmet>
				<div className="fullwidth pic ddos-protection">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Offshore <span>DDoS Protection</span></h1>
								<h2>100% SLA-backed DDoS Protection in an Offshore Location</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<OffshoreSwitzerland />
				<InfoPointsDDoS />
				<FAQinclude category={'ddos'} color={'light'} />
				<PaymentMethodsInclude />

			</React.Fragment>
		)
	}
}

export default DDoSProtectionOffshore;