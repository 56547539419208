import React from 'react';
import ListOfProducts from '../products/listOfProducts';

class ProductSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
<div className="row">
<div className="twelve columns indexProducts">
<ListOfProducts page={'home'}/>
</div>
</div>
</div>
            )
            }}

export default ProductSlider