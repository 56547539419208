import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import PopsMap from './mapPops';

class CDN extends React.Component {

	render() { 

	const richData = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"name": "CDN in Switzerland",
		"mainEntity": [
		  {
			"@type": "Question",
			"name": "What is a CDN?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "A CDN (Content Delivery Network) is a network of servers distributed across multiple locations around the world. It is designed to improve website performance by delivering static content, such as images, stylesheets, and scripts, from the server closest to the user's location. This reduces latency, accelerates load times, and provides a better user experience."
			}
		  },
		  {
			"@type": "Question",
			"name": "How does a CDN work?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "A CDN works by caching static content from your website on multiple servers across its network. When a user requests content from your website, the CDN routes the request to the server closest to the user's location, reducing the distance the data must travel. This process shortens the response time, resulting in faster load times and improved website performance."
			}
		  },
		  {
			"@type": "Question",
			"name": "What are the benefits of using a CDN?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Using a CDN offers several benefits, including: 1. Faster load times: By delivering content from a server closer to the user, a CDN can significantly reduce latency and improve page load speeds. 2. Scalability: A CDN can help your website handle increased traffic by distributing requests across multiple servers. 3. Improved user experience: Faster load times and reduced latency can lead to a better user experience and increased user satisfaction. 4. Enhanced security: CDNs can protect your website against DDoS attacks and other security threats by filtering malicious traffic and providing SSL/TLS encryption. 5. SEO advantages: Faster-loading websites are often ranked higher in search engine results, leading to increased visibility and traffic."
			}
		  },
		  {
			"@type": "Question",
			"name": "How do I purchase a CDN service?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "To purchase a CDN service, visit our pricing page and choose the plan that best suits your needs. Follow the checkout process to complete your purchase. Once your payment is processed, you'll receive instructions on how to set up and configure your CDN service."
			}
		  },
		  {
			"@type": "Question",
			"name": "How do I integrate a CDN with my website?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Integrating a CDN with your website typically involves updating your website's settings to point to the CDN's servers for static content. This process varies depending on the platform your website is built on and the specific CDN service you are using. After purchasing a CDN service, you'll receive detailed instructions and support to help you integrate the CDN with your website."
			}
		  },
		  {
			"@type": "Question",
			"name": "What type of websites can benefit from using a CDN?",
			"acceptedAnswer": {
			  "@type": "Answer",
			  "text": "Websites of all types and sizes can benefit from using a CDN, especially those with a global audience, high traffic volume, or a significant amount of static content. Examples include e-commerce websites, news portals, online forums, and streaming platforms. A CDN can help improve performance, enhance user experience, and provide additional security for these websites."
			}
		  }
		]
	}

	return (
    <React.Fragment>
    	<Helmet>
			<title>CDN - Content Delivery Network | Swiss Made Host</title>
			<meta name="description" content="Speed up content delivery to up to 4 times and ensure its uninterrupted availability from any location around the globe." />
			<link rel="canonical" href="https://swissmade.host/en/cdn-switzerland"/>
			<script type="application/ld+json">{JSON.stringify(richData)}</script>
		</Helmet>
    <div className="fullwidth pic cdn">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
				<h1 className="title">CDN <span>Content Delivery Network</span></h1>
				<h2>Speed up content delivery to up to 4 times</h2>
				</div>
			</div>
		</div>
	</div>
	<DiscountInclude/>
	<div className="fullwidth light">
		<div className="row">
			<div className="twelve columns">
				<h3>Content Delivery Network</h3>
				<p>CDN consists of multiple servers strategically placed across the globe, that are often referred to as Points of Presence (PoPs). Each PoP has an up to date copy of your website's static content that you choose to be distributed across the CDN network. The main objective of CDN is to reduce time of content delivery to each particular user by serving it through the most efficient route. When a user visits your website, CDN determines a PoP to serve content based on factors such as proximity to user, network speed, latency and PoP's availability. CDN has proven to speed up content delivery to up to 4 times in comparison to serving content from a single server.</p>
			</div>
		</div>
	</div>
			<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
				<FeatureSlider/>
			</ReactDependentScript>
			<DynamicContent type='all'/>
<ReactDependentScript scripts={["https://maps.googleapis.com/maps/api/js?key=AIzaSyAV2hiC14nyL2QLQvfd-WF2yT1iqZno4kI&libraries=places&callback=initMap"]}>
<div className="fullwidth light">
		<div className="row">
			<div className="twelve columns">
				<h2>Points of Presence</h2>
				<hr className="grey"/>
				<PopsMap/>
			</div>
		</div>
			<div className="row">
				<div className="six columns">
					<table className="pops">
					<tbody>
						<tr>
							<td><i className="fa fa-map-marker"></i> Australia</td>
							<td>Sydney</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Belgium</td>
							<td>Brussels</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Brazil</td>
							<td>Sao paulo</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Canada</td>
							<td>Montreal</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> China</td>
							<td>Hong kong</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Czech Republic</td>
							<td>Prague</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Denmark</td>
							<td>Copenhagen</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Finland</td>
							<td>Helsinki</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> France</td>
							<td>Villeurbanne</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Germany</td>
							<td>Berlin</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Hungary</td>
							<td>Budapest</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> India</td>
							<td>Pune</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Indonesia</td>
							<td>Bekasi</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Ireland</td>
							<td>Dublin</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Japan</td>
							<td>Tokyo</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div className="six columns">
					<table className="pops">
						<tbody>
						<tr>
							<td><i className="fa fa-map-marker"></i> Kazakhstan</td>
							<td>Shakhtinsk</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Malaysia</td>
							<td>Cyberjaya</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Netherlands</td>
							<td>Amsterdam</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> New Zealand</td>
							<td>Palmerston north</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Poland</td>
							<td>Gdansk, Warsaw</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Portugal</td>
							<td>Lisbon</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Russia</td>
							<td>Saint petersburg, Moscow</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Singapore</td>
							<td>Singapore</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Spain</td>
							<td>Barcelona</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Sweden</td>
							<td>Goteborg</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Switzerland</td>
							<td>Zurich</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Thailand</td>
							<td>Bangkok</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> Ukraine</td>
							<td>Kiev</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> United Kingdom</td>
							<td>Maidenhead</td>
						</tr>
						<tr>
							<td><i className="fa fa-map-marker"></i> USA</td>
							<td>Chicago, Seattle, Miami, Washington, Los angeles</td>
						</tr>
						</tbody>
					</table>
			</div>
		</div>
	</div>
</ReactDependentScript>
	<PaymentMethodsInclude/>
	</React.Fragment>
	)}
	toggleAnimation = (e) => {
		document.getElementById('ddos-protection-info').classList.toggle('expanded')
		  }
}

export default CDN;