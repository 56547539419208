import React from 'react';
import { NavLink } from "react-router-dom";

class DataVault extends React.Component {

	render() {
        
        return (
        <div className="fullwidth dark">
            <div className="row">
                <div className="eight columns">
                    <div className="row">
                        <div className="twelve columns">
                            <h2>Your Personal <NavLink to="/en/data-protection-switzerland">Data Vault</NavLink></h2><p>Switzerland is renowned for its strict laws and regulations on privacy and data protection that make it impossible for any third party including authorities to get access to a web hosting provider's data, unless a formal request is filed by a prosecutor, who is investigating a client in particular. At the federal level, the collection and use of personal data is regulated by the Federal Act on Data Protection. Additionally, each canton in Switzerland has a cantonal Data Protection Act in place. There's hardly any other country in the world with a matching grade of privacy protection at the political level.</p></div>
                        </div>
                    <div className="row">
                        <div className="twelve columns">
                            <p>Not only your data and privacy are protected by Swiss laws, but we also take weighty efforts to secure it at the infrastructure level. Our data center in Zürich is practically a fortress: 24x7 CCTV surveillance, perimeter and inner premises security patrols, multi-layered access barriers, including mantraps and biometric readers.</p>
                            <NavLink className="readmore left" to="/en/data-protection-switzerland">Data protection <i className="fa-regular fa-square-caret-right"></i></NavLink>
                        </div>
                    </div>
                </div>
                <div className="four columns center">
                    <NavLink to="/en/data-protection-switzerland" title="Read more about data protection in Switzerland."><img src="/images/data-protection-swiss-vault.png" className="data-protection themed" alt="Data protection in Switzerland" title="Click to read more about data protection in Switzerland."/></NavLink>
                </div>
            </div>
        </div>
            )
            }}

export default DataVault