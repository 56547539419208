import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';

class AddonsVPS extends Component {

    render() {
        const addons = this.props.addons

        if(!addons) {
            return (
                <div className="fullwidth dark">
                    <div className="row">
                        <div className="twelve columns">
                            <h3>No Available Addons</h3>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="twelve columns">
                            <p>There are no add-ons available for this type of products at the moment.</p>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="fullwidth dark">
                <div className="row">
                    <div className="twelve columns">
                        <h3>Additional Storage</h3>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="four columns">
                    <p>If you are getting close to your SSD storage limit, don't hesitate to order an upgrade. All prices are monthly and based on 10 GB increments.</p>
                    </div>
                    <div className="eight columns addons">
                        <div className="row">
                            <div className="twelve columns">
                                <div className="name six columns">{addons.find(a => a.key === 'vps-disk-space').items.name} ({addons.find(a => a.key === 'vps-disk-space').items.units_each})</div>
                                <div className="price six columns">
                                    <span className='base' style={{cursor:'default'}} data-tip data-for={addons.find(a => a.key === 'vps-disk-space').items.id.toString()}>{addons.find(a => a.key === 'vps-disk-space').items.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                    <ReactTooltip id={addons.find(a => a.key === 'vps-disk-space').items.id.toString()} effect='solid'>
                                        <span>{(addons.find(a => a.key === 'vps-disk-space').items.price * this.props.usdRate).toFixed(2)} USD</span>
                                        <span>{(addons.find(a => a.key === 'vps-disk-space').items.price * this.props.eurRate).toFixed(2)} EUR</span>
                                    </ReactTooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <h3>Bandwidth Overage</h3>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="four columns">
                    <p>Need more bandwidth for your VPS? Order as many TB's as needed while configuring your virtual server or anytime later. All prices are monthly.</p>
                    </div>
                    <div className="eight columns addons">
                        <div className="row">
                                <div className="twelve columns">
                                    <div className="name six columns">{addons.find(a => a.key === 'bandwidth-overage').items.name} ({addons.find(a => a.key === 'bandwidth-overage').items.units_each})</div>
                                        <div className="price six columns">
                                            <span className='base' style={{cursor:'default'}} data-tip data-for={addons.find(a => a.key === 'bandwidth-overage').items.id.toString()}>{addons.find(a => a.key === 'bandwidth-overage').items.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                            <ReactTooltip id={addons.find(a => a.key === 'bandwidth-overage').items.id.toString()} effect='solid'>
                                                <span>{(addons.find(a => a.key === 'bandwidth-overage').items.price * this.props.usdRate).toFixed(2)} USD</span>
                                                <span>{(addons.find(a => a.key === 'bandwidth-overage').items.price * this.props.eurRate).toFixed(2)} EUR</span>
                                            </ReactTooltip>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <h3>DDoS Protection</h3>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="four columns">
                    <p>All virtual private servers, that are hosted with our company, are protected against DDoS attacks of all types that scale up to 10 Gbps completely free of charge. In an unlikely event of an attack that goes beyond the free limit, please consider ordering an upgrade.</p>
                    </div>
                    <div className="eight columns addons">
                        <div className="row">
                        {this.props.addons.find(a => a.key === 'ddos_protection').items.sort((a, b) => a.price - b.price).map((item,i) =>
                                <div className="twelve columns" key={i}>
                                    <div className="name six columns">{item.name}</div>
                                        <div className="price six columns">
                                        {item.price === 0
                                            ? <span className='base'>Free</span>
                                            : <React.Fragment><span className='base' style={{cursor:'default'}} data-tip data-for={item.name}>{item.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                            <ReactTooltip id={item.name} effect='solid'>
                                                <span>{(item.price * this.props.usdRate).toFixed(2)} USD</span>
                                                <span>{(item.price * this.props.eurRate).toFixed(2)} EUR</span>
                                            </ReactTooltip>
                                            </React.Fragment>
                                            }
                                        </div>
                                </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }}

export default AddonsVPS;
