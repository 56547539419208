import React from 'react';
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";

function Technology() {
	return (

		<React.Fragment>
			<Helmet>
				<title>Technology &amp; Infrastructure | Swiss Made Host</title>
				<meta name="description" content="Learn more about the cutting edge technology we utilize to provide the best hosting experience possible." />
			</Helmet>
			<div className="fullwidth pic technology">
				<div className="row">
					<div className="twelve columns">
						<div className="transparent">
							<h1 className="title">Technology &amp; <span>Infrastructure</span></h1>
							<h2>Security, Redundancy and Carbon Neutrality</h2>
						</div>
					</div>
				</div>
			</div>

			<div className="fullwidth dark">
				<div className="row pagetitle flex-center">
					<div className="ten columns">
						<div className="panel nomargin bordered">
							<h3>Industry-leading technology we utilize to provide the best hosting experience possible</h3>
							<p>At SWISSMADE.HOST, we are dedicated to providing the finest hosting experience possible by leveraging industry-leading technology and constantly innovating to meet the evolving needs of our clients. Our success is built upon a foundation of rigorous research and development that enables us to offer cutting-edge solutions to various challenges that our customers may encounter. By embracing established best practices and developing our own proprietary techniques, we ensure that our offerings remain at the forefront of the industry. Our expertise extends across a range of areas, including DDoS protection, IT security, and data protection. Through the combination of these advanced infrastructural, software, and hardware solutions, we guarantee our clients the highest availability rate for both our bare metal and virtual servers.</p>
							<p>Our relentless focus on maintaining a secure and robust network has transformed it into a virtually impenetrable data vault, ensuring the utmost safety for our customers' valuable information. This unwavering commitment to excellence has resulted in an exceptional customer satisfaction rate, further solidifying our reputation as a leading provider of hosting services. As the digital landscape continues to change, we remain steadfast in our mission to stay ahead of the curve and provide our customers with the best possible hosting experience. By continually investing in research, development, and innovation, we ensure that our clients always have access to the most advanced and reliable hosting solutions available on the market.</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="twelve columns technology">
						<div className="row">
							<div className="four columns">
								<h5>Redundant Power Supply</h5>
								<hr className="gray" />
								<div className="left paddingright"><span className="fa-stack fa-3x">
									<i className="fa-regular fa-square fa-stack-2x"></i>
									<i className="fa fa-plug fa-stack-1x"></i>
								</span>
								</div>
								<p>Uninterrupted power supply is the key to the record-setting availability. These are the measures taken to guarantee power supply security: two different power providers, two independent power feeds to each rack, industrial-grade UPS with auto switchover and diesel generators ready for indefinite running in case of a serious blackout.</p>
							</div>
							<div className="four columns">
								<h5>Premium Networking &amp; Connectivity</h5>
								<hr className="gray" />
								<div className="left paddingright"><span className="fa-stack fa-3x">
									<i className="fa-regular fa-square fa-stack-2x"></i>
									<i className="fa-solid fa-signal fa-stack-1x"></i>
								</span>
								</div>
								<p>We have put a lot of effort into building a no single point of failure network architecture: redundant hardware with automatic failover protection, multiple Tier-1 bandwidth providers, direct connection to SwissIX Internet exchange point, professional DDoS protection, automatic network health monitoring and 24/7/365 intelligent hands on site.</p>
							</div>
							<div className="four columns">
								<h5>Seven-layer Physical Security</h5>
								<hr className="gray" />
								<div className="left paddingright"><i className="fa fa-lock fa-5x" aria-hidden="true"></i></div>
								<p>Physical security is as important as IT security when it comes to choosing the right data center to collocate your mission critical hardware. 24/7 video surveillance and security patrols, multiple access barriers with biometric readers and mantraps render unauthorized access to servers and networking hardware absolutely impossible.</p>
							</div>
						</div>
						<div className="row">
							<div className="four columns">
								<h5>DDoS Protection</h5>
								<hr className="gray" />
								<div className="left paddingright"><i className="fa fa-shield-halved fa-5x" aria-hidden="true"></i></div>
								<p>DDoS attack can disrupt operation of almost any web property, even a well established one. Please don't worry about this unlikely scenario, we've got you covered. Our engineers have developed a proprietary solution that will <NavLink to="/en/ddos-protection-switzerland">protect your servers</NavLink> against 99.999% of DDoS attacks registered to this day.</p>
							</div>
							<div className="four columns">
								<h5>Private Networking</h5>
								<hr className="gray" />
								<div className="left paddingright"><i className="fa fa-sitemap fa-5x" aria-hidden="true"></i></div>
								<p>If you have ordered two or more dedicated servers from our company, you can easily connect them to an isolated private network with no public access from the internet. This feature can be especially useful for certain Backend/Frontend interconnection setups.</p>
							</div>
							<div className="four columns">
								<h5>All Flash SSD Storage by SolidFire®</h5>
								<hr className="gray" />
								<div className="left paddingright"><span className="fa-stack fa-3x">
									<i className="fa-regular fa-hard-drive fa-2x" aria-hidden="true"></i>
									<strong className="fa-stack-1x hdd-text">SSD</strong>
								</span>
								</div>
								<p>What's an ideal data storage solution? It has to be fast, secure and virtually invulnerable. We have carefully selected a solution that would allow our clients to benefit from highest I/O rate of an all-flash SSD array, secure sensitive data with 256-bit encryption and prevent data-loss with the industry leading resilient, self-healing architecture.</p>
							</div>
						</div>
						<div className="row">
							<div className="four columns">
								<h5>VMware® ESXi Bare-metal Hypervisor</h5>
								<hr className="gray" />
								<div className="left paddingright"><i className="fa fa-server fa-5x" aria-hidden="true"></i></div>
								<p>When it comes to selecting the right virtualization solution, industry-leading, purpose-built bare-metal VMware ESXi hypervisor is considered the most mature and reliable product on the market. Virtual environment based on VMware hypervisor is simply the closest you can get to a true dedicated server experience.</p>
							</div>
							<div className="four columns">
								<h5>Direct Connection to SwissIX</h5>
								<hr className="gray" />
								<div className="left paddingright"><i className="fa fa-upload fa-5x" aria-hidden="true"></i></div>
								<p>Our data center in Zurich has direct connection to <a title="SwissIX Swiss Internet Exchange" href="https://www.swissix.ch/" target="_blank" rel="noopener noreferrer">SwissIX</a> Internet Exchange, which is the largest Internet Exchange Point (IXP) in Switzerland in terms of peering participants. Direct IXP connection lowers latency by improving routing efficiency and provides an extra layer of networking fault-tolerance.</p>
							</div>
							<div className="four columns">
								<h5>Minimum Carbon Footprint</h5>
								<hr className="gray" />
								<div className="left paddingright"><i className="fa fa-leaf fa-5x" aria-hidden="true"></i></div>
								<p>We strive to set an example of responsible approach to energy and keep our Power Usage Effectiveness (PUE) exceptional by any standards. We use energy originating from renewable sources only and our facility in Zurich is the first and the only carbon-neutral data center in Switzerland certified by myclimate®.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Technology;