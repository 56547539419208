import React from 'react';
import OpenVPNapp from './appOpenVPN';
import WireGuardApp from './appWireGuard';
import ShadwosocksApp from './shadowsocksApp';
import Speedtest from './speedtest';

class AppList extends React.Component {

	render() {

		if (this.props.protocol === 'all') {

			return (
				<div className="fullwidth light">
					<div className="row">
						<div className="four columns">
							<WireGuardApp />
						</div>
						<div className="four columns">
							<OpenVPNapp />
						</div>
						<div className="four columns">
							<ShadwosocksApp />
						</div>
					</div>
					<div className="row flex-center">
						<div className="six columns">
							<Speedtest />
						</div>
					</div>
				</div>

			)
		}
		if (this.props.protocol === 'wireguard') {

			return (
				<div className="fullwidth light">
					<div className="row flex-center">
						<div className="six columns">
							<WireGuardApp />
						</div>
						<div className="six columns">
							<Speedtest />
						</div>
					</div>
				</div>

			)
		}
		if (this.props.protocol === 'openvpn') {

			return (
				<div className="fullwidth light">
					<div className="row flex-center">
						<div className="six columns">
							<OpenVPNapp />
						</div>
						<div className="six columns">
							<Speedtest />
						</div>
					</div>
				</div>

			)
		}
		if (this.props.protocol === 'shadowsocks') {

			return (
				<div className="fullwidth light">
					<div className="row flex-center">
						<div className="six columns">
							<ShadwosocksApp />
						</div>
						<div className="six columns">
							<Speedtest />
						</div>
					</div>
				</div>

			)
		}
	}
}

export default AppList