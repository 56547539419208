import React from 'react';

class LoadingError extends React.Component {
    render() { return (
		<div className="fullwidth light">
			<div className="row">
				<div className="twelve columns">
					<div className="panel pricing">
						<h3>Something Went Wrong</h3>
						<p>Looks like there is a temporary issue with this page. Please try again later.</p>			
					</div>
				</div>
    		</div>
		</div>
    ) }
}

export default LoadingError;