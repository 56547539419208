import React from 'react';

class ShadwosocksApp extends React.Component {

	render() {

		return (

			<div className="panel nomargin marginmobile">
				<h3>Shadowsocks App</h3>
				<hr className="black" />
				<p>First, download a client which supports either Shadowsocks or Outline. Next, create a VPN configuration in your account area. Then, add this configuration to your app either by copying the config link we've supplied you with or scanning the QR code.</p>
				<a href="https://getoutline.org/get-started/#step-3" target='_blank' rel="noopener noreferrer nofollow" className='readmore left'><i className="fa-regular fa-square-caret-down"></i> Download Shadowsocks App</a>
			</div>

		)
	}
}

export default ShadwosocksApp