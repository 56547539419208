import React from 'react';
import { NavLink } from "react-router-dom";

class LocationSwitzerland extends React.Component {

	render() {
        
        return (
            <div className="fullwidth light">
                <div className="row">
                    <div className="four columns">
                        <NavLink to="/en/data-center-switzerland" title="Take a tour on our data center in Zürich"><img src="/images/map-of-switzerland.png" alt="Map of Switzerland" title="Click to take a tour and learn more about our data center in Zürich."/></NavLink>
                    </div>
                    <div className="eight columns">
                        <div className="row">
                            <div className="twelve columns">
                                <h2>Location: <NavLink to="/en/data-center-switzerland">Zürich, Switzerland</NavLink></h2>
                                <p>Switzerland is ideally located right in the heart of Europe and is bordered by three of its largest market economies. Our country is well-known for its long lasting stability, neutrality, political independence and highly educated workforce. All these advantages put together make Switzerland the perfect location for organizations that focus their activities on EU area. </p>
                                <p>Our company resides at Interxion™ Zürich data center - the largest of its kind in Switzerland. Interxion™ is an ultra-secure, carbon and carrier neutral location for storing and processing data in line with Swiss data protection regulations. It's hard to overestimate the importance of selecting the right location to host your business - locating in a safe harbor country right in the center of Europe is a strategic decision aimed at securing your success in the future.</p>
                                <NavLink className="readmore left" to="/en/data-center-switzerland">Data center <i className="fa-regular fa-square-caret-right"></i></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
            }}

export default LocationSwitzerland