import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import FAQinclude from '../../components/includes/FAQinclude';

const ServerProduct = () => {
  const { specs, name, id } = useParams(),
  [productsJSON, setProductsJSON] = useState(null), 
  handleJsonLdGenerated = (jsonLd) => {
    if (productsJSON === null) {
      setProductsJSON(jsonLd);
    }
    };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {
          "Swiss Dedicated Server: " +
            specs
              .replace(/-/g, " ")
              .replace(/ddr(\d+)\s+(\d+)/gi, "DDR$1-$2")
              .replace(/(\d+(\.\d+)?)\s*ghz/gi, "$1 GHz")
              .replace(/(cpu|ram)/gi, (match) => match.toUpperCase() + ",")
              .replace(/storage/gi, "Storage,")
              .replace(/ecc/g, "ECC")
              .replace(/vlp\s*ecc/gi, "VLP ECC")
              .replace(/,\s*\d+\s*drive(s?)/, "")
              .replace(/\b\w/g, (match) => match.toUpperCase())
              .replace(/,$/, "")
          }
        </title>
        <meta name="description" content={
            "Dedicated Server in Zurich, Switzerland. Configuration: " +
              (specs
                .replace(/-/g, " ")
                .replace(/ddr(\d+)\s+(\d+)/gi, "DDR$1-$2")
                .replace(/(\d+(\.\d+)?)\s*ghz/g, "GHz")
                .replace(/(\d+)\s*cores\s*cpu/g, "CPU ($1 cores),")
                .replace(/ram/g, "RAM,")
                .replace(/ecc/g, "ECC")
                .replace(/vlp\s*ecc/gi, "VLP ECC")
                .replace(/(storage|drives)/gi, (match, p1, offset, string) => {
                  if (p1 === "drives") {
                    return p1;
                  }
                  return p1 + ",";
                })
                .replace(/,\s*(\d+\s*drive[s]?)/gi, " ($1)")
                .replace(/\b\w/g, (match) => match.toUpperCase()))
              + '. Free KVM/IPMI. Optional DDoS protection.'
          }
        />
      	{productsJSON && <script type="application/ld+json">{JSON.stringify(productsJSON)}</script>}
        <link rel="canonical" href={`https://swissmade.host${window.location.pathname}`} />
      </Helmet>
      <div className="fullwidth pic dedicated">
        <div className="row">
          <div className="twelve columns">
            <div className="transparent">
              <h1 className="title">
                 <strong className="colored">{name.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}</strong> Dedicated Server in <span><i className="fa fa-plus-square" aria-hidden="true"></i> Switzerland</span>
              </h1>
              <h2>{
                    (specs
                      .replace(/-/g, " ")
                      .replace(/ddr(\d+)\s+(\d+)/gi, "DDR$1-$2")
                      .replace(/(\d+(\.\d+)?)\s*ghz/g, "GHz")
                      .replace(/(\d+)\s*cores\s*cpu/g, "CPU ($1 cores) /")
                      .replace(/ram/g, "RAM /")
                      .replace(/ecc/g, "ECC")
                      .replace(/vlp\s*ecc/gi, "VLP ECC")
                      .replace(/(storage|drives)/gi, (match, p1, offset, string) => {
                        if (p1 === "drives") {
                          return p1;
                        }
                        return p1 + ",";
                      })
                      .replace(/,\s*(\d+\s*drive[s]?)/gi, " ($1)")
                      .replace(/\b\w/g, (match) => match.toUpperCase()))
                  }
              </h2>
            </div>
          </div>
        </div>
      </div>
      <DiscountInclude />
      <DynamicContent onJsonLdGenerated={handleJsonLdGenerated} id={id} />
      <FAQinclude category={'dedicated'} color={'light'} />
      <PaymentMethodsInclude />
    </React.Fragment>
  );
};

export default ServerProduct;
