import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';
import { NavLink } from 'react-router-dom';

const owloptions = {
	loop: true,
	margin: 10,
	nav: false,
	autoplay: true,
	autoplayTimeout: 5000,
	items: 3,
	slideBy: 1,
	responsive: {
		0: {
			items: 1,
			slideBy: 1,
			autoplayTimeout: 5000
		},
		900: {
			items: 3
		},
		1000: {
			items: 3
		}
	}
};


class FeatureSlider extends React.Component {

	render() {

		return (

			<div className="fullwidth dark">
				<div className="row">
					<div className="twelve columns">
						<OwlCarousel2 options={owloptions}>
							<div className="item">
								<h5>High Proficiency</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa fa-graduation-cap fa-5x" aria-hidden="true"></i></div>
								<p>Solar Communications unites highly qualified professionals in their respective areas of expertise including system administration, networking, data protection, IT security and customer relations. All members of our team undergo regular training aimed at improving their knowledge and skillset and ensuring competence and ability to deal with the newly emerging challenges.</p>
							</div>

							<div className="item">
								<h5>Customer Orientation</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa fa-user fa-5x" aria-hidden="true"></i></div>
								<p>In accordance with Solar Communications customer orientation policy we make great efforts to have a deep understanding of customers' needs and expectations. We focus on helping customers to meet their long-term needs and deliver an efficient solution to any issue that may arise with a sense of warmth, friendliness, mutual respect and company spirit.</p>
							</div>

							<div className="item">
								<h5>LIR &amp; RIPE NCC Accredited</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa fa-certificate fa-5x" aria-hidden="true"></i></div>
								<p>Solar Communications is accredited by LIR (Local Internet Registry) and involved in allocation of IP address ranges and <a target="_blank" href="https://stat.ripe.net/AS197988" rel="noopener noreferrer">AS by RIPE NCC</a> (The Réseaux IP Européens Network Coordination Centre) - the regional Internet registry for Europe that allocates and registers blocks of IP addresses to Internet service providers (ISPs) and other organizations.</p>
							</div>

							<div className="item">
								<h5>Minimum Carbon Footprint</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa fa-leaf fa-5x" aria-hidden="true"></i></div>
								<p>We strive to set an example of responsible approach to energy and keep our Power Usage Effectiveness (PUE) exceptional by any standards. We use energy originating from renewable sources only and our facility in Zurich is the first and the only carbon-neutral data center in Switzerland certified by myclimate®.</p>
							</div>

							<div className="item">
								<h5>Data Protection</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa fa-key fa-5x" aria-hidden="true"></i></div>
								<p>Switzerland is well known for its strict laws on privacy and data protection - a hosting provider cannot be forced to disclose data unless when Swiss authorities investigate a client and an injunction is obtained. There's hardly any other country in the world with a matching grade of <NavLink to="/en/data-protection-switzerland">data protection</NavLink> on the state level.</p>
							</div>
							<div className="item">
								<h5>24/7 Live Support</h5>
								<hr className="grey" />
								<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
								<p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function (event) { event.preventDefault(); toggleWidget() }} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
							</div>
						</OwlCarousel2>
					</div>
				</div>
			</div>

		)
	}
}

export default FeatureSlider