import React, {Component} from 'react';

class CloudInfo extends Component {
    
    render() {
        return (
            <div className="fullwidth dark">
   <div className="row">
      <div className="twelve columns">
         <h2>Why Cloud Hosting?</h2>
         <p>Cloud Server is basically one of the most advanced, secure and highly available means of hosting a mission critical web site, service or application. VMware®-based Cloud environment offers pretty much the same user experience as that of a dedicated server while providing extra layers of data security, scalability and failover protection. There are numerous advantages of scalable Cloud-based hosting solutions over classic virtual private and bare metal servers, including dynamic resource scalability and higher availability that can safely be regarded as the most significant ones. </p>
		 <span className="left readmore" id="readmore" onClick={this.toggleAnimation}>Read more <i className="fa-regular fa-caret-square-right" id="caret" aria-hidden="true"></i></span>
	  </div>
   </div>
   <div className="row expandableContent mt30" id="cloud-info">
      <div className="twelve columns">
         <div className="four columns">
            <table className="cloudVS">
               <thead>
                  <tr>
                     <th>&nbsp;</th>
                     <th>VPS</th>
                     <th>CLOUD</th>
                  </tr>
               </thead>
               <tbody>
                  <tr className="visible-xs" aria-hidden="true">
                     <td>&nbsp;</td>
                     <td colSpan="3">VMware® virtualization</td>
                  </tr>
                  <tr>
                     <td>VMware® virtualization</td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                  </tr>
                  <tr className="visible-xs" aria-hidden="true">
                     <td>&nbsp;</td>
                     <td colSpan="3">10 Gbps networking</td>
                  </tr>
                  <tr>
                     <td>10 Gbps networking</td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                  </tr>
                  <tr className="visible-xs" aria-hidden="true">
                     <td>&nbsp;</td>
                     <td colSpan="3">All flash mirrored SSD array</td>
                  </tr>
                  <tr>
                     <td>All flash mirrored SSD array</td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                  </tr>
                  <tr className="visible-xs" aria-hidden="true">
                     <td>&nbsp;</td>
                     <td colSpan="3">Hypervisor failover protection</td>
                  </tr>
                  <tr>
                     <td>Hypervisor failover protection</td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                  </tr>
                  <tr className="visible-xs" aria-hidden="true">
                     <td>&nbsp;</td>
                     <td colSpan="3">Custom hardware configuration</td>
                  </tr>
                  <tr>
                     <td>Custom hardware configuration</td>
                     <td className="no"><i className="fa fa-close" aria-hidden="true"></i></td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                  </tr>
                  <tr className="visible-xs" aria-hidden="true">
                     <td>&nbsp;</td>
                     <td colSpan="3">Upscaling/downscaling as you go</td>
                  </tr>
                  <tr>
                     <td>Upscaling/downscaling as you go</td>
                     <td className="no"><i className="fa fa-close" aria-hidden="true"></i></td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                  </tr>
                  <tr className="visible-xs" aria-hidden="true">
                     <td>&nbsp;</td>
                     <td colSpan="3">Custom OS images</td>
                  </tr>
                  <tr>
                     <td>Custom OS images</td>
                     <td className="no"><i className="fa fa-close" aria-hidden="true"></i></td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                  </tr>
                  <tr className="visible-xs" aria-hidden="true">
                     <td>&nbsp;</td>
                     <td colSpan="3">Free daily backups</td>
                  </tr>
                  <tr>
                     <td>Free daily backups</td>
                     <td className="no"><i className="fa fa-close" aria-hidden="true"></i></td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                  </tr>
                  <tr className="visible-xs" aria-hidden="true">
                     <td>&nbsp;</td>
                     <td colSpan="3">Monthly billing</td>
                  </tr>
                  <tr>
                     <td>Monthly billing</td>
                     <td className="no"><i className="fa fa-close" aria-hidden="true"></i></td>
                     <td className="yes"><i className="fa fa-circle" aria-hidden="true"></i></td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div className="eight columns">
            <h4>Smart Resource Scalability &amp; Cost Efficiency</h4>
            <p>Cloud architecture allows easy and hassle-free expanding of your resource pool to accommodate heavier loads and meet the growing demand of your online property. Start small with a less expensive plan and add resources when needed — while paying for what you use only.</p>
            <h4>Higher Reliability</h4>
            <p>Cloud environment provides a higher level of reliability and convenience than that of VPS or dedicated server. While benefiting from the same infrastructure solutions as classic virtual and bare metal servers (multihomed networking with physical connection line divided among the multiple Tier-1 upstream providers, redundant power sources with automatic switchover) Cloud servers provide additional failover protection and usability features of its own, that are not available with VPS or dedicated server plan.</p>
            <p>Each Cloud machine is hosted on multiple hardware servers with data mirrored through a number of secure SSD storage arrays to make sure that there is no single point of failure. In an unlikely event of a hardware hypervisor malfunction, other chained hypervisors will seamlessly balance the load without any service interruption.</p>
         </div>
      	</div>
   		</div>
		</div>
        )
    }
    toggleAnimation = (e) => {
        document.getElementById('cloud-info').classList.toggle('expanded')
          }}
    export default CloudInfo;
