import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class CpanelPreloader extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="fullwidth light">
                    <div className="row">
                        <div className="four columns">
                            <Skeleton count={5} />
                            <p />
                            <Skeleton count={20} />
                            <p />
                            <Skeleton count={5} />
                            <p />
                            <Skeleton count={3} />
                        </div>
                        <div className="four columns">
                            <Skeleton count={5} />
                            <p />
                            <Skeleton count={20} />
                            <p />
                            <Skeleton count={5} />
                            <p />
                            <Skeleton count={3} />
                        </div>
                        <div className="four columns">
                            <Skeleton count={5} />
                            <p />
                            <Skeleton count={20} />
                            <p />
                            <Skeleton count={5} />
                            <p />
                            <Skeleton count={3} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CpanelPreloader;