import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';

const owloptions = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 3,
    slideBy: 1,
    responsive:{
        0:{
            items:1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        900:{
            items:3
        },
        1000:{
            items:3
        }
    }
};


class FeatureSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
	<div className="row">
		<div className="twelve columns">
    <OwlCarousel2 options={owloptions}>
			<div className="item">
				<h5>Domains and Mail</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-envelope fa-5x"></i></div>
				<p>cPanel allows server administrators to control their domains, manage DNS zones and create redirects without hassle. User-friendly cPanel mailer has all the functions you may need: create mail accounts, setup forwarders and auto-responders, filter out unsolicited messages with built-in spam protection and manage mailing lists to keep you users updated about your latest news, events and promos.</p>
			</div>
			<div className="item">
				<h5>Enhanced Security</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-lock fa-5x"></i></div>
				<p>Set password-protected directories, white-list or black-list IP addresses, configure SSL/TLS and GnuPG keys to restrict access. Protect your site with Leech Protection module (safeguards restricted areas of your site), HotLink Protection module (prevents direct linking to files and saves bandwidth) and ModSecurity firewall (toolkit for real-time web application monitoring, logging, and access control).</p>
			</div>
			<div className="item">
				<h5>Files &amp; Databases</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-database fa-5x"></i></div>
				<p>Get an easy access to your files via browser-based file manager, modify, back up or restore files and folders, edit and manage images saved to your account and monitor your site's available disk space. cPanel provides built-in tools to create, manage and control databases with MySQL, PostgreSQL and phpMyAdmin modules.</p>
			</div>
			<div className="item">
				<h5>Statistics &amp; Logs</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-bar-chart fa-5x"></i></div>
				<p>View your website's metrics including hosting account statistics, bandwidth and disk space usage. Analyze either raw or processed into detailed graphs and tables server logs, monitor installed modules activity. Know your site's visitors and track your site's performance with Webalizer module (charts and graphs about your audience) and AWStats module (visual statistics about visitors of your site).</p>
			</div>
			<div className="item">
				<h5>Automatic Maintenance and Updates</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-gears fa-5x" aria-hidden="true"></i></div>
				<p>Web based control panels provides tools to configure Cron jobs, MIME types and Apache handlers to automate web server and host server tasks according to your site's preferences. cPanel and its modules are subject to regular automatic updates to ensure that you have the latest and most secure build installed on your server and significantly eliminate risk of software malfunction, critical errors or unauthorized access.</p>
			</div>
			<div className="item">
				<h5>24/7 Live Support</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
				<p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
		   </div>
		</OwlCarousel2>
		</div>
	</div>
</div>

            )
            }}

export default FeatureSlider