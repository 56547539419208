import React from 'react';
import Helmet from "react-helmet";

function PrivacyPolicy() {
  return (

    <React.Fragment>
    <Helmet>
		<title>Privacy Policy | Swiss Made Host</title>
		<meta name="description" content="Our company sticks to a strict Privacy Policy to ensure that your personal information is secure and not compromised in any way." />
		<meta name="robots" content="noindex"/>
	</Helmet>
	<div className="fullwidth pic legal">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
					<h1 className="title">Privacy Policy <span>SWISSMADE.HOST</span></h1>
					<h2>Your Privacy is our Concern</h2>
				</div>
			</div>
		</div>
	</div>
<div class="fullwidth light">
	<div class="row flex-center">
		<div class="ten columns">
				<div class="panel light">
					<blockquote class="white">Solar Communications Gmbh. is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. Solar may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</blockquote>
					<h3 class="mt30">1. Introduction</h3>
					<p>This policy explains when, why and how we collect personal information from the people who visit our website, sign up or purchase any of our services or request or contact us in any other manner. The policy also details the conditions under which we may disclose the information to others and how we keep it secure.
						While we encourage clients to read our privacy policy in full, we do have a number of guiding principles we operate our company by:
					</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>We will never sell your data on.</li>
							<li>We will never share your data for marketing to by any third parties.</li>
							<li>We will always keep your personal data secure using strong encryption, abiding by data protection rules and by implementing good security practice.</li>
							<li>In rare cases we may send you email marketing that contains news or current promotions. You can opt-out at any time by contacting us using email below.</li>
						</ul>
					</blockquote>
					<p>If you are ever concerned or have question about how your personal data is collected or used, please contact us (see Contacting Us).</p>
					<h3 class="mt30">2. About Us</h3>
					<p>Solar Communications GmbH is registered at the following address:</p>
					<p>Mürtschenstrasse 30<br />
						CH-8048 Zürich<br />
						Switzerland</p>
					<p>This policy (together with our terms of use Terms and Conditions and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it</p>
					<p>For the purpose of the Data Protection Act 1998 (the Act) and GDPR (General Data Protection Regulation), the data controller is Solar Communications GmbH, a company registered in Switzerland.</p>
					<p>Our nominated representative for the purpose of the Act is available via following email: info@solarcom.ch</p>
					<h3 class="mt30">3. When do we collect your personal data</h3>
					<p>We may collect data about you in the following ways:</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>By filling in forms on any of our websites, this includes information provided at the time of registering to use our websites, subscribing to any of our services, posting material or requesting further services. We may also ask you for information when you enter a competition or promotion sponsored by us or if you report a problem with our site.</li>
							<li>If you contact us, we may keep a record of that correspondence.</li>
							<li>We may also ask you to complete surveys that we use for research purposes, although you do not have to respond to them.</li>
							<li>Details of transactions you carry out through our site and of the fulfillment of your orders.</li>
							<li>Visiting our sites, including, but not limited to, traffic data, location data and other communication data required for our own security, diagnostic, authentication and billing.</li>
						</ul>
					</blockquote>
					<h3 class="mt30">4. What personal data do we collect</h3>
					<p>If you are simply viewing our website then we will collect the following information within our logs:</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>IP Address (See cookies and IP addresses)</li>
						</ul>
					</blockquote>
					<p>If you sign up to use our services we will then collect the following information:</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>Name</li>
							<li>Address</li>
							<li>Email Address(s)</li>
							<li>Telephone number(s)</li>
							<li>IP address</li>
							<li>Payment details</li>
						</ul>
					</blockquote>
					<p>While not actively collected we may store any other personal information that you may disclose during live chat, tickets or emails.</p>
					<h3 class="mt30">5. Cookies and IP Addresses</h3>
					<h4>IP Addresses</h4>
					<p>We collect IP address information for security (DDoS prevention, anti-hacking and fraud prevention), diagnostics and statistical analysis of traffic used for improvements to the performance and usability of our services, for GDPR purposes we collect this information under legitimate interest.</p>
					<h4>Cookies</h4>
					<p>A cookie is a small file saved on your computer that is used to help store preferences and other information that is used by websites you may visit.</p>
					<p>We use cookies for the Following:</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>Authentication of users when logging in or out of our services</li>
							<li>Analysis of traffic to our website.</li>
							<li>Tracking of website preferences.</li>
							<li>Third party services such live chat.</li>
						</ul>
					</blockquote>
					<p>If you register with us or if you continue to use our site, you agree to the use of cookies. You can block cookies by activating the setting in your browser which allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies you may not be allowed access to all or parts of our site.</p>
					<p>Unless you have adjusted your browser settings so that it will refuse cookies, our system will issue cookies as soon as you visit our site.</p>
					<h3 class="mt30">6. Where we store and process your personal data</h3>
					<p>The data that we collect from you is stored in Switzerland but it may be processed by staff operating outside the EEA (European Economic Area) who work for us or for one of our suppliers. Such staff may be engaged in, among other things, the fulfillment of your order, the processing of your payment details and the provision of support services.</p>
					<p>By submitting your personal data, you agree to the processing of this data outside of the EEA. We take all steps necessary to ensure that your data, regardless of where it is processed, is treated securely and in accordance with this privacy policy and the GDPR (General Data Protection Regulation) which includes certification to ISO27001 standard.</p>
					<h3 class="mt30">7. How we Protect your personal data</h3>
					<p>We treat all data with the utmost care and take appropriate steps in compliance with data protection regulation to ensure it is kept safe.</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>All data we collect is done so over encrypted connections (https).</li>
							<li>All data is stored behind state of the art firewalls managed by our security team.</li>
							<li>All data is access controlled to ISO27001 standards.</li>
							<li>All systems storing personal data have access logging.</li>
							<li>All passwords are encoded at rest.</li>
							<li>All systems are subject to regular penetration testing and are monitored for vulnerabilities and attacks.</li>
						</ul>
					</blockquote>
					<h3 class="mt30">8. Uses we make of personal data</h3>
					<p>We use information held about you in the following ways:</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>To verify your identity.</li>
							<li>To provide you with the products and services you have purchased from us.</li>
							<li>To carry out our obligations arising from any contracts entered into between you and us.</li>
							<li>To ensure that content from our site is presented in the most effective manner for your device.</li>
							<li>To respond to queries.</li>
							<li>To provide you with information, products or services that you request from us where you have consented to be contacted.</li>
							<li>To notify you about changes to our services.</li>
							<li>To display personalized Ads from us.</li>
							<li>To prevent fraud.</li>
							<li>To detect, prevent and diagnose potential security breaches.</li>
						</ul>
					</blockquote>
					<h3 class="mt30">9. Legal basis for the collection and processing</h3>
					<p>Data protection law sets out the conditions under which personal data can be collected and which we use as the basis for collection and processing, these include:</p>
					<h3>Contractual obligations</h3>
					<p>When you purchase a service, we need to collect personal data to fulfill our legal contractual obligation, for example: so we can manage and invoice your account and contact you for technical support purpose</p>
					<h3>Legal compliance</h3>
					<p>The law requires we collect and process data for certain purposes such as for keeping financial records (i.e. invoicing) and that we can comply with law enforcement requests for data.</p>
					<h3>Legitimate interest</h3>
					<p>We collect data under legitimate interest in a way that is reasonably expected as part of the day to day running of our business. For example, we collect and log IP addresses for fraud protection and security as well as analysis of our site usage.</p>
					<h3>Consent</h3>
					<p>In certain cases, we may collect and process your data with your consent, for example our newsletter and hot offers emails. Consent to receive these can be withdrawn at any time.</p>
					<h3 class="mt30">10. How long we keep personal data</h3>
					<p>Overall, your data will be kept until it is no longer required for the purpose of its collection. Certain data that is required to handle our business activities or linked to purchases, or any other financial transaction, will be kept for extended periods of time.</p>
					<h3 class="mt30">11. Who we share personal data with</h3>
					<p>We may disclose your personal information to third parties:</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>Any cardholder who has purchased services on your behalf;</li>
							<li>Any employees, agents or service providers of Solar Communications GmbH to deal with any accounts or to deliver specific services to you;</li>
							<li>Any associated company who are our business partners and/or with whom we work;</li>
							<li>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets, subject to their entering into appropriate confidentiality undertakings;</li>
							<li>If Solar Communications GmbH or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets; and</li>
							<li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation; or to protect the rights, property, or safety of Solar Communications GmbH, our customers, or others. This includes but is not limited to exchanging information with other companies and organizations for the purposes of fraud protection, credit risk reduction and dispute policies.</li>
						</ul>
					</blockquote>
					<h3 class="mt30">12. Newsletter and Marketing Emails</h3>
					<p>In rare cases we may send you email marketing that contains news or current promotions. To opt-out please contact customer support or via email info@solarcom.ch.</p>
					<h3 class="mt30">13. Disclosure of your personal information</h3>
					<p>We may disclose your personal information to any member of Solar Communications GmbH. We may disclose your personal information to third parties:</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>If Solar Communications GmbH or substantially all of its assets are acquired by a third party, in which case personal data held by it (Solar Communications GmbH) about its customers will be one of the transferred assets.</li>
							<li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions of supply Terms and Conditions and other agreements; or to protect the rights, property, or safety of our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
						</ul>
					</blockquote>
					<h3 class="mt30">14. External links</h3>
					<p>Our site may, from time to time, contain links to and from the websites of our partners. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>
					<h3 class="mt30">15. Your rights under GDPR</h3>
					<p>Under GDPR (General Data Protection regulation) you have a number of rights relating to your personal data, these will come into force on the 25th May 2018</p>
					<h3>Right to Restrict Processing</h3>
					<blockquote class="white">
						<ul class="nobullet">
							<li>You have the right to request we restrict processing of your personal data where there is no legitimate interest for us to do so:</li>
							<li>Where the accuracy of the personal data is contested, to restrict the processing until such time as the accuracy has been sufficiently verified.</li>
							<li>Where you object to the processing (See Right to Object), and where we are considering whether there are legitimate grounds to override the request.</li>
							<li>When processing is unlawful and you oppose erasure and request restriction instead.</li>
							<li>If we no longer need the personal data but you require the data to establish, exercise or defend a legal claim.</li>
						</ul>
					</blockquote>
					<p>You can exercise the right at any time by contacting our team (see Contacting Us).</p>
					<h3>Right of Access (Access to Information)</h3>
					<p>The GDPR Act gives you the right to access information held about you. You can exercise this right by contacting us (see Contacting Us). We are required to verify your identify before processing any right to access request, once verified the data shall be provided within 28 days. The data shall be provided free of charge however an admin fee may be applied where a request is manifestly unfounded or excessive, particularly if it is repetitive.</p>
					<h3>Right to Erasure (Also known as the right to be forgotten)</h3>
					<p>As an individual you have the right to request the erasure of any data we hold on you, this is not an absolute right, for example it does not override our requirement under Swiss law to keep financial data such as invoice information.</p>
					<p>You can make a request where your personal data is no longer necessary in relation to the purpose for which it was originally collected/processed, for example if you cancel all services you have with us. To make a right to erasure request please contact our customer services team (see Contacting Us).</p>
					<h3>Right to Rectification</h3>
					<p>The GDPR provides the right to have any personal data rectified that may be incorrect or incomplete. Customers can update their own personal details via the client area however if this is not sufficient please contact our customer services team (See contacting Us).</p>
					<h3>Right to Object</h3>
					<p>You have the right to object to the processing of your personal data where there is no legitimate or lawful reason to do so. To make a right to object request please contact our customer services team (see Contacting Us).</p>
					<h3 class="mt30">16. Changes to our privacy policy</h3>
					<p>We keep our privacy policy under regular review, you can always find the latest version at the URL below:</p>
					<p><a href="https://solarcom.ch/en/privacy_policy" target='_blank' rel='noopener noreferrer'>https://solarcom.ch/en/privacy_policy</a></p>
					<h3 class="mt30">17. Data Aggregation Opt-out</h3>
					<iframe id="optOut" title="Data aggregation opt-out" src="https://owa.swissmade.host/optout?module=CoreAdminHome&action=optOut&idsite=1&language=en"></iframe>
					<h3 class="mt30">18. Contacting us</h3>
					<p>Questions, comments and requests regarding this privacy policy are welcomed, you can contact us in the following ways:</p>
					<blockquote class="white">
						<ul class="nobullet">
							<li>Existing customers can contact us by creating a ticket through their client area.</li>
							<li>Email us at info@solarcom.ch</li>
						</ul>
					</blockquote>
					<h3>Revisions to This Policy</h3>
					<p>Solar Communications reserves the right to revise, amend, or modify this policy, and our other policies and agreements at any time and in any manner. We will take reasonable steps to draw your attention to any changes in our Policy. Please contact us if you have any questions questions or comments about our privacy policy.</p>
				</div>
		</div>
	</div>
</div>
	</React.Fragment>
  );
}
 
export default PrivacyPolicy;