import React from 'react';
import { NavLink } from "react-router-dom";

class ProtocolsList extends React.Component {

	render() {

		return (
			<div className="fullwidth light data-protection">
				<div className="row">
					<div className="four columns">
						<h3>What is a VPN</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fa-solid fa-lock fa-5x" aria-hidden="true"></i></div>
						<p>A Virtual Private Network (VPN) uses advanced technology to create a private, encrypted connection between your device and a server in a data center. All of your internet traffic is directed through this secure tunnel, keeping your IP address hidden and protecting your data from hackers, ISPs or anybody else who might want to snoop on your activity. Your internet connection is much more <strong>private and difficult to track</strong> when routed through a VPN. Read more about <NavLink to="/en/blog/vpn-in-switzerland-where-security-meets-privacy">VPN in Switzerland</NavLink>.</p>
					</div>
					<div className="four columns">
						<h3>Your Private Swiss VPN</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fa-solid fa-server fa-5x" aria-hidden="true"></i></div>
						<p>Many VPN service providers offer public VPNs, where multiple clients share a single server and IP address. We offer a different solution: a VPN with <strong>dedicated IP address</strong> that geolocates to <NavLink to="/en/data-center-switzerland">Zurich, Switzerland</NavLink>. In a nutshell, each client gets their own VPN server and unique IP address, offering the ultimate level of privacy and security. It's the perfect VPN solution for those who are looking for uncompromised security without having to set up and configure their own server.</p>
					</div>
					{this.props.protocol === 'all' &&
						<div className="four columns">
							<h3>Supported Protocols</h3>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa-solid fa-code fa-5x" aria-hidden="true"></i></div>
							<p>Our VPN servers are powered by the folowing protocols: <NavLink to="/en/openvpn-switzerland">OpenVPN</NavLink>, <NavLink to="/en/wireguard-vpn-switzerland">WireGuard®</NavLink> and <NavLink to="/en/shadowsocks-vpn-switzerland">Shadowsocks</NavLink>. These protocols have been thoroughly examined by independent experts and have been found to be secure, fast, and efficient. They are widely supported by a wide range of apps for various platforms and operating systems, including Windows, Linux, macOS, Android and iOS. More VPN protocols coming soon, please make sure to follow updates in our <NavLink to="/en/blog">blog</NavLink>.</p>
						</div>
					}
					{this.props.protocol === 'openvpn' &&
						<div className="four columns">
							<h3>OpenVPN Protocol</h3>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa-solid fa-code fa-5x" aria-hidden="true"></i></div>
							<p><a href="https://openvpn.net/faq/what-is-openvpn/" target="_blank" rel="noopener noreferrer nofollow">OpenVPN</a> is an open-source virtual private network (VPN) protocol for creating secure point-to-point or site-to-site connections. It uses SSL/TLS protocols and provides a secure connection over the internet to protect data in transit from eavesdropping, tampering and malicious attacks. Users trust OpenVPN for their VPN needs due to its strong security features and open-source nature, allowing for greater transparency and community-driven development.</p>
						</div>
					}
					{this.props.protocol === 'wireguard' &&
						<div className="four columns">
							<h3>WireGuard® Protocol</h3>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa-solid fa-code fa-5x" aria-hidden="true"></i></div>
							<p><a href="https://www.wireguard.com/" target="_blank" rel="noopener noreferrer nofollow">WireGuard</a> is a virtual private network (VPN) protocol that focuses on speed, simplicity, and security. WireGuard uses a minimalist design with fewer lines of code than most VPN protocols, reducing the attack surface and potential for vulnerabilities. The use of high-speed cryptographic primitives and integration within the Linux kernel make WireGuard a fast and secure networking solution. It can be used on a range of devices, from small smartphones to powerful backbone routers.</p>
						</div>
					}
					{this.props.protocol === 'shadowsocks' &&
						<div className="four columns">
							<h3>Shadowsocks Protocol</h3>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa-solid fa-code fa-5x" aria-hidden="true"></i></div>
							<p><a href="https://shadowsocks.org/" target="_blank" rel="noopener noreferrer nofollow">Shadowsocks</a> is a secure socks5 proxy, designed to protect your Internet traffic. It operates on a client-server model, offering enhanced privacy and accessibility online. Shadowsocks maintains a lightweight design, resulting in an efficient, less resource-intensive solution. It employs high-performance encryption methods to ensure secure, fast, and reliable connections. The utility of Shadowsocks extends to various devices, from lightweight handheld devices to high-capacity servers.</p>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default ProtocolsList