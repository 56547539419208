
import React from 'react';

class DownloadLink extends React.Component {

    constructor(props, context) {
		super(props, context);

        this.state = {
			error: false,
            loading: true
        }
    }
     render() {
        return (
            <div className={this.props.operator ? "rcw-snippet" : "rcw-snippet user"}>
                <h5 className="rcw-snippet-title">Download file:</h5>
                <div className="rcw-snippet-details" dangerouslySetInnerHTML={{ __html: this.props.message }}/>
            </div>
            )}}

export default DownloadLink
