import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import DiscountCounter from './dscountCounter';
import AppList from './appsList';
import ProtocolsList from './protocolsList';
import FreeSpeechInSwitzerland from '../free-speech-hosting/freeSpeechInSwitzerland';


class VPNfreeSpeech extends React.Component {

	render() {

		const protocol = 'all'

		return (
			<React.Fragment>
				<Helmet>
					<title>Free Speech VPN Servers in Switzerland | Swiss Made Host</title>
					<meta name="description" content="Freedom of Speech inspired VPN is a high security virtual key to your offshore Swiss residency that protects identity, enables access to geo-restricted content and improves secrutiy of your browsing." />
					<link rel="canonical" href="https://swissmade.host/en/free-speech-vpn" />
				</Helmet>
				<div className="fullwidth pic vpn">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Freedom of Speech <span>VPN Servers</span></h1>
								<h2>Protect your Right to Speak Freely with a Private VPN Server</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<FreeSpeechInSwitzerland />
				<ProtocolsList protocol={protocol}/>
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider protocol={protocol} />
				</ReactDependentScript>
				<DiscountCounter protocol={protocol}/>
				<AppList protocol={protocol}/>
				<PaymentMethodsInclude />
			</React.Fragment>
		)
	}
	toggleAnimation = (e) => {
		document.getElementById('ddos-protection-info').classList.toggle('expanded')
	}
}

export default VPNfreeSpeech;