import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';

const owloptions = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 3,
    slideBy: 1,
    responsive:{
        0:{
            items:1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        900:{
            items:3
        },
        1000:{
            items:3
        }
    }
};


class FeatureSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
	<div className="row">
		<div className="twelve columns">
    <OwlCarousel2 options={owloptions}>
	<div className="item">
				<h5>Private Server</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-laptop fa-5x" aria-hidden="true"></i></div>
				<p>Each CMS hosting order is delivered on a separate VPS with reserved resources and a dedicated IP address, exclusively assigned to your CMS for the duration of your active order.</p>
			</div>
			<div className="item">
				<h5>Fast Network</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-bolt fa-5x" aria-hidden="true"></i></div>
				<p>We operate a high-capacity, multi-homed network with robust DDoS protection. All servers that host Content Management Systems are connected to the Internet through a 10 Gbps port.</p>
			</div>
			<div className="item">
				<h5>Full Control</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-cogs fa-5x" aria-hidden="true"></i></div>
				<p>Easily manage the security of your website, schedule blog posts, eliminate spam, set user access permissions, and control every aspect of your site with the CMS.</p>
			</div>
			<div className="item">
				<h5>Crypto Accepted</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-brands fa-bitcoin fa-5x" aria-hidden="true"></i></div>
				<p>You can pay with any major cryptocurrency including Bitcoin, Litecoin, Ethereum, Tether USDT and other tokens, stablecoins, and digital payment processors.</p>
			</div>
			<div className="item">
				<h5>DDoS Protection</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-shield-halved fa-5x" aria-hidden="true"></i></div>
				<p>All servers on our network are equipped with DDoS protection, including the servers that host WordPress, Drupal, Joomla, and other CMS applications.</p>
			</div>
			<div className="item">
				<h5>Direct Connection to SwissIX</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-upload fa-5x" aria-hidden="true"></i></div>
				<p>Our data center in Zurich has direct connection to <a title="SwissIX Swiss Internet Exchange" href="https://www.swissix.ch/" target="_blank" rel="noopener noreferrer">SwissIX</a> Internet Exchange for improved routing efficiency, lower latency and networking fault-tolerance.</p>
			</div>
			<div className="item">
				<h5>24/7 Live Support</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
				<p>Should you have any questions, please text us through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
			</div>
			<div className="item">
				<h5>Swiss Approach</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-plus-square fa-5x colored" aria-hidden="true"></i></div>
				<p>We guarantee that your orders and requests will be processed in accordance with our company's strict privacy, data protection, customer orientation and quality assurance policies.</p>
			</div>
		</OwlCarousel2>
		</div>
	</div>
</div>

            )
            }}

export default FeatureSlider