import React from 'react';

class OpenVPNapp extends React.Component {

	render() {

		return (

			<div className="panel nomargin">
				<h3>OpenVPN App</h3>
				<hr className="black" />
				<p>To use your private VPN server, download the OpenVPN Connect® app on your device, import the .ovpn config file provided in your account area, connect to the server, enable killswitch option for added security and browse the Internet with peace of mind.</p>
				<a href="https://openvpn.net/vpn-client/" target='_blank' rel="noopener noreferrer nofollow" className='readmore left'><i className="fa-regular fa-square-caret-down"></i> Download OpenVPN App</a>
			</div>

		)
	}
}

export default OpenVPNapp