import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';
import { NavLink } from 'react-router-dom';

const owloptions = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 3,
    slideBy: 1,
    responsive:{
        0:{
            items:1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        900:{
            items:3
        },
        1000:{
            items:3
        }
    }
};


class FeatureSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
	<div className="row">
		<div className="twelve columns">
    	<OwlCarousel2 options={owloptions}>
			<div className="item">
				<h5>SLA Guarantee &amp; Moneyback Policy</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-certificate fa-5x" aria-hidden="true"></i></div>
				<p>Successful mitigation of a DDoS attack is 100% SLA guaranteed within the limitations implied by the chosen protection package specifications. In an unlikely scenario of failure to mitigate against an attack that does not exceed the ordered protection package limits, we will return the whole amount paid for the protection package and its setup.</p>
			</div>
			<div className="item">
				<h5>400 Gbps Mitigation Capacity</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-shield-halved fa-5x" aria-hidden="true"></i></div>
				<p>DDoS attacks continue to increase in size and frequency on a monthly basis. Keeping up with this alarming trend requires regular investments in hardware and uplink bandwidth capacity. Current bandwidth capacity of our network exceeds 400 Gbps which is more than enough for successful mitigation of virtually every attack that can target our customers.</p>
			</div>
			<div className="item">
				<h5>Real-time Automatic Filter Adjustment</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-sliders fa-5x" aria-hidden="true"></i></div>
				<p>Hackers behind the DDoS attacks who are seriously determined to bring the target server down often shift tactics, especially if the initial attack strategy was of no success. That's why it is so important to constantly monitor the attack patterns, both automatically and under engineers' supervision, and adjust mitigation techniques accordingly.</p>
			</div>
			<div className="item">
				<h5>Remote Proxy &amp; On-site Protection</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-exchange fa-5x" aria-hidden="true"></i>
				</div>
				<p>No matter whether you have a hosting package with our company or rent a server from any other provider, we have a solution for you. Our engineers will filter out all the malicious traffic and direct legitimate request to your backend server hosted anywhere in the world through an encrypted tunnel which is often referred to as a <i>Remote DDoS Protected Proxy</i>.</p>
			</div>
			<div className="item">
				<h5>Customizable Firewall Settings</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-wrench fa-5x" aria-hidden="true"></i></div>
				<p>If your server setup requires any specific networking, security or access patterns, configure your personal firewall presets and rules to be executed on a hardware firewall without adding any extra CPU load to your backend server. Hardware firewall can be especially useful when your server experiences a high traffic load.</p>
			</div>
			<div className="item">
				<h5>Fast Filter Engagement Time</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-bolt fa-5x" aria-hidden="true"></i></div>
				<p>We constantly monitor incoming traffic for signs of malicious activity and start filtering it out when the first few  packets with attack signature are detected. Basically, reaction time is so fast that in most scenarios neither you nor your users will be able to notice that a DDoS attack has been launched against your website or server.</p>
			</div>
			<div className="item">
				<h5>Low False Positive Rate</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-bullseye fa-5x" aria-hidden="true"></i></div>
				<p>To ensure best user experience while providing strong DDoS protection, our engineers implement various measures to achieve the lowest false positive rate possible. This includes studying each customer's legitimate traffic patterns and developing custom filter rules that tailor to their requirements and eliminate false positives.</p>
			</div>
			<div className="item">
				<h5>Strategic Location</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa-solid fa-map-marker fa-5x" aria-hidden="true"></i></div>
				<p>Strategic location right in the heart of Europe, long lasting stability, neutrality and political independence make Switzerland the perfect country to host your mission critical infrastructure. Switzerland is well known for its strict laws on privacy and data security. There's hardly any other country in the world with a matching grade of <NavLink to="/en/data-protection-switzerland">data protection</NavLink> at the state level.</p>
			</div>
			<div className="item">
				<h5>Over {new Date().getFullYear() - 2011} Years of Expertise</h5>
				<hr className="grey"/>
				<div className="left paddingright"><strong className="fa-5x"></strong></div>
				<p>We've been fighting with web-based threats and successfully mitigating against DDoS attacks since the very moment of our company's foundation. Experience gained by our engineers over the course of {new Date().getFullYear() - 2011} years allowed us to develop a proprietary attack mitigation solution and provide DDoS protection with an SLA guaranteed result.</p>
			</div>
			<div className="item">
				<h5>24/7 Live Support</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
				<p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
			</div>
			<div className="item">
				<h5>Swiss Approach</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-plus-square fa-5x colored" aria-hidden="true"></i></div>
				<p>At SwissMade.host, we are proud to be a Zurich-based web hosting company that shares the most distinct features that Switzerland is renowned for. We guarantee that your orders and requests will be processed in accordance with our company's strict privacy, data protection, customer orientation and quality assurance policies.</p>
			</div>
		</OwlCarousel2>
		</div>
	</div>
</div>

            )
            }}

export default FeatureSlider