import React from 'react';

const ErrorPrompt = () => {
  return (
        <React.Fragment>
			<h2>Something Went Wrong</h2>
			<p>Looks like there is a temporary issue with this page. Please try again later.</p>
		</React.Fragment>
    ) 
}

export default ErrorPrompt;