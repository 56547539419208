import React, { useState } from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import FAQinclude from '../../components/includes/FAQinclude';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import OffshoreSwitzerland from '../offshore-hosting/offshoreSwitzerland';
import AboutVPS from './aboutVPS';

const VPSoffshore = () => {
	const [productsJSON, setProductsJSON] = useState(null), 
	handleJsonLdGenerated = (jsonLd) => {
	  if (productsJSON === null) {
		setProductsJSON(jsonLd);
	  }
	  };

	return (
    <React.Fragment>
      <Helmet>
	  	<title>Offshore VPS | Swiss Made Host</title>
	  	<meta name="description" content="Offshore Virtual Private Server hosting with SSD storage in Zurich, Switzerland. Powered by VMware®, backed by free DDoS protection and DNS hosting." />
		<link rel="canonical" href="https://swissmade.host/en/offshore-vps"/>
		{productsJSON && <script type="application/ld+json">{JSON.stringify(productsJSON)}</script>}
	  </Helmet>

    <div className="fullwidth pic vps">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
					<h1 className="title">Offshore <span>VPS Hosting</span></h1>
					<h2>SSD storage / VMware®-based / Linux &amp; Windows</h2>
				</div>
			</div>
		</div>
	</div>
      		<DiscountInclude/>
			<AboutVPS />
			<OffshoreSwitzerland />
			<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
				<FeatureSlider/>
			</ReactDependentScript>
			<DynamicContent type='all' onJsonLdGenerated={handleJsonLdGenerated} />
			<FAQinclude category={'vps'} type={'offshore'} color={'light'}/>
			<PaymentMethodsInclude />

	</React.Fragment>
	)}

export default VPSoffshore;