import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink } from 'react-router-dom';

class OffshoreSwitzerland extends React.Component {

    render() {
    
        return (
<div className="fullwidth light">
<div className="row">
	<div className="four columns">
		<NavLink to="/en/blog/Offshore-Hosting-101-Why-Switzerland-is-the-Ideal-Location" title="Offshore Hosting 101: Why Switzerland is the Ideal Location"><LazyLoadImage src="/images/map-of-switzerland.png" title="Offshore Hosting 101: Why Switzerland is the Ideal Location" alt="Map of Switzerland"/></NavLink>
	</div>
	<div className="eight columns">
		<div className="row">
			<div className="twelve columns">
				<h2>Switzerland — the Perfect Location for <NavLink to="/en/blog/Offshore-Hosting-101-Why-Switzerland-is-the-Ideal-Location" title="Offshore Hosting 101: Why Switzerland is the Ideal Location">Offshore Hosting</NavLink></h2>
				<p>Switzerland is ideally located right in the heart of Europe and is bordered by three of its largest market economies. Our country is well-known for its long lasting stability, political independence, neutrality, highly educated workforce and top freedom rankings. All these advantages put together make Switzerland not only the perfect location for organizations that focus their activities on EU area, but also an ideal country to host your business offshore.</p>
			<div className="six columns">
				<h5>Political Independence &amp; Neutrality</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-institution fa-5x" aria-hidden="true"></i></div>
				<p>Switzerland has a long history as an independent liberal democracy with the oldest policy of military neutrality in the world. Even though Switzerland borders countries that constitute the European Union, it hasn't joined EU as a member state to preserve its neutrality and political independence.</p>
			</div>
				<div className="six columns">
				<h5>Freedom of Speech</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-bullhorn fa-5x" aria-hidden="true"></i></div>
				<p>Civil liberties are deeply respected in Switzerland and it has the highest <a href="https://freedomhouse.org/report/freedom-world/2019/switzerland" target="_blank" rel="noopener noreferrer">Freedom in The World</a> score. Switzerland has a free media environment and freedom of speech is guaranteed by the Swiss Federal Constitution. In 2019 Switzerland has the 6th rank in the <a href="https://rsf.org/en/switzerland" target="_blank" rel="noopener noreferrer">World Press Freedom Index</a> compiled by RSF.</p>
			</div>
			</div>
			</div>
		</div>
	</div>
</div>
        )}}

export default OffshoreSwitzerland