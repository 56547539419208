import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';
import { NavLink } from 'react-router-dom';

const owloptions = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 3,
    slideBy: 1,
    responsive:{
        0:{
            items:1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        900:{
            items:3
        },
        1000:{
            items:3
        }
    }
};


class FeatureSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
	<div className="row">
		<div className="twelve columns">
    <OwlCarousel2 options={owloptions}>
		<div className="item">
            <h5>High Availability</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa fa-battery-full fa-5x" aria-hidden="true"></i></div>
            <p>Downtime can be a huge stress for clients and engineers working at the data center. Here's an insight on what measures are taken to avoid any unlikely occurrence: multiple power-lines with automatic switchover, UPS and diesel generators, Tier-1 network multihoming, hypervisor failover protection and SSD storage array with data loss prevention for an extra layer of redundancy.</p>
         </div>
         <div className="item">
            <h5>Premium Network Connectivity</h5>
            <hr className="grey"/>
            <div className="left paddingright">
				<span className="fa-stack fa-3x">
					<i className="fa-regular fa-square fa-stack-2x"></i>
					<i className="fa-solid fa-signal fa-stack-1x"></i>
				</span>
			</div>
            <p>Low latency across Europe, direct connection to Swiss IX internet exchange point, efficient IP traffic routes to other regions and 10 Gbps dedicated connection for every Cloud server. Whatever network-intensive task you intend to use your Cloud machine for, latency and connectivity will not disappoint you.</p>
         </div>
         <div className="item">
            <h5>VMware® ESXi Bare-metal Hypervisor</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa fa-server fa-5x" aria-hidden="true"></i></div>
            <p>When it comes to selecting the right virtualization solution, industry-leading, purpose-built bare-metal VMware ESXi hypervisor is considered the most mature and reliable product on the market. Virtual environment based on VMware hypervisor is simply the closest you can get to a true dedicated server experience.</p>
         </div>
         <div className="item">
			<h5>All Flash SSD Storage by SolidFire®</h5>
			<hr className="grey"/>
			<div className="left paddingright"><span className="fa-stack fa-3x">
				<i className="fa-regular fa-hard-drive fa-2x" aria-hidden="true"></i>
				<strong className="fa-stack-1x hdd-text">SSD</strong>
				</span>
			</div>
			<p>What's an ideal data storage solution? It has to be fast, secure and virtually invulnerable. We have carefully selected a solution that would allow our clients to benefit from highest I/O rate of an all-flash SSD array, secure sensitive data with 256-bit encryption and prevent data-loss with the industry leading resilient, self-healing architecture.</p>
		</div>
         <div className="item">
            <h5>DDoS Protection</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa-solid fa-shield-halved fa-5x" aria-hidden="true"></i></div>
            <p>DDoS attack can disrupt operation of almost any web property, even a well established one. Please don't worry about this unlikely scenario, we've got you covered. Our engineers have developed a proprietary solution that will protect your servers against 99.999% of DDoS attacks registered to this day.</p>
         </div>
         <div className="item">
            <h5>VMware® vCloud Director Panel</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa fa-sliders fa-5x" aria-hidden="true"></i></div>
            <p>Start, pause or shut down your Cloud servers, deploy new virtual machines with an OS template or with a custom OVF image of your choice. Clone existing servers, manage backups, control your resources and perform multiple other tasks with a comprehensive yet convenient and easy-to-use <a title="vCLoud Director tutorial" href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/docs/vmware'}>vCloud Director</a> web-based control panel by VMware.</p>
         </div>
         <div className="item">
            <h5>Custom OS Images</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa-brands fa-linux fa-5x" aria-hidden="true"></i></div>
            <p>If you need to install a custom OS build on your virtual cloud server, simply import your own preconfigured OVF (Open Virtualization Format) file that contains the desired operating system image to a vCloud Director Panel and use it to launch a new vApp. Please take a look at the <a title="vCLoud Director Tutorial: Import your own ISO image" href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/docs/vmware/catalogs_and_users/import_iso'}>vCLoud Director tutorial</a> for details.</p>
         </div>
         <div className="item">
            <h5>Firewall, Daily Backups, DNS Hosting</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa fa-gears fa-5x" aria-hidden="true"></i></div>
            <p>These essential features are included with every Cloud package to create an all-in-one hosting environment at no extra charges: free daily backups for each VM, in-house DNS Hosting with PTR with PTR records, hardware firewall, isolated and connected virtual networks, VPN connections, DHCP, NAT, dynamic routing and load balancing.</p>
         </div>
         <div className="item">
            <h5>Data Protection</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa fa-key fa-5x" aria-hidden="true"></i></div>
            <p>Switzerland is well known for its strict laws on privacy and data protection - a hosting provider cannot be forced to disclose data unless when Swiss authorities investigate a client and an injunction is obtained. There's hardly any other country in the world with a matching grade of <NavLink to="/en/data-protection-switzerland">data protection</NavLink> at the state level.</p>
         </div>
         <div className="item">
			<h5>Direct Connection to SwissIX</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa fa-upload fa-5x" aria-hidden="true"></i></div>
            <p>Our data center in Zurich has direct connection to <a title="SwissIX Swiss Internet Exchange" href="https://www.swissix.ch/" target="_blank" rel="noopener noreferrer">SwissIX</a> Internet Exchange, which is the largest Internet Exchange Point (IXP) in Switzerland in terms of peering participants. Direct IXP connection lowers latency by improving routing efficiency and provides an extra layer of networking fault-tolerance.</p>
		   </div>
		   <div className="item">
            <h5>24/7 Live Support</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
            <p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
		   </div>
         <div className="item">
            <h5>Swiss Approach</h5>
            <hr className="grey"/>
            <div className="left paddingright"><i className="fa fa-plus-square fa-5x colored" aria-hidden="true"></i></div>
            <p>At SwissMade.host, we are proud to be a Zurich-based web hosting company that shares the most distinct features that Switzerland is renowned for. We guarantee that your orders and requests will be processed in accordance with our company's strict privacy, data protection, customer orientation and quality assurance policies.</p>
         </div>
		</OwlCarousel2>
		</div>
	</div>
</div>

            )
            }}

export default FeatureSlider