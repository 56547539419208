import React, {Component} from 'react';

class CpanelProductList extends Component {

    render() {

        const list = this.props.list;
        const usdRate = this.props.usdRate;
        const eurRate = this.props.eurRate;

        return (
        <React.Fragment>
            {list.map((item,i) =>

        <div className="four columns" key={i}>
            <div className="panel reddish radius text-center">
                <div className="package">
                    <h3 className="cdn">{item.name === 'Remote Desktop License' ? 'Remote Desktop' : item.name}</h3>
                    <span className='description'>
                        {item.id === 288 && 'A comprehensive set of server management tools that are optimized for a VPS. '}
                        {item.id === 289 && 'A comprehensive set of server management tools that are optimized for a Dedicated Server. '}
                        {item.id === 290 && 'Connect to your server via remote desktop and manage it via GUI with your mouse and keyboard. '}
                    </span>
                    <div className="price reddish">
                    <h4>{item.price} CHF per month</h4>
                        <span>Setup fee: {item.setup_fee === 0
                            ? 'free of charge.'
                            : item.setup_fee + ' CHF'
                            }
                        </span>
                    </div>
                    <div className="elements">
                    <ul className="no-bullet product base">
                        <li>{(item.price * usdRate).toFixed(2)} USD /month</li>
                        <li>{(item.price * eurRate).toFixed(2)} EUR /month</li>
                    </ul>
                    <div className="price">
                        <a className="button radius large" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + item.id}>Configure</a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
            )}
        </React.Fragment>
        )
    }
}

export default CpanelProductList;
