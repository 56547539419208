import React from 'react';

class WireGuardApp extends React.Component {

	render() {

		return (

			<div className="panel nomargin marginmobile">
				<h3>WireGuard® App</h3>
				<hr className="black" />
				<p>To use your private VPN server on your device, download the WireGuard® app, open it, scan the QR-code provided in your account area to add the VPN configuration to your device. Once done, activate the tunnel and enjoy safe and secure browsing.</p>
				<a href="https://www.wireguard.com/install/" target='_blank' rel="noopener noreferrer nofollow" className='readmore left'><i className="fa-regular fa-square-caret-down"></i> Download WireGuard® App</a>
			</div>

		)
	}
}

export default WireGuardApp