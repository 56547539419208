import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toggleWidget } from 'react-chat-widget';

const LiveChatDetector = () => {
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);

    if (params.has('livechat')) {
		toggleWidget()
    }
  }, [search]);

  return null;
};

export default LiveChatDetector