import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';

const owloptions = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 3,
    slideBy: 1,
    responsive:{
        0:{
            items:1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        900:{
            items:3
        },
        1000:{
            items:3
        }
    }
};


class FeatureSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
	<div className="row">
		<div className="twelve columns">
    <OwlCarousel2 options={owloptions}>
			<div className="item">
				<h5>Faster Content Delivery</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-dashboard fa-5x"></i></div>
				<p>A 2004 study on <a target="_blank" href="http://cba.unl.edu/research/articles/548/" rel="noopener noreferrer">tolerable waiting time</a> suggests that users are willing to wait approximately 2 seconds for a web page to be downloaded and rendered before abandoning it. CDN can significantly speed up your website by serving static content through the most efficient route, no matter what user's actual location is.</p>
			</div>
			<div className="item">
				<h5>Higher Availability</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-battery-full fa-5x"></i></div>
				<p>Uninterrupted content delivery is one of the main advantages of CDN determined by its design. Media files, executables, applications and other content will be replicated across the 170+ points of presence ensuring its availability through the content delivery network even if your backend server is temporarily not responding.</p>
			</div>
			<div className="item">
				<h5>Load Balancing</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-balance-scale fa-5x" aria-hidden="true"></i></div>
				<p>Sudden traffic spikes can saturate your server's bandwidth capacity, damage user experience and even render your site completely unreachable. CDN can be especially useful in this scenario, as it will serve you as a load balancer, distributing user requests across multiple servers all around the globe.</p>
			</div>
			<div className="item">
				<h5>170+ PoPs in 43 Countries</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-globe fa-5x" aria-hidden="true"></i></div>
				<p>Content delivery network consists of servers located on each continent except Antarctica and amounts to 170+ points of presence across 43 countries. Global coverage provided by this network of strategically placed CDN servers ensures efficient routing, low latency and fast content delivery for every user despite of their location.</p>
			</div>
			<div className="item">
				<h5>All Types of Content</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-film fa-5x" aria-hidden="true"></i></div>
				<p>Text information and images, media files of all types, applications and other executable software, live video and audio streams  - virtually any static content that your website serves to users from all around the globe can be distributed across the CDN network to ensure its fast delivery and high accessibility.</p>
			</div>
			<div className="item">
				<h5>Extra Layer of Protection</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-shield-halved fa-5x" aria-hidden="true"></i></div>
				<p>Volumetric DDoS attacks aim to consume bandwidth capacity of the target server and make it totally unavailable to legitimate requests. Content Delivery Network serves as a load balancer and can handle traffic spikes by design, therefore it can double to some extent as an extra layer of protection against volume based DDoS attacks. </p>
			</div>
			<div className="item">
				<h5>Pay as You Go</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-history fa-5x" aria-hidden="true"></i></div>
				<p>CDN is billed on a prepaid basis. Price of each Gb served through content delivery network depends on PoPs location (Europe, North America, South America or Asia) and is slightly different for each region. Credit your account with a deposit that never expires and pay only for the actual GBs of traffic that your users consumed.</p>
			</div>
			<div className="item">
				<h5>Fast &amp; Easy Setup</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-gears fa-5x" aria-hidden="true"></i></div>
				<p>Content Delivery Network setup is not a complicated process. In fact it takes just a few minutes and three simple steps to get your content distributed across numerous servers all around the globe. If you place an order right now, you'll be able to notice a difference in content delivery speed within several hours.</p>
			</div>
			<div className="item">
				<h5>Free Trial</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-gift fa-5x" aria-hidden="true"></i></div>
				<p>Content Delivery Network offers numerous benefits for businesses seeking to provide internationally and cater to multiple regions of the world. Sign up for a free-of-charge CDN trial to put this technology to a test and evaluate the increase in site's load speed, higher content availability and all the user experience improvements that CDN has been designed for.</p>
			</div>
			<div className="item">
				<h5>24/7 Live Support</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
				<p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
		   </div>
		</OwlCarousel2>
		</div>
	</div>
</div>

            )
            }}

export default FeatureSlider