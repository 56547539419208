import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import FAQinclude from '../../components/includes/FAQinclude';
import LocationSwitzerland from '../../components/includes/locationSwitzerland';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import CloudInfo from './cloudInfo';

class CloudSwitzerland extends React.Component {

	render() {

		const richData =
		{
			"@context": "https://schema.org",
			"@type": "FAQPage",
			"name": "Cloud hosting in Switzerland",
			"mainEntity": [
				{
					"@type": "Question",
					"name": "What is cloud hosting?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Cloud hosting is a service that allows you to host your websites, applications, and data on a virtual infrastructure. This infrastructure is made up of multiple interconnected servers, which work together to provide resources on demand. Cloud hosting is highly scalable, flexible, and cost-effective, as it allows you to pay only for the resources you actually use, and easily adjust them according to your needs."
					}
				},
				{
					"@type": "Question",
					"name": "What differs cloud hosting from VPS or dedicated hosting?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "The main difference between cloud hosting, VPS (Virtual Private Server), and dedicated hosting lies in the infrastructure, scalability, and cost. In cloud hosting, resources are provided by a network of interconnected servers, which allows for easy scaling and high availability. VPS hosting involves partitioning a single physical server into multiple virtual servers, each with its own resources, while dedicated hosting involves leasing an entire physical server for your exclusive use. Cloud hosting generally offers greater flexibility and cost-effectiveness compared to VPS and dedicated hosting, as you can adjust your resources on the fly and pay only for what you use."
					}
				},
				{
					"@type": "Question",
					"name": "What network connectivity is offered?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We offer low latency across Europe, direct connection to Swiss IX internet exchange point, efficient IP traffic routes to other regions, and 10 Gbps dedicated connection for every Cloud server."
					}
				},
				{
					"@type": "Question",
					"name": "Which hypervisor is used?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We use the industry-leading, purpose-built bare-metal VMware ESXi hypervisor for virtualization, offering a dedicated server-like experience."
					}
				},
				{
					"@type": "Question",
					"name": "What type of storage is used?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We use all-flash SSD storage by SolidFire, providing fast, secure, and virtually invulnerable data storage with 256-bit encryption and industry-leading resilient, self-healing architecture."
					}
				},
				{
					"@type": "Question",
					"name": "Is DDoS protection provided?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, our proprietary solution protects your servers against 99.999% of DDoS attacks registered to date."
					}
				},
				{
					"@type": "Question",
					"name": "What control panel is offered?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We offer the comprehensive and easy-to-use VMware vCloud Director web-based control panel."
					}
				},
				{
					"@type": "Question",
					"name": "Can I use custom OS images?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, you can import your own preconfigured OVF (Open Virtualization Format) file containing the desired OS image to the vCloud Director Panel and use it to launch a new vApp."
					}
				},
				{
					"@type": "Question",
					"name": "What additional features are included?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We provide free daily backups, in-house DNS hosting with PTR records, hardware firewall, isolated and connected virtual networks, VPN connections, DHCP, NAT, dynamic routing, and load balancing."
					}
				},
				{
					"@type": "Question",
					"name": "How is data protected?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Switzerland's strict privacy and data protection laws ensure that a hosting provider cannot be forced to disclose data unless Swiss authorities investigate a client and obtain an injunction."
					}
				},
				{
					"@type": "Question",
					"name": "What are the available billing options?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We offer flexible billing options, including monthly, quarterly, and yearly plans. You can also choose between fixed resource packages or pay-as-you-go solutions, depending on your needs."
					}
				},
				{
					"@type": "Question",
					"name": "What support options are available?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We provide 24/7 technical support through live chat, email, and phone. Our knowledgeable support team is ready to assist you with any questions or issues you might encounter."
					}
				},
				{
					"@type": "Question",
					"name": "Do you offer Service Level Agreements (SLAs)?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, we offer a 99.9% uptime SLA with credits for any downtime that goes beyond the guaranteed level. We also provide a 24-hour hardware replacement SLA to ensure minimal disruptions in case of hardware failures."
					}
				},
				{
					"@type": "Question",
					"name": "Can I scale my resources easily?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, our cloud hosting platform allows for easy resource scaling. You can upgrade or downgrade CPU, RAM, and storage without downtime, ensuring you always have the right resources to meet your needs."
					}
				}
			]
		}

		return (
			<React.Fragment>
				<Helmet>
					<meta name="description" content="Swiss-based scalable cloud servers powered by VMware hypervisors, all-flash SSD array and 10 Gbps networking. Ultra-secure data center in Zurich, Switzerland." />
					<title>Cloud Server Hosting in Switzerland | Swiss Made Host</title>
					<link rel="canonical" href="https://swissmade.host/en/cloud-switzerland" />
					<script type="application/ld+json">{JSON.stringify(richData)}</script>
				</Helmet>
				<div className="fullwidth pic cloud">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Secure Cloud Servers in <span><i className="fa fa-plus-square" aria-hidden="true"></i> Switzerland</span></h1>
								<h2>Business Continuity VMware®-based Hosting</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<LocationSwitzerland />
				<CloudInfo />
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider />
				</ReactDependentScript>
				<DynamicContent type='all' />
				<FAQinclude category={'cloud'} color={'light'} />
				<PaymentMethodsInclude />
			</React.Fragment>
		)
	}

	toggleAnimation = (e) => {
		document.getElementById('cloud-info').classList.toggle('expanded')
	}
}

export default CloudSwitzerland;