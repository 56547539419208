import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';
import { NavLink } from 'react-router-dom';

const owloptions = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 3,
    slideBy: 1,
    responsive:{
        0:{
            items:1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        900:{
            items:3
        },
        1000:{
            items:3
        }
    }
};


class FeatureSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
	<div className="row">
		<div className="twelve columns">
    	<OwlCarousel2 options={owloptions}>
		<div className="item">
				<h5>Redundant Power Supply</h5>
				<hr className="grey"/>
				<div className="left paddingright"><span className="fa-stack fa-3x">
					<i className="fa-regular fa-square fa-stack-2x"></i>
					<i className="fa fa-plug fa-stack-1x"></i>
					</span>
				</div>
				<p>Uninterrupted power supply is the key to the record-setting availability. These are the measures taken to guarantee power supply security: two different power providers, two independent power feeds to each rack, industrial-grade UPS with auto switchover and diesel generators ready for indefinite running in case of a serious blackout.</p>
			</div>
			<div className="item">
				<h5>Premium Networking &amp; Connectivity</h5>
				<hr className="grey"/>
				<div className="left paddingright"><span className="fa-stack fa-3x">
					<i className="fa-regular fa-square fa-stack-2x"></i>
					<i className="fa fa-signal fa-stack-1x"></i>
					</span>
				</div>
				<p>We have put a lot of effort into building a no single point of failure network architecture: redundant hardware with automatic failover protection, multiple Tier-1 bandwidth providers, direct connection to SwissIX Internet exchange point, professional DDoS protection, automatic network health monitoring and 24/7/365 intelligent hands on site.</p>
			</div>
			<div className="item">
				<h5>Seven-layer Security</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-lock fa-5x" aria-hidden="true"></i></div>
				<p>Physical security is as important as IT security when it comes to choosing the right data center to collocate your mission critical hardware. 24/7 video surveillance and security patrols, multiple access barriers with biometric readers and mantraps render unauthorized access to servers and networking hardware absolutely impossible.</p>
			</div>
			<div className="item">
				<h5>Carbon Neutrality</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-leaf fa-5x" aria-hidden="true"></i></div>
				<p>We strive to set an example of responsible approach to energy and keep our Power Usage Effectiveness (PUE) exceptional by any standards. We use energy originating from renewable sources only and our facility in Zürich is the first and the only carbon-neutral data center in Switzerland certified by myclimate®.</p>
			</div>
			<div className="item">
				<h5>KVM/IPMI Free-of-charge</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-terminal fa-5x" aria-hidden="true"></i></div>
				<p>IPMI (Intelligent Platform Management Interface) and KVM (Keyboard, Video, Mouse, over IP) are very usefull tools that will help you manage your server remotely and install custom operating systems with your own image as if you had direct physical access to your server. </p>
			</div>
			<div className="item">
				<h5>Hassle-free Equipment Setup</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-wrench fa-5x" aria-hidden="true"></i></div>
				<p>You can either bring your equipment by yourself or arrange for a courier to collect and deliver your servers and other hardware up to 4 rack units in size to our data center in Zurich. As soon we receive the equipment, our engineers will install and configure it accordingly to your specifications and needs.</p>
			</div>
			<div className="item">
				<h5>DDoS Protection</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-shield-halved fa-5x" aria-hidden="true"></i></div>
				<p>DDoS attack can disrupt operation of almost any web property, even a well established one. Please don't worry about this unlikely scenario, we've got you covered. Our engineers have developed a proprietary solution that will protect your servers against 99.999% of DDoS attacks registered to this day.</p>
			</div>
			<div className="item">
				<h5>Direct Connection to SwissIX</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-upload fa-5x" aria-hidden="true"></i></div>
				<p>Our data center in Zurich has direct connection to <a title="SwissIX Swiss Internet Exchange" href="https://www.swissix.ch/" target="_blank" rel="noopener noreferrer">SwissIX</a> Internet Exchange, which is the largest Internet Exchange Point (IXP) in Switzerland in terms of peering participants. Direct IXP connection lowers latency by improving routing efficiency and provides an extra layer of networking fault-tolerance.</p>
		   	</div>
			<div className="item">
				<h5>Data Protection</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-key fa-5x" aria-hidden="true"></i></div>
				<p>Switzerland is well known for its strict laws on privacy and data protection - a hosting provider cannot be forced to disclose data unless when Swiss authorities investigate a client and an injunction is obtained. There's hardly any other country in the world with a matching grade of <NavLink to="/en/data-protection-switzerland">data protection</NavLink> at the state level.</p>
			</div>
			<div className="item">
				<h5>24/7 Live Support</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
				<p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
		   </div>
			<div className="item">
				<h5>Swiss Approach</h5>
				<hr className="grey"/>
				<div className="left paddingright"><i className="fa fa-plus-square fa-5x colored" aria-hidden="true"></i></div>
				<p>At SwissMade.host, we are proud to be a Zurich-based web hosting company that shares the most distinct features that Switzerland is renowned for. We guarantee that your orders and requests will be processed in accordance with our company's strict privacy, data protection, customer orientation and quality assurance policies.</p>
			</div>
		</OwlCarousel2>
		</div>
	</div>
</div>

            )
            }}

export default FeatureSlider