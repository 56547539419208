import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class CDNPreLoader extends React.Component {
    render() { return (
        <React.Fragment>
			<section className="content-row">
						<div className="container">
                                    <Skeleton count={55} />
									<p/>
                                    <Skeleton count={15} />
						</div>
			</section>
        </React.Fragment>
    ) }
}

export default CDNPreLoader;