import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { NavLink, withRouter } from 'react-router-dom';

class VPSconfigsList extends Component {

    generateJsonLd(products) {
            const jsonLdProducts = products.map((product) => {
            const backupInfo = product.config.backup_included ? 'Backup included free of charge.' : `Backup is available at extra CHF ${product.config.backup_price}.`,
            osType = product.config.windows ? 'Windows OS' : 'Linux/BSD OS',
            usdRate = this.props.usdRate,
            eurRate = this.props.eurRate,
            osTemplates = this.props.os_templates
            .filter((template) => (product.config.windows ? template.windows : !template.windows))
            .map((osTemplate) => osTemplate.name);

            return {
                "@type": "Product",
                "name": product.name,
                'sku': product.id,
                "image": "https://swissmade.host/images/products/vps-product.png",
                'brand': {
                    '@type': 'Brand',
                    'name': 'SWISSMADE.HOST'
                },
                "description": `A Swiss-based VPS configuration with ${product.config.cores} CPU cores, ${product.config.ram} MB of RAM, ${product.config.storage} GB SSD of storage and ${osType}. ${backupInfo}`,
                "offers": {
                    "@type": "Offer",
                    'url': "https://swissmade.host/en/vps/switzerland/" + ((!product.config.windows ? "linux" : "windows") + "/" + product.config.cores + "-cores-cpu-" + product.config.ram + "-mb-ram-" + product.config.storage + "-gb-ssd-storage-vmware-hypervisor/" + product.name + "/id-" + product.id).toLowerCase(),
                    "priceCurrency": "CHF",
                    "price": product.price,
                    "priceValidUntil": new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0],
                    "priceSpecification": [
                        {
                          "@type": "UnitPriceSpecification",
                          "price": parseFloat((product.price * (Number(usdRate))).toFixed(2)),
                          "priceCurrency": "USD"
                        },
                        {
                          "@type": "UnitPriceSpecification",
                          "price": parseFloat((product.price * (Number(eurRate))).toFixed(2)),
                          "priceCurrency": "EUR"
                        }
                      ],
                    "areaServed": "Worldwide",
                    "availability": "https://schema.org/InStock",
                    'seller': {
                        '@type': 'Organization',
                        'name': 'SWISSMADE.HOST'
                    },
                    'category': 'VPS Hosting',
                    'itemCondition': 'https://schema.org/NewCondition'
                },
                "additionalProperty": [
                    {
                      "@type": "PropertyValue",
                      "name": "CPU cores",
                      "value": product.config.cores
                    },
                    {
                      "@type": "PropertyValue",
                      "name": "RAM",
                      "value": product.config.ram + " MB"
                    },
                    {
                      "@type": "PropertyValue",
                      "name": "Storage",
                      "value": product.config.storage + " GB"
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Operating System",
                        "value": osType
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Hypervisor",
                        "value": "VMware®"
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Bandwidth",
                        "value": product.config.bandwidth + " TB monthly"
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "IPv4 address",
                        "value": 1
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Port speed",
                        "value": "10 Gbps"
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "DDoS Protection",
                        "value": "Free of charge, limited to 10 Gbps or 4 Mpps."
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Backup",
                        "value": backupInfo
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Supported Operating Systems",
                        "value": osTemplates
                    },
                    {
                        "@type": "PropertyValue",
                        "name": "Location",
                        "value": "Zurich, Switzerland"
                    }
                ]
            }
        });

        if (!this.props.id)
         return (
            
                {
                    "@context": "https://schema.org/",
                    "@type": "ItemList",
                    "name": "VPS offerings by Swiss Made Host",
                    "itemListElement": [jsonLdProducts]
                }
            
            )
        else {
            return (
                {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    ...jsonLdProducts[0]
                }
            )
        }
    }

    render() {
        const { history, location, match } = this.props,
            id = match.params.id,
            list = !id ? this.props.list : this.props.list.filter((a) => a.id === Number(id)),
            payment_period = this.props.payment_period,
            type = this.props.type,
            usdRate = this.props.usdRate,
            eurRate = this.props.eurRate,
            price_from = this.props.sliderPriceFrom,
            price_to = this.props.sliderPriceTo,
            ram_from = this.props.sliderRamFrom,
            ram_to = this.props.sliderRamTo,
            storage_from = this.props.sliderStorageFrom,
            storage_to = this.props.sliderStorageTo,
            bandwidth_from = this.props.sliderBandwidthFrom,
            bandwidth_to = this.props.sliderBandwidthTo,
            countConfigs = this.props.countConfigs,
            linux = this.props.linux,
            windows = this.props.windows,
            backup = this.props.backup,
            discount = this.props.discount,
            FilterConditions =
                list.filter((a) =>
                    a.price >= price_from
                    && a.price <= price_to
                    && a.config.ram >= ram_from
                    && a.config.ram <= ram_to
                    && a.config.storage >= storage_from
                    && a.config.storage <= storage_to
                    && a.config.bandwidth >= bandwidth_from
                    && a.config.bandwidth <= bandwidth_to
                    && (discount === true ? a.old_price !== 0 : a.old_price !== 0 || a.old_price === 0)
                    && (backup === true ? a.config.backup_included === true : a.config.backup_included === true || a.config.backup_included === false)
                    && (windows === true
                        ? windows === true && linux === true
                            ? a.config.windows === true || a.config.windows === false
                            : a.config.windows === true
                        : a.config.windows === true || a.config.windows === false)
                    && (type !== 'linux' && type !== 'windows'
                        ?
                        linux === true
                            ? linux === true && windows === true
                                ? a.config.windows === true || a.config.windows === false
                                : a.config.windows === false
                            : a.config.windows === true || a.config.windows === false
                        : type === 'linux'
                            ? a.config.windows === false
                            : a.config.windows === true
                    )

                ).sort((a, b) => a.price - b.price)

        countConfigs(FilterConditions.length)

        if (this.props.usdRate !== undefined && this.props.list !== undefined) {
        const jsonLdData = this.generateJsonLd(
            !this.props.id
                ? this.props.list
                : this.props.list.filter((a) => a.id === Number(this.props.id))
        );
        this.props.onJsonLdGenerated(jsonLdData);
        }
        
        return (
            <div className="fullwidth light">
                {FilterConditions.length > 0 ? FilterConditions.sort((a, b) => a.config.windows - b.config.windows).map((item, i) =>
                    <div className="row" key={i}>
                        <div className="twelve columns">
                            <div className="panel pricing">
                                <div className="twelve columns">
                                    <div className="four columns">
                                        <h3 className="config">
                                            <span className="colored">{item.name}</span>
                                            {item.config.windows === true
                                                ? <span className="hp"><i className="fa-brands fa-windows" aria-hidden="true"></i> <span>Windows<br />Operating System</span></span>
                                                : <span className="hp"><i className="fa-brands fa-linux" aria-hidden="true"></i> <span>Linux/BSD<br />Operating System</span></span>
                                            }
                                        </h3>
                                    </div>
                                    <div className='eight columns'>
                                        {item.old_price !== 0 &&
                                            <span className="tag right special-offer">SPECIAL OFFER</span>
                                        }
                                        {item.config.backup_included &&
                                            <span className="tag right">FREE BACKUP</span>
                                        }
                                    </div>
                                </div>
                                <hr className="black" />
                                <div className="eight columns">
                                    <ul className="dp">
                                        <li>
                                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>CPU Cores</span>
                                            <span className="value">{item.config.cores} cores</span>
                                        </li>
                                        <li>
                                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>RAM</span>
                                            <span className="value">{item.config.ram} MB</span>
                                        </li>
                                        <li>
                                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Storage</span>
                                            <span className="slash">{item.config.storage} GB all-flash SSD</span>
                                        </li>
                                        <li>
                                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Bandwidth and IP</span>
                                            <span className="slash">{item.config.bandwidth} TB monthly @ 10 Gbps / 1 IPv4 address</span>
                                        </li>
                                        <li className="protection">
                                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>DDoS Protection</span>
                                            <span className="value">Free of charge</span>
                                        </li>
                                        <li className="protection">
                                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Backup (Optional)</span>
                                            {item.config.backup_included
                                                ? <span className="value">Included free of charge</span>
                                                : <React.Fragment>
                                                    <span className="value" style={{ cursor: 'default' }} data-tip data-for={'backupAddon-' + item.id}>{item.config.backup_price} CHF / month <i className="fa-regular fa-circle-question no-margin"></i></span>
                                                    <ReactTooltip id={'backupAddon-' + item.id} effect='solid'>
                                                        <p>{(item.config.backup_price * usdRate).toFixed(2)} USD <br /> {(item.config.backup_price * eurRate).toFixed(2)} EUR</p>
                                                    </ReactTooltip>
                                                </React.Fragment>}
                                        </li>
                                    </ul>
                                    {!this.props.id && <span className="expand-products" title="View configuration details" onClick={this.toggleAnimation} animation-id={item.id}><i className="fa fa-caret-down colored" aria-hidden="true"></i>View all specifications</span>}
                                    <ul className={!this.props.id ? "nobullet co expandInfo" : "nobullet co expandInfo expanded"} id={"details-" + item.id}>
                                        <li>
                                            <span className="name">Server Location</span><span>Zurich, Switzerland</span>
                                        </li>
                                        <li>
                                            <span className="name">Virtualization</span><span>VMware® vSphere High Availability</span>
                                        </li>
                                        <li>
                                            <span className="name">DNS Hosting with PTR</span><span>Free of charge</span>
                                        </li>
                                        <li>
                                            <span className="name">Billing Options</span><span>1 month (additional account verification) to 12 months (no additional verification)</span>
                                        </li>
                                        <li>
                                            <span className="name">Support</span><span>24/7 Live</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="four columns">
                                    <div className="row">
                                        <div className="twelve columns monthly-fee margintop">

                                            <span className="chf">{
                                                (payment_period === 1
                                                    ? item.old_price === 0
                                                        ? new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)
                                                        : <React.Fragment><span style={{ color: '#D74937', textDecoration: 'line-through', fontSize: '0.7em', fontWeight: 'normal' }}><span style={{ color: '#2D2D2D' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price !== 0 ? item.old_price : item.price)}</span></span><br />
                                                            {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))}
                                                        </React.Fragment>
                                                    : <React.Fragment><span style={{ color: '#D74937', textDecoration: 'line-through', fontSize: '0.7em', fontWeight: 'normal' }}><span style={{ color: '#2D2D2D' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price !== 0 ? item.old_price : item.price)}</span></span><br />
                                                        {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))}
                                                    </React.Fragment>)
                                            }</span>
                                            <div className="usd">USD <span className="val">{
                                                ((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)
                                            }</span>
                                            </div>
                                            <div className="eur">EUR <span className="val">{
                                                ((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)
                                            }</span>
                                            </div>
                                            <span className="month">per month</span>
                                        </div>
                                    </div>
                                    {(() => {

                                    const url = "/en/vps/switzerland/" + ((!item.config.windows ? "linux" : "windows") + "/" + item.config.cores + "-cores-cpu-" + item.config.ram + "-mb-ram-" + item.config.storage + "-gb-ssd-storage-vmware-hypervisor/" + item.name + "/id-" + item.id).toLowerCase() 
                                    if (!id) {
                                        return (
                                    <NavLink className="button radius large right linkToProduct" to={url} title="View details or share the link" aria-label="View details or share the link"><i className="fas fa-link" aria-hidden="true"></i></NavLink>
                                        );
                                    } else {
                                        if (location.pathname !== url)
                                        {history.push(url)}
                                    }
                                    })()}
                                    <span className="link-for-storage_w"><a className="button radius large right" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + item.id + "?bill=" + payment_period} title="Configure and place order">{item.old_price !== 0 || payment_period > 1 ? 'Buy with discount' : 'Configure'}</a></span>                               
                                     </div>
                            </div>
                        </div>
                    </div>
                )

                    : !id ?
                    <div className="row">
                        <div className="twelve columns">
                            <div className="panel pricing">
                                <h3>No Match</h3>
                                <p>No VPS configuration matches your requirements. Please relax search parameters or reset the filters.</p>
                            </div>
                        </div>
                    </div>
                    : history.push('/en/error-404')
                }
            </div>
        )
    }
    toggleAnimation = (e) => {
        document.getElementById("details-" + e.target.getAttribute('animation-id')).classList.toggle('expanded')
    }
}

export default withRouter(VPSconfigsList);
