import React from 'react';
import Helmet from "react-helmet";

function AUP() {
  return (

    <React.Fragment>
    <Helmet>
		<title>Acceptable Use Policy | Swiss Made Host</title>
		<meta name="description" content="Acceptable Use Policy (AUP) is designed to avoid misuse the use of our company&#039;s web hosting products and services." />
		<meta name="robots" content="noindex"/>
	</Helmet>
	<div className="fullwidth pic legal">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
					<h1 className="title">Acceptable Use Policy <span>SWISSMADE.HOST</span></h1>
					<h2>Policies aimed at avoiding misuse of our services</h2>
				</div>
			</div>
		</div>
	</div>
<div className="fullwidth light">
	<div className="row flex-center">
		<div className="ten columns">
				<div className="panel light">
					<blockquote className="white">Acceptable Use Policy (AUP) is designed to avoid misuse of Solar Communication dedicated server, colocation service and other services. By using the Solar Communications services you accept and agree to all the rules listed below. The purpose of the AUP is to define and prevent behaviors that are harmful to fellow Solar Communications users and Internet community as a whole. Overall the Services may only be used for lawful purposes. Under no circumstances is the customer authorized to engage in any activity that is illegal under local, national or international laws while utilizing Solar Communications services.</blockquote>
					<h3 className="mt30">The following use of any of our services is prohibited:</h3>
					<h4 className="mt30 colored">Unsolicited E-Mail (Spam)</h4>
					<p>Use of our services to transmit, or procure the sending of, any unsolicited bulk or commercial messages ('spam'). This includes hosting web pages advertised within 'spam e-mail' sent from another network, using the Service to receive replies from unsolicited emails, configuring any email server in such a way that it will accept third party emails for forwarding and running mail lists.</p>
					<h4 className="mt30 colored">Offensive Content</h4>
					<blockquote className="white">
						<ul className="nobullet">
							<li>Harmful to minors in any way (constitutes child pornography).</li>
							<li>Offensive, hateful or inflammatory</li>
							<li>Promotes violence, illegal activity or discrimination based on sex, religion, nationality or religion</li>
							<li>Promotes fraudulent activity or is deceptive under the consumer protection laws of any jurisdiction</li>
							<li>Threatening, abuse or invade another's privacy</li>
							<li>Creates the risk to a person's safety or health, creates a risk to public safety or health, compromises national security, or
								interferes with a investigation by law enforcement</li>
							<li>Promotes illegal drugs, or substances that are legal, but are known to be abused</li>
						</ul>
					</blockquote>
					<h4 className="mt30 colored">Software &amp; Copyrighted Material</h4>
					<p>Distribution of the following content is prohibited under all circumstances:</p>
					<blockquote className="white">
						<ul className="nobullet">
							<li>Copyrighted material without the explicit permission of the copyright owner</li>
							<li>Pirated or cracked versions of commercial software</li>
							<li>Copyrighted music files</li>
							<li>IRC related sites (this includes servers, bots or clients)</li>
							<li>Phishing / Identity Theft</li>
						</ul>
					</blockquote>
					<h4 className="mt30 colored">Spyware, Adware, Harmful Software</h4>
					<p>Under all circumstances customers are forbidden to knowingly transmit any data that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs designed to harm any computer software or hardware.</p>
					<h4 className="mt30 colored">Online Abusive Behavior</h4>
					<blockquote className="white">
						<ul className="nobullet">
							<li>Unauthorized access or attempt to access to systems or networks without prior authorization of the administrator of the
								appropriate network or system (hacking)</li>
							<li>Interference with service of system, network, host including deliberate attempts to overload a system and various forms of
								online attacks</li>
							<li>Monitoring data or traffic on any network or system without prior authorization of the administrator of the corresponding network</li>
							<li>Use of computer without the owner's authorization, including password theft, security vulnerability scanning, and port scanning</li>
						</ul>
					</blockquote>
					<h4 className="mt30 colored">Mining</h4>
					<p>Cryptocurrency mining is prohibited on our virtual and dedicated servers due to its extensive resource use that may lead to disruption of service and negatively impact other clients, who may share the same hardware. This restriction includes CPU, HDD and GPU mining.</p>
					<h3 className="mt30">Enforcement</h3>
					<p>We reserves the right to suspend or terminate without warning or notice, the account and services to any customer found to have violated this policy. Your account may be suspend or terminated and you may be held liable for any loss or damage resulting from the breach.</p>
					<h3 className="mt30">Reporting Violations</h3>
					<p>If you feel that a of this AUP violation is taking place please contact us immediately. We will react to your report as soon as possible,
						investigate the issue and take appropriate action.</p>
					<h3 className="mt30">Changes to the Acceptable Use Policy</h3>
					<p>We reserve the right to change this AUP at any time, without prior notice. Please visit this page form time to time to take notice of any
						changes we make.</p>
				</div>
		</div>
	</div>
</div>
	</React.Fragment>
  );
}
 
export default AUP;