
import React from 'react';
import { NavLink } from "react-router-dom";

class Pagination extends React.Component {

    render() {
		if (this.props.blogPostsTotal === undefined || (this.props.blogPostsTotal / this.props.pageLimit) <= 1) {
			return null
		}
    else {
var currentPage = Number((this.props.location.hash).replace("#page=", ""))
var paginationArray = Array.from(Array(
	Math.ceil(this.props.blogPostsTotal / this.props.pageLimit) > 4 
	? Math.ceil(this.props.blogPostsTotal / this.props.pageLimit) <= (currentPage + 4)
		? currentPage + 2 <= Math.ceil(this.props.blogPostsTotal / this.props.pageLimit)
			? currentPage + 3
			: currentPage + 1 <= Math.ceil(this.props.blogPostsTotal / this.props.pageLimit)
				? currentPage + 2
				: Math.ceil(this.props.blogPostsTotal / this.props.pageLimit) + 1
		: currentPage < 5
			? currentPage + 4 >= Math.ceil(this.props.blogPostsTotal / this.props.pageLimit)
				? Math.ceil(this.props.blogPostsTotal / this.props.pageLimit)
				: currentPage <= 1
					? currentPage === 0
						? currentPage + 6
						: currentPage + 5
					: currentPage === 2
						? currentPage + 4
						: currentPage + 3
			: currentPage + 3
	: Math.ceil(this.props.blogPostsTotal / this.props.pageLimit) + 1)
	.keys()).slice(
				(this.props.blogPostsTotal / this.props.pageLimit) > 4
					? (currentPage + 4) >= Math.ceil(this.props.blogPostsTotal / this.props.pageLimit)
						? (Math.ceil(this.props.blogPostsTotal / this.props.pageLimit) - currentPage) >= 4
							? currentPage - 2
							: Math.ceil(this.props.blogPostsTotal / this.props.pageLimit) - currentPage === 3
								? Math.ceil(this.props.blogPostsTotal / this.props.pageLimit) - 5
								: Math.ceil(this.props.blogPostsTotal / this.props.pageLimit) - 4
						: currentPage - 1 <=1
							? 1
							: currentPage - 2
					: 1
				);
    return (
<section className="content-row content-row-gray content-row-border-top">
				<div className="container">
					<ul className="pagination">
						<li className={currentPage <= 1 ? 'directional hidden' : 'directional'}>
							<NavLink to={'#page=' + (currentPage - 1)}><i className="fas fa-chevron-left"></i></NavLink>
						</li>
						{paginationArray.map((item,i) =>
							<li className={item === currentPage || (currentPage === 0 && item === 1) ? "active" : null} key={i}>
								<NavLink to={'#page='+item}>{item}</NavLink>
							</li>
						)}
						<li className={currentPage >= Math.ceil(this.props.blogPostsTotal / this.props.pageLimit) ? 'directional hidden' : 'directional'}>
							<NavLink to={'#page=' + (currentPage === 0 ? currentPage + 2 : currentPage + 1)}><i className="fas fa-chevron-right"></i></NavLink>
						</li>
					</ul>
				</div>
			</section>
    )}}}

export default Pagination