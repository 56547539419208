export const FAQSchema = (blogData) => {
    if (blogData) {
        return {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "name": blogData?.post.title,
            "mainEntity": blogData?.post.faq.map((item) => ({
                "@type": "Question",
                "name": item.q,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": item.a
                }
            }))
        };
    } else {
        return;
    }
};

export const RichData = (blogData, description, keywords) => {
    if (blogData) {
        return {
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://swissmade.host/en/blog/" + blogData.post.slug
            },
            "url": "https://swissmade.host/en/blog/" + blogData.post.slug,
            "headline": blogData.post.title,
            "description": description,
            "keywords": keywords,
            "image": blogData.post.image.length > 0 ? blogData.post.image : 'https://swissmade.host/images/blog/' + blogData.post.id + '.jpg',
            "author": {
                "@type": "Person",
                "name": blogData.post.author,
                "url": "https://swissmade.host/en/blog?author=" + encodeURIComponent(blogData.post.author)
            },
            "publisher": {
                "@type": "Organization",
                "name": "Swiss Made Host",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://swissmade.host/images/commonlogo.png"
                  }
            },
            "datePublished": blogData.post.date,
            "articleSection": blogData.post.category,
            "articleBody": blogData.post.content.replace(/<[^>]*>/g, '')
        }
    }
    else {
        return;
    }
};
