import React from 'react';
import { axiosinstance, axiosCatch } from '../../components/axios';
import ObjectStorageProductList from './productList';
import LoadingError from '../../components/loadingError';
import lscache from 'lscache';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class DynamicContent extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            products: [],
            error: false,
            loading: true
        }
    }

    componentDidMount() {

        this.load();
    }

    load = () => {

        this.setState({
            loading: true
        }, () => {

            if (lscache.get('objectStorage') === null) {

                axiosinstance({
                    method: 'get',
                    url: process.env.REACT_APP_BACKEND_URL + "/products/storage.en.json"
                })
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data.result === true) {
                                lscache.set('objectStorage', response, 10);
                                this.setState({
                                    products: response.data.data.products,
                                    loading: false
                                });

                            } else {
                                this.setState({
                                    error: true,
                                    loading: false
                                });
                            }
                        } else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }
                    }).catch((args) => {
                        axiosCatch(args, this.props);
                    });

            }

            else {
                this.setState({
                    products: lscache.get('objectStorage').data.data.products,
                    loading: false
                })

            }

            if (lscache.get('exchangeRates') === null) {

                axiosinstance.get(process.env.REACT_APP_BACKEND_URL + "/variables.json")
                    .then(res => {

                        if (res.status === 200 && res.data.result === true) {
                            this.setState({
                                usd: res.data.data.currencies.find(x => x.short_name === 'USD').rate,
                                eur: res.data.data.currencies.find(x => x.short_name === 'EUR').rate,
                                btc: res.data.data.currencies.find(x => x.short_name === 'BTC').rate
                            })
                            lscache.set('exchangeRates', res, 3);
                        }

                        else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }

                    })
            }
            else {
                this.setState({
                    usd: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'USD').rate,
                    eur: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'EUR').rate,
                    btc: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'BTC').rate
                })
            }

        });
    };

    render() {

        if (this.state.loading) {
            return (
                <div className="fullwidth light">
                    <div className="row">
                        <div className="twelve columns">
                            <Skeleton style={{ height: '270px' }} />
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.error) {
            return (
                <LoadingError />
            )
        } else {

            return (
                <section className="content-row">
                    <div className="container">
                        <ObjectStorageProductList usdRate={this.state.usd} eurRate={this.state.eur} btcRate={this.state.btc} list={this.state.products} />
                    </div>
                </section>
            )
        }
    }
}

export default DynamicContent;
