import React from 'react';
import Helmet from "react-helmet";
import ReactDependentScript from "react-dependent-script";
import MapCompanyDataCenter from '../../components/includes/mapCompanyDataCenter';
import Timeline from './timeline';
import FeatureSlider from './featureSlider';

class AboutUs extends React.Component {

    render() {
    
        return (
		<React.Fragment>
		<Helmet>
			<title>About Us | Swiss Made Host</title>
			<meta name="description" content="Learn more about SwissMadeHost and core principles and beliefs that shape our decisions and vector company&#039;s development." />
		</Helmet>
    <div className="fullwidth pic about-us">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
					<h1 className="title">About <span>SWISSMADE.HOST</span></h1>
					<h2>Proudly Operated by Solar Communications GmbH.</h2>
				</div>
			</div>
		</div>
	</div>
	<div className="fullwidth dark">
		<div className="row">
			<div className="twelve columns">
				<h3>Operating Company</h3><p>SwissMade.Host products and services are exclusively provided by Solar Communications GmbH. - a rapidly growing hosting business located in Zurich, Switzerland. Unlike larger corporations, our company is privately owned and offers advantage of excellent service that is convenient, flexible and truly tailored to the needs of each individual client. Solar Communications GmbH. successful operation is based on continuous research and development of better practices and solutions to various challenges our clients may face. We have developed a number of proprietary techniques in the field of DDoS protection, IT security and data protection that make our network a virtually unbreachable data vault.</p>
			</div>
		</div>
	</div>
<div className="fullwidth light">
	<div className="row">
		<div className="six columns">
			<div className="panel nomargin marginmobile">
				<h3 className="colored">Our Mission</h3>
				<hr className="black"/>
				<p>Only best products of Switzerland deserve the privilege to be awarded with the Swiss Made label. We make great efforts to see the business through the eyes of our customers, understand their expectations and needs and develop products and services that would meet or exceed the highest industry standards. Solar Communications are proud to be a part of Swiss community and contribute to businesses all around the globe by developing secure and efficient hosting platforms and services.</p>
			</div>
		</div>
		<div className="six columns">
			<div className="panel nomargin">
				<h3 className="colored">Core Values</h3>
				<hr className="black"/>
				<p>Solar Communications core values are the principles and beliefs that shape our decisions and vector company's development. Security, privacy, excellence and responsibility are the cornerstones of our company's philosophy. We are deeply convinced that every our client has the fundamental and unbreachable right of privacy that we must protect at political, infrastructural and physical levels. It is our responsibility and area of expertise to provide secure, efficient and by all means excellent web hosting products and services.</p>
			</div>
		</div>
	</div>
</div>
<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
	<FeatureSlider/>
</ReactDependentScript>	
<Timeline/>
<ReactDependentScript scripts={["https://maps.googleapis.com/maps/api/js?key=AIzaSyAV2hiC14nyL2QLQvfd-WF2yT1iqZno4kI&libraries=places&callback=initMap"]}>
	<MapCompanyDataCenter color={'light'}/>
</ReactDependentScript>
			

</React.Fragment>
        )}}

export default AboutUs