import React, {Component} from 'react';
import { NavLink, withRouter } from 'react-router-dom';

class DedicatedServersList extends Component {

    generateJsonLd(products) {
        const jsonLdProducts = products.map((product) => {
        const usdRate = this.props.usdRate,
        eurRate = this.props.eurRate,
        osTemplates = this.props.osTemplates[0].items.map((os) => os.name).filter((os) => os !== "No OS installation is required");

        return {
            "@type": "Product",
            "name": product.name,
            'sku': product.id,
            "image": "https://swissmade.host/images/products/dedicated-server-product.png",
            'brand': {
                '@type': 'Brand',
                'name': 'SWISSMADE.HOST'
            },
            "description": `A Swiss-based dedicated server with ${product.config.cpu.model + ' ' + product.config.cpu.code} CPU, ${product.config.ram.capacity + ' ' + product.config.ram.freq + (product.config.ram.features ? ' ' + product.config.ram.features : "")} RAM, ${product.config.storage.summary.replace(/:/g, " - ") + ' (' + product.config.storage.disk_capacity + ' GB of total storage)' + (product.only_eco === true ? '' : ', optional DDoS protection') + ' and free KVM/IPMI.'}`,
            "offers": {
                "@type": "Offer",
                'url': "https://swissmade.host/en/dedicated-server/switzerland/" + encodeURIComponent((product.config.cpu.code + '-' + product.config.cpu.model + '-' + product.config.cpu.freq.replace(/(\d+(?:\.\d+)?)\s*GHz/gi, (match, p1) => `${parseFloat(p1).toFixed(2)}-ghz`) + '-' + product.config.cpu.cores.split("/")[0] + '-cores-cpu-' + product.config.ram.capacity + '-' + product.config.ram.freq + (product.config.ram.features ? "-" + product.config.ram.features : "") + "-ram-" + product.config.storage.disk_capacity + "-gb-storage-" + (product.config.storage.disks !== 1 ? product.config.storage.disks + '-drives' : product.config.storage.disks + '-drive') + (product.config.gpu.model ? '-' + product.config.gpu.model + '-gpu' : '') + (product.config.gpu.manufacturer ? '-' + product.config.gpu.manufacturer : '')).replace(/[/\s]+/g, '-').toLowerCase()) + "/" + product.name.replace(/\s+/g, "-").toLowerCase() + "/id-" + product.id,
                "priceCurrency": "CHF",
                "price": product.price,
                "priceValidUntil": new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0],
                "priceSpecification": [
                    {
                      "@type": "UnitPriceSpecification",
                      "price": parseFloat((product.price * (Number(usdRate))).toFixed(2)),
                      "priceCurrency": "USD"
                    },
                    {
                      "@type": "UnitPriceSpecification",
                      "price": parseFloat((product.price * (Number(eurRate))).toFixed(2)),
                      "priceCurrency": "EUR"
                    }
                  ],
                "areaServed": "Worldwide",
                "availability": "https://schema.org/InStock",
                'seller': {
                    '@type': 'Organization',
                    'name': 'SWISSMADE.HOST'
                },
                'category': 'Dedicated Server Hosting',
                'itemCondition': 'https://schema.org/NewCondition'
            },
            "additionalProperty": [
                {
                    "@type": "PropertyValue",
                    "name": "CPU model",
                    "value": product.config.cpu.model + ' ' + product.config.cpu.code
                  },
                  {
                    "@type": "PropertyValue",
                    "name": "CPU cores",
                    "value": product.config.cpu.cores
                  },
                  {
                    "@type": "PropertyValue",
                    "name": "CPU frequency",
                    "value": product.config.cpu.freq.replace(/(\d+(?:\.\d+)?)\s*GHz/gi, (match, p1) => `${parseFloat(p1).toFixed(2)} GHz`)
                  },
                  {
                    "@type": "PropertyValue",
                    "name": "Number of CPUs on board",
                    "value": product.config.cpu.physical_cpu
                  },
                  {
                      "@type": "PropertyValue",
                      "name": "RAM",
                      "value": product.config.ram.capacity + ' ' + product.config.ram.freq + (product.config.ram.features ? ' ' + product.config.ram.features : "")
                  },
                  {
                      "@type": "PropertyValue",
                      "name": "Storage",
                      "value": product.config.storage.summary.replace(/:/g, " - ")
                  },
                  {
                    "@type": "PropertyValue",
                    "name": "Total storage capacity",
                    "value": product.config.storage.disk_capacity + ' GB (' + product.config.storage.disks + (product.config.storage.disks === 1 ? ' Drive)' : ' Drives)')
                },
                {
                    "@type": "PropertyValue",
                    "name": "RAID",
                    "value": (product.config.storage.raid === "NA" ? "Not available" : product.config.storage.raid.replace(/:/g, " / ")) + (product.config.storage.hardware_raid === true ? " / Hardware RAID card" : '')
                },
                {                 
                    "@type": "PropertyValue",
                    "name": "GPU",
                    "value": product.group === 'gpu' ? product.config.gpu.model + (product.config.gpu.manufacturer &&  ' / ' + product.config.gpu.manufacturer) : 'Not available'
                },
                {
                    "@type": "PropertyValue",
                    "name": "Bandwidth",
                    "value": "15 TB monthly"
                },
                {
                    "@type": "PropertyValue",
                    "name": "IP addresses",
                    "value": "1 IPv4 address"
                },
                {
                    "@type": "PropertyValue",
                    "name": "DDoS protection",
                    "value": product.only_eco === true ? 'Not available with this server' : 'Available as an add-on'
                },
                {
                    "@type": "PropertyValue",
                    "name": "KVM/IPMI",
                    "value": "Included free of charge"
                },
                {
                    "@type": "PropertyValue",
                    "name": "Supported operating systems",
                    "value": osTemplates
                },
                {
                    "@type": "PropertyValue",
                    "name": "Support",
                    "value": "24/7 live chat and support tickets"
                },
                {
                    "@type": "PropertyValue",
                    "name": "Full disk encryption",
                    "value": "Supported"
                },
                {
                    "@type": "PropertyValue",
                    "name": "Location",
                    "value": "Zurich, Switzerland"
                }
            ]
        }
    });

    if (!this.props.match.params.id)
     return (
        
            {
                "@context": "https://schema.org/",
                "@type": "ItemList",
                "name": "VPS offerings by Swiss Made Host",
                "itemListElement": [jsonLdProducts]
            }
        
        )
    else {
        return (
            {
                "@context": "https://schema.org/",
                "@type": "Product",
                ...jsonLdProducts[0]
            }
        )
    }
}

render() {
        const { history, location, match } = this.props,
        id = match.params.id,
        list = !id ? this.props.list : this.props.list.filter((a) => a.id === Number(id)),
        payment_period = this.props.payment_period,
        usdRate = this.props.usdRate,
        eurRate = this.props.eurRate,
        DDoS = this.props.ddosProtectionOn,
        price_from = this.props.sliderPriceFrom,
        price_to = this.props.sliderPriceTo,
        storage_from = this.props.sliderStorageFrom,
        storage_to = this.props.sliderStorageTo,
        ram_from = this.props.sliderRamFrom,
        ram_to = this.props.sliderRamTo,
        countConfigs = this.props.countConfigs,
        raid = this.props.raid,
        discount = this.props.discount,
        instock = this.props.instock,
        gpu = this.props.gpu,
        cpuList = this.props.cpuList === undefined || this.props.cpuList.length === 0 ? list.map(item => item.config.cpu.code) : this.props.cpuList.map(element => element.value),
        FilterConditions = 
        list.filter((a) => a.eco_price >= price_from 
        && a.eco_price <= price_to 
        && a.config.storage.disk_capacity >= storage_from 
        && a.config.storage.disk_capacity <= storage_to 
        && a.config.ram.gb >= ram_from 
        && a.config.ram.gb <= ram_to 
        && (raid === true ? a.config.storage.hardware_raid === true : a.config.storage.hardware_raid === false || true) 
        && (DDoS === true ? a.only_eco !== true : a.only_eco === false || true)
        && (discount === true ? a.old_price !== 0 : a.old_price !== 0 || a.old_price === 0)
        && (instock === true ? a.instock > 0 : a.instock >= 0)
        && (gpu === true ? a.group === 'gpu' : a.group !== undefined)
        && Object.values(cpuList).includes(a.config.cpu.code)
        ).sort((a, b) => a.eco_price - b.eco_price)
        
        countConfigs(FilterConditions.length)

        if (this.props.usdRate !== undefined && this.props.list !== undefined) {
            const jsonLdData = this.generateJsonLd(
                !id
                    ? this.props.list
                    : this.props.list.filter((a) => a.id === Number(id))
            );
            this.props.onJsonLdGenerated(jsonLdData);
            }

        return (

<div className="fullwidth light">
{FilterConditions.length > 0 ? FilterConditions.map((item,i) =>
    <div className="row" key={i}>
        <div className="twelve columns">
            <div className="panel pricing">
                <div className="twelve columns">
                    <div className="four columns">
                        <h3 className="config">
                            <span className="colored">{item.name}</span>
                        </h3>
                    </div>
                    <div className='eight columns'>
                    {item.instock < 1
                    ? (<span className="tag right"><i className="fas fa-history preorder"></i> Pre-order</span>)
                    : (<span className="tag right"><span>IN STOCK:</span> {item.instock === 1 
                        ? 
                            <span className='one'>ONE</span>
                        : item.instock > 1 && item.instock < 10 
                            ?   <span className='few'>FEW</span>
                            :   <span className='many'>MANY</span>
                        }
                        </span>
                        )
                    }
                        {item.old_price !== 0 && 
                        <span className="tag right special-offer">SPECIAL OFFER</span>
                        }
                        {item.group === 'gpu' && 
                        <span className="tag right">GPU</span>
                        }
                    </div>
                </div>
                <hr className="black"/>
                <div className="eight columns">
                    <ul className="dp">
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>CPU</span>
                            <span className="value">{item.config.cpu.code} {item.config.cpu.model} {item.config.cpu.freq.replace(/(\d+(?:\.\d+)?)\s*GHz/gi, (match, p1) => `${parseFloat(p1).toFixed(2)} GHz`) } ({item.config.cpu.cores} cores)</span>
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>RAM</span> 
                            <span className="value">{item.config.ram.capacity} {item.config.ram.freq} {item.config.ram.features ?? ""}</span>
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Storage</span>
                            <span className="slash">{item.config.storage.summary.replace(/:/g, " / ")}</span>
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Total Capacity / RAID</span>
                            <span className="slash">{item.config.storage.disk_capacity} GB / {item.config.storage.disks} {item.config.storage.disks === 1 ? 'Drive' : 'Drives'} ({item.config.storage.raid === "NA" ? "No" : item.config.storage.raid.replace(/:/g, " / ")} {item.config.storage.hardware_raid === true && "Hardware"} RAID)</span>
                        </li>
                        {item.group === 'gpu'
                        && 
                        <li>
                        <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>GPU</span>
                        <span className="slash">{item.config.gpu.model} / {item.config.gpu.manufacturer}</span>
                        </li>
                        }
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Bandwidth &amp; IP</span>
                            <span className="value">15 TB monthly @ 1 Gbps / 1 IPv4 address</span>
                        </li>
                        <li className="protection">
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>DDoS Protection</span>
                            {item.only_eco === true
                            ? (<div className="value"><i className="far fa-times-circle gray"></i> Not available with this server</div>)
                            : DDoS === false
                                ? <span className="value">Available on demand</span>
                                : <div className="value"><i className="fas fa-check orange"></i> Included</div>
                            }
                        </li>
                    </ul>
                    {!id && <span className="expand-products" title="View configuration details" onClick={this.toggleAnimation} animation-id={item.id}><i className="fa fa-caret-down colored" aria-hidden="true"></i>View all specifications</span>}
	                <ul className={!id ? "nobullet co expandInfo" : "nobullet co expandInfo expanded"} id={"details-"+item.id}>
                        <li>
                            <span className="name">Server Location</span><span>Zurich, Switzerland</span>
                        </li>
                        <li>
                            <span className="name">Support</span><span>24/7 Live</span>
                        </li>
                        <li>
                            <span className="name">KVM/IPMI</span><span>Free of charge</span>
                        </li>
                        <li>
                            <span className="name">Full Disk Encryption</span><span>Supported</span>
                        </li>
                        <li>
                            <span className="name">Billing Options</span><span>Monthly, quarterly, semi-annual, annual</span>
                        </li>
                        <li>
                            <span className="name">Hardware Components</span><span>{item.description}</span>
                        </li>
	                </ul>
                </div>
            <div className="four columns">
                <div className="row">
                    <div className="twelve columns monthly-fee margintop">
                        
                        <span className="chf">{
                       DDoS === false
                       ? (payment_period === 1
                        ? item.old_eco_price === 0 
                            ? new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.eco_price)
                            : <React.Fragment><span style={{ color: '#D74937', textDecoration: 'line-through', fontSize: '0.7em', fontWeight: 'normal' }}><span style={{color: '#2D2D2D'}}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_eco_price)}</span></span><br/>
                            {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.eco_price - (item.eco_price / 100 * (payment_period - 1) * 2)))}
                            </React.Fragment>
                        : <React.Fragment><span style={{ color: '#D74937', textDecoration: 'line-through', fontSize: '0.7em', fontWeight: 'normal' }}><span style={{color: '#2D2D2D'}}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_eco_price !== 0 ? item.old_eco_price : item.eco_price)}</span></span><br/>
                        {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.eco_price - (item.eco_price / 100 * (payment_period - 1) * 2)))}
                        </React.Fragment>
                        )
                       : (payment_period === 1
                        ? item.old_price === 0 
                            ? new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)
                            : <React.Fragment><span style={{ color: '#D74937', textDecoration: 'line-through', fontSize: '0.7em', fontWeight: 'normal' }}><span style={{color: '#2D2D2D'}}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price)}</span></span><br/>
                            {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))}
                            </React.Fragment>
                        : <React.Fragment><span style={{ color: '#D74937', textDecoration: 'line-through', fontSize: '0.7em', fontWeight: 'normal' }}><span style={{ color: '#2D2D2D' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price !== 0 ? item.old_price : item.price)}</span></span><br/>
                            {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))}
                        </React.Fragment>
                        )
                        }</span> 
                    <div className="usd">USD <span className="val">{
                       DDoS === false
                        ? new Intl.NumberFormat("de-CH", {
                            style: "decimal",
                            maximumFractionDigits: 2,
                          }).format((item.eco_price - (item.eco_price / 100 * (payment_period - 1) * 2)) * usdRate)
                        : new Intl.NumberFormat("de-CH", {
                            style: "decimal",
                            maximumFractionDigits: 2
                          }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate)
                        }</span>
                    </div> 
                    <div className="eur">EUR <span className="val">{
                       DDoS === false
                        ? new Intl.NumberFormat("de-CH", {
                            style: "decimal",
                            maximumFractionDigits: 2,
                          }).format((item.eco_price - (item.eco_price / 100 * (payment_period - 1) * 2)) * eurRate)
                        : new Intl.NumberFormat("de-CH", {
                            style: "decimal",
                            maximumFractionDigits: 2,
                          }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate)
                        }</span>
                    </div>
                        <span className="month">per month</span>
                    </div>
                </div>
                {(() => {

                const url = "/en/dedicated-server/switzerland/" + encodeURIComponent((item.config.cpu.code + '-' + item.config.cpu.model + '-' + item.config.cpu.freq.replace(/(\d+(?:\.\d+)?)\s*GHz/gi, (match, p1) => `${parseFloat(p1).toFixed(2)}-ghz`) + '-' + item.config.cpu.cores.split("/")[0] + '-cores-cpu-' + item.config.ram.capacity + '-' + item.config.ram.freq + (item.config.ram.features ? "-" + item.config.ram.features : "") + "-ram-" + item.config.storage.disk_capacity + "-gb-storage-" + (item.config.storage.disks !== 1 ? item.config.storage.disks + '-drives' : item.config.storage.disks + '-drive') + (item.config.gpu.model ? '-' + item.config.gpu.model + '-gpu' : '') + (item.config.gpu.manufacturer ? '-' + item.config.gpu.manufacturer : '')).replace(/[/\s]+/g, '-').toLowerCase()) + "/" + item.name.replace(/\s+/g, "-").toLowerCase() + "/id-" + item.id;
                if (!id) {
                    return (
                    <NavLink className="button radius large right linkToProduct" to={url} title="View details or share the link" aria-label="View details or share the link">
                        <i className="fas fa-link" aria-hidden="true"></i>
                    </NavLink>
                    );
                } else {
                    if (location.pathname !== url)
                    {history.push(url)}
                }
                })()}
                <span className="link-for-storage_w"><a className="button radius large right" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + item.id + "?bill=" + payment_period + (DDoS === false ? '&eco=1' : '')} title="Configure and place order">{item.old_price !== 0 || payment_period > 1 ? 'Buy with discount' : 'Configure'}</a></span>
            </div>
        </div>
    </div>
</div>
)

:   !id ?
        <div className="row">
            <div className="twelve columns">
                <div className="panel pricing">
                    <h3>No Match</h3>
                    <p>No dedicated server configurations match your requirements. Please relax search parameters or reset the filters.</p>
                </div>
            </div>
        </div>
        : history.push('/en/dedicated-servers-switzerland')
}
</div>
            )
    }
    toggleAnimation = (e) => {
    document.getElementById("details-"+e.target.getAttribute('animation-id')).classList.toggle('expanded')
      }
}

export default withRouter(DedicatedServersList);
