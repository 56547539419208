import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import DiscountCounter from './dscountCounter';
import AboutCMS from './aboutCMS';
import FreeSSL from './freeSSL'
import OffshoreSwitzerland from '../offshore-hosting/offshoreSwitzerland';

class CMSoffshore extends React.Component {

	render() {

		const cms = 'all'

		return (
			<React.Fragment>
				<Helmet>
					<title>Offshore CMS Hosting - Dedicated IP &amp; Private Server | Swiss Made Host</title>
					<meta name="description" content="Offshore Content Management System Hosting with dedicated resources and IP address. Powered by WordPress, Joomla and Drupal." />
					<link rel="canonical" href="https://swissmade.host/en/offshore-cms-hosting" />
				</Helmet>
				<div className="fullwidth pic cms">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Offshore <span>CMS Hosting</span></h1>
								<h2>Privacy-focused CMS Hosting powered by WordPress, Drupal and Joomla</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<OffshoreSwitzerland />
				<AboutCMS cms={cms} page='offshore' />
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider />
				</ReactDependentScript>
				<DiscountCounter cms={cms} />
				<FreeSSL cms={cms} />
				<PaymentMethodsInclude />
			</React.Fragment>
		)
	}
	toggleAnimation = (e) => {
		document.getElementById('ddos-protection-info').classList.toggle('expanded')
	}
}

export default CMSoffshore;