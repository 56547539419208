import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./css/style.css";
import "./css/cryptocoins.css";
import "./css/fontawesome.min.css";
import ScrollToTop from './components/scrolltotop';
import Header from './components/structure/header';
import Footer from './components/structure/footer';
import Livechat from './components/livechat/livechat'
import HomePage from './pages/home/homePage';
import NotFound from './pages/404/notFound';
import DedicatedServers from './pages/dedicated/dedicatedServersSwitzerland';
import VPSSwitzerland from './pages/vps/vpsSwitzerland';
import CloudSwitzerland from './pages/cloud/cloudSwitzerland';
import DDoSProtectionSwitzerland from './pages/ddos/ddosProtectionSwitzerland';
import ColocationSwitzerland from './pages/colocation/colocationSwitzerland';
import Cpanel from './pages/cpanel/cPanel';
import CDN from './pages/cdn/cdn'
import PaymentMethods from './pages/payment-methods/paymentMethods'
import Contact from './pages/contact/contact'
import DataCenter from './pages/data-center/dataCenter'
import AboutUs from './pages/about-us/aboutUs'
import TOS from './pages/TOS/tos'
import AUP from './pages/AUP/aup'
import SLA from './pages/SLA/sla'
import PrivacyPolicy from "./pages/privacy-policy/privacyPolicy";
import KnowledgeBase from "./pages/knowledgebase/knowledgeBaseIndex";
import Products from "./pages/products/products";
import BlogHome from "./pages/blog/blogHome";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import Offshore from "./pages/offshore-hosting/offshore";
import FreeSpeech from "./pages/free-speech-hosting/freeSpeech";
import Technology from "./pages/technology/technology";
import DataProtection from "./pages/data-protection/dataProtection";
import VPNswitzerland from "./pages/vpn/VPNswitzerland";
import WireGuardSwitzerland from "./pages/vpn/WireGuardSwitzerland";
import OpenVPNSwitzerland from "./pages/vpn/OpenVPNSwitzerland";
import CMSswitzerland from "./pages/cms/CMSswitzerland";
import WordPressCMS from "./pages/cms/wordPressCMS";
import DrupalCMS from "./pages/cms/drupalCMS";
import JoomlaCMS from "./pages/cms/joomlaCMS";
import ObjectStorage from "./pages/object-storage/objectStorage";
import VPNoffshore from "./pages/vpn/VPNoffshore";
import VPSoffshore from "./pages/vps/vpsOffshore";
import DedicatedServersOffshore from "./pages/dedicated/dedicatedServersOffshore";
import WordPressCMSoffshore from "./pages/cms/wordPressCMSoffshore";
import ObjectStorageOffshore from "./pages/object-storage/objectStorageOffshore";
import CloudOffshore from "./pages/cloud/cloudOffshore";
import ColocationOffshore from "./pages/colocation/colocationOffshore";
import CMSoffshore from "./pages/cms/CMSoffshore";
import DDoSProtectionOffshore from "./pages/ddos/ddosProtectionOffshore";
import VPSfreeSpeech from "./pages/vps/vpsFreeSpeech";
import DedicatedServersFreeSpeech from "./pages/dedicated/dedicatedServersFreeSpeech";
import CloudFreeSpeech from "./pages/cloud/cloudFreeSpeech";
import ColocationFreeSpeech from "./pages/colocation/colocationFreeSpeech";
import VPNfreeSpeech from "./pages/vpn/VPNfreeSpeech";
import CMSfreeSpeech from "./pages/cms/CMSfreeSpeech";
import ObjectStorageFreeSpeech from "./pages/object-storage/objectStorageFreeSpeech";
import RedirectMemberPanel from "./components/redirects/redirectToMemberPanel";
import VPSProduct from './pages/vps/vpsProduct';
import serverProduct from './pages/dedicated/serverProduct';
import { JsonData, cryptocurrencies } from './components/json/JsonData';
import ShadowsocksSwitzerland from "./pages/vpn/shadowsocksSwitzerland";

const instance = createInstance({
  urlBase: 'https://owa.swissmade.host',
  siteId: 1,
  trackerUrl: 'https://owa.swissmade.host/data', 
  srcUrl: 'https://owa.swissmade.host/data.js',
  disabled: false
})

require('dotenv').config();
ReactDOM.render(
  <MatomoProvider value={instance}>
  <BrowserRouter>
  <ScrollToTop>
    <Header />
    <JsonData.Provider value={cryptocurrencies}>
    <main>
    <Switch>
            <Route exact path={["/", "/es/","/fr/","/ru/","/de/"]}>
              <Redirect to="/en/" />
            </Route>
            <Route exact path="/en/cloud">
              <Redirect to="/en/cloud-switzerland" />
            </Route>
            <Route exact path={/^\/en\/vps(?:\/switzerland(?:\/(?:linux|windows))?)?\/?$/}>
              <Redirect to="/en/vps-switzerland" />
            </Route>
            <Route exact path="/en/license">
              <Redirect to="/en/cpanel-switzerland" />
            </Route>
            <Route exact path="/en/cdn">
              <Redirect to="/en/cdn-switzerland" />
            </Route>
            <Route exact path="/en/colocation">
              <Redirect to="/en/colocation-switzerland" />
            </Route>
            <Route exact path="/en/ddos-protection">
              <Redirect to="/en/ddos-protection-switzerland" />
            </Route>
            <Route exact path="/en/data-center">
              <Redirect to="/en/data-center-switzerland" />
            </Route>
            <Route exact path="/en/data-protection">
              <Redirect to="/en/data-protection-switzerland" />
            </Route>
            <Route exact path="/en/openvpn-vpn-switzerland">
              <Redirect to="/en/openvpn-switzerland" />
            </Route>
            <Route exact path="/en/dedicated">
              <Redirect to="/en/dedicated-servers-switzerland" />
            </Route>
            <Route exact path={/^\/en\/dedicated-server(?:\/switzerland)?\/?$/}>
              <Redirect to="/en/dedicated-servers-switzerland" />
            </Route>

            <Redirect from="/:locale/livechat/help" to="/en/contact-us?livechat" />
            <Redirect from="/en/blog/tags/:tag" to="/en/blog?tag=:tag" />
            <Redirect from="/en/blog/cat/:category" to="/en/blog?category=:category" />
            <Route path="/en/docs/vmware/:category/:subcategory" component={RedirectMemberPanel} />
            <Route path="/en/cart/add/:id" component={RedirectMemberPanel} />
            <Route path="/en/balance" component={RedirectMemberPanel} />

            <Route exact path="/en/" component={HomePage} />
            <Route path="/en/dedicated-servers-switzerland" component={DedicatedServers} />
            <Route path="/en/dedicated-server/switzerland/:specs/:name/id-:id" component={serverProduct} />
            <Route path="/en/offshore-dedicated-servers" component={DedicatedServersOffshore} />
            <Route path="/en/free-speech-dedicated-servers" component={DedicatedServersFreeSpeech} />
            <Route path="/en/vps-switzerland" component={VPSSwitzerland} />
            <Route path="/en/vps/switzerland/:os/:specs/:name/id-:id" component={VPSProduct} />
            <Route path="/en/offshore-vps" component={VPSoffshore} />
            <Route path="/en/free-speech-vps" component={VPSfreeSpeech} />
            <Route path="/en/cloud-switzerland" component={CloudSwitzerland} />
            <Route path="/en/offshore-cloud" component={CloudOffshore} />
            <Route path="/en/free-speech-cloud" component={CloudFreeSpeech} />
            <Route path="/en/ddos-protection-switzerland" component={DDoSProtectionSwitzerland} />
            <Route path="/en/offshore-ddos-protection" component={DDoSProtectionOffshore} />
            <Route path="/en/colocation-switzerland" component={ColocationSwitzerland} />
            <Route path="/en/offshore-colocation" component={ColocationOffshore} />
            <Route path="/en/free-speech-colocation" component={ColocationFreeSpeech} />
            <Route path="/en/cpanel-switzerland" component={Cpanel} />
            <Route path="/en/cdn-switzerland" component={CDN} />
            <Route path="/en/vpn-switzerland" component={VPNswitzerland} />
            <Route path="/en/wireguard-vpn-switzerland" component={WireGuardSwitzerland} />
            <Route path="/en/shadowsocks-vpn-switzerland" component={ShadowsocksSwitzerland} />
            <Route path="/en/openvpn-switzerland" component={OpenVPNSwitzerland} />
            <Route path="/en/offshore-vpn" component={VPNoffshore} />
            <Route path="/en/free-speech-vpn" component={VPNfreeSpeech} />
            <Route path="/en/cms-hosting-switzerland" component={CMSswitzerland} />
            <Route path="/en/offshore-cms-hosting" component={CMSoffshore} />
            <Route path="/en/free-speech-cms-hosting" component={CMSfreeSpeech} />
            <Route path="/en/wordpress-hosting-switzerland" component={WordPressCMS} />
            <Route path="/en/offshore-wordpress-hosting" component={WordPressCMSoffshore} />
            <Route path="/en/drupal-hosting-switzerland" component={DrupalCMS} />
            <Route path="/en/joomla-hosting-switzerland" component={JoomlaCMS} />
            <Route path="/en/object-storage-switzerland" component={ObjectStorage} />
            <Route path="/en/offshore-object-storage" component={ObjectStorageOffshore} />
            <Route path="/en/free-speech-object-storage" component={ObjectStorageFreeSpeech} />
            <Route path="/en/offshore-hosting" component={Offshore} />
            <Route path="/en/free-speech-hosting" component={FreeSpeech} />
            <Route path="/en/accepted-payment-methods" component={PaymentMethods} />
            <Route path="/en/contact-us" component={Contact} />
            <Route path="/en/data-center-switzerland" component={DataCenter} />
            <Route path="/en/technology" component={Technology} />
            <Route path="/en/data-protection-switzerland" component={DataProtection} />
            <Route path="/en/about-us" component={AboutUs} />
            <Route path="/en/terms-of-service" component={TOS} />
            <Route path="/en/acceptable-use-policy" component={AUP} />
            <Route path="/en/service-level-agreement" component={SLA} />
            <Route path="/en/privacy-policy" component={PrivacyPolicy} />
            <Route path="/en/knowledgebase" component={KnowledgeBase} />
            <Route path="/en/products" component={Products} />
            <Route path="/en/blog" component={BlogHome} />
            <Route component={NotFound} />
    </Switch>
    </main>
    </JsonData.Provider>
    <Footer />
    <Livechat />
    </ScrollToTop>
  </BrowserRouter>
  </MatomoProvider>,
  document.getElementById("root")
);
