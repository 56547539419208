import React from 'react';

class AboutOS extends React.Component {

	render() {

		return (
			<div className="fullwidth light cms-hosting">
				<div className="row">
					<div className="four columns">
						<h3>What is Object Storage</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fas fa-cloud-upload-alt fa-5x" aria-hidden="true"></i></div>
						<p>Object storage stores data as <em>objects</em>, rather than as files within a hierarchical directory structure. Each object contains data, metadata, and a unique identifier that enables it to be retrieved without knowing its physical location. Object storage is designed to handle large amounts of unstructured data and is a popular choice for cloud-based applications, backup archiving and big data analytics.</p>
					</div>
					<div className="four columns">
						<h3>Why Object Storage</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fas fa-list-ul fa-5x" aria-hidden="true"></i></div>
						<p>Object storage offers a range of advantages over hierarchical storage, including scalability, durability, accessibility, and cost-effectiveness. It can store massive amounts of unstructured data and provides a flat address space, making it easy to access data regardless of its physical location. Object storage is also designed to be fault-tolerant and highly available, with data replication and backup features for redundancy.</p>
					</div>
					<div className="four columns">
						<h3>Amazon® S3 REST API</h3>
						<hr className="grey" />
						<div className="left paddingright"><i className="fas fa-code fa-5x" aria-hidden="true"></i></div>
						<p>Oobject storage supports the S3 API, which enables easy integration with other S3-compatible applications and services, without causing any disruption to existing workflows. Accessing the storage is a hassle-free process, as any S3-compatible client or library can be utilized to manage it. Once you make an order, we will provide you with access credentials, making it quick and easy to get started with object storage.</p>
					</div>
				</div>
			</div>
		)
	}
}

export default AboutOS