import React from 'react';
import { NavLink } from 'react-router-dom';

class FAQinclude extends React.Component {
    render() {

        const category = this.props.category,
            color = this.props.color,
            type = this.props.type

        const speedtest = 
            <div className={category === 'cloud' | category === 'dedicated' ? "six columns" : "four columns"}>
                <h2>Speed Test</h2>
                <p>Measure the network speed (download and upload speeds, jitter, and ping) between your device and our server in <NavLink to="/en/data-center-switzerland">Zurich, Switzerland</NavLink>.</p>
                <p><a href="https://speedtest.swissmade.host/" target='_blank' rel="noopener noreferrer nofollow" className='readmore left'>Test Your Network Speed <i className="fa-regular fa-square-caret-right"></i></a></p>
            </div>

        return (
            <div className={color === 'dark' ? 'fullwidth dark' : 'fullwidth light'}>
                <div className="row">
                    <div className={category === 'vps' ? "four columns" : category === 'cloud' | category === 'dedicated' ? "six columns" : "twelve columns"}>
                        <h2>{category === 'dedicated' && 'Dedicated Servers '}
                            {category === 'vps' && 'VPS Hosting '}
                            {category === 'cloud' && 'Cloud Hosting '}
                            {category === 'ddos' && 'DDoS Protection '}
                            {category === 'colocation' && 'Colocation Hosting '}
                            101</h2>

                        <p>Take a look through the most frequently asked questions about our
                            {category === 'dedicated' && ' dedicated server '}
                            {category === 'vps' && ' VPS '}
                            {category === 'cloud' && ' Cloud hosting '}
                            {category === 'ddos' && ' DDoS protection '}
                            {category === 'colocation' && ' colocation hosting '}
                            offerings to learn more about the configurations, available addons and terms of use.</p>
                        <p><NavLink className='readmore left' to={'/en/knowledgebase?category=' + category}>Knowledgebase <i className="fa-regular fa-square-caret-right"></i></NavLink></p>
                    </div>
                    {category === 'vps' &&
                        <>
                            {speedtest}
                            <div className="four columns">
                                <h2>CMS Hosting</h2>
                                <p>Content Management System (CMS) is a cheaper yet tightly packed with features and functions alternative to VPS hosting. Supported CMS: <NavLink to={type === 'offshore' ? '/en/offshore-wordpress-hosting' : '/en/wordpress-hosting-switzerland'}><i className="fa-brands fa-wordpress"></i> WordPress</NavLink>, <NavLink to="/en/drupal-hosting-switzerland"><i className="fa-brands fa-drupal"></i> Drupal</NavLink> and <NavLink to="/en/joomla-hosting-switzerland"><i className="fa-brands fa-joomla"></i> Joomla</NavLink>.</p>
                                <p><NavLink className="readmore left" to="/en/cms-hosting-switzerland">CMS Hosting <i className="fa-regular fa-square-caret-right"></i></NavLink></p>
                            </div>
                        </>
                    }
                     {category === 'cloud' | category === 'dedicated' ? speedtest : ""}
                </div>
            </div>
        )
    }
}

export default FAQinclude;