import React,  { useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { NavLink } from "react-router-dom";
import '../../css/notifications.css';
import Cookies from 'js-cookie';

function DiscountInclude() {

		const difference = +new Date("2024-01-15T23:59:59+01:00") - +new Date(),
		couponCode = 'XMAS2023',
		blogURL = '/en/blog/seasons-greetings-from-swiss-made-host',
		hasArticle = true,
		discountPercent = 15,
		minOrder = 5,
		firstMonth = false,
		vpn = false

		const calculateTimeLeft = () => {
			let timeLeft = {};
			Math.floor(difference / (1000 * 60 * 60 * 24)) <= 1
			? Math.floor((difference / (1000 * 60 * 60)) % 24) === 1 
				?	timeLeft = {
					day: Math.floor(difference / (1000 * 60 * 60 * 24)),
					hour: Math.floor((difference / (1000 * 60 * 60)) % 24)
					}
				: timeLeft = {
					day: Math.floor(difference / (1000 * 60 * 60 * 24)),
					hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
				}
			: Math.floor((difference / (1000 * 60 * 60)) % 24) !== 1 
				?	timeLeft = {
					days: Math.floor(difference / (1000 * 60 * 60 * 24)),
					hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
					}
				: timeLeft = {
					days: Math.floor(difference / (1000 * 60 * 60 * 24)),
					hour: Math.floor((difference / (1000 * 60 * 60)) % 24)
			}	

			return timeLeft;
			
		  };

		  
		  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
		
		  useEffect(() => {
			difference > 0 &&
			setTimeout(() => {
			  setTimeLeft(calculateTimeLeft());
			}, 1000);
		  });
		  const timerComponents = [];

		  Object.keys(timeLeft).forEach((interval,i) => {
			if (!timeLeft[interval]) {
			  return;
			}

			timerComponents.push(
			  <span key={i}>
				{timeLeft[interval] > 0 && i === 0 
					? timeLeft[interval] 
					: Object.values(timeLeft)[0] > 0
						? ' & ' + timeLeft[interval]
						: timeLeft[interval]} 
				{timeLeft[interval] > 0 && ' ' + interval}
			  </span>
			)
		  })

		  const createNotification = type => () => {
			switch (type) {
			  case 'coupon-copied':
				NotificationManager.info('Coupon code has been copied to your clipboard, just paste it at checkout.');
				break;
			  default:
				break;
			}}
		 if (timerComponents.length > 0 && Math.sign(timeLeft.hours) !== -1 && Cookies.get('discount-notification') !== 'dismissed')
		 {
		return (
		<React.Fragment>
			<NotificationContainer/>
			<div className="notify fullwidth discount" id="discountNotification" data-title="discount" data-expires="3" data-dismissible="true">
				<div className="row">
					<div className="twelve columns">
					<p>Redeem code  <CopyToClipboard text={couponCode} onCopy={createNotification('coupon-copied')}><strong className="coupon" title="Click to copy to clipboard"><i className="fas fa-cut"></i>{couponCode}</strong></CopyToClipboard> at checkout and get {discountPercent}% off {firstMonth === true && 'first month payment for'} {vpn === true ? 'a VPN with dedicated IP' : 'any order above ' + minOrder + ' CHF'}.{firstMonth === true && <br/>} Offer expires in {timerComponents}. {hasArticle ? <NavLink to={blogURL}>{vpn === true ? 'Get a VPN' : 'Learn more'}</NavLink> : ''}
					<span role="button" aria-label="Dismiss notification" className="notification-dismiss" title="Dismiss notification"	onClick={() => Cookies.set('discount-notification', 'dismissed')}>Dismiss</span>
					</p>		
					</div>
				</div>
			</div>
		</React.Fragment>
)}
else {
	return (null)
}
}


export default DiscountInclude;
