import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class FAQpreloader extends React.Component {
    render() { return (
    <div className="fullwidth light">
		<div className="row">
			<div className="ten columns">
            <Skeleton count={3} />
            <p/>
            <Skeleton count={10} />
            <p/>
            <Skeleton count={3} />
            <p/>
            <Skeleton count={10} />
            <p/>
            <Skeleton count={3} />
            <p/>
            <Skeleton count={10} />
            <p/>
            <Skeleton count={3} />
            <p/>
            <Skeleton count={10} />
            <p/>
            <Skeleton count={3} />
            <p/>
            <Skeleton count={10} />
            <p/>
            </div>
        </div>
    </div>
    ) }
}

export default FAQpreloader;