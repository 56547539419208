import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { toggleWidget } from 'react-chat-widget';
import { NavLink } from 'react-router-dom';

const owloptions = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 3,
    slideBy: 1,
    responsive:{
        0:{
            items:1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        900:{
            items:3
        },
        1000:{
            items:3
        }
    }
};


class FeatureSlider extends React.Component {

	render() {
        
        return (

<div className="fullwidth dark">
	<div className="row">
		<div className="twelve columns">
    <OwlCarousel2 options={owloptions}>
	<div className="item">
		<h5>Redundant Power Supply</h5>
		<hr className="grey" />
		<div className="left paddingright"><span className="fa-stack fa-3x">
			<i className="fa fa-square-o fa-stack-2x"></i>
			<i className="fa fa-plug fa-stack-1x"></i>
		</span></div>
		<p>Uninterrupted power supply is the key to the record-setting availability. These are the measures taken to guarantee power supply security: two different power providers, two independent power feeds to each rack, industrial-grade UPS with auto switchover and diesel generators ready for indefinite running in case of a serious blackout.</p>
	</div>
	<div className="item">
		<h5>Premium Networking &amp; Connectivity</h5>
		<hr className="grey" />
		<div className="left paddingright"><span className="fa-stack fa-3x">
			<i className="fa fa-square-o fa-stack-2x"></i>
			<i className="fa fa-signal fa-stack-1x"></i>
		</span></div>
		<p>We have put a lot of effort into building a no single point of failure network architecture: redundant hardware with automatic failover protection, multiple Tier-1 bandwidth providers, direct connection to SwissIX Internet exchange point, professional DDoS protection, automatic network health monitoring and 24/7/365 intelligent hands on site.</p>
	</div>

	<div className="item">
		<h5>Seven-layer Security</h5>
		<hr className="grey" />
		<div className="left paddingright"><i className="fa fa-lock fa-5x" aria-hidden="true"></i></div>
		<p>Physical security is as important as IT security when it comes to choosing the right data center to colocate your mission critical hardware. 24/7 video surveillance and security patrols, multiple access barriers with biometric readers and mantraps render unauthorized access to servers and networking hardware absolutely impossible.</p>
	</div>
	<div className="item">
		<h5>Carbon Neutrality</h5>
		<hr className="grey" />
		<div className="left paddingright"><i className="fa fa-leaf fa-5x" aria-hidden="true"></i></div>
		<p>We strive to set an example of responsible approach to energy and keep our Power Usage Effectiveness (PUE) exceptional by any standards. We use energy originating from renewable sources only and our facility in Zurich is the first and the only carbon-neutral data center in Switzerland certified by myclimate®.</p>
	</div>
	<div className="item">
		<h5>Data Protection</h5>
		<hr className="grey" />
		<div className="left paddingright"><i className="fa fa-key fa-5x" aria-hidden="true"></i></div>
		<p>Switzerland is well known for its strict laws on privacy and data protection - a hosting provider cannot be forced to disclose data unless when Swiss authorities investigate a client and an injunction is obtained. There's hardly any other country in the world with a matching grade of <NavLink to="/en/data-protection-switzerland">data protection</NavLink> at the state level.</p>
	</div>
	<div className="item">
		<h5>Financial Services Ready</h5>
		<hr className="grey" />
		<div className="left paddingright"><i className="fa fa-bank fa-5x" aria-hidden="true"></i></div>
		<p>Interxion™ provides proximity hosting services and a secure FINMA circular 07/8-compliant facility for your financial institution. Place your firm close to the companies that provide services to you, and to the access points (SwissIX) that provide direct and efficient connection to key liquidity sites and exchanges.</p>
	</div>
	<div className="item">
		<h5>Direct Connection to SwissIX</h5>
		<hr className="grey"/>
		<div className="left paddingright"><i className="fa fa-upload fa-5x" aria-hidden="true"></i></div>
		<p>Our data center in Zurich has direct connection to <a title="SwissIX Swiss Internet Exchange" href="https://www.swissix.ch/" target="_blank" rel="noopener noreferrer">SwissIX</a> Internet Exchange, which is the largest Internet Exchange Point (IXP) in Switzerland in terms of peering participants. Direct IXP connection lowers latency by improving routing efficiency and provides an extra layer of networking fault-tolerance.</p>
	</div>
	<div className="item">
		<h5>24/7 Live Support</h5>
		<hr className="grey"/>
		<div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
		<p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
	</div>
</OwlCarousel2>
	</div>
	</div>
</div>

            )
            }}

export default FeatureSlider