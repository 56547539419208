import React from 'react';
import isEmail from 'validator/lib/isEmail';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import '../../css/notifications.css';
import axios from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Cookies from 'js-cookie';

class EmailForm extends React.Component {

    constructor(props){
        super(props)
        this.onChange = this.onChange.bind(this);
        this.state = {
            email: false,
            name: false,
            subject: false,
            captcha: false,
            message: false,
            uploadURL: process.env.REACT_APP_BACKEND_URL + '/contact_form/en'
        }
      }

      onChange(el) {
            el.target.onblur = (el) => {
                if (el.target.name === "email")
                {
                    if (isEmail(el.target.value))
                        {el.target.classList.remove("validationFailed"); this.setState({email: true})}
                    else {el.target.classList.add("validationFailed"); this.setState({email: false})}
                }
                if (el.target.name === "name" || el.target.name === "subject" || el.target.name === "message")
                {
                    if (el.target.value.length < 1)
                    {el.target.classList.add("validationFailed"); this.setState({[el.target.name]: false})}
                    else {el.target.classList.remove("validationFailed"); this.setState({[el.target.name]: true})}
                }
            }
    }

	captchaSolved = () => {
        this.setState({captcha: true})
      }

    captchaExpired = () => {
        this.setState({captcha: false})
    }

    captchaError = () => {
        NotificationManager.error('hCAPTCHA failed to load, please try again later.', 'Error', 10000);
    }

    submitForm = () => {
        if (this.state.name && this.state.email && this.state.subject && this.state.message && this.state.captcha)
        {
        axios.post(this.state.uploadURL, "get_csrf=1", {
            withCredentials: true,
            headers: {'Session-Token': Cookies.get('session')},
            referer: window.location.href,
        }).then(res => {
            var formData = new FormData();
            formData.set('_csrf_token', res.data.data.csrf);
            formData.set('first_name', document.querySelector('#form-name').value);
            formData.set('subject', document.querySelector('#form-subject').value);
            formData.set('email', document.querySelector('#form-email').value);
            formData.set('message', document.querySelector('#form-message').value);
            axios.post(this.state.uploadURL, formData, {
                withCredentials: true,
                headers: {'Session-Token': Cookies.get('session')},
                referer: window.location.href
            }).then(res => {
                if (res.data.data.result === 'success')
                {
                window._paq = window._paq || [];
                window._paq.push(['trackGoal', 2]);        
                NotificationManager.success('Your message has been successfully sent to the support desk.', 'Message Sent', 10000)
                document.querySelector("#emailForm").reset()
                this.hcaptcha.resetCaptcha()
                this.setState({
                    email: false,
                    name: false,
                    subject: false,
                    captcha: false,
                    message: false
                    })
                }
            }).catch(error => {
                NotificationManager.error('Sending of message failed, please try again later.', 'Error', 10000);
            })
        }).catch(error => {
            NotificationManager.error('Sending of message failed, please try again later.', 'Error', 10000);
        })
        }
        else {
    this.state.name === false && document.querySelector('#form-name').classList.add("validationFailed")
    this.state.email === false && document.querySelector('#form-email').classList.add("validationFailed")
    this.state.subject === false && document.querySelector('#form-subject').classList.add("validationFailed")
    this.state.message === false && document.querySelector('#form-message').classList.add("validationFailed")
    var errorText = <span>Please complete the following field(s): <ul>{this.state.name === false && <li>Name</li>}{this.state.email === false && <li>Email</li>}{this.state.subject === false && <li>Subject</li>}{this.state.message === false && <li>Message</li>}{this.state.captcha === false && <li>Captcha</li>}</ul></span>
    NotificationManager.error(errorText, 'Form Incomplete', 10000);
    }}

    render() {

        return (
		    <form id="emailForm">
				<div className="row">
					<div className="twelve columns">
                        <label htmlFor="name">Name<span className="required">*</span></label>
                        <input id="form-name" type="text" name="name" onChange={this.onChange}/>
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <label htmlFor="email">Email Address<span className="required">*</span></label>
                        <input id="form-email" type="text" name='email' onChange={this.onChange}/>
                    </div>
				</div>
				<div className="row">
                    <div className="twelve columns">
                        <label htmlFor="subject">Subject<span className="required">*</span></label>
                        <input id="form-subject" type="text" name="subject" onChange={this.onChange}/>
                    </div>
				</div>
				<div className="row">
                    <div className="twelve columns">
                        <label htmlFor="message">Message<span className="required">*</span></label>
                        <textarea id="form-message" name='message' cols="10" rows="10" onChange={this.onChange}/>
                    </div>
				</div>
				<div className="row">
                    <div className="twelve columns">
                            <HCaptcha
                                ref={e => (this.hcaptcha = e)}
                                sitekey="f7355579-f076-4f29-88f6-25f6bee4679d"
                                onVerify={this.captchaSolved}
                                onError={this.captchaError}
                                onExpire={this.captchaExpired}
                                onChalExpired={this.captchaExpired}
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <button type="button" id="submitContactForm" className="button radius left" style={{marginTop: '10px'}} onClick={this.submitForm}>Send Message</button>
                    </div>
                </div>
                <NotificationContainer/>
			</form>
        )}}

export default EmailForm
