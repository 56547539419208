import React from 'react';

class FreeSSL extends React.Component {

	render() {

		return (
			<div className="fullwidth light">
				<div className="row flex-center">
					<div className='six columns'>
						<div className="panel nomargin">
							<h3>Free SSL Certificate for {this.props.cms === 'all' && 'CMS '}{this.props.cms === 'wordpress' && 'WordPress '}{this.props.cms === 'drupal' && 'Drupal '}{this.props.cms === 'joomla' && 'Joomla '}</h3>
							<hr className="black" />
							<p>In order to setup a free SSL certiifcate on your CMS server, complete the following steps. First of all, direct your domain to the IP address of your CMS. Then, navigate to your order management section and press the "Let's Encrypt SSL" button. This will trigger the issuance of a <a href="https://letsencrypt.org/" target='_blank' rel="noopener noreferrer">Let's Encrypt</a> SSL certificate, which will be automatically installed on your server to enhance the security of your CMS and website. Additionally, if you have your own SSL ceritifcate, you can configure it in your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN} title="Existing customer login">member account</a> area as well.</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default FreeSSL