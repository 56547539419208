import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import DiscountCounter from './dscountCounter';
import AppList from './appsList';
import ProtocolsList from './protocolsList';

class OpenVPNSwitzerland extends React.Component {

	render() {

		const protocol = 'openvpn'

		return (
			<React.Fragment>
				<Helmet>
					<title>Private OpenVPN Server in Switzerland - Dedicated IP | Swiss Made Host</title>
					<meta name="description" content="Private OpenVPN server is a high security key to your virtual Swiss residency that protects identity, enables access to geo-restricted content and improves secrutiy of your browsing." />
					<link rel="canonical" href="https://swissmade.host/en/openvpn-switzerland" />
				</Helmet>
				<div className="fullwidth pic vpn">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Private OpenVPN Server<span>in Switzerland</span></h1>
								<h2>A High Security Key to Your Virtual Swiss Residence</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<ProtocolsList protocol={protocol}/>
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider protocol={protocol} />
				</ReactDependentScript>
				<DiscountCounter protocol={protocol}/>
				<AppList protocol={protocol}/>
				<PaymentMethodsInclude />
			</React.Fragment>
		)
	}
	toggleAnimation = (e) => {
		document.getElementById('ddos-protection-info').classList.toggle('expanded')
	}
}

export default OpenVPNSwitzerland;