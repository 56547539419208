import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class PlanPreLoader extends React.Component {
    render() { return (
        <React.Fragment>
			<div className="fulwidth">
						<div className="row">
                            <div className="twelve columns">
                                    <p/>
                                    <Skeleton count={25} />
									<p/>
                                    <Skeleton count={25} />
                                    <p/>
                                    <Skeleton count={25} />
									<p/>
                                    <Skeleton count={25} />
                                    <p/>
                                    <Skeleton count={25} />
                                    <p/>
                                </div>
						</div>
			</div>
        </React.Fragment>
    ) }
}

export default PlanPreLoader;