import React from 'react';
import Helmet from "react-helmet";
import MapCompanyDataCenter from '../../components/includes/mapCompanyDataCenter';
import EmailForm from './emailForm-hCaptcha';
import { toggleWidget } from 'react-chat-widget';
import LiveChatDetector from './liveChatLink.js'

class Contact extends React.Component {

	render() {

		return (
			<React.Fragment>
				<LiveChatDetector />
				<Helmet>
					<title>Contact Us | Swiss Made Host</title>
					<meta name="description" content="Swiss Made Host provides 24/7/365 live sales &amp; technical support and assistance with any issues that our clients may encounter." />
				</Helmet>
				<div className="fullwidth pic contact-us">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Contact <span>SwissMade.Host</span></h1>
								<h2>24/7 Live Support</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="fullwidth dark">
					<div className="row pagetitle">
						<div className="six columns">
							<h3>Always ready to help</h3>
							<p>If you have questions or need assistance in any way, please don't hesitate to contact our friendly customer support team through any of the available means of communication that you find most convenient. We provide 24/7/365 live support via text chat widget that you can find on every page of our website, support tickets and email.</p>
						</div>
						<div className="six columns">
							<h3>Your needs drive our priorities</h3>
							<p>In accordance with our customer orientation policy we make great efforts to have a deep understanding of customers' needs and expectations. We guarantee that our customer support engineers will deliver an efficient solution to any issue that may arise with a sense of  warmth, friendliness, mutual respect and company spirit.</p>
						</div>
					</div>
				</div>
				<div className="fullwidth light">

					<div className="row margintop">

						<div className="four columns">
							<div className="row">

								<div className="twelve columns">
									<div className="panel">
										<div className="row">
											<div className="three columns">
												<p><i className="fa fa-comments fa-5x" aria-hidden="true"></i></p>
											</div>
											<div className="nine columns">
												<h5 className="nomargin">24/7 Live Help</h5>
												<ul className="no-bullet">
													<li><p>Round-the-clock assistance for any questions that you may have about our products and services. Click the button below to start a new chat with the support team.</p></li>
													<li><a href="/" onClick={function (event) { event.preventDefault(); toggleWidget() }} className="button radius left" title="Start a chat">Start Chat</a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="twelve columns">
									<div className="panel">
										<div className="row">
											<div className="three columns">
												<p><i className="fa-solid fa-sign-in fa-5x" aria-hidden="true"></i></p>
											</div>
											<div className="nine columns">
												<h5 className="nomargin">Support Ticket</h5>
												<ul className="no-bullet">
													<li><p>To streamline the process of getting the help you need, we kindly ask our existing customers to submit enquiries through the support ticket system. This helps ensure that our support team has all the necessary information about your account and previous inquiries, allowing us to provide you with faster and more personalized assistance.</p></li>
													<li><a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/ticket/list'} className="button radius left">Open Ticket</a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="twelve columns">
									<div className="panel">
										<div className="row">
											<div className="three columns">
												<p><i className="fa fa-envelope fa-5x" aria-hidden="true"></i></p>
											</div>
											<div className="nine columns">
												<h5 className="nomargin">E-mail</h5>
												<ul className="no-bullet">
													<li><p>If you have not yet signed up for an account and require assistance with sales or general inquiries, please use the following email address: <a href="mailto:support@swissmade.host" title="Send us a message">support@swissmade.host</a></p></li>
													<li><a href="mailto:support@swissmade.host" title="Send us a message" className="button radius left">Send Email</a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="eight columns">
							<div className="panel">
								<h4>Contact Form</h4>
								<hr className="grey" />
								<p>To get in touch with our professional and friendly support team, please fill in the contact form provided on our website. It's important to include all relevant details about your request, such as your account information and a description of the issue you're experiencing. This will help our team provide you with the most accurate and timely response possible.</p>
								<p>For our existing customers, we recommend opening a ticket from within your account area for the best communication experience and fastest response time. This allows our support team to access your account information and previous inquiries, enabling us to provide you with a more personalized and efficient service.</p>
								<EmailForm />
							</div>
						</div>
					</div>
				</div>
				<div className="fullwidth dark">
					<div className="row pagetitle">
						<MapCompanyDataCenter color={'dark'} />
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Contact