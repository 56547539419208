import React, {Component} from 'react';

class CloudConfigsList extends Component {

    render() {

        const list = this.props.list,
        payment_period = this.props.payment_period,
        usdRate = this.props.usdRate,
        eurRate = this.props.eurRate,
        price_from = this.props.sliderPriceFrom,
        price_to = this.props.sliderPriceTo,
        ram_from = this.props.sliderRamFrom,
        ram_to = this.props.sliderRamTo,
        storage_from = this.props.sliderStorageFrom,
        storage_to = this.props.sliderStorageTo,
        countConfigs = this.props.countConfigs,
        discount = this.props.discount,
        FilterConditions = 
        list.filter((a) => 
        a.price >= price_from 
        && a.price <= price_to
        && a.config.ram >= ram_from
        && a.config.ram <= ram_to
        && a.config.storage >= storage_from
        && a.config.storage <= storage_to
        && (discount === true ? a.old_price !== 0 : a.old_price !== 0 || a.old_price === 0)
        ).sort((a, b) => a.price - b.price)
        
        countConfigs(FilterConditions.length)

        return (
            <div className="fullwidth light">
{FilterConditions.length > 0 ? FilterConditions.sort((a, b) => a.config.windows - b.config.windows).map((item,i) =>
    <div className="row" key={i}>
        <div className="twelve columns">
            <div className="panel pricing">
                <div className="twelve columns">
                    <div className="four columns">
                        <h3 className="config">
                            <span className="colored">{item.name}</span>
                        </h3>
                    </div>
                    <div className='eight columns'>
                        {item.old_price !== 0 && 
                        <span className="tag right special-offer">SPECIAL OFFER</span>
                        }
                        {item.config.backup_included && 
                        <span className="tag right">FREE BACKUP</span>
                        }
                    </div>
                </div>
                <hr className="black"/>
                <div className="eight columns">
                    <ul className="dp">
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>CPU Cores</span>
                            <span className="value">{item.config.cpu.replace(':', ' / ')} / {item.config.cores} core(s)</span>
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>RAM</span> 
                            <span className="value">{item.config.ram} MB</span>
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Storage</span>
                            <span className="slash">{item.config.storage} GB all-flash SSD</span>
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Bandwidth and IP</span>
                            <span className="slash">{item.config.bandwidth} TB monthly @ 10 Gbps / 1 IPv4 address</span>
                        </li>
                        <li className="protection">
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>DDoS Protection</span>
                            <span className="value">Free of charge</span>
                        </li>
                        <li className="protection">
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Daily Backups</span>   
                            <span className="value">Free of charge</span>
                        </li>
                    </ul>
	                <ul className="nobullet co expandInfo" id={"details-"+item.id}>
                        <li>
                            <span className="name">Server Location</span><span>Zurich, Switzerland</span>
                        </li>
                        <li>
                            <span className="name">Virtualization</span><span>VMware® ESXi Bare-metal Hypervisor</span>
                        </li>
                        <li><span className="name">Custom OS Images</span><span>Supported</span>
                        </li>
                        <li>
                            <span className="name">Firewall</span><span>Free of charge</span>
                        </li>
                        <li>
                            <span className="name">DNS Hosting with PTR</span><span>Free of charge</span>
                        </li>
                        <li>
                            <span className="name">Billing Options</span><span>1 month to 12 months</span>
                        </li>
                        <li>
                            <span className="name">Support</span><span>24/7 Live</span>
                        </li>
	                </ul>
                </div>
            <div className="four columns">
                <div className="row">
                    <div className="twelve columns monthly-fee margintop">
                        
                        <span className="chf">{
                     (payment_period === 1
                        ? item.old_price === 0 
                            ? new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)
                            : <React.Fragment><span style={{ color: '#D74937', textDecoration: 'line-through', fontSize: '0.7em', fontWeight: 'normal' }}><span style={{color: '#2D2D2D'}}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price !== 0 ? item.old_price : item.price)}</span></span><br/>
                            {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))}
                            </React.Fragment>
                        : <React.Fragment><span style={{ color: '#D74937', textDecoration: 'line-through', fontSize: '0.7em', fontWeight: 'normal' }}><span style={{ color: '#2D2D2D' }}>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.old_price !== 0 ? item.old_price : item.price)}</span></span><br/>
                            {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format((item.price - (item.price / 100 * (payment_period - 1) * 2)))}
                        </React.Fragment>)
                    }</span> 
                    <div className="usd">USD <span className="val">{
                      ((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)
                    }</span>
                    </div> 
                    <div className="eur">EUR <span className="val">{
                      ((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)
                    }</span>
                    </div>
                        <span className="month">per month</span>
                    </div>
                </div>
                <span className="button radius large right showInfo" title="View configuration details" onClick={this.toggleAnimation} animation-id={item.id}><i className="fa fa-info" aria-hidden="true" animation-id={item.id}></i></span>
                <span className="link-for-storage_w"><a className="button radius large right" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + item.id + "?bill=" + payment_period} title="Configure and place order">{item.old_price !== 0 || payment_period > 1 ? 'Buy with discount' : 'Configure'}</a></span>
            </div>
        </div>
    </div>
</div>
)

:   
    <div className="row">
        <div className="twelve columns">
        <div className="panel nomargin">
            <h3 className="colored">Have something specific on mind?</h3>
            <hr className="black"/>
            <h4>Configure a custom Cloud server to perfectly meet your requirements and budget.</h4>
            <p>If none of the preconfigured Cloud server plans fits your tasks, please feel free to select just the right number of CPU cores, amount of RAM, storage capacity and other addons to build your very own custom Cloud server configuration.</p>
            <a className="button radius large" href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/cart/add/172'}>Custom Cloud Server</a>
        </div>
        </div>
    </div>
}
</div>
            )
    }
    toggleAnimation = (e) => {
    document.getElementById("details-"+e.target.getAttribute('animation-id')).classList.toggle('expanded')
      }
}

export default CloudConfigsList;
