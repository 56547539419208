import React from 'react';
import Helmet from "react-helmet";
import ReactDependentScript from "react-dependent-script";
import FeatureSlider from './featureSlider';

class DataCenter extends React.Component {

    render() {
		const richData = {
		   "@context":"https://schema.org",
		   "@type":"FAQPage",
		   "mainEntity":[
			{
				"@type": "Question",
				"name": "What are the benefits of hosting a data center in Switzerland?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "Switzerland's liberal economic system, competitive tax regime, highly educated workforce, and political stability make it an ideal location for organizations looking to establish operations in the heart of Europe. Although outside the EU, Switzerland has strong trading links with the EU and is bordered by three of its largest market economies."
				}
			  },
			  {
				"@type": "Question",
				"name": "What are the data protection laws in Switzerland?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "Switzerland has strong data protection laws, managed under Swiss data protection regulations. Organizations that want to keep data within Switzerland can benefit from these regulations."
				}
			  },
			  {
				"@type": "Question",
				"name": "What are the connectivity options for data centers in Switzerland?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "Data centers in Switzerland offer a wide range of connectivity options, with access to leading internet exchanges, as well as direct connections to various carriers and content delivery networks."
				}
			  },
			  {
				"@type": "Question",
				"name": "What kind of security measures are in place at data centers in Switzerland?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "Data centers in Switzerland have strict security measures in place, including 24x7 CCTV monitoring, multiple access barriers, contactless key cards, and biometric readers. They also have various compliance certifications and follow best practices for security and operations management."
				}
			  },
			  {
				"@type": "Question",
				"name": "What is the level of energy efficiency in data centers in Switzerland?",
				"acceptedAnswer": {
				  "@type": "Answer",
				  "text": "Data centers in Switzerland prioritize energy efficiency and often use innovative cooling technologies and renewable energy sources. Many data centers in Switzerland also aim to be carbon-neutral, reducing their environmental impact."
				}
			  },			  
			  {
				 "@type":"Question",
				 "name":"What makes Switzerland an ideal location for a data center?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Switzerland's liberal economic system, competitive tax regime, highly educated workforce, and political stability make it an ideal location for organizations looking to establish operations in the heart of Europe. Additionally, Switzerland has strong trading links with the EU and is bordered by three of its largest market economies."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What are the benefits of Interxion's Zurich data center?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center provides ultra-secure, known location for storing and processing data in line with Swiss data protection regulations. It is the largest data center of its kind in Switzerland and is the perfect European base for international organizations. Benefits include proximity to the financial district and FINMA-compliant operations, a strong community of financial institutions, compliance with the FINMA circular 07/8, and access to leading providers of services such as market data, direct market access, sponsored access, and clearing services. Additionally, it is one of the Switzerland's carbon-neutral data centers."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What is the connectivity like at Interxion's Zurich data center?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center provides direct access to SwissIX and NL-ix internet exchanges, over 40 carriers, ISPs, and CDNs, and a diverse community of cloud platforms, hosting providers, and systems integrators. The ideal environment for communities of interest to thrive."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What type of organizations is Interxion's Zurich data center suitable for?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center is suitable for companies in Zurich's financial sector who value proximity to the financial district and its FINMA-compliant operations, national and international organizations that want to keep data within Switzerland, managed under Swiss data protection laws, and any organization that cares about sustainability. It is also home to a diverse community of companies that provide hosting services, offer system integration, build and operate public or private cloud platforms, and develop and deliver cloud applications and services."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What security measures are in place at Interxion's Zurich data center?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center is monitored 24x7 by CCTV and security patrols, with multiple access barriers including mantraps, contactless key cards, and biometric readers. Externally audited compliance with the FINMA circular 07/8 is also in place. Additionally, it has ISO 27001 and ISO 22301 certification and follows best practice and operates in accordance with the ITILv3 framework."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What are the sustainability measures in place at Interxion's Zurich data center?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center uses 100% renewable energy and is certified by myclimate as a carbon-neutral data center. It also uses free cooling anda custom cold-aisle containment solution to support higher power densities on low raised floors more efficiently, reducing the overall cooling overhead by as much as 30%. These measures help to reduce the carbon footprint of the data center and promote sustainability."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What kind of community is present at Interxion's Zurich data center?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center provides an ideal environment for communities of interest to thrive, with direct access to SwissIX, a choice of over 40 carriers, and the ability to connect directly with partners, suppliers, and customers using cross connects. The data center is home to a strong community of financial institutions, including banks, exchanges, and brokers, as well as a growing community of cloud platforms, hosting providers, and systems integrators. The enterprise community ranges from small national companies to large multinationals."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What kind of security measures are in place at Interxion's Zurich data center?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center provides ultra-secure, known location for storing and processing data in line with Swiss data protection regulations. The data center is monitored 24x7 by CCTV and security patrols, with multiple access barriers, including mantraps, contactless key cards, and biometric readers. It has externally audited compliance with the FINMA circular 07/8 and operates in full accordance with the ITILv3 framework, with ISO 27001 and ISO 22301 certification. Customers can also add their own security systems to a private room."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What kind of colocation services are available at Interxion's Zurich data center?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center offers colocation in cabinets, cages, and private rooms, with the ability to rapidly install cross connects. It also provides 24x7 hands and eyes technical support service, meeting rooms, and office space. Customers have significant flexibility in what they do with their space and how they extend their footprint. The on-campus team can advise on best practice for cabling, cooling, and power delivery and management, and help customers make the best choices on leased lines, IP transit, peering arrangements, and physical cabling."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What kind of connectivity options are available at Interxion's Zurich data center?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center offers direct access to SwissIX and NL-ix internet exchanges, with a choice of over 40 carriers, ISPs, and CDNs. The data center provides an ideal environment for communities of interest to thrive, with the ability to connect directly with partners, suppliers, and customers using cross connects. Customers can also access a strong community of financial institutions, including banks, exchanges, and brokers, as well as a growing community of cloud platforms, hosting providers, and systems integrators."
				 }
			  },
			  {
				 "@type":"Question",
				 "name":"What kind of compliance standards does Interxion's Zurich data center meet?",
				 "acceptedAnswer":{
					"@type":"Answer",
					"text":"Interxion's Zurich data center operates in full accordance with the ITILv3 framework, with ISO 27001 and ISO 22301 certification. It has externally audited compliance with the FINMA circular 07/8 and is compliant with the internationally recognized ISO/IEC 27001 certification for Information Security Management and ISO 22301 for Business Continuity Management across all of its European operations."
			 }
		 }
				 ]
			 }
        return (
		<React.Fragment>
		<Helmet>
			<title>Ultra-secure Carbon-neutral Data Center in Switzerland | Swiss Made Host</title>
			<meta name="description" content="Ultra-secure, carbon and carrier-neutral data center in Zurich, Switzerland." />
			<link rel="canonical" href="https://swissmade.host/en/data-center-switzerland"/>
			<script type="application/ld+json">{JSON.stringify(richData)}</script>
		</Helmet>
    <div className="fullwidth pic data-center">
		<div className="row">
			<div className="twelve columns">
				<div className="transparent">
					<h1 className="title">Interxion™ Zürich <span>Data Center</span></h1>
					<h2>Ultra-secure location for storing and processing data</h2>
				</div>
			</div>
		</div>
	</div>
	<div className="fullwidth light">
		<div className="row pagetitle">
			<div className="four columns">
				<h3>Interxion™ Zürich Campus</h3><p>Liberal economic system, strategic location right in the heart of Europe, long lasting stability, neutrality and political independence, competitive tax regime, highly educated workforce and strict laws on privacy and data protection make Switzerland the perfect country to host your mission critical infrastructure. This distinct combination of factors makes Interxion™ data center in Zurich – the largest of its kind in Switzerland – the perfect European base for your web property.</p>
				<h3>Certification &amp; Compliance</h3>
				<p>Interxion™ Zurich data center is an ultra-secure location for storing and processing data in line with Swiss data protection regulations. Zurich campus operates in full accordance with the <b>ITILv3</b> framework and has <b>ISO 27001</b> and <b>ISO 22301</b> certification. Compliance with the <b>FINMA circular 07/8</b> is audited externally.</p>
			</div>
			<div className="eight columns right">
				<div className='embed-container'><iframe title="Data center tour" src='https://www.youtube.com/embed/K_ZK_j4A28E' frameBorder='0' allowFullScreen></iframe></div>
			</div>
		</div>
	</div>
<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
	<FeatureSlider/>
</ReactDependentScript>	
<div className="fullwidth dark">
		<div className="row">
			<div className="twelve columns">
				<div className="panel nomargin bordered">
					<h3>Download Zurich Interxion™ Campus Fact Sheet</h3>
					<hr className="black" />
					<h5 className="nobold">Take a tour on all distinct advantages and features that make Interxion™ Zurich campus an ideal place to host your online business.</h5>
					<a className="button radius large" target="_blank" href="https://www.interxion.com/sites/default/files/2020-01/Zurich%20Campus%20Factsheet%20%281%29.pdf" rel="noopener noreferrer">Download PDF (1MB)</a>
				</div>
			</div>
		</div>
	</div>
</React.Fragment>
        )}}

export default DataCenter