import React, { useState, useEffect, useCallback } from 'react';
import { axiosinstance, axiosCatch } from '../../components/axios';
import Helmet from "react-helmet";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ErrorPrompt from '../../components/errorPrompt';

const TOS = (props) => {
	const [state, setState] = useState({
		tos: [],
		loading: false,
		error: false,
	});

	const load = useCallback(() => {
		setState((prevState) => ({ ...prevState, loading: true }));

		axiosinstance({
			method: "get",
			url: process.env.REACT_APP_BACKEND_URL + "/tos.json",
		})
			.then((response) => {
				if (response.status === 200) {
					if (response.data.result === true) {
						setState({
							tos: response.data,
							loading: false,
							error: false,
						});
					} else {
						setState({
							tos: [],
							error: true,
							loading: false,
						});
					}
				} else {
					setState({
						tos: [],
						error: true,
						loading: false,
					});
				}
			})
			.catch((args) => {
				setState({
					tos: [],
					error: true,
					loading: false,
				});
				axiosCatch(args, props);
			});
	}, [props]);

	useEffect(() => {
		load();
	}, [load]);

	const renderContent = (content, isNested = false) => {
		if (!content) return null;

		const contentArray = Array.isArray(content) ? content : [content];

		return contentArray.map((item, itemIndex) => {
			if (item.p) {
				return <p key={`p-${itemIndex}`}>{item.p}</p>;
			}

			if (item.ol) {
				return (
					<React.Fragment key={`ol-${itemIndex}`}>
						{isNested ? (
							<ol type={item.ol.type}>{renderContent(item.ol.items, true)}</ol>
						) : (
							<blockquote className="white">
								<ol type={item.ol.type}>{renderContent(item.ol.items, true)}</ol>
							</blockquote>
						)}
					</React.Fragment>
				);
			}

			if (item.ul) {
				return (
					<React.Fragment key={`ul-${itemIndex}`}>
						{isNested ? (
							<ul>{item.ul.map((li, liIndex) => <li key={`li-${liIndex}`}>{li.li}</li>)}</ul>
						) : (
							<blockquote className="white">
								<ul>{item.ul.map((li, liIndex) => <li key={`li-${liIndex}`}>{li.li}</li>)}</ul>
							</blockquote>
						)}
					</React.Fragment>
				);
			}

			if (item.li) {
				const liContent = typeof item.li === 'string' ? item.li : renderContent(item.li, true);
				return (
					<li key={`li-${itemIndex}`}>
						{liContent}
					</li>
				);
			}

			return null;
		});
	};

	return (

		<React.Fragment>
			<Helmet>
				<title>Terms of Service | Swiss Made Host</title>
				<meta name="description" content="These Terms of Service govern the relationship between SWISSMADE.HOST and its clients." />
				<meta name="robots" content="noindex" />
			</Helmet>
			<div className="fullwidth pic legal">
				<div className="row">
					<div className="twelve columns">
						<div className="transparent">
							<h1 className="title">Terms Of Service <span>SWISSMADE.HOST</span></h1>
							<h2>Terms and conditions of subscribing to our services</h2>
						</div>
					</div>
				</div>
			</div>
			<div className="fullwidth light">
				<div className="row flex-center">
					<div className="ten columns">
						<div className="panel light">
							{
								state.loading ? (
									<React.Fragment>
										<p><Skeleton style={{ height: '50px' }} /></p>
										<p><Skeleton style={{ height: '250px' }} /></p>
										<p><Skeleton style={{ height: '50px' }} /></p>
										<p><Skeleton style={{ height: '250px' }} /></p>
										<p><Skeleton style={{ height: '50px' }} /></p>
										<p><Skeleton style={{ height: '250px' }} /></p>
									</React.Fragment>
								) : state.error ? (
									<React.Fragment>
										<ErrorPrompt />
									</React.Fragment>
								) : (
									state.tos.result && (
										<React.Fragment>
											{state.tos.data.text.map((section, index) => (
												<React.Fragment key={index}>
													<h2>{section.subtitle}</h2>
													{section.content && renderContent(section.content)}
												</React.Fragment>
											))}
										</React.Fragment>
									)
								)
							}
						</div>
					</div>
				</div>
			</div>

		</React.Fragment>
	);
}

export default TOS;