import React, { Component } from 'react';

class ObjectStorageProductList extends Component {

    render() {

        const list = this.props.list,
            usdRate = this.props.usdRate,
            eurRate = this.props.eurRate

        return (
            <div className="fullwidth light">
                <div className="row">
                    <div className="twelve columns">
                        {list.map((item, i) =>
                            <div className='one-liner-plan' key={i}>
                                <div className='plan-row'>
                                    <div className="header">
                                        <h4>{item.name} - Zurich, Switzerland</h4>
                                    </div>
                                </div>
                                <div className='plan-row'>
                                    <div className="feature">
                                        <strong>Storage</strong><span>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price_by_size)} <small>(USD {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.price_by_size * usdRate)} / EUR {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.price_by_size * eurRate)})</small></span>
                                        <div className='price-description'>per GB per month</div>
                                    </div>
                                    <div className="feature">
                                        <strong>Networking out</strong><span>{item.price_by_traffic_sent === 0 ? 'Free' : <React.Fragment>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price_by_traffic_sent)} <small>(USD {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.price_by_traffic_sent * usdRate)} / EUR {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.price_by_traffic_sent * eurRate)})</small></React.Fragment>}</span>
                                        <div className='price-description'>per GB per month</div>
                                    </div>
                                    <div className="feature">
                                        <strong>Networking In</strong><span>{item.price_by_traffic_received === 0 ? 'Free' : <React.Fragment>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price_by_traffic_received)} <small>(USD {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.price_by_traffic_received * usdRate)} / EUR {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.price_by_traffic_received * eurRate)})</small></React.Fragment>}</span>
                                    </div>
                                    <div className="feature">
                                        <strong>API Type</strong><span>S3-compatible</span>
                                    </div>
                                    <div className="feature">
                                        <strong>API operations</strong><span>{item.price_by_operations === 0 ? 'Free' : <React.Fragment>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price_by_operations)} <small>(USD {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.price_by_operations * usdRate)} / EUR {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.price_by_operations * eurRate)})</small></React.Fragment>}</span>
                                    </div>
                                    <div className="feature">
                                        <strong>Setup fee</strong><span>{item.setup_fee === 0 ? 'Free' : <React.Fragment>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.setup_fee)} <small>(USD {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.setup_fee * usdRate)} / EUR {new Intl.NumberFormat("de-CH", { style: "decimal", maximumFractionDigits: 2, }).format(item.setup_fee * eurRate)})</small></React.Fragment>}</span>
                                    </div>
                                    <div className="feature">
                                        <strong>Availability SLA</strong><span>99.9%</span>
                                    </div>
                                </div>
                                <div className='plan-row'>
                                    <div className="feature">
                                        <a className="button button-primary" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + item.id}>Configure</a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default ObjectStorageProductList;
