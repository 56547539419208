import React from 'react';
import {axiosinstance, axiosCatch} from '../../components/axios';
import FAQpreloader from './faqPreloader';
import LoadingError from '../../components/loadingError';
import lscache from 'lscache';
import Category from './category';

class DynamicContent extends React.Component {

	constructor(props, context) {
		super(props, context);

        this.state = {
            faq: []
        }
    }

	componentDidMount () {

		this.load();
	}

	load = () => {

        this.setState({
            loading: true
        }, () => {

            if (lscache.get('faq') === null)
            {
            axiosinstance({
            	method: 'get',
            	url: process.env.REACT_APP_BACKEND_URL + "/faq.en.json"
            })
                .then((response) => {
                    if (response.status === 200) {
                            lscache.set('faq', response.data.list, 10);
                            this.setState({
								faq: response.data.list,
                                loading: false
                            })
                    } else {
                        this.setState({
                            error: true,
                            loading: false
                        });
                    }
                }).catch((args) => {
                axiosCatch(args, this.props);
            });
        }
        else {
            this.setState({
                faq: lscache.get('faq'),
                loading: false
                })
        }
        });
	};

	render() {          

        if (this.state.loading) {
            return (
					<FAQpreloader/>
            )
        }
        if (this.state.error) {
            return (

                <LoadingError/>
            )
        } else {

			return (
            <Category faq={this.state.faq} />
	)}}}

export default DynamicContent;
