import React from 'react';
import $ from 'jquery';
import { NavLink } from "react-router-dom";

class BlogSidebar extends React.Component {

	componentDidMount() {
		showTags();
	}
	render() {
		const blogOverall = this.props.blogOverall;

		return (
			<React.Fragment>
				<div className='row'>
					<h4>Recent posts</h4>
					<hr className="grey" />
					<ul className="arrow">
						{blogOverall.recent_posts.map((item, i) =>
							<li key={i}>
								<NavLink to={'/en/blog/' + item.slug}>{item.title}</NavLink>
							</li>
						)}
					</ul>
				</div>
				<div className="row">
					<div className="twelve columns">
						<div className="dividerimg"></div>
					</div>
				</div>
				<div className='row'>
					<h4>Popular posts</h4>
					<hr className="grey" />
					<ul className="arrow">
						{blogOverall.popular.map((item, i) =>
							<li key={i}>
								<NavLink to={'/en/blog/' + item.slug}>{item.title}</NavLink>
							</li>
						)}
					</ul>
				</div>
				<div className="row">
					<div className="twelve columns">
						<div className="dividerimg"></div>
					</div>
				</div>
				<div className='row'>
					<h4>Categories</h4>
					<hr className="grey" />
					<ul className="arrow">
						{blogOverall.categories.map((item, i) =>
							<li key={i}>
								<NavLink to={'/en/blog?category=' + encodeURIComponent(item)}>{item}</NavLink>
							</li>
						)}
					</ul>
				</div>
				<div className="row">
					<div className="twelve columns">
						<div className="dividerimg"></div>
					</div>
				</div>
				<div className='row'>
					<h4>Tags</h4>
					<hr className="grey" />
					<ul className="arrow tags">
						{blogOverall.tags.map((item, i) =>
							<li key={i}>
								<NavLink to={'/en/blog?tag=' + encodeURIComponent(item.tag)}>{item.tag}</NavLink>
							</li>
						)}
					</ul>
					<p><button className="button viewtags"><i className="fas fa-chevron-down icon-left"></i> <span id="tagText">Show more tags</span></button></p>
				</div>
			</React.Fragment>
		)
	}
}

function showTags() {
	$('.viewtags').click(function () {
		var maxHeight = $('.tags').prop('scrollHeight');
		if ($('.tags').hasClass('expanded')) {
			$('.tags').animate({
				height: '20em'
			}, 'slow');
			$('#tagText').text('Show more tags');
			$('.viewtags i').removeClass('fa-chevron-up');
			$('.viewtags i').addClass('fa-chevron-down');
			$('.tags').removeClass('expanded');
		} else {
			$('.tags').animate({
				height: maxHeight
			}, 'slow');
			$('.tags').addClass('expanded');
			$('#tagText').text('Show fewer tags');
			$('.viewtags i').removeClass('fa-chevron-down');
			$('.viewtags i').addClass('fa-chevron-up');
		}
		return false;
	});
}

export default BlogSidebar