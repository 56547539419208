import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';

class AddonsDedicatedServers extends Component {

    render() {
        const addons = this.props.addons

        if(!addons) {
            return (
                <div className="fullwidth dark">
                    <div className="row">
                        <div className="twelve columns">
                            <h3>No Available Addons</h3>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="twelve columns">
                            <p>There are no add-ons available for this type of products at the moment.</p>
                        </div>
                    </div>
                </div>
            )
        }

        const addonsMap = addons.reduce((acc, item) => ({
            ...acc,
            [item.key]: item
        }), {});

        return (
            <div className="fullwidth dark">
                {('operating-system' in addonsMap) && (
                    <React.Fragment>
                <div className="row">
                    <div className="twelve columns">
                        <h3>Operating Systems</h3>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="four columns">
                    <p>Select an operating system to be installed on your server while configuring your order. If a desired operating system is not present on the list, please feel free to install it with your own image via KVM/IPMI once your server is deployed, or contact a support engineer for assistance.	</p><p>You'll be provided with SSH login credentials for all Linux/Unix servers upon setup completion. Please note that all prices are monthly.</p>
                    </div>
                    <div className="eight columns addons">
                        <div className="row">
                                    {addonsMap['operating-system'].items.sort((a, b) => a.price - b.price).map((item,i) =>
                                    item.id === 52 ? null :
                                    <div className="twelve columns" key={i}>
                                            <div className="name six columns">{item.name}</div>
                                            {item.price === 0
                                                ? <div className="price six columns"><span className='base'>Free</span></div>
                                                : <React.Fragment>
                                                    <div className="price six columns">
                                                    <span className='base' style={{cursor:'default'}} data-tip data-for={item.id.toString()}>{item.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                                    <ReactTooltip id={item.id.toString()} effect='solid'>
                                                    <span>{(item.price * this.props.usdRate).toFixed(2)} USD</span>
                                                    <span>{(item.price * this.props.eurRate).toFixed(2)} EUR</span>
                                                    </ReactTooltip>
                                                    </div>
                                                </React.Fragment>
                                            }
                                    </div>
                                    )}
                        </div>
                    </div>
                </div>
                </React.Fragment>
                )}
                {('operating-system' in addonsMap) && (
                    <React.Fragment>
                <div className="row">
                    <div className="twelve columns">
                        <h3>Control Panel</h3>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="four columns">
                    <p>Control panel is a web-based application that makes running and managing a dedicated server a less complicated and demanding process. Kindly note, cPanel is available for servers running CentOS only.</p>
                    </div>
                    <div className="eight columns addons">
                        <div className="row">
                                    {addonsMap['control-panel'].items.sort((a, b) => a.price - b.price).map((item,i) =>
                                    item.id === 52 ? null :
                                    <div className="twelve columns" key={i}>
                                            <div className="name six columns">{item.name}</div>
                                            {item.price === 0
                                                ? <div className="price six columns"><span className='base'>Free</span></div>
                                                : <React.Fragment>
                                                    <div className="price six columns">
                                                    <span className='base' style={{cursor:'default'}} data-tip data-for={item.id.toString()}>{item.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                                    <ReactTooltip id={item.id.toString()} effect='solid'>
                                                    <span>{(item.price * this.props.usdRate).toFixed(2)} USD</span>
                                                    <span>{(item.price * this.props.eurRate).toFixed(2)} EUR</span>
                                                    </ReactTooltip>
                                                    </div>
                                                </React.Fragment>
                                            }
                                    </div>
                                    )}
                        </div>
                    </div>
                </div>
                </React.Fragment>
                )}
                {('additional-bandwidth' in addonsMap || 'bandwidth-overage' in addonsMap) && (
                    <React.Fragment>
                <div className="row">
                    <div className="twelve columns">
                        <h3>Bandwidth &amp; IP</h3>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="four columns">
                    <p>Should you need to increase bandwidth limit or order a few more IP addresses, please update your order or submit a new query to the customer support desk. Kindly be advised that you'll be asked to provide a reason for obtaining extra addresses when ordering more than 3 IPs.</p>
                    </div>
                    <div className="eight columns addons">
                        <div className="row">
                            {('additional-bandwidth' in addonsMap) && (
                                <div className="twelve columns">
                                    <div className="name six columns">Additional bandwidth ({addonsMap['additional-bandwidth'].items.units_each})</div>
                                    {addonsMap['additional-bandwidth'].items.price === 0
                                        ? <div className="price six columns"><span className='base'>Free</span></div>
                                        : <React.Fragment>
                                    <div className="price six columns">
                                    <span className='base' style={{cursor:'default'}} data-tip data-for={toString(addonsMap['additional-bandwidth'].items.id)}>{addonsMap['additional-bandwidth'].items.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                    <ReactTooltip id={toString(addonsMap['additional-bandwidth'].items.id)} effect='solid'>
                                    <span>{(addonsMap['additional-bandwidth'].items.price * this.props.usdRate).toFixed(2)} USD</span>
                                    <span>{(addonsMap['additional-bandwidth'].items.price * this.props.eurRate).toFixed(2)} EUR</span>
                                    </ReactTooltip>
                                    </div>
                                     </React.Fragment>
                                  }
                                </div>
                            )}
                            {('bandwidth-overage' in addonsMap) && (
                                <div className="twelve columns">
                                    <div className="name six columns">Bandwidth overage ({addonsMap['bandwidth-overage'].items.units_each})</div>
                                    {addonsMap['bandwidth-overage'].items.price === 0
                                        ? <div className="price six columns"><span className='base'>Free</span></div>
                                        : <React.Fragment>
                                    <div className="price six columns">
                                    <span className='base' style={{cursor:'default'}} data-tip data-for={toString(addonsMap['bandwidth-overage'].items.id)}>{addonsMap['bandwidth-overage'].items.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                    <ReactTooltip id={toString(addonsMap['bandwidth-overage'].items.id)} effect='solid'>
                                    <span>{(addonsMap['bandwidth-overage'].items.price * this.props.usdRate).toFixed(2)} USD</span>
                                    <span>{(addonsMap['bandwidth-overage'].items.price * this.props.eurRate).toFixed(2)} EUR</span>
                                    </ReactTooltip>
                                    </div>
                                     </React.Fragment>
                                  }
                                </div>
                            )}
                            {('additional-ips' in addonsMap) && (
                                <div className="twelve columns">
                                    <div className="name six columns">Additional IP addresses ({addonsMap['additional-ips'].items.units_each})</div>
                                    {addonsMap['additional-ips'].items.price === 0
                                        ? <div className="price six columns"><span className='base'>Free</span></div>
                                        : <React.Fragment>
                                    <div className="price six columns">
                                    <span className='base' style={{cursor:'default'}} data-tip data-for={toString(addonsMap['additional-ips'].items.id)}>{addonsMap['additional-ips'].items.price} CHF <i className="fa-regular fa-circle-question"></i></span>
                                    <ReactTooltip id={toString(addonsMap['additional-ips'].items.id)} effect='solid'>
                                    <span>{(addonsMap['additional-ips'].items.price * this.props.usdRate).toFixed(2)} USD</span>
                                    <span>{(addonsMap['additional-ips'].items.price * this.props.eurRate).toFixed(2)} EUR</span>
                                    </ReactTooltip>
                                    </div>
                                     </React.Fragment>
                                  }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                </React.Fragment>
                )}
            </div>
        )
    }}

    export default AddonsDedicatedServers;
