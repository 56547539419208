import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import AboutOS from './aboutObjectStorage';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import OffshoreSwitzerland from '../offshore-hosting/offshoreSwitzerland';

class ObjectStorageOffshore extends React.Component {

	render() {

		return (

			<React.Fragment>
				<Helmet>
					<title>Offshore Object Storage S3 | Swiss Made Host</title>
					<meta name="description" content="Secure and highly scalable offshore object storage in Switzerland with S3 compatible API. Store and manage your data with ease while ensuring data durability and accessibility." />
					<link rel="canonical" href="https://swissmade.host/en/offshore-object-storage" />
				</Helmet>
				<div className="fullwidth pic object-storage">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Offshore <span>Object Storage </span></h1>
								<h2>Privacy-focused Object Storage with S3-compatible API</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<OffshoreSwitzerland />
				<AboutOS/>
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider />
				</ReactDependentScript>
				<DynamicContent />
				<PaymentMethodsInclude />
		</React.Fragment >
	)
	}
}

export default ObjectStorageOffshore;