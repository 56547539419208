import React from 'react';

class SystemMessages extends React.Component {

     render() {
        if (this.props.rated === 'up' || this.props.rated === 'down')
        {
            return (
                <div className="rcw-system-message">
        <p>{this.props.message} <span style={{fontSize: '1.2em', paddingLeft: '3px'}}>{this.props.rated === 'up' ? <i className="far fa-thumbs-up"></i> : <i className="far fa-thumbs-down"></i>}</span></p>
                </div>
                            )
        }

        if (this.props.value === 'quit')
        {
            return (
                <div className="rcw-system-message">
        <p>{this.props.message} <span style={{fontSize: '1.2em', paddingLeft: '3px'}}><i className="fas fa-sign-out-alt"></i></span></p>
                </div>
                            )
        }

        if (this.props.value === 'file')
        {
            return (
                <div className="rcw-system-message">
        <p>{this.props.message} <span style={{fontSize: '1.2em', paddingLeft: '3px'}}><i className="fas fa-check-double"></i></span></p>
                </div>
                            )
        }

        if (this.props.sounds === 'ON' || this.props.sounds === 'OFF')
        {
            return (
                <div className="rcw-system-message">
        <p>{this.props.message} {this.props.sounds} <span style={{fontSize: '1.2em', paddingLeft: '3px'}}>{this.props.sounds === 'ON' ? <i className="fas fa-volume-up"></i> : <i className="fas fa-volume-mute"></i>}</span></p>
                </div>
                            )
        }

        if (this.props.network === 'error')
        {
            return (
                <div className="rcw-system-message networkError">
        <p>{this.props.message} {this.props.sounds} <span style={{fontSize: '1.2em', paddingLeft: '3px'}}><i className="fas fa-spinner fa-spin"></i></span></p>
                </div>
                            )
        }

        else {
        return (
<div className="rcw-system-message">
    <p>{this.props.message}</p>
</div>
            )}}}

export default SystemMessages
