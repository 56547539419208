import React from 'react';
import OwlCarousel2 from 'react-owl-carousel2';
import { NavLink } from 'react-router-dom';
import { toggleWidget } from 'react-chat-widget';

const owloptions = {
    loop: true,
    dots: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 2,
    slideBy: 1,
    responsive: {
        0: {
            items: 1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        800: {
            items: 1,
            slideBy: 1,
            autoplayTimeout: 5000
        },
        1000: {
            items: 2,
            slideBy: 1,
            autoplayTimeout: 5000
        }
    }
};


class FeatureSlider extends React.Component {

    render() {

        return (

            <div className="fullwidth light">
                <div className="row">
                    <div className="four columns">
                        <h2>Why Us?</h2>
                        <p>Only best products of Switzerland deserve the privilege to be awarded with the Swiss Made label. We make great efforts to see the business through the eyes of our customers, understand their expectations and needs and develop products and services that would meet or exceed the highest industry standards.</p>
                        <NavLink className="readmore left" to="/en/about-us">About us <i className="fa-regular fa-square-caret-right"></i></NavLink>
                    </div>
                    <div className="eight columns whyUs">
                        <OwlCarousel2 options={owloptions}>
                            <div className="item">
                                <h5>Redundant Power Supply</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><span className="fa-stack fa-3x">
                                    <i className="fa-regular fa-square fa-stack-2x"></i>
                                    <i className="fa fa-plug fa-stack-1x"></i>
                                </span>
                                </div>
                                <p>Uninterrupted power supply is the key to the record-setting availability. These are the measures taken to guarantee power supply security: two different power providers, two independent power feeds to each rack, industrial-grade UPS with auto switchover and diesel generators ready for indefinite running in case of a serious blackout.</p>
                            </div>
                            <div className="item">
                                <h5>Premium Networking &amp; Connectivity</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><span className="fa-stack fa-3x">
                                    <i className="fa-regular fa-square fa-stack-2x"></i>
                                    <i className="fa-solid fa-signal fa-stack-1x"></i>
                                </span>
                                </div>
                                <p>We have put a lot of effort into building a no single point of failure network architecture: redundant hardware with automatic failover protection, multiple Tier-1 bandwidth providers, direct connection to SwissIX Internet exchange point, professional DDoS protection, automatic network health monitoring and 24/7/365 intelligent hands on site.</p>
                            </div>
                            <div className="item">
                                <h5>Seven-layer Physical Security</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa fa-lock fa-5x" aria-hidden="true"></i></div>
                                <p>Physical security is as important as IT security when it comes to choosing the right data center to collocate your mission critical hardware. 24/7 video surveillance and security patrols, multiple access barriers with biometric readers and mantraps render unauthorized access to servers and networking hardware absolutely impossible.</p>
                            </div>
                            <div className="item">
                                <h5>DDoS Protection</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa fa-shield-halved fa-5x" aria-hidden="true"></i></div>
                                <p>DDoS attack can disrupt operation of almost any web property, even a well established one. Please don't worry about this unlikely scenario, we've got you covered. Our engineers have developed a proprietary solution that will <NavLink to="/en/ddos-protection-switzerland">protect your servers</NavLink> against 99.999% of DDoS attacks registered to this day.</p>
                            </div>
                            <div className="item">
                                <h5>LIR &amp; RIPE NCC Accredited</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa fa-check-circle fa-5x" aria-hidden="true"></i></div>
                                <p>Our company is accredited by LIR (Local Internet Registry) and involved in allocation of IP address ranges and <a target="_blank" href="https://stat.ripe.net/AS197988" rel="noopener noreferrer">AS by RIPE NCC</a> (The Réseaux IP Européens Network Coordination Centre) - the regional Internet registry for Europe that allocates and registers blocks of IP addresses to Internet service providers (ISPs) and other organizations.</p>
                            </div>
                            <div className="item">
                                <h5>Data Center Certification</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa fa-certificate fa-5x" aria-hidden="true"></i></div>
                                <p>Interxion™ Zurich data center is an ultra-secure location for storing and processing data in line with Swiss data protection regulations. Zurich campus operates in full accordance with the ITILv3 framework and has <a href="https://www.iso.org/isoiec-27001-information-security.html" target="_blank" rel="noopener noreferrer">ISO 27001</a> and <a href="https://www.iso.org/standard/50038.html" target="_blank" rel="noopener noreferrer">ISO 22301</a> information security certification. Compliance with the FINMA circular 07/8 is audited externally.</p>
                            </div>
                            <div className="item">
                                <h5>Direct Connection to SwissIX</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa fa-upload fa-5x" aria-hidden="true"></i></div>
                                <p>Our data center in Zurich has direct connection to <a title="SwissIX Swiss Internet Exchange" href="https://www.swissix.ch/" target="_blank" rel="noopener noreferrer">SwissIX</a> Internet Exchange, which is the largest Internet Exchange Point (IXP) in Switzerland in terms of peering participants. Direct IXP connection lowers latency by improving routing efficiency and provides an extra layer of networking fault-tolerance.</p>
                            </div>
                            <div className="item">
                                <h5>Minimum Carbon Footprint</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa fa-leaf fa-5x" aria-hidden="true"></i></div>
                                <p>We strive to set an example of responsible approach to energy and keep our Power Usage Effectiveness (PUE) exceptional by any standards. We use energy originating from renewable sources only and our facility in Zurich is the first and the only carbon-neutral data center in Switzerland certified by myclimate®.</p>
                            </div>
                            <div className="item">
                                <h5>Swiss Approach</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa fa-plus-square fa-5x colored" aria-hidden="true"></i></div>
                                <p>At SwissMade.host, we are proud to be a Zurich-based web hosting company that shares the most distinct features that Switzerland is renowned for. We guarantee that your orders and requests will be processed in accordance with our company's strict privacy, data protection, customer orientation and quality assurance policies.</p>
                            </div>
                            <div className="item">
                                <h5>Customer Satisfaction</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa fa-star fa-5x" aria-hidden="true"></i></div>
                                <p>We put a lot of efforts into developing services and platforms of exceptional quality that deserve to be labeled <i>Swiss Made</i>. We are proud that our clients value our efforts and are happy to report a high customer satisfaction rate. As of September 2019 <span itemScope itemType="http://schema.org/Organization"><span itemProp="name">Swiss Made Host</span><span itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating"> <a href="https://www.whtop.com/review/swissmade.host" title="Swiss Made Host Reviews" target="_blank" rel="noopener noreferrer">has been awarded</a> an average rating of <span itemProp="ratingValue">9.5</span> out of <span itemProp="bestRating">10</span> by <span itemProp="ratingCount">10</span> reviewers.</span></span></p>
                            </div>
                            <div className="item">
                                <h5>24/7 Live Support</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa fa-headphones fa-5x" aria-hidden="true"></i></div>
                                <p><span className="swissmade">Swiss <i className="fa fa-plus-square" aria-hidden="true"></i> Made</span> is a customer oriented company. We strive to hold a very high customer satisfaction rate and provide live around-the-clock sales and technical support. Should you have any questions, please text us through the <a href="/en/" onClick={function (event) { event.preventDefault(); toggleWidget() }} title="Start a chat">live chat</a> widget to get an immediate assistance or open a new support ticket inside your <a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}>account area</a>.</p>
                            </div>
                            <div className="item">
                                <h5>We are on Twitter!</h5>
                                <hr className="grey" />
                                <div className="left paddingright"><i className="fa-brands fa-twitter fa-5x" aria-hidden="true"></i></div>
                                <p>We believe that our Twitter account is an excellent way for us to engage with you and keep you informed of our latest news and updates. If you have any questions, comments, or feedback, don't hesitate to reach out to us through direct messages or tweets. We're here to help and would love to <a href="https://twitter.com/SwissMadeHOST" rel="noopener noreferrer nofollow" target="_blank">connect with you</a>.</p>
                            </div>
                        </OwlCarousel2>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeatureSlider