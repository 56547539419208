import React from 'react';
import { NavLink, withRouter } from "react-router-dom";

class Navigation extends React.Component {

  componentDidMount() {
    this.Navcripts();
  }

  Navcripts() {
    const navDropdown = document.querySelectorAll('.nav-dropdown');
    const nestedLinkTitles = document.querySelectorAll('nav ul li.nested a.title');
    const toggleBtn = document.querySelector('#nav-toggle');
    const navList = document.querySelector('nav ul');

    nestedLinkTitles.forEach(link => {
      link.addEventListener('click', (e) => {
        if (window.innerWidth <= 1275) {
          e.preventDefault()
          navDropdown.forEach(el => el.style.display = 'none');
          if (link.parentNode.querySelector('.nav-dropdown').style.display === 'block') {
            link.parentNode.querySelector('.nav-dropdown').style.display = 'none';
          } else {
            link.parentNode.querySelector('.nav-dropdown').style.display = 'block';
          }
          e.stopPropagation();
          return false;
        }
      });
    });

    var nestedLinks = document.querySelectorAll("nav ul li.nested a");

    for (var i = 0; i < nestedLinks.length; i++) {
      nestedLinks[i].addEventListener("mouseenter", function (e) {
        if (window.innerWidth >= 1275) {
          var parent = this.closest("li.nested");
          var dropdown = parent.querySelector(".nav-dropdown");
          dropdown.style.display = "block";
          e.stopPropagation();
        }
      });
      nestedLinks[i].addEventListener("mouseleave", function (e) {
        if (window.innerWidth >= 1275) {
          var dropdowns = document.querySelectorAll(".nav-dropdown");
          for (var j = 0; j < dropdowns.length; j++) {
            dropdowns[j].style.display = "none";
          }
          e.stopPropagation();
        }
      });
    }

    toggleBtn.addEventListener('click', () => {
      navList.style.display === 'block' ? navList.style.display = 'none' : navList.style.display = 'block';
      toggleBtn.classList.toggle('active');
    });

    document.querySelectorAll('.nav-list li:not(.nested) a').forEach((link, event) => {
      link.addEventListener('click', () => {
        toggleBtn.click();
      });
    });
  }

  productsHighlight() {
    return ['/en/cpanel-switzerland', '/en/offshore-hosting', '/en/colocation-switzerland', '/en/offshore-colocation', '/en/free-speech-colocation', '/en/free-speech-hosting', '/en/cdn-switzerland', '/en/ddos-protection-switzerland', '/en/offshore-ddos-protection', '/en/cms-hosting-switzerland', '/en/offshore-cms-hosting', '/en/free-speech-cms-hosting', '/en/wordpress-hosting-switzerland', '/en/joomla-hosting-switzerland', '/en/drupal-hosting-switzerland', '/en/offshore-wordpress-hosting', '/en/object-storage-switzerland', '/en/offshore-object-storage', '/en/free-speech-object-storage'].includes(this.props.location.pathname)
      ? 'title active'
      : 'title'
  }
  dedicatedServersHighlight() {
    const regexes = [
      /^\/en\/dedicated-server\/switzerland\//
    ];
    const path = this.props.location.pathname;
    const regexMatch = regexes.some((regex) => regex.test(path));

    return (regexMatch ||
      ['/en/offshore-dedicated-servers', '/en/free-speech-dedicated-servers'].includes(path))
      ? 'title active'
      : 'title';
  }

  virtualServersHighlight() {
    const regexes = [
      /^\/en\/vps\/switzerland\//
    ];
    const urls = [
      '/en/vps-switzerland',
      '/en/cloud-switzerland',
      '/en/offshore-vps',
      '/en/offshore-cloud',
      '/en/free-speech-vps',
      '/en/free-speech-cloud'
    ];
    return [...urls, ...regexes].some(pattern => {
      if (pattern instanceof RegExp) {
        return pattern.test(this.props.location.pathname);
      } else {
        return pattern === this.props.location.pathname;
      }
    }) ? 'title active' : 'title';
  }
  vpnHighlight() {
    return ['/en/offshore-vpn', '/en/free-speech-vpn', '/en/openvpn-switzerland', '/en/wireguard-vpn-switzerland', '/en/shadowsocks-vpn-switzerland'].includes(this.props.location.pathname)
      ? 'title active'
      : 'title'
  }
  aboutHighlight() {
    return ['/en/about-us', '/en/contact-us', '/en/data-center-switzerland', '/en/privacy-policy', '/en/acceptable-use-policy', '/en/terms-of-service', '/en/knowledgebase', '/en/service-level-agreement', '/en/accepted-payment-methods', '/en/technology', '/en/blog/The-Advantages-of-Hosting-Your-Data-in-Switzerland', '/en/blog', '/en/data-protection-switzerland'].includes(this.props.location.pathname)
      ? 'title active'
      : RegExp('\\bblog\\b').test(this.props.location.pathname)
        ? 'title active'
        : 'title'
  }

  render() {
    return (
      <div className="row fullwidth navRow">
        <div className="navigation">
          <div className="nav-container">
            <div className="brand">
              <NavLink className="logo" to="/en/" title="SWISSMADE.HOST - The Privacy-First Hosting Provider"><span className="body"><span className="a">SWISS</span><img
                alt="SWISSMADE.HOST Logo" loading="lazy" width="20" height="20" decoding="async"
                src="/images/swiss-made-host-logo.svg" /><span className="b">MADE</span></span>
                <span className="motto">The Privacy-First	Hosting	Provider</span>
              </NavLink>
            </div>
            <nav>
              <div className="nav-mobile"><a id="nav-toggle" href='/' onClick={(event) => { event.preventDefault() }}><span></span></a></div>
              <ul className="nav-list">
                <li>
                  <NavLink to="/en/dedicated-servers-switzerland" className={this.dedicatedServersHighlight()}>Dedicated Servers</NavLink>
                </li>
                <li className="nested">
                  <NavLink to="/en/vps-switzerland" className={this.virtualServersHighlight()}>Virtual Servers <i className="fa fa-caret-down" aria-hidden="true"></i></NavLink>
                  <ul className="nav-dropdown">
                    <li>
                      <NavLink to="/en/vps-switzerland">VPS</NavLink>
                    </li>
                    <li>
                      <NavLink to="/en/cloud-switzerland">Cloud</NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nested">
                  <NavLink to="/en/vpn-switzerland" className={this.vpnHighlight()}>VPN <i className="fa fa-caret-down" aria-hidden="true"></i></NavLink>
                  <ul className="nav-dropdown">
                    <li>
                      <NavLink to="/en/openvpn-switzerland">OpenVPN</NavLink>
                    </li>
                    <li>
                      <NavLink to="/en/wireguard-vpn-switzerland">WireGuard</NavLink>
                    </li>
                    <li>
                      <NavLink to="/en/shadowsocks-vpn-switzerland">Shadowsocks</NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nested">
                  <NavLink to="/en/products" className={this.productsHighlight()}>Other Products <i className="fa fa-caret-down" aria-hidden="true"></i></NavLink>
                  <ul className="nav-dropdown">
                    <li><NavLink to="/en/colocation-switzerland">Colocation</NavLink></li>
                    <li><NavLink to="/en/ddos-protection-switzerland">DDoS Protection</NavLink></li>
                    <li><NavLink to="/en/cdn-switzerland">CDN</NavLink></li>
                    <li><NavLink to="/en/cpanel-switzerland">Control Panels</NavLink></li>
                    <li><NavLink to="/en/cms-hosting-switzerland">CMS Hosting</NavLink></li>
                    <li><NavLink to="/en/object-storage-switzerland">Object Storage</NavLink></li>
                    <li><NavLink to="/en/offshore-hosting">Offshore Hosting</NavLink></li>
                    <li><NavLink to="/en/free-speech-hosting">Free Speech Hosting</NavLink></li>
                  </ul>
                </li>
                <li className="nested">
                  <NavLink to="/en/about-us" className={this.aboutHighlight()}>About <i className="fa fa-caret-down" aria-hidden="true"></i></NavLink>
                  <ul className="nav-dropdown">
                    <li><NavLink to="/en/about-us">About Us</NavLink></li>
                    <li><NavLink to="/en/data-center-switzerland">Data Center</NavLink></li>
                    <li><NavLink to="/en/technology" title="Learn more about the cutting edge technology we utilize to provide the best hosting experience possible.">Technology</NavLink></li>
                    <li><NavLink to="/en/data-protection-switzerland" title="Learn how we protect your information and maintain data security.">Data Protection</NavLink></li>
                    <li><NavLink to="/en/blog/The-Advantages-of-Hosting-Your-Data-in-Switzerland">Why Host in Switzerland</NavLink></li>
                    <li><NavLink to="/en/service-level-agreement">Service-level Agreement</NavLink></li>
                    <li><NavLink to="/en/terms-of-service">Terms of Service</NavLink></li>
                    <li><NavLink to="/en/acceptable-use-policy">Acceptable Use Policy</NavLink></li>
                    <li><NavLink to="/en/privacy-policy">Privacy Policy</NavLink></li>
                    <li><NavLink to="/en/accepted-payment-methods">Payment Methods</NavLink></li>
                    <li><a href="https://speedtest.swissmade.host">Speed Test</a></li>
                    <li><NavLink to="/en/blog">Blog &amp; News</NavLink></li>
                    <li><NavLink to="/en/knowledgebase">Knowledge Base</NavLink></li>
                    <li><NavLink to="/en/contact-us">Contact Us</NavLink></li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Navigation);
