import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import DiscountCounter from './dscountCounter';
import AboutCMS from './aboutCMS';
import FreeSSL from './freeSSL'

class CMSswitzerland extends React.Component {

	render() {

		const cms = 'all',
			richData = {
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"name": "CMS Hosting in Switzerland",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What is CMS?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "A CMS, or Content Management System, is a software application that allows users to create, manage, and modify digital content on a website. It provides a user-friendly interface and simplifies the process of organizing and displaying content, making it easier for non-technical users to maintain their websites."
						}
					},
					{
						"@type": "Question",
						"name": "What is CMS hosting?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "CMS hosting is a web hosting service specifically designed for Content Management Systems (CMS) such as WordPress, Joomla, or Drupal. These hosting services are optimized for performance, security, and ease of use with the CMS platforms, providing seamless installation, updates, and maintenance. CMS hosting typically includes features like one-click installations, automatic backups, and built-in caching, making it easier for users to manage and maintain their websites or applications built on a CMS platform."
						}
					},
					{
						"@type": "Question",
						"name": "What are the benefits of CMS hosting in Switzerland?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "CMS hosting in Switzerland offers several benefits, such as strong data privacy laws, excellent infrastructure, high-speed connectivity, and a central European location. The country's strict data protection regulations ensure that your data is secure and private. Switzerland's advanced infrastructure and connectivity provide reliable, high-performance hosting services. The central location in Europe ensures low latency and fast access to your website or applications for users across the continent."
						}
					},
					{
						"@type": "Question",
						"name": "What types of CMS are supported?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The supported CMS platforms for the hosting plans are WordPress, Drupal, and Joomla. These are popular and widely-used content management systems that provide a variety of features and flexibility for website creation and management."
						}
					},
					{
						"@type": "Question",
						"name": "What are the benefits of a dedicated IP and resources?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "A dedicated IP address and resources provide better performance, security, and control for your website. With a dedicated IP, your site is not affected by the actions of other users sharing the same IP. This can lead to better reputation, easier access to your website, and improved SEO. Dedicated resources, such as CPU cores and RAM, ensure that your website has consistent performance and is not affected by resource usage of other websites on the same server."
						}
					},
					{
						"@type": "Question",
						"name": "How can I save on longer-term CMS hosting deals?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The longer the billing period, the higher your discount will be. Move the slider thumb on the discount slider to see how much you can save on the longer-term CMS hosting deal."
						}
					},
					{
						"@type": "Question",
						"name": "What are the CMS hosting plans offered?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Three CMS hosting plans are offered: CMS Starter, CMS Essential, and CMS Prime. These plans have different configurations such as the number of cores, storage capacity, RAM, and CMS compatibility. All plans are hosted on private servers located in Switzerland, offer dedicated IP addresses, and include free SSL certificates. Crypto payments are accepted for these plans."
						}
					},
					{
						"@type": "Question",
						"name": "How do I set up a free SSL certificate for my CMS?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "In order to set up a free SSL certificate on your CMS server, complete the following steps. First, direct your domain to the IP address of your CMS. Then, navigate to your order management section and press the 'Let's Encrypt SSL' button. This will trigger the issuance of a Let's Encrypt SSL certificate, which will be automatically installed on your server to enhance the security of your CMS and website. Additionally, if you have your own SSL certificate, you can configure it in your member account area as well."
						}
					}]
			}

		return (
			<React.Fragment>
				<Helmet>
					<title>CMS Hosting in Switzerland - Dedicated IP &amp; Private Server | Swiss Made Host</title>
					<meta name="description" content="Content Management System Hosting in Switzerland with dedicated resources and IP address. Powered by WordPress, Joomla and Drupal." />
					<link rel="canonical" href="https://swissmade.host/en/cms-hosting-switzerland" />
					<script type="application/ld+json">{JSON.stringify(richData)}</script>
				</Helmet>

				<div className="fullwidth pic cms">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">CMS Hosting<span>in Switzerland</span></h1>
								<h2>Privacy-focused Content Management System Hosting</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<AboutCMS cms={cms} />
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider />
				</ReactDependentScript>
				<DiscountCounter cms={cms} />
				<FreeSSL cms={cms} />
				<PaymentMethodsInclude />
			</React.Fragment>
		)
	}
	toggleAnimation = (e) => {
		document.getElementById('ddos-protection-info').classList.toggle('expanded')
	}
}

export default CMSswitzerland;