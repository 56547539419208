import React from 'react';
import {axiosinstance} from '../../components/axios';
import axios from 'axios';
import {renderCustomComponent, setQuickButtons } from 'react-chat-widget';
import SystemMessages from './systemMessages';

class DialogConfirmation extends React.Component {
    
    hideDialogs(){
        document.querySelectorAll(".rcw-dialog-message").forEach(function(element) {
        element.parentElement.remove()
        })}

    quitChat(){
        axiosinstance({
            method: 'post',
            url: this.props.requestURL,
            data:{
                act: 'close',
                referer: window.location.href
            },
            withCredentials: true,
            headers: {'Session-Token':this.props.sessionToken}
    }).then(res => {
        renderCustomComponent(SystemMessages, {'message': 'You have left the chat', 'value': 'quit'})
        this.hideDialogs()
        setQuickButtons([])
        this.props.refreshStateOnQuit(false);
    })
    }

    rateOperatorUp(){
        axiosinstance({
            method: 'post',
            url: this.props.requestURL,
            data:{
                act: 'rate',
                rate: true,
                referer: window.location.href
            },
            withCredentials: true,
            headers: {'Session-Token':this.props.sessionToken}
    }).then(res => {
        renderCustomComponent(SystemMessages, {'message': 'You have rated the chat operator with', 'rated': 'up'})
        this.hideDialogs()
    })}

    rateOperatorDown(){
        axiosinstance({
            method: 'post',
            url: this.props.requestURL,
            data:{
                act: 'rate',
                rate: false,
                referer: window.location.href
            },
            withCredentials: true,
            headers: {'Session-Token':this.props.sessionToken}
    }).then(res => {
        renderCustomComponent(SystemMessages, {'message': 'You have rated the chat operator with', 'rated': 'down'})
        this.hideDialogs()
    })}

    getInputValue(e){
       if (document.getElementById('image-file').files[0].type === 'image/jpeg' || document.getElementById('image-file').files[0].type === 'image/png' || document.getElementById('image-file').files[0].type === 'application/pdf')
       {if (document.getElementById('image-file').files[0].size < 5000000)
            {document.getElementById('fileName').innerHTML= '<i class="far fa-file-image"></i> ' + e.target.value.split("\\")[2]; document.getElementById('sendFile').disabled = false}
            else {document.getElementById('fileName').innerHTML = "<i class='fas fa-exclamation-triangle' style='color: #F2A900'></i> File exceeds 5 MB"; document.getElementById('sendFile').disabled = true}}
       else { document.getElementById('fileName').innerHTML = "<i class='fas fa-exclamation-triangle' style='color: #F2A900'></i> PNG, JPG &amp; PDF only"; document.getElementById('sendFile').disabled = true}
    }

    uploadFile(){
        
        if (document.querySelector('#image-file').value.length === 0)
        {document.querySelector('#fileName').innerHTML = "<i class='fas fa-exclamation-triangle' style='color: #F2A900'></i> Please select a file"}
        else { 
            document.querySelector('#fileName').innerHTML = "<i class='fas fa-sync fa-spin'></i> Uploading..."
            var formData = new FormData();
            var imagefile = document.querySelector('#image-file');
            formData.append("image", imagefile.files[0]);
            axios.post(this.props.uploadURL, formData, {
            withCredentials: true,
            headers: {'Session-Token':this.props.sessionToken}
        }).then(res => {
            if (res.data.result === true)
            {renderCustomComponent(SystemMessages, {'message': 'File has been successfully uploaded', 'value': 'file'})
            this.hideDialogs()}
            else {document.querySelector('#fileName').innerHTML = "<i class='fas fa-exclamation-triangle' style='color: #F2A900'></i> Upload error"}
        }).catch(() =>
        document.querySelector('#fileName').innerHTML = "<i class='fas fa-exclamation-triangle' style='color: #F2A900'></i> Upload error"
        )
        }}
        
     render() {

        if (this.props.value === 'quit')
        {return (
<div className={"rcw-dialog-message " + this.props.value}>
    <h4>{this.props.message}</h4>
    <p><button className="quick-button" onClick={this.quitChat.bind(this)}>QUIT</button> <button className="quick-button" onClick={this.hideDialogs.bind(this)}
    >CANCEL</button></p>
</div>
            )}
        if (this.props.value === 'upload')
            {return (
    <div className={"rcw-dialog-message " + this.props.value}>
        <h4>{this.props.message}</h4>
        <p><label htmlFor="image-file" className="rcw-file-upload">Select File</label><input id="image-file" onChange={this.getInputValue} type="file" /></p>
        <p><span className="fileName"><span id="fileName"><i className="far fa-file-image"></i> JPG, PNG &amp; PDF (5 MB)</span></span><button className="quick-button" id="sendFile" onClick={this.uploadFile.bind(this)}>SEND</button> <button className="quick-button" onClick={this.hideDialogs.bind(this)}>CANCEL</button></p>
    </div>
    
                )}
        if (this.props.value === 'thumbsUp' || this.props.value === 'thumbsDown')

        {return (
            <div className={"rcw-dialog-message " + this.props.value}>
                <h4>{this.props.message}</h4>
                <p><button className="quick-button" onClick={this.rateOperatorUp.bind(this)}><i className="far fa-thumbs-up"></i></button> <button className="quick-button" onClick={this.rateOperatorDown.bind(this)}><i className="far fa-thumbs-down"></i></button> <button className="quick-button" onClick={this.hideDialogs.bind(this)}
                >CANCEL</button></p>
            </div>
                        )}
        
        }}

export default DialogConfirmation
