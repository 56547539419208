import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink } from 'react-router-dom';

class FreeSpeechInSwitzerland extends React.Component {

	render() {

		return (
			<React.Fragment>
				<div className="fullwidth light">
					<div className="row">
						<div className="four columns">
							<NavLink to="/en/blog/Web-Hosting-for-Political-Activists-and-Opposition-Websites" title="Web Hosting for Political Activists and Opposition Websites"><LazyLoadImage src="/images/freedom-of-speech.png" alt="Free Speech Activism" title="Web Hosting for Political Activists and Opposition Websites" /></NavLink>
						</div>
						<div className="eight columns">
							<div className="row">
								<div className="twelve columns">
									<h2><NavLink to="/en/blog/Web-Hosting-for-Political-Activists-and-Opposition-Websites" title="Web Hosting for Political Activists and Opposition Websites">Share Your Ideas</NavLink>, Not Privacy</h2>
									<p>Trends showcase that oppressive regimes throughout the world constantly censor the internet activity of citizens, while also exercising their control on the press. This leads to constant misinformation, alongside the inability of citizens to learn more about what’s really happening within their country, or in some cases, in other regions of the world as well. Generally, oppressive regimes actively target freedom of speech, and press freedom, both of which are regarded as inalienable human rights.</p>
									<p>Political and social activists who live in countries with authoritarian regimes, criticize their government and promote free speech might be especially vulnerable to privacy and data breaches. We strongly believe that every person has voice and the right to express their thoughts securely while keeping their privacy intact. We are committed to protecting your privacy and inalienable rights and this is exactly what we've developed our web hosting platforms for.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="fullwidth dark">
					<div className="row">
						<div className="four columns">
							<h5>Political Independence &amp; Neutrality</h5>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa fa-institution fa-5x" aria-hidden="true"></i></div>
							<p>Switzerland has a long history as an independent liberal democracy with the oldest policy of military neutrality in the world. Even though Switzerland borders countries that constitute the European Union, it hasn't joined EU as a member state to preserve its neutrality and political independence.</p>
						</div>
						<div className="four columns">
							<h5>Freedom of Speech</h5>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa fa-bullhorn fa-5x" aria-hidden="true"></i></div>
							<p>Civil liberties are deeply respected in Switzerland and it has the highest <a href="https://freedomhouse.org/report/freedom-world/2019/switzerland" target='_blank' rel='noopener noreferrer'>Freedom in The World</a> score. Switzerland has a free media environment and freedom of speech is guaranteed by the Swiss Federal Constitution. In 2022 Switzerland had the 14th rank in the <a href="https://rsf.org/en/switzerland" target='_blank' rel='noopener noreferrer'>World Press Freedom Index</a> compiled by RSF.</p>
						</div>
						<div className="four columns">
							<h5>Developed Infrastructure</h5>
							<hr className="grey" />
							<div className="left paddingright"><i className="fa fa-hdd-o fa-5x" aria-hidden="true"></i></div>
							<p>Switzerland has a superior hosting infrastructure, backed by numerous high-quality data centers that leverage enterprise-level hardware and fulfil all industry standards. Our company operates from <NavLink to="/en/data-center-switzerland">Interxion™ Zurich data center</NavLink> which is an ultra-secure location for storing and processing data in line with Swiss data protection regulations.</p>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default FreeSpeechInSwitzerland