import React from 'react';
import Helmet from "react-helmet";
import DiscountInclude from '../../components/includes/discountInclude';
import PaymentMethodsInclude from '../../components/includes/paymentMethodsInclude';
import DynamicContent from './dynamicContent';
import FAQinclude from '../../components/includes/FAQinclude';
import LocationSwitzerland from '../../components/includes/locationSwitzerland';
import FeatureSlider from './featureSlider';
import ReactDependentScript from "react-dependent-script";
import CloudInfo from './cloudInfo';
import FreeSpeechInSwitzerland from '../free-speech-hosting/freeSpeechInSwitzerland';

class CloudFreeSpeech extends React.Component {

	render() {

		return (
			<React.Fragment>
				<Helmet>
					<meta name="description" content="Free speech cloud server hosting powered by VMware hypervisors, all-flash SSD array and 10 Gbps networking. Ultra-secure data center in Zurich, Switzerland." />
					<title>Free Speech Cloud Server Hosting | Swiss Made Host</title>
					<link rel="canonical" href="https://swissmade.host/en/free-speech-cloud" />
				</Helmet>
				<div className="fullwidth pic cloud">
					<div className="row">
						<div className="twelve columns">
							<div className="transparent">
								<h1 className="title">Freedom Of Speech <span>Cloud Hosting</span></h1>
								<h2>VMware®-powered Solutions in a Free Speech Safe Harbor</h2>
							</div>
						</div>
					</div>
				</div>
				<DiscountInclude />
				<FreeSpeechInSwitzerland />
				<LocationSwitzerland />
				<CloudInfo />
				<ReactDependentScript scripts={["/js/jquery-3.4.1.js"]}>
					<FeatureSlider />
				</ReactDependentScript>
				<DynamicContent type='all' />
				<FAQinclude category={'cloud'} color={'light'} />
				<PaymentMethodsInclude />
			</React.Fragment>
		)
	}
}

export default CloudFreeSpeech;