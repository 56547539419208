import React from 'react';
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from 'js-cookie';
import {axiosinstance} from '../axios';
import Navigation from './navigation';
import { toggleWidget } from 'react-chat-widget';

class Header extends React.Component {

    constructor(props, context) {
		super(props, context);

        this.state = {
			error: false,
            loading: false
        }
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      };

    componentDidMount () {
        this.load();
        this.refCheck();
    }

    load = () => {

        this.setState({
            loading: true
        }, () => {
        if (Cookies.get('session') === undefined || Cookies.get('session') === 'undefined') 
            {return null}
        else {this.getSessionWithToken()}
    })}

    getSessionWithToken = () => {
        axiosinstance({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + "/session.json",
            withCredentials: true,
            referer: window.location.href,
            headers: {'Session-Token': Cookies.get('session')}
    }).then(response => {
        if (response.status === 200 && response.data.result === true) {
            if (response.data.data.authorized === true)
            {
        this.setState({
        loggedIn: true,
        unreadEmails: response.data.data.unread_emails,
        clientName: response.data.data.fullname,
        balance: response.data.data.balance
        })}

        else {this.setState({
            loggedIn: false
            })}

    } 

    else {
        this.setState({
            loggedIn: false
        });
    }

    })
    }

    refCheck = () => {
        if (RegExp('\\bref=\\b').test(this.props.location.search) === true)
        {
           Cookies.get('ref_accepted') === undefined &&
            axiosinstance({
                    method: 'post',
                    url: process.env.REACT_APP_BACKEND_URL + "/livechat.en.json",
                    data: {
                        _tracker: 1,
                        ref: this.props.location.search.replace("?ref=", ""),
                        referer: document.referrer
                    },
                    withCredentials: true,
                    headers:{'X-Requested-With':'XMLHttpRequest'}
            }).then(response => {
                response.statusText === "OK" && Cookies.set('ref_accepted', true)
                })
            }
        }

    render() {
      return (
        <header>
        <div className="topmenu">
            <div className="row">
                <div className="twelve columns">
                    <ul className="topright">
                    <li className="chat"><a href="/en/" onClick={function(event){event.preventDefault();toggleWidget()}}><i className="fa-regular fa-comment-dots" aria-hidden="true"></i> LIVE CHAT 24x7</a></li>
                    <li className="contact"><NavLink to="/en/contact-us"><i className="fa-regular fa-envelope" aria-hidden="true"></i> CONTACT US</NavLink></li>
                    
                    {this.state.loggedIn === true
                        ? <React.Fragment>
                            <li className="login"><a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/ticket/list'}><i className="fa-solid fa-inbox"></i> UNREAD MESSAGES: {this.state.unreadEmails}</a></li>
                            <li className="login"><a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/account-history'}><i className="fa-solid fa-money-bills"></i>{this.state.balance} CHF</a></li>
                            <li className="login"><a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN + '/en/'}><i className="fa-regular fa-address-card" aria-hidden="true"></i> MY ACCOUNT</a></li>
                            </React.Fragment>
                        :  <li className="login last"><a href={'//' + process.env.REACT_APP_MEMBER_DOMAIN}><i className="fa-solid fa-right-to-bracket" aria-hidden="true"></i> CUSTOMER LOGIN</a></li>

                        }
              </ul>
                </div>
            </div>
        </div>
<Navigation/>
</header>
    );
  }
}

export default withRouter(Header);
