import React from 'react';
import { axiosinstance } from '../../components/axios';
import LoadingError from '../../components/loadingError';
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class BlogWidget extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            error: false,
            loading: true
        }
    }

    componentDidMount() {
        this.load()
    }

    load = () => {
        axiosinstance.get(process.env.REACT_APP_BACKEND_URL + '/blog.en.json?page=1&limit=2')
            .then(res => {
                if (res.status === 200) {
                    if (res.data.result === true) {
                        this.setState({
                            loading: false,
                            articlesWidgetData: res.data.data
                        })
                    }

                    else {
                        this.setState({
                            error: true,
                            loading: false
                        })
                    }
                }

                else {
                    this.setState({
                        error: true,
                        loading: false
                    });
                }

            })
            .catch(err => {
                this.setState({
                    error: true,
                    loading: false
                })
            })
    }

    removeElements = (raw) => {
        const doc = new DOMParser().parseFromString(raw.split('<break>')[0], 'text/html')
        const tagsToRemove = 'h1, h2, h3, h4, h5';
        for (const elm of doc.querySelectorAll('*')) {
            if (elm.matches(tagsToRemove)) {
                elm.remove();
            }
        }
        return doc.body.innerHTML
    }

    render() {

        if (this.state.loading) {
            return (
                <div className="fullwidth light">
                    <div className="row">
                        <div className="six columns">
                            <div className="panel light">
                                <Skeleton style={{ height: "400px" }} />
                                <h2 className="articleTitle"><Skeleton style={{ height: "50px" }} /></h2>
                                <ul className="bloginfo">
                                    <li><Skeleton count={2} /></li>
                                    <li><Skeleton count={2} /></li>
                                    <li><Skeleton count={2} /></li>
                                </ul>
                                <p><Skeleton style={{ height: "200px" }} /></p>
                            </div>
                        </div>
                        <div className="six columns">
                            <div className="panel light">
                                <Skeleton style={{ height: "400px" }} />
                                <h2 className="articleTitle"><Skeleton style={{ height: "50px" }} /></h2>
                                <ul className="bloginfo">
                                    <li><Skeleton count={2} /></li>
                                    <li><Skeleton count={2} /></li>
                                    <li><Skeleton count={2} /></li>
                                </ul>
                                <p><Skeleton style={{ height: "200px" }} /></p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.error) {
            return (
                <LoadingError />
            )
        } else {
            return (
                <div className="fullwidth light">
                    <div className="row blog-widget">
                        {this.state.articlesWidgetData.posts.map((item, i) =>
                                <div className="blog-widget-article" key={i}>
                                    <NavLink to={'/en/blog/' + encodeURIComponent(item.slug)}>
                                        <LazyLoadImage alt={item.title} height={600} src={item.image.length > 0 ? item.image : '/images/blog/' + item.id + '.jpg'} width={900} onError={(event) => event.target.setAttribute("style", "display: none")} />
                                    </NavLink>
                                    <div className="date text-center">{new Date(item.date).toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric" })}</div>
                                    <h2 className="articleTitle"><NavLink to={'/en/blog/' + encodeURIComponent(item.slug)}>{item.title}</NavLink></h2>
                                    <ul className="bloginfo">
                                        <li>By <NavLink className="blogLink" to={'/en/blog?author=' + encodeURIComponent(item.author)}>{item.author}</NavLink></li>
                                        <li>In <NavLink className="blogLink" to={'/en/blog?category=' + encodeURIComponent(item.category)}>{item.category}</NavLink></li>
                                        <li>Tags: {item.tags.map((item, i, array) =>
                                            <NavLink className="blogLink" to={'/en/blog?tag=' + encodeURIComponent(item)} key={i}>{i === array.length - 1 ? item : item + ', '}</NavLink>
                                        )}</li>
                                    </ul>
                                    <div dangerouslySetInnerHTML={{ __html: this.removeElements(item.content) }} />
                                    <NavLink className="button radius small" to={'/en/blog/' + encodeURIComponent(item.slug)}>Read full article</NavLink>
                                </div>
                        )}
                    </div>
                </div>


            )

        }


    }
}

export default BlogWidget
