import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';

class ColoConfigList extends Component {

    render() {

        const list = this.props.list,
        payment_period = this.props.payment_period,
        usdRate = this.props.usdRate,
        eurRate = this.props.eurRate

        return (
          <div className="fullwidth light">
	        <div className="row">
		    <div className="twelve columns">
            {list.map((item, i) =>
            <div className="row" key={i}>
            <div className="twelve columns">
                <div className="panel pricing">
                    <h3><span className="colored">{item.name}</span> Colocation Package</h3>
                    <hr className="black"/>
                    <div className="four columns">
                        {item.id === 10 && <p>This package is designed for owners of bare metal servers of up to two standard rack units in size who wish to collocate their equipment at a secure and innovative data center located right in the heart of Europe.</p>}
                        {item.id === 11 && <p>Running a bigger business and have a few bare metal servers and other hardware to collocate? This package provides enough space to fit up to 12 units of your equipment.</p>}
                        {item.id === 12 && <p>Enterprise-grade collocation solution for high-performing hardware that provides 42 standard rack units of space and includes 12 Amps of power and 32 IPv4 addresses.</p>}
                        <div className="row">
                            <div className="twelve columns monthly-fee margintop price-for-standard">
                                <span className="chf">
                                    <span className="val">
                                        {(payment_period === 1
                                            ? new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)
                                            : <React.Fragment><span style={{ color: '#D74937', textDecoration: 'line-through' }}><span style={{ color: '#4D4D4E' }}>{item.price.toFixed(2)} CHF</span></span><br/>
                                            {(item.price - (item.price / 100 * (payment_period - 1) * 2)).toFixed(2)} CHF
                                            </React.Fragment>
                                        )}
                                    </span></span>       
                                <span className="usd">
                                    <span className="val">{((item.price - (item.price / 100 * (payment_period - 1) * 2)) * usdRate).toFixed(2)}</span> USD </span>
                                    / 
                                    <span className="eur"><span className="val"> {((item.price - (item.price / 100 * (payment_period - 1) * 2)) * eurRate).toFixed(2)}</span> EUR</span>
                                    <br/> per month</div>
                        </div>
                        <hr className="black"/>
                        <div className="row ">
                            <div className="four columns">One-time Setup Fee:</div>
                            <div className="eight columns setup-fee">
                            {item.setup_fee === 0
                                ? <span className="chf">Free</span>
                                : <React.Fragment>
                                    <span className="chf">
                                {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.setup_fee)}
                                    </span> 
                                {(item.setup_fee * usdRate).toFixed(2)} USD / {(item.setup_fee * eurRate).toFixed(2)} EUR 
                                </React.Fragment>}
                          </div>
                        </div>
                        <span className="button radius large right showInfo" title="View details" onClick={this.toggleAnimation} animation-id={item.id}><i className="fa fa-info" aria-hidden="true" animation-id={item.id}></i></span><span className="link-for-standard"><a className="button radius large right" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + item.id + "?bill=" + payment_period} title="Configure and place order">Configure</a></span>
                    </div>
                    <div className="eight columns">
                        <ul className="dp">
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Rack space</span> <span>{item.config.space}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Bandwidth</span><span>{item.config.bandwidth.split(':').join(' / ')}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Bandwidth overage per TB</span> <span>{item.config.bandwidth_overage < 1
                              ? 'N/A'
                              : <React.Fragment><span style={{cursor:'default'}} data-tip data-for={"bandwidth-overage-" + item.id}>{item.config.bandwidth_overage} CHF <small>/month</small> <i className="fas fa-circle-question no-margin"></i></span>
                            <ReactTooltip id={"bandwidth-overage-" + item.id} effect='solid'>
                           {(item.config.bandwidth_overage * this.props.usdRate).toFixed(2)} USD <br/> {(item.config.bandwidth_overage * this.props.eurRate).toFixed(2)} EUR
                            </ReactTooltip>
                            </React.Fragment>
                            }</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Power 220v</span> <span className="value">{item.config.power.replace(':',' / ')}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Power overage per KWt</span>
                              <span>
                              {item.config.power_overage < 1
                                ? 'N/A'
                                : <React.Fragment><span style={{cursor:'default'}} data-tip data-for={"power-overage-" + item.id}>{item.config.power_overage} CHF <small>/month</small> <i className="fas fa-circle-question no-margin"></i></span>
                              <ReactTooltip id={"power-overage-" + item.id} effect='solid'>
                              {(item.config.power_overage * this.props.usdRate).toFixed(2)} USD <br/> {(item.config.power_overage * this.props.eurRate).toFixed(2)} EUR
                              </ReactTooltip>
                              </React.Fragment>
                              }
                              </span>
                            </li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Network</span><span>{item.config.network.replace(':',' / ')}</span></li>
                            <li><span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Min. billing cycle</span><span>{item.min_billing_cycle} month(s)</span></li>
                        </ul>
                        <ul className="nobullet co expandInfo" id={"details-"+item.id}>
                            <li><span className="name">Support</span><span>24/7</span></li>
                            <li><span className="name">Data center access</span><span>24/7</span></li>
                            <li><span className="name">KVM/IPMI</span><span>Free of charge</span></li>
                            <li><span className="name">Reboots</span><span>Free of charge</span></li>
                            <li><span className="name">DDoS protection</span><span>Available at extra cost</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        )}
            </div>
            </div>
            </div>
            )
    }
    toggleAnimation = (e) => {
      document.getElementById("details-"+e.target.getAttribute('animation-id')).classList.toggle('expanded')
  }
}

export default ColoConfigList;
