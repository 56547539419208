import React from "react";

const MapCompanyDataCenter = () => {

  return (
    <div className="fullwidth dark">
      <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className="three column">
          <h3>Company Location</h3>
          <p>Mürtschenstrasse 30<br />
            CH-8048 Zürich<br />
            Switzerland</p>
        </div>
        <div className="three column">
          <h3>Data Center Location</h3>
          <p>Sägereistrasse 35<br />
            CH-8152 Glattbrugg<br />
            Switzerland</p>
        </div>
      </div>
    </div>
  )
}

export default MapCompanyDataCenter;