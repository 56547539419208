import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class BlogPreLoader extends React.Component {
    render() { return (
    <div className="row margintop">
        <h4 className="searchTitle">Loading articles...</h4>
        <div className="twelve columns">
            <div className="panel light article">
                <p><Skeleton style={{height: '500px'}}/></p>
                <p><Skeleton style={{height: '50px'}}/></p>
                <p><Skeleton style={{height: '50px'}}/></p>
                <p><Skeleton style={{height: '300px'}}/></p>
            </div>
        </div>
    </div>
    ) }
}

export default BlogPreLoader;